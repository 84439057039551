<template>
  <div class="p-4 flex flex-col gap-4">
    <p class="text-2xl text-dark-gray">Help</p>
    <div class="flex flex-col md:flex-row gap-2">
      <div @click="clickFaq">
        <p class="text-lg underline underline-offset-2 cursor-pointer">FAQ</p>
      </div>
      <div @click="clickSupport">
        <p class="text-lg underline underline-offset-2 cursor-pointer">Support</p>
      </div>
    </div>
    <div>
      <div v-show="page === 'faq'">
        <FAQ />
      </div>
      <div v-show="page === 'support'">
        <Support />
      </div>
    </div>
  </div>
</template>

<script>
import FAQ from '@/components/Documentation/Help/FAQ.vue';
import Support from '@/components/Documentation/Help/Support.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Help',
  components: {
    FAQ,
    Support
  },
  computed: {
    ...mapGetters({
      page: 'helpStoreModule/getPage'
    })
  },
  methods: {
    clickFaq(){
      this.$store.commit('helpStoreModule/setPage', 'faq')
    },
    clickSupport(){
      this.$store.commit('helpStoreModule/setPage', 'support')
    },
  }
}
</script>