<template>
  <div class="container">
    <div class="register-container">
      <form @submit.prevent="register">
        <h2>Create A New Account</h2>
        <div class="name-container">
          <input type="text" v-model="firstName" placeholder="First Name" id="input-first-name" />
          <input type="text" v-model="lastName" placeholder="Last Name" id="input-last-name" />
        </div>
        <div class="email-container">
          <input type="email" v-model="email" placeholder="Email Address" id="input-email" />
        </div>
        <div class="password-container">
          <input type="password" v-model="password" placeholder="Password" id="input-password" />
          <input type="password" v-model="confirmPassword" placeholder="Confirm Password" id="input-confirm-password" />
        </div>
        <div class="birthday-container">
          <label for="dob" id="birthday-label">Birth Date</label>
          <select v-model="birthDateMonth" id="input-month">
            <option value="null" disabled selected>Month</option>
            <option v-for="option in ageMonthOptions" v-bind:value="option.id" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <select v-model="birthDateDay" id="input-day">
            <option value="null" disabled selected>Day</option>
            <option v-for="option in ageDayOptions" v-bind:value="option" :key="option">
              {{ option }}
            </option>
          </select>
          <select v-model="birthDateYear" id="input-year">
            <option value="null" disabled selected>Year</option>
            <option v-for="year in ageYearOptions" :value="year" :key="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="gender-container">
          <label id="gender-label">Gender</label>
          <select v-model="gender" id="input-gender">
            <option value="" disabled selected>Gender</option>
            <option v-for="option in genderOptions" v-bind:value="option" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div id="sign-up-button-container">
          <button id="button-sign-up">Sign Up</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Auth, Hub, I18n } from 'aws-amplify';
import store from '@/store/store';

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      birthDateMonth: null,
      birthDateDay: null,
      birthDateYear: null,
      gender: '',
      ageMonthOptions: [
        { name: 'January', id: '1' },
        { name: 'February', id: '2' },
        { name: 'March', id: '3' },
        { name: 'April', id: '4' },
        { name: 'May', id: '5' },
        { name: 'June', id: '6' },
        { name: 'July', id: '7' },
        { name: 'August', id: '8' },
        { name: 'September', id: '9' },
        { name: 'October', id: '10' },
        { name: 'November', id: '11' },
        { name: 'December', id: '12' },
      ],
      daysPerMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      genderOptions: ['Male', 'Female', 'Other', 'Prefer not to answer'],
    };
  },
  computed: {
    ageYearOptions() {
      const year = new Date().getFullYear();
      return Array.from({ length: year - 1900 }, (value, index) => 1901 + index);
    },
    ageDayOptions() {
      if (this.birthDateMonth === null) {
        return Array.from({ length: 31 }, (value, index) => 1 + index);
      } else {
        return Array.from({ length: this.daysPerMonth[this.birthDateMonth - 1] }, (value, index) => 1 + index);
      }
    },
  },
  setup() {},
  methods: {
    async register() {
      try {
        // store email so it can be used later for account verification
        store.state.userEmail = this.email;
        await Auth.signUp({
          username: this.email,
          password: this.password,
          attributes: {
            email: this.email,
            birthdate: this.birthDateYear + '-' + this.birthDateMonth + '-' + this.birthDateDay,
            family_name: this.lastName,
            given_name: this.firstName,
            gender: this.gender,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: auto;
  display: flex;
  justify-content: center;
}

.register-container {
  width: 580px;
}

.name-container {
  display: flex;
}

#input-first-name {
  flex: 1;
}

#input-last-name {
  flex: 1;
}

.email-container {
  display: flex;
}

#input-email {
  flex: 1;
}

.password-container {
  display: flex;
}

#input-password {
  flex: 1;
}

#input-confirm-password {
  flex: 1;
}

.birthday-container {
  display: flex;
}

#birthday-label {
  flex: 2;
}

#input-day {
  flex: 3;
}

#input-month {
  flex: 3;
}

#input-year {
  flex: 3;
}

.gender-container {
  display: flex;
}

#gender-label {
  flex: 2;
}

#input-gender {
  flex: 9;
}

#sign-up-button-container {
  display: flex;
  justify-content: center;
}
</style>
