<template>
  <div class="flex flex-col gap-3">
    <div class="text-dark-blue text-sm text-start uppercase font-bold">
      {{ this.aspectName }}
    </div>
    <div class="flex justify-between">
      <p class="text-light-gray italic text-sm">Worst</p>
      <p class="text-light-gray italic text-sm">Best</p>
    </div>
    <div class="slider">
      <input v-model="this.aspectRating" type="range" min="0" max="10" />
      <div class="flex flex-row justify-between pl-2 md:pl-1 pr-1 text-xs">
        <p>0</p>
        <p>1</p>
        <p>2</p>
        <p>3</p>
        <p>4</p>
        <p>5</p>
        <p>6</p>
        <p>7</p>
        <p>8</p>
        <p>9</p>
        <p>10</p>
      </div>
    </div>
    <textarea
      @focus="expandInput()"
      @blur="reduceInput()"
      v-model="this.aspectDescription"
      :style="`height: ${inputHeight}`"
      class="md:hidden placeholder-light-gray italic"
      placeholder="Expand on your rating (Optional)"
    ></textarea>
    <textarea
      @focus="expandInput()"
      @blur="reduceInput()"
      v-model="this.aspectDescription"
      class="hidden md:inline h-24 placeholder-light-gray italic"
      placeholder="Expand on your rating (Optional)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'EditRatingSlider',
  props: ['name', 'rating', 'description'],
  data() {
    return {
      aspectName: this.name,
      aspectRating: this.rating,
      aspectDescription: this.description,
      inputHeight: '40px',
    };
  },
  methods: {
    saveAspectData() {
      let newAspect = {
        name: this.aspectName,
        rating: this.aspectRating,
        description: this.aspectDescription,
        selected: true,
      };
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === this.aspectName) {
          this.aspects[i] = newAspect;
        }
      }
    },
    expandInput() {
      this.inputHeight = '80px';
    },
    reduceInput() {
      this.inputHeight = '40px';
    },
  },
  computed: {
    aspects: {
      get() {
        return this.$store.getters['editPostStoreModule/getAspects'];
      },
      set(aspects) {
        this.$store.commit('editPostStoreModule/setAspects', aspects);
      },
    },
  },
};
</script>

<style scoped>
input[type='range'] {
  height: 16px;
  -webkit-appearance: none;
  width: 100%;
  border: 1px solid transparent;
  padding: 0;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: linear-gradient(268.88deg, #e5fafb 33.57%, #fee2da 78.71%);
  border-radius: 10px;
}

/* slider */
input[type='range']::-webkit-slider-thumb {
  height: 10px;
  width: 20px;
  border-radius: 10px;
  background: #012337;
  cursor: pointer;
  -webkit-appearance: none;
}
</style>
