<template>
  <div>
    <router-link :to="{ name: 'ViewSlydepost', params: { postId: id } }">
      <div class="flex flex-row gap-1 items-center">
        <img class="w-12 h-12 rounded-xl" :src="presignedUrl" alt="" />
        <MetaRatingCircle :meta-rating="this.metaRating" />
        <div class="flex flex-col">
          <div class="flex flex-row items-center overflow-hidden">
            <p class="whitespace-nowrap text-[11px]">{{ this.category }}</p>
            <p class="whitespace-nowrap text-[11px]" v-if="this.subcategory != null">&nbsp;> {{ this.subcategory }}</p>
            <p class="whitespace-nowrap text-[11px]">&nbsp;|&nbsp;</p>
            <p class="text-[11px] text-blue line-clamp-1">
              {{ formattedAspects }}
            </p>
          </div>
          <div class="flex flex-row items-center overflow-hidden">
            <p class="font-bold capitalize line-clamp-1">{{ title }}</p>
          </div>
          <div class="flex flex-row gap-1">
            <p class="whitespace-nowrap text-[11px]" v-if="username != null">@{{ username }}</p>
            <p class="whitespace-nowrap text-[11px]" v-else>{{ firstName }} {{ lastName }}</p>
            <p class="whitespace-nowrap text-[11px]" v-if="this.location != null">
              {{ this.location }}
            </p>
            <p class="text-[11px] line-clamp-1">{{ this.date }}</p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import MetaRatingCircle from '@/components/ViewSlydepost/MetaRatingCircle.vue';
import slydepostLogo from '@/assets/no-photo-background-square.png';

export default {
  name: 'PostSearchResult',
  props: {
    id: String,
    metaRating: Number,
    category: String,
    subcategory: String,
    title: String,
    imageUrl: String,
    location: String,
    date: String,
    aspects: Array,
    username: String,
    firstName: String,
    lastName: String,
  },
  components: {
    MetaRatingCircle,
  },
  computed: {
    presignedUrl() {
      if (this.imageUrl != null) {
        return this.imageUrl;
      } else {
        return slydepostLogo;
      }
    },
    formattedAspects() {
      let aspectString = '';
      this.aspects.forEach((aspect) => {
        aspectString += `${aspect.name}, `;
      });
      aspectString = aspectString.substring(0, aspectString.length - 2);
      return aspectString;
    },
  },
};
</script>
