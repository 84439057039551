<template>
  <div class="flex flex-row gap-2">
    <div class="grid grid-rows-2 grid-cols-2 w-10 h-10">
      <div></div>
      <div class="border-dotted border-l border-l-teal border-b border-b-teal"></div>
      <div></div>
      <div></div>
    </div>
    <div class="w-full">
      <div class="w-full flex flex-row gap-2 items-start">
        <EditCommentModal
          :showModal="this.showEditModal"
          :text="this.comment"
          :message="'Edit your comment'"
          @edit="this.updateReply"
          @cancel="this.showEditModal = false"
        />
        <AreYouSureModal
          :showModal="this.showDeleteModal"
          :message="'Are you sure you want to delete your comment?'"
          @accept="this.deleteReply"
          @cancel="this.showDeleteModal = false"
        />
        <div>
          <img v-lazy="getProfilePictureUrl" alt="Avatar" class="h-10 w-10 rounded-full" />
        </div>
        <div class="w-full flex flex-col gap-1">
          <div class="flex justify-between">
            <router-link :to="`/profile/${this.userId}`">
              <div class="capitalize font-bold">
                <p class="text-blue text-sm">{{ this.userFirstName }} {{ this.userLastName }}</p>
              </div>
            </router-link>
            <div class="flex items-center gap-2">
              <MoreOptionsButton
                v-click-outside="closeOptions"
                @click="this.showOptions = !this.showOptions"
                @edit="this.showEditModal = true"
                @delete="this.showDeleteModal = true"
                :options="this.userOwnsComment"
                :showOptions="this.showOptions"
                :icon-color="'black'"
              />
              <p class="text-sm">
                {{ this.formatCommentDate(this.createdAt) }}
              </p>
            </div>
          </div>
          <div class="flex w-full justify-between">
            <div class="flex justify-center items-center">
              <p class="text-sm">{{ this.existingComment }}</p>
            </div>
            <div class="flex items-center">
              <div @click="this.showReplyInput = !this.showReplyInput" class="italic font-normal mr-2 cursor-pointer">
                <p class="text-blue text-sm">Reply</p>
              </div>
              <div @click="this.like()" class="h-6 w-6 cursor-pointer">
                <div
                  class="rounded-full border border-solid border-blue flex items-center justify-center text-blue h-6 w-6"
                >
                  <div class="bg-transparent flex justify-center align-middle">
                    <font-awesome-icon v-if="userInLikesList" class="text-xs text-blue" icon="heart" />
                    <font-awesome-icon v-else class="text-xs" icon="fa-regular fa-heart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="relative">
            <textarea
              v-if="this.showReplyInput === true"
              v-model="this.replyComment"
              type="text"
              class="h-12"
              :placeholder="'Reply'"
              @keydown.enter="this.replyToReply()"
            />
            <p
              v-if="replyComment.length > 0"
              @click="this.replyToReply"
              class="absolute bottom-5 right-4 text-blue font-bold"
            >
              Post
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';
import EditCommentModal from '@/components/Modals/EditCommentModal.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import client from '@/axios/client';
import { uuid } from 'vue-uuid';

export default {
  name: 'SlydepostCommentReply',
  emits: ['reply', 'deleteReply', 'likeReply', 'deleteLikeReply'],
  props: [
    'id',
    'parentId',
    'postId',
    'userId',
    'profilePhotoUrl',
    'userFirstName',
    'userLastName',
    'createdAt',
    'comment',
    'likes',
    'childComments',
    'postOwnerId',
  ],
  data() {
    return {
      existingComment: '',
      replyComment: '',
      showReplyInput: false,
      showOptions: false,
      showEditModal: false,
      showDeleteModal: false,
      showMoreReplies: false,
      isUpdatingReply: false,
      isReplyingToReply: false,
      isLikingReply: false,
      // likes: [],
      userOwnsCommentOptions: [
        {
          text: 'Edit',
          onClickEmit: 'edit',
        },
        {
          text: 'Delete',
          onClickEmit: 'delete',
        },
      ],
      userNotOwnsCommentOptions: [],
    };
  },
  components: { MoreOptionsButton, EditCommentModal, AreYouSureModal },
  methods: {
    closeOptions() {
      this.showOptions = false;
    },
    formatCommentDate(created) {
      let createdAt = new Date(this.createdAt);
      const createdAtUtc = Date.UTC(
        createdAt.getFullYear(),
        createdAt.getMonth(),
        createdAt.getDate(),
        createdAt.getHours(),
        createdAt.getMinutes(),
        createdAt.getSeconds()
      );
      createdAt = new Date(createdAtUtc);

      const currentTime = new Date();
      const difference = currentTime - createdAt;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      if (days > 0) {
        return `${days}d`;
      }
      if (hours > 0) {
        return `${hours}h`;
      }
      if (minutes > 0) {
        return `${minutes}m`;
      }
      return `${seconds}s`;
    },
    updateReply(editedComment) {
      if (this.isUpdatingReply === true) {
        return;
      }
      if (this.existingComment.trim() === '') {
        return;
      }
      this.isUpdatingReply = true;
      this.existingComment = editedComment;
      this.showEditModal = false;
      const id = this.id;
      const postId = this.postId;
      const userId = this.loggedInUserId;
      const comment = this.existingComment;
      const jsonBody = JSON.stringify({
        id,
        postId,
        userId,
        comment,
      });
      client
        .put(`/post/comment`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          this.isUpdatingReply = false;
        })
        .catch((res) => {
          console.error(`Error update comment response: ${res.data}`);
          this.isUpdatingReply = false;
        });
    },
    deleteReply() {
      this.showDeleteModal = false;
      const userId = this.loggedInUserId;
      this.$emit('deleteReply', this.id);
      client
        .delete(`/post/comment/${this.id}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {})
        .catch((res) => {
          console.error(`Error delete comment response: ${res.data}`);
        });
    },
    replyToReply() {
      if (this.isReplyingToReply === true) {
        return;
      }
      if (this.replyComment.trim() === '') {
        return;
      }
      this.isReplyingToReply = true;
      const postId = this.postId;
      const userId = this.loggedInUserId;
      const comment = this.replyComment;

      let notification = {
        postId: this.postId,
        friendId: this.loggedInUserId,
        comment: this.replyComment,
        parentComment: this.comment,
        userId: this.userId,
        friendProfilePictureUrl: this.$store.getters['getS3Url'],
        friendFirstName: this.$store.getters['getFirstName'],
        friendLastName: this.$store.getters['getLastName'],
        resized: this.$store.getters['getIsResized'],
      };

      // if the parentId is not null, the reply will go under the existing parent meaning this
      // comment is already a reply.
      // if parentId is null, the reply is going as a parent of this comment
      const parentId = this.parentId != null ? this.parentId : this.id;
      const jsonBody = JSON.stringify({
        parentId,
        postId,
        userId,
        comment,
        notification,
      });
      client
        .post(`/post/comment`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          response.data.userFirstName = this.loggedInUserFirstName;
          response.data.userLastName = this.loggedInUserLastName;
          response.data.presignedUrl = this.loggedInUserProfilePhotoUrl;
          this.$emit('reply', response.data);
          this.showReplyInput = false;
          this.replyComment = '';
          this.isReplyingToReply = false;
        })
        .catch((res) => {
          console.error(`Error comment reply response: ${res.data}`);
          this.isReplyingToReply = false;
        });
    },
    like() {
      const userId = this.loggedInUserId;

      // if likes has current user in it, remove the like
      if (this.likes != null) {
        for (let i = 0; i < this.likes.length; i++) {
          if (this.likes[i].userId == userId) {
            const likeId = this.likes[i].id;
            this.deleteLikeReply(likeId);
            return;
          }
        }
      }
      // user is not in likes list, add them
      this.likeReply();
    },
    likeReply() {
      if (this.isLikingReply === true) {
        return;
      }
      if (this.id == null || this.id === '') {
        return;
      }
      this.isLikingReply = true;
      const commentId = this.id;
      const userId = this.loggedInUserId;
      let notification = {
        commentId: this.id,
        friendId: this.loggedInUserId,
        comment: this.comment,
        userId: this.userId,
        friendProfilePictureUrl: this.$store.getters['getS3Url'],
        friendFirstName: this.$store.getters['getFirstName'],
        friendLastName: this.$store.getters['getLastName'],
        resized: this.$store.getters['getIsResized'],
      };
      const jsonBody = JSON.stringify({
        commentId,
        userId,
        notification,
      });
      client
        .post(`/post/comment/like`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          const payload = {
            id: response.data.id,
            parentId: this.parentId,
            commentId: response.data.commentId,
            userId: response.data.userId,
            postId: this.postId,
          };
          this.$emit('likeReply', payload);
          this.isLikingReply = false;
        })
        .catch((res) => {
          this.isLikingReply = false;
        });
    },
    deleteLikeReply(likeId) {
      if (likeId == null || likeId === '') {
        return;
      }
      const payload = {
        id: likeId,
        parentId: this.parentId,
        commentId: this.id,
        postId: this.postId,
      };
      this.$emit('deleteLikeReply', payload);
      const userId = this.loggedInUserId;
      client
        .delete(`/post/comment/like/${likeId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {})
        .catch((response) => {
          console.error(`Unable to remove like.`, response);
        });
    },
  },
  computed: {
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
    loggedInUserFirstName: {
      get() {
        return this.$store.getters['authStoreModule/getFirstName'];
      },
    },
    loggedInUserLastName: {
      get() {
        return this.$store.getters['authStoreModule/getLastName'];
      },
    },
    loggedInUserProfilePhotoUrl: {
      get() {
        return this.$store.getters['getProfilePictureUrl'];
      },
    },
    getProfilePictureUrl() {
      if (this.profilePhotoUrl != null && this.profilePhotoUrl !== '') {
        return this.profilePhotoUrl;
      } else {
        return 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
      }
    },
    userInLikesList() {
      if (this.likes == null) {
        return false;
      }
      const userId = this.loggedInUserId;
      return this.likes.filter((like) => like.userId === userId).length > 0;
    },
    userOwnsComment() {
      const userId = this.loggedInUserId;
      return this.userId === userId ? this.userOwnsCommentOptions : this.userNotOwnsCommentOptions;
    },
    limitedReplies() {
      return this.childComments.slice(0, 2);
    },
  },
  mounted() {
    this.existingComment = this.comment;
  },
};
</script>

<style scoped></style>
