<template>
  <div v-if="this.showChecker" :key="this.showChecker" class="password-checker-container">
    <div class="flex flex-col gap-0.5">
      <transition-group name="list">
        <div v-for="error in passwordValidation.errors" :key="error" class="list-none p-0 m-0 text-xs">
          {{ error }}
        </div>
      </transition-group>
    </div>
    <transition name="list">
      <div class="text-xs" v-if="notSamePasswords">
        <p>Passwords don't match.</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PasswordChecker',
  props: ['password', 'checkPassword', 'showChecker'],
  data() {
    return {
      rules: [
        { message: 'One lowercase letter required.', regex: /[a-z]+/ },
        { message: 'One uppercase letter required.', regex: /[A-Z]+/ },
        { message: '8 characters minimum.', regex: /.{8,}/ },
        { message: 'One number required.', regex: /[0-9]+/ },
      ],
      passwordVisible: false,
      submitted: false,
    };
  },
  methods: {},
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.password !== this.checkPassword;
      } else {
        return false;
      }
    },
    passwordsFilled() {
      return this.password !== '' && this.checkPassword !== '';
    },
    passwordValidation() {
      let errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors };
      } else {
        return { valid: false, errors };
      }
    },
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
