import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const listsStoreModule = {
  namespaced: true,
  state: {
    addToListModalPayload: {
      show: false,
      postId: '',
      fromCreateSlydepost: false,
      selectedListsForNewSlydepost: [],
    },
    loadingMyLists: false,
    loadingFollowedLists: false,
    loadingFollowRequests: false,
    myLists: [],
    myListsSize: 0,
    followedLists: [],
    followedListsSize: 0,
    followRequests: [],
    followRequestsSize: 0,
    listData: {},
    postsData: [],
    view: 'myLists',
    draftId: '',
  },
  getters: {
    getView(state) {
      return state.view;
    },
    getMyListsSize(state) {
      return state.myListsSize;
    },
    getFollowedListsSize(state) {
      return state.followedListsSize;
    },
    getLoadingMyLists(state) {
      return state.loadingMyLists;
    },
    getLoadingFollowedLists(state) {
      return state.loadingFollowedLists;
    },
    getLoadingFollowRequests(state) {
      return state.loadingFollowRequests;
    },
    getMyList(state) {
      return state.myLists;
    },
    getFollowedLists(state) {
      return state.followedLists;
    },
    getFollowRequests(state) {
      return state.followRequests;
    },
    getFollowRequestsSize(state) {
      return state.followRequestsSize;
    },
    getMyListsCount(state) {
      return state.myLists.length;
    },
    getFollowedListsCount(state) {
      return state.followedLists.length;
    },
    getListPosts(state) {
      return state.listData?.posts || [];
    },
    getListFollows(state) {
      return state.listData?.follows?.length || 0;
    },
    getListTotalPosts(state) {
      return state.listData?.totalPosts || 0;
    },
    getListDescription(state) {
      return state.listData?.description || '';
    },
    getListTitle(state) {
      return state.listData?.title || '';
    },
    getUserName(state) {
      return state.listData?.username || 0;
    },
    getPostCount(state) {
      return state.listData?.listPosts?.length || 0;
    },
    getListUpdatedate(state) {
      const data = state.listData?.updatedAt.split(' ');
      return `${data[1]} ${data[2]}, ${data[5]}`;
    },
    getUserProfileImg(state) {
      return state.listData?.userProfilePictureUrl || '';
    },
    getlistImage(state) {
      return state.listData?.url || '';
    },
    getPostData(state) {
      return state.postsData;
    },
    getAddToListModalPayload(state) {
      return state.addToListModalPayload;
    },
    getListId(state) {
      return state.listId;
    },
    getTitle(state) {
      return state.title;
    },
    getDescription(state) {
      return state.description;
    },
    getStatus(state) {
      return state.status || 'PUBLIC';
    },
    getFile(state) {
      return state.file;
    },
    getExistingImageUrl(state) {
      return state.existingImageUrl;
    },
    getListDraftId(state) {
      return state.draftId;
    },
    getUrl(state) {
      return state.url;
    },
  },
  mutations: {
    setListId(state, id) {
      state.listId = id;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setFile(state, file) {
      state.file = file;
    },
    setExistingImageUrl(state, image) {
      state.existingImageUrl = image;
    },
    setView(state, view) {
      state.view = view;
    },
    setMyListsSize(state, size) {
      state.myListsSize = size;
    },
    setFollowedListsSize(state, size) {
      state.followedListsSize = size;
    },
    setLoadingMyLists(state, loading) {
      state.loadingMyLists = loading;
    },
    setLoadingFollowedLists(state, loading) {
      state.loadingFollowedLists = loading;
    },
    setLoadingFollowRequests(state, loading) {
      state.loadingFollowRequests = loading;
    },
    addLoadedMyLists(state, payload) {
      if (state.myLists == null) {
        state.myLists = [];
      }
      state.myLists.push(payload);
    },
    addLoadedMyListsAtFront(state, payload) {
      if (!state.myLists) {
        state.myLists = [];
      }
      state.myLists.unshift(payload);
    },

    addLoadedFollowedLists(state, payload) {
      if (state.followedLists == null) {
        state.followedLists = [];
      }
      state.followedLists.push(payload);
    },
    addFollowRequest(state, payload) {
      if (state.followRequests == null) {
        state.followRequests = [];
      }
      state.followRequests.push(payload);
    },
    setFollowRequestsSize(state, size) {
      state.followRequestsSize = size;
    },
    setListData(state, payload) {
      state.listData = payload;
    },
    clearListData(state) {
      state.listData = {};
    },
    setDraft(state, payload) {
      state.draftId = payload.id;
      state.title = payload.title;
      state.description = payload.description;
      state.file = null;
      state.status = payload.status;
      state.existingImageUrl = payload.presignedUrl || '';
      state.url = payload.url;
    },
    clearDraft(state) {
      state.draftId = '';
      state.title = '';
      state.description = '';
      state.file = null;
      state.status = '';
      state.existingImageUrl = '';
      state.url = '';
    },
    clear(state) {
      state.myLists = [];
      state.followedLists = [];
      state.followRequests = [];
      state.addToListModalPayload = {
        show: false,
        postId: '',
        fromCreateSlydepost: false,
        selectedListsForNewSlydepost: [],
      };
      state.followRequestsSize = 0;
      state.followedListsSize = 0;
      state.myListsSize = 0;
    },
    setAddToListModalPayload(state, payload) {
      state.addToListModalPayload = payload;
    },
    addPostIds(state, payload) {
      let i = state.myLists.findIndex((e) => e.id === payload.listId);
      !state.myLists[i].listPosts ? (state.myLists[i]['listPosts'] = []) : null;
      state.myLists[i].listPosts.push({
        ...payload,
        listTitle: state.myLists[i].title,
      });
    },
    removePostIds(state, payload) {
      // post was not removed from AddToListModal
      if (payload.listPostIds == null) {
        return;
      }
      let i = state.myLists.findIndex((e) => e.id === payload.listId);
      for (let j = 0; j < payload.listPostIds.length; j++) {
        state.myLists[i].listPosts = state.myLists[i].listPosts.filter(
          (e) => e.listId !== payload.listId && e.postId !== payload.listPostIds[j]
        );
      }
    },
    removeFollowRequest(state, id) {
      if (id == null) {
        return;
      }
      if (state.followRequests == null || state.followRequests.length === 0) {
        return;
      }
      for (let i = 0; i < state.followRequests.length; i++) {
        if (state.followRequests[i].id === id) {
          state.followRequests.splice(i, 1);
          state.followRequestsSize -= 1;
          return;
        }
      }
    },
    removeListFromMyLists(state, listId) {},
    clearListsFromCreateSlydepost(state) {
      state.addToListModalPayload.show = false;
      state.addToListModalPayload.postId = '';
      state.addToListModalPayload.fromCreateSlydepost = false;
      state.addToListModalPayload.selectedListsForNewSlydepost = [];
    },
  },
  actions: {
    async fetchMyList({ dispatch, commit, getters, rootGetters }) {
      console.log(`getting lists`)
      if (getters['getLoadingMyLists'] === true) {
        return;
      }
      commit('setLoadingMyLists', true);
      const userId = rootGetters['authStoreModule/getUserId'];
      return client
        .get(`/post/list/user/${userId}?startingIndex=${getters.getMyList.length}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.lists.length; i++) {
            commit('addLoadedMyLists', response.data.lists[i]);
          }
          console.log(`got lists`)
          commit('setMyListsSize', response.data.total);
          commit('setLoadingMyLists', false);
        })
        .catch((error) => {
          console.error(`error loading lists`, error);
          commit('setLoadingMyLists', false);
        });
    },
    async fetchFollowedLists({ dispatch, commit, getters, rootGetters }) {
      if (getters['getLoadingFollowedLists'] === true) {
        return;
      }
      commit('setLoadingFollowedLists', true);
      const userId = rootGetters['authStoreModule/getUserId'];
      await client
        .get(`/post/list/following/${userId}?startingIndex=${getters.getFollowedLists.length}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.lists.length; i++) {
            commit('addLoadedFollowedLists', response.data.lists[i]);
          }
          commit('setFollowedListsSize', response.data.total);
          commit('setLoadingFollowedLists', false);
        })
        .catch((error) => {
          console.error(`Unable to loaded followed lists`, error);
          commit('setLoadingFollowedLists', false);
        });
    },
    async getFollowRequests({ dispatch, commit, getters, rootGetters }, payload) {
      if (getters['getLoadingFollowRequests'] === true) {
        return;
      }
      commit('setLoadingFollowRequests', true);
      const userId = rootGetters['authStoreModule/getUserId'];
      await client
        .get(`/post/list/follow/requests/${userId}?startingIndex=${getters.getFollowedLists.length}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.requests.length; i++) {
            commit('addFollowRequest', response.data.requests[i]);
          }
          commit('setFollowRequestsSize', response.data.total);
          commit('setLoadingFollowRequests', false);
        })
        .catch((error) => {
          console.error(`Unable to loaded list follow requests`, error);
          commit('setLoadingFollowRequests', false);
        });
    },
    async createListByLoggedInUser({ dispatch, commit, getters, rootGetters }, payload) {
      const userId = rootGetters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      const title = payload.title;
      const description = payload.description;
      const status = payload.status;
      const userFirstName = rootGetters['authStoreModule/getFirstName'];
      const userLastName = rootGetters['authStoreModule/getLastName'];
      const userProfilePictureUrl = rootGetters['getS3Url'];
      const isFromDraft = payload.isFromDraft || false;
      let url = undefined;
      if (payload.file && payload.file) {
        url = payload.file.name;
      } else {
        url = payload.url;
      }
      let jsonBody = '';
      if (!payload.file) {
        const draftUrl = url;
        jsonBody = JSON.stringify({
          draftUrl,
          title,
          description,
          userId,
          userFirstName,
          userLastName,
          userProfilePictureUrl,
          status,
        });
      } else {
        jsonBody = JSON.stringify({
          url,
          title,
          description,
          userId,
          userFirstName,
          userLastName,
          userProfilePictureUrl,
          status,
        });
      }
      return await client
        .post(`/post/list`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then(async (response) => {
          const { data } = response;
          if (data.presignedUrlForUpload) {
            await client
              .put(data.presignedUrlForUpload, payload.file, {
                headers: {
                  'Content-Type': 'image/jpeg',
                },
              })
              .then((response) => {})
              .catch((response) => {
                console.error(`could not upload`, response);
              });
          }
          if (payload.file != null) {
            response.data.presignedUrl = URL.createObjectURL(payload.file);
          }
          response.data.userProfilePicturePresignedUrl = rootGetters['getProfilePictureUrl'];
          commit('addLoadedMyListsAtFront', response.data);
          commit('setLoadingMyLists', false);
          return response.data;
        })
        .catch((error) => {
          console.error('Unable to create a new list', error);
          return undefined;
        });
    },
    // This will trigger when an user tries to follow a list
    async followedLists({ dispatch, commit, getters, rootGetters }, payload) {
      const userId = rootGetters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      const listId = payload.listId;
      const userFirstName = payload.userFirstName;
      const userLastName = payload.userLastName;
      const status = payload.status;
      const jsonBody = JSON.stringify({
        listId,
        userId,
        userFirstName,
        userLastName,
        status,
      });
      client
        .post(`post/list/follow`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
        });
    },
    async addPostIdsToList({ dispatch, commit, getters, rootGetters }, payload) {
      const userId = rootGetters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      const listId = payload.listId;
      const postIds = payload.postIds;
      const listNotificationPayload = payload.notification;
      const jsonBody = JSON.stringify({
        listId,
        postIds,
        listNotificationPayload,
      });
      client
        .put(`/post/list/add`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('addPostIds', { ...response.data[0], listUserId: userId });
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
        });
    },
    async removePostsFromList({ dispatch, commit, getters, rootGetters }, payload) {
      const userId = rootGetters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      const listId = payload.listId;
      const listPostIds = payload.listPostIds;
      const jsonBody = JSON.stringify({
        listId,
        listPostIds,
      });
      client
        .put(`/post/list/remove`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('removePostIds', payload);
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
        });
    },
    acceptFollowRequest({ dispatch, commit, getters, rootGetters }, id) {
      const userId = rootGetters['authStoreModule/getUserId'];
      const jsonBody = JSON.stringify({
        listFollowId: id,
        status: 'FOLLOWING',
      });
      client
        .put(`/post/list/follow`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('removeFollowRequest', id);
        })
        .catch((error) => {
          console.error('Unable to accept follow request', error);
        });
    },
    ignoreFollowRequest({ dispatch, commit, getters, rootGetters }, id) {
      const userId = rootGetters['authStoreModule/getUserId'];
      const jsonBody = JSON.stringify({
        listFollowId: id,
        status: 'REJECTED',
      });
      client
        .put(`/post/list/follow`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('removeFollowRequest', id);
        })
        .catch((error) => {
          console.error('Unable to accept follow request', error);
        });
    },
  },
};

// export the entire module
export { listsStoreModule };
