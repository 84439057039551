<template>
  <div class="flex flex-col gap-4 md:gap-6 w-full px-4 py-4">
    <div class="flex flex-col gap-2">
      <div class="flex flex-col md:flex-row items-start justify-center md:justify-start md:items-center gap-4">
        <img class="hidden md:inline w-12 h-12 rounded-2xl" :src="profilePictureUrl" />
        <div class="flex flex-row justify-between w-full items-center">
          <p class="review-text text-lg text-blue">What do you want to review?</p>
          <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
        </div>
      </div>
      <div v-show="this.showMissingTitleError === true">
        <p class="text-red-500">{{ this.missingTitleErrorMessage }}</p>
      </div>
      <div>
        <input v-model="this.title" type="text" placeholder="Title" class="text-blue" />
      </div>
    </div>
    <div>
      <div class="flex flex-col gap-2">
        <p class="text-start text-lg text-blue">Choose a category</p>
        <div v-show="this.showMissingCategoryError === true">
          <p class="text-red-500">{{ this.missingCategoryErrorMessage }}</p>
        </div>
        <div class="relative h-8">
          <CategoryDropdown class="absolute w-full" :placeholder="'Find a category'" />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        <p class="text-start text-lg text-blue mt-4">Tell us more</p>
      </div>
      <div>
        <input v-model="this.address" class="text-blue" type="text" placeholder="Location" />
      </div>
      <div>
        <input v-model="this.link" class="text-blue" type="text" placeholder="Link" />
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <p class="text-sm">Select up to 5 photos</p>
      <UploadMedia :text="'Upload Media'" />
    </div>
    <div>
      <div class="relative float-right flex flex-row gap-2 items-center">
        <LoaderButton
          :icon-left-side="false"
          :show-error-icon="false"
          :status="createDraftStatus"
          :waiting-text="''"
          :in-progress-text="'Saving'"
          :done-text="'Saved!'"
          :error-text="'Try Again?'"
          :waiting-icon="'save'"
          @click="createDraft"
        />
        <font-awesome-icon
          @click="nextModal"
          class="text-3xl cursor-pointer"
          :color="navigationArrowColor"
          :icon="['fas', 'arrow-right']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UploadMedia from '@/components/CreateSlydepost/CreateSlydepostUploadMedia.vue';
import CategoryDropdown from '@/components/CreateSlydepost/CategoryDropdown.vue';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import { mapGetters } from 'vuex';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore';

export default {
  name: 'CreateSlydepostInfo',
  emits: ['next'],
  data() {
    return {
      showMissingTitleError: false,
      showMissingCategoryError: false,
      missingTitleErrorMessage: 'Please enter a title',
      missingCategoryErrorMessage: 'Please select a category',
    };
  },
  components: { CategoryDropdown, UploadMedia, LoaderButton },
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  methods: {
    nextModal() {
      console.log(`going to next modal`);
      if (this.title == null || this.title.trim() === '') {
        this.showMissingTitleError = true;
        return;
      }
      if (this.category == null || this.category === '') {
        this.showMissingCategoryError = true;
        return;
      }
      this.$emit('next');
    },
    async createDraft() {
      if (this.createDraftStatus === 'inProgress') {
        return;
      }
      this.createDraftStatus = 'inProgress';
      // check if draft should be created or updated
      let draftId = this.$store.getters['createPostStoreModule/getDraftId'];
      // create draft if there is no draft id
      if (draftId == null || draftId === '') {
        let payload = this.getCurrentSlydepostData();
        let newDraft = await this.slydepostDraftStore.addDraft(payload);
        if (newDraft !== undefined) {
          this.slydepostDraftStore.slydepostDrafts.unshift(newDraft);
        }
        this.createDraftStatus = 'done';
        // push drafts to router
        this.$emit('draft');
      }
      // update draft
      else {
        const draftData = this.getCurrentSlydepostData();
        draftData.id = this.$store.getters['createPostStoreModule/getDraftId'];
        const draftStore = useSlydepostDraftStore();
        await draftStore.updateDraft(draftData);
        this.$emit('draft');
      }
    },
    getCurrentSlydepostData() {
      return {
        title: this.$store.getters['createPostStoreModule/getTitle'],
        address: this.$store.getters['createPostStoreModule/getAddress'],
        link: this.$store.getters['createPostStoreModule/getLink'],
        category: this.$store.getters['createPostStoreModule/getCategory'],
        subcategory: this.$store.getters['createPostStoreModule/getSubcategory'],
        files: this.$store.getters['createPostStoreModule/getFiles'],
        aspects: this.$store.getters['createPostStoreModule/getSelectedAspects'],
        description: this.$store.getters['createPostStoreModule/getDescription'],
        sharedWith: this.$store.getters['createPostStoreModule/getSharedWith'],
        metaRating: this.$store.getters['createPostStoreModule/getMetaRating'],
        userId: this.$store.getters['authStoreModule/getUserId'],
        userFirstName: this.$store.getters['authStoreModule/getFirstName'],
        userLastName: this.$store.getters['authStoreModule/getLastName'],
        suggestedAspects: this.$store.getters['createPostStoreModule/getSuggestedAspects'],
        categoryDropdownSelectedOptionIndex:
          this.$store.getters['createPostStoreModule/getCategoryDropdownSelectedOptionIndex'],
        username: this.$store.getters['authStoreModule/getUser'].username,
        images: this.$store.getters['createPostStoreModule/getExistingS3Images'],
        userProfilePictureUrl: this.$store.getters['authStoreModule/getProfilePhoto'],
      };
    },
  },
  computed: {
    createDraftStatus: {
      get() {
        return this.$store.getters['createPostStoreModule/getCreateDraftStatus'];
      },
      set(status) {
        this.$store.commit('createPostStoreModule/setCreateDraftStatus', status);
      },
    },
    title: {
      get() {
        return this.$store.getters['createPostStoreModule/getTitle'];
      },
      set(title) {
        this.$store.commit('createPostStoreModule/setTitle', title);
      },
    },
    category: {
      get() {
        return this.$store.getters['createPostStoreModule/getCategory'];
      },
    },
    subcategory: {
      get() {
        return this.$store.getters['createPostStoreModule/getSubcategory'];
      },
    },
    address: {
      get() {
        return this.$store.getters['createPostStoreModule/getAddress'];
      },
      set(address) {
        this.$store.commit('createPostStoreModule/setAddress', address);
      },
    },
    link: {
      get() {
        return this.$store.getters['createPostStoreModule/getLink'];
      },
      set(link) {
        this.$store.commit('createPostStoreModule/setLink', link);
      },
    },
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    navigationArrowColor() {
      if (this.title == null || this.title.trim() === '') {
        return '#E0E0E0';
      }
      if (this.category == null || this.category === '') {
        return '#E0E0E0';
      }
      return '#30C3E3';
    },
  },
};
</script>
