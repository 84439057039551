import client from '@/axios/client';

const trendingListStoreModule = {
  namespaced: true,
  state: {
    lists: [],
    viewableLists: [],
    isLoading: false,
    batchAddNumber: 21,
  },
  getters: {
    getLists(state) {
      return state.lists;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getViewableLists(state) {
      return state.viewableLists;
    },
  },
  mutations: {
    addList(state, list) {
      state.lists.push(list);
    },
    setIsLoading(state, loading) {
      state.isLoading = loading;
    },
    clear(state) {
      state.lists = [];
      state.viewableLists = [];
      state.isLoading = false;
    },
    clearLists(state) {
      state.lists = [];
      state.viewableLists = [];
    },
    addListsToViewableLists(state) {
      let listsLength = state.viewableLists.length;
      let endLength = state.viewableLists.length + state.batchAddNumber;
      let newLists = state.lists.slice(listsLength, endLength);
      for (let i = 0; i < newLists.length; i++) {
        state.viewableLists.push(newLists[i]);
      }
    },
  },
  actions: {
    loadLast7Days({ commit, getters, rootGetters }) {
      commit('clearLists');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-lists-last-7-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addList', response.data.results[i]);
          }
          commit('addListsToViewableLists');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast30Days({ commit, getters, rootGetters }) {
      commit('clearLists');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-lists-last-30-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addList', response.data.results[i]);
          }
          commit('addListsToViewableLists');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast365Days({ commit, getters, rootGetters }) {
      commit('clearLists');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-lists-last-365-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addList', response.data.results[i]);
          }
          commit('addListsToViewableLists');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast24Hours({ commit, getters, rootGetters }) {
      commit('clearLists');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-lists-last-1-day.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addList', response.data.results[i]);
          }
          commit('addListsToViewableLists');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadAllTime({ commit, getters, rootGetters }) {
      commit('clearLists');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-lists-all-time.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addList', response.data.results[i]);
          }
          commit('addListsToViewableLists');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
  },
};

// export the entire module
export { trendingListStoreModule };
