<template>
  <div class="flex justify-center bg-ice py-4">
    <AreYouSureModal
      :message="'Are you sure you want to leave? All Slydepost progress will be lost.'"
      :show-modal="showNavigateAwayModal"
      @accept="this.navigateAway"
      @cancel="this.showNavigateAwayModal = false"
    />
    <EditSlydepost @slydepostEdited="this.completedSlydepost = true" />
  </div>
</template>

<script>
import EditSlydepost from '@/components/EditSlydepost/EditSlydepost.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';

export default {
  name: 'EditSlydepostView',
  components: {
    EditSlydepost,
    AreYouSureModal,
  },
  data() {
    return {
      navigateToPath: '',
      isNavigateAway: false,
      showNavigateAwayModal: false,
      completedSlydepost: false,
    };
  },
  methods: {
    navigateAway() {
      this.$router.replace(this.navigateToPath || '/');
      return true;
    },
    navigateAwayWithoutSaving() {
      this.$router.replace(this.navigateToPath || '/');
    },
  },
  beforeRouteLeave(to, from) {
    if (this.completedSlydepost === true) {
      return true;
    } else if (this.showNavigateAwayModal === false) {
      this.navigateToPath = to.path;
      this.showNavigateAwayModal = true;
      return false;
    }
  },
};
</script>
