<template>
  <div>
    Slydepost Community Guidelines
    <br />
    Last updated 06/22/2023
    <br />
    <br />
    1. Slydepost Values
    <br />
    <br />
    Welcome to Slydepost!
    <br />
    <br />
    Slydepost’s mission is to give you the power to review anything and to share those reviews with the people that
    matter most in your community. Slydepost believes that everyone is entitled to their own opinion and should be able
    to share their viewpoint with the people who are important to them.
    <br />
    <br />
    We encourage you to be your authentic self on the Slydepost platform, and we want to empower you to create reviews
    that matter to you and your community!
    <br />
    <br />
    Slydepost is where your reviews matter.
    <br />
    <br />
    We are committed to maintaining a supportive environment for our growing community. Our Community Guidelines
    establish a set of norms and common code of conduct that provide for a safe and welcoming space for everyone.
    <br />
    <br />
    At Slydepost, we encourage our people to treat the other people on the platform how they would like to be treated.
    We try to treat our users with respect, and we ask that they treat our team, and their other fellow users, with
    respect.
    <br />
    <br />
    We prioritize safety, diversity, inclusion, and authenticity. We are a company that values the individual human
    rights of our users around the world. We aim to cultivate an environment for genuine interactions by encouraging
    authentic content on Slydepost.
    <br />
    <br />
    Our Community Guidelines apply to everyone and everything on Slydepost. We proactively enforce them using a mix of
    technology and human moderation and aim to do so before people report potentially violative content to us. We
    encourage our community members to report a problem to Slydepost directly and to report any content or account they
    believe violates our Community Guidelines immediately.
    <br />
    <br />
    If you wish to file a complaint about information or materials uploaded by other users, contact us at:
    info@slydepost.com and the Subject line must contain the word: “REPORT”
    <br />
    <br />
    We will remove any content – including video, audio, images, comments, links, or other text – that violates our
    Community Guidelines. Individuals are notified of our decisions and can appeal them if they believe no violation has
    occurred. We will temporarily or permanently ban accounts and/or users that are involved in severe or repeated
    on-platform violations; we may also consider actions on other platforms and offline behavior in these decisions.
    Circumstances that involve any instance of a threat of real-world harm to human life that is specific, credible, and
    imminent may be reported to law enforcement authorities. The full Slydepost experience is for people 18 and older,
    and we actively remove accounts of people we suspect are under this age.
    <br />
    <br />
    2. Minor Safety
    <br />
    <br />
    We are deeply committed to protecting the safety of minors on our platform. Slydepost defines a minor as any person
    under the age of 18. We prohibit activities that perpetuate the abuse, harm, endangerment, or exploitation of minors
    on Slydepost. Any content, including animation or digitally created or manipulated media, that depicts abuse,
    exploitation, or endangerment of minors is a violation on our platform and will be removed when detected. We report
    child sexual abuse material (CSAM) and supporting evidence to the National Center for Missing & Exploited Children
    (NCMEC) and to any additional relevant legal authorities.
    <br />
    <br />
    Our users must meet the minimum age requirements to use Slydepost. When an underage account is identified, we remove
    it. Given our platform is designed with the safety of minors in mind, some of our features are age restricted.
    <br />
    <br />
    Sexual exploitation of minors
    <br />
    <br />
    Slydepost will take action on any content or accounts involving CSAM or sexual exploitation of a minor. Sexual
    exploitation of minors includes any abuse of a position of power or trust for sexual purposes, including profiting
    financially, socially, sexually, or politically from the exploitation of a minor. CSAM is defined as any visual
    depiction of sexually explicit nudity or conduct of a minor, whether captured by predatory adults, peers, or
    self-generated by minors.
    <br />
    Do not post, upload, download, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that shares, reshares, offers to trade or sell, or directs users off platform to obtain or distribute
        CSAM
      </li>
      <li>
        Content that engages with minors in a sexualized way, or otherwise sexualizes a minor (e.g., via product
        features like duets)
      </li>
      <li>
        Content that depicts, solicits, glorifies, or encourages child abuse imagery including nudity, sexualized
        minors, or sexual activity with minors
      </li>
      <li>Content that depicts, promotes, normalizes, or glorifies pedophilia or the sexual assault of a minor</li>
      <li>
        Content that re-victimized or capitalizes on minor victims of abuse by third party reshares or reenactments of
        assault or confessions
      </li>
    </ul>

    Grooming behavior
    <br />
    <br />
    Grooming behaviors are those in which an adult builds an emotional relationship with a minor in order to gain the
    minor's trust for the purposes of future or ongoing sexual contact, sexual abuse, trafficking, or other
    exploitation. These behaviors may include: flattery, requests for contact on or off platform, requests for personal
    information, solicitation of minor sexual abuse material, sexual solicitations or comments, and gift-giving.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Grooming advances</li>
      <li>Content that depicts, promotes, normalizes, or glorifies grooming behaviors</li>
      <li>
        Content that solicits real-world contact between a minor and an adult or between minors with a significant age
        difference
      </li>
      <li>Content that displays or offers nudity to minors</li>
      <li>
        Content that solicits minors to connect with an adult on another platform, website, or other digital space
      </li>
      <li>Any solicitation of nude imagery or sexual contact, through blackmail or other means of coercion</li>
    </ul>
    Nudity and sexual activity involving minors
    <br />
    <br />
    Nudity and sexual activity involving minors include content that is overtly revealing of breasts, genitals, anus, or
    buttocks, or behaviors that mimic, imply, or display sex acts involving minors. We do not allow the depiction,
    including digitally created or manipulated content, of nudity or sexual activity.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that depicts or implies minor sexual activities including penetrative and non-penetrative sex, oral sex,
        or intimate kissing
      </li>
      <li>Content that depicts sexual arousal or sexual stimulation involving a minor</li>
      <li>Content that depicts a sexual fetish involving a minor</li>
      <li>Content that depicts exposed genitals, buttocks, the pubic region, or female nipples of a minor</li>
      <li>Content that contains sexually explicit language depicting or describing a minor</li>
      <li>Content depicting a minor that contains sexually explicit song lyrics</li>
      <li>
        Content with sexually explicit dancing of a minor, including twerking, breast shaking, pelvic thrusting, or
        fondling the groin or breasts of oneself or another
      </li>
      <li>Content depicting a minor undressing</li>
      <li>Content depicting a minor in minimal clothing that is not situationally relevant to the location</li>
      <li>
        Sexualized comments, emojis, text, or other graphics used to veil or imply nudity or sexual activity of a minor
      </li>
    </ul>
    Harmful activities by minors
    <br />
    <br />
    Harmful minor activities include the possession or consumption of substances prohibited for minors, the misuse of
    legal substances, engagement in illegal activities, and participation in activities, physical challenges, or dares
    that may threaten the well-being of minors. We remove any such content from our platform.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that suggests, depicts, imitates, or promotes the possession or consumption of alcoholic beverages,
        tobacco, or drugs by a minor
      </li>
      <li>
        Content that offers instruction targeting minors on how to buy, sell, or trade alcohol, tobacco, or controlled
        substances
      </li>
      <li>
        Content that depicts or promotes activities that may jeopardize youth well-being, including physical challenges,
        dares, or stunts
      </li>
    </ul>
    Physical and psychological harm of minors
    <br />
    <br />
    Behaviors that place minors at risk of physical or psychological harm include physical abuse, neglect, child
    endangerment, and psychological disparagement. We remove any such content from our platform.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that depicts or promotes physical abuse, neglect, endangerment, or psychological disparagement of minors
      </li>
    </ul>
    Crimes against children
    <br />
    <br />
    We do not allow users who have been convicted of crimes against children to have an account on our platform. These
    crimes include: sexual assault, molestation, murder, physical abuse or neglect, abduction, international parental
    kidnapping, trafficking, exploitation of minors for prostitution, live online sexual abuse of a minor, sexual
    exploitation of minors in the context of travel and tourism, attempts to obtain or distribute CSAM, and the
    production, possession, or distribution of CSAM. If we discover any such users, we ban the account. Any
    self-disclosed user information that states the account holder is a pedophile or minor sex offender will be taken at
    face value and the account will be deleted.
    <br />
    <br />
    3. Dangerous Acts and Challenges
    <br />
    <br />
    We do not permit users to share content depicting, promoting, normalizing or glorifying dangerous acts that may lead
    to serious injury or death. We also do not allow content which promotes or endorses collective participation in
    dangerous or harmful activities that violate any aspect of our Community Guidelines.
    <br />
    <br />
    We define dangerous acts or other dangerous behavior as activities conducted in a non-professional context or
    without the necessary skills and safety precautions that may lead to serious injury or death for the user or the
    public. This includes amateur stunts or dangerous challenges.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Content that shows the potentially inappropriate use of dangerous tools or objects</li>
      <li>Content that depicts dangerous driving behavior</li>
      <li>
        Content that depicts or promotes ingesting substances that are not meant for consumption and could lead to
        severe harm
      </li>
      <li>Content that describes or provides instructional detail on how to perform a dangerous activity</li>
      <li>Dangerous games, dares, challenges, or stunts that might lead to injury or property damage</li>
    </ul>
    4. Suicide, self-harm, and disordered eating
    <br />
    <br />
    We care deeply about the health and well-being of the individuals who make up our community. We do not allow content
    depicting, promoting, normalizing, or glorifying activities that could lead to suicide, self-harm, or disordered
    eating. However, we do support members of our community sharing their personal experiences with these issues in a
    safe way to raise awareness and find community support. We also encourage individuals who are struggling with
    thoughts of suicide or self-harm, or who know someone is seriously considering suicide, to immediately contact local
    emergency services or the Center for Disease and Control Prevention. In the event that our intervention could help a
    user who may be at risk of harming themselves, Slydepost may also alert local emergency services.
    <br />
    <br />
    Suicide and self-harm
    <br />
    <br />
    We remove content that depicts suicide, involves suicidal ideation, or that might encourage suicidal or
    self-injurious behavior. We also remove content that depicts attempted suicide or behavior likely to lead to
    self-inflicted death. We prohibit any form of content that promotes, normalizes, or glorifies suicide, provides
    instructions for suicide, or posts that portray a suicide as heroic or honorable. Circumstances that involve any
    instance of a threat of real-world harm to human life that is specific, credible, and imminent may be reported to
    law enforcement authorities.
    <br />
    <br />
    Content that encourages or promotes suicide or self-harm hoaxes are also not allowed. This includes alarming
    warnings that could cause panic and widespread harm. We will remove such warnings, while allowing content that seeks
    to dispel panic and promote accurate information about such hoaxes.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Content that depicts, promotes, normalizes, or glorifies suicide or self-harm</li>
      <li>Content that provides instructions for suicide or how to engage in self-harm</li>
      <li>Suicide or self harm games, dares, challenges, pacts, or hoaxes</li>
    </ul>
    Disordered eating
    <br />
    <br />
    Content that promotes unhealthy eating behaviors or habits that are likely to cause adverse health outcomes is not
    allowed on the platform. This includes content expressing desire for an eating disorder, sharing tips or coaching on
    disordered eating, and participation in unhealthy body measurement challenges.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Content that depicts, promotes, normalizes, or glorifies disordered eating</li>
      <li>
        Content that depicts, promotes, normalizes, or glorifies any dangerous weight loss behaviors associated with
        disordered eating
      </li>
    </ul>
    5. Adult nudity and sexual activities
    <br />
    <br />
    We strive to create a platform that feels welcoming and safe. We do not allow nudity, pornography, or sexually
    explicit content on our platform. We also prohibit content depicting or supporting non-consensual sexual acts, the
    sharing of non-consensual intimate imagery, and adult sexual solicitation.
    <br />
    <br />
    Sexual exploitation
    <br />
    <br />
    Sexual exploitation is defined as any actual or attempted abuse of a position of vulnerability, power, or trust for
    sexual purposes, including profiting monetarily, socially, or politically from the sexual exploitation of another.
    We do not permit sexually exploitative content.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that depicts, solicits, promotes, normalizes, or glorifies non-consensual sexual acts or non-consensual
        touching, including rape and sexual assault
      </li>
      <li>
        Content that depicts, solicits, promotes, normalizes, or glorifies the sharing of non-consensual intimate
        imagery, including sexual images that are taken, created, or shared without consent
      </li>
      <li>Content that depicts, promotes, normalizes, or glorifies sexual violence</li>
      <li>
        Content that depicts, promotes, or glorifies sexual solicitation, including offering or asking for sexual
        partners, sexual chats or imagery, sexual services, premium sexual content, or sexcamming
      </li>
    </ul>
    Nudity and sexual activity involving adults
    <br />
    <br />
    Nudity and sexual activity include content that is overtly revealing of breasts, genitals, anus, or buttocks, or
    behaviors that mimic, imply, or display sex acts. We do not allow depictions, including digitally created or
    manipulated content, of nudity or sexual activity. We are mindful that some content may be offensive or culturally
    inappropriate in certain regions or may not be suitable for users of all ages.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that explicitly or implicitly depicts sexual activities including penetrative and non-penetrative sex,
        oral sex, or erotic kissing
      </li>
      <li>Content that depicts sexual arousal or sexual stimulation</li>
      <li>Content that depicts a sexual fetish</li>
      <li>Content that depicts genitals, buttocks, the pubic region, or female nipples</li>
      <li>Content that contains sexually explicit language for sexual gratification</li>
    </ul>
    6. Bullying and harassment
    <br />
    <br />
    We believe in an inclusive community and individual expression without fear of abuse. We do not tolerate members of
    our community being shamed, bullied, or harassed. Abusive content or behavior can cause severe psychological
    distress and will be removed from our platform.
    <br />
    <br />
    Abusive behavior
    <br />
    <br />
    We remove expressions of abuse, including threats or degrading statements intended to mock, humiliate, embarrass,
    intimidate, or hurt an individual. This prohibition extends to the use of Slydepost features.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that insults another individual, or disparages an individual on the basis of attributes such as
        intellect, appearance, personality traits, or hygiene
      </li>
      <li>Content that encourages coordinated harassment</li>
      <li>Content that disparages victims of violent tragedies</li>
      <li>Content that depicts willful harm or intimidation, such as cyberstalking or trolling</li>
      <li>Content that wishes death, serious disease, or other serious harm on an individual</li>
    </ul>
    Sexual harassment
    <br />
    <br />
    Sexual harassment involves unwanted or inappropriate sexual behavior directed at another individual. We do not allow
    content that glorifies, normalizes or promotes sexual harassment, regardless of the user's intent.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Content that attempts to make unwanted sexual contact</li>
      <li>Content that disparages another person’s sexual activity</li>
      <li>
        Content that simulates sexual activity with another person, either verbally, in text (including emojis), or
        through the use of any in-app features
      </li>
      <li>
        Content that alters or morphs an image of another individual to portray or imply sexual suggestiveness or
        engagement in sexual activity
      </li>
      <li>
        Content that reveals, or threatens to reveal, details of a person's private sexual life, including digital
        content, sexual history, and names of previous sexual partners
      </li>
      <li>Content that exposes, or threatens to expose, a person's sexual orientation without their consent</li>
    </ul>
    Threats of hacking, doxxing, and blackmail
    <br />
    <br />
    Threatening to hack or dox with an intent to harass or blackmail another individual can cause serious emotional
    distress and other offline harm. We define doxxing as the act of collecting and publishing personal data or
    personally identifiable information (PII) for malicious purposes. We consider these online behaviors as forms of
    abuse and do not allow them on our platform.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that threatens to reveal personal data or personally identifiable information (PII), including
        residential address, private email address, private phone number, bank statement, social security number, or
        passport number
      </li>
      <li>Content that threatens to blackmail an individual or to hack an individual's account</li>
      <li>
        Content that incites or encourages others to hack or reveal another person's account, personal data, or
        personally identifiable information (PII)
      </li>
      <li>
        An individual's account, personal data, or personally identifiable information to encourage others to abuse,
        troll, or harass that individual
      </li>
    </ul>
    Hateful behavior
    <br />
    <br />
    Slydepost is a diverse and inclusive community that has no tolerance for discrimination. We do not permit content
    that contains hate speech or involves hateful behavior, and we remove it from our platform. We ban accounts and/or
    users that engage in severe or multiple hate speech violations or that are associated with hate speech off the
    Slydepost platform.
    <br />
    <br />
    Attacks and slurs on the basis of protected attributes
    <br />
    <br />
    We define hate speech or behavior as content that attacks, threatens, incites violence against, or otherwise
    dehumanizes an individual or a group on the basis of the following protected attributes:
    <ul class="list-disc list-outside p-4">
      <li>Race</li>
      <li>Ethnicity</li>
      <li>National origin</li>
      <li>Religion</li>
      <li>Caste</li>
      <li>Sexual orientation</li>
      <li>Sex</li>
      <li>Gender</li>
      <li>Gender identity</li>
      <li>Serious disease</li>
      <li>Disability</li>
      <li>Immigration status</li>
    </ul>
    Slurs are defined as derogatory terms that are intended to disparage groups or individuals based on any protected
    attributes listed above. To minimize the spread of egregiously offensive terms, we remove all slurs from our
    platform, unless the terms are reappropriated, used self-referentially (i.e., by members of the protected group), or
    used in a way that does not disparage (e.g., educational context).
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content claiming individuals or groups with protected attributes are physically, mentally, or morally inferior
        or referring to them as criminals, animals, inanimate objects, or other non-human entities
      </li>
      <li>Content promoting or justifying violence, exclusion, segregation, or discrimination against them</li>
      <li>Content that includes the use of non-self referential slurs</li>
      <li>Content that targets transgender or non-binary individuals through misgendering or deadnaming</li>
      <li>Content that depicts harm inflicted upon an individual or a group on the basis of a protected attribute</li>
      <li>
        Content that praises, promotes, glorifies, or supports any hateful ideology (e.g., white supremacy, misogyny,
        anti-LGBTQ, antisemitism)
      </li>
      <li>
        Content that contains names, symbols, logos, flags, slogans, uniforms, gestures, salutes, illustrations,
        portraits, songs, music, lyrics, or other objects related to a hateful ideology
      </li>
      <li>Content that promotes, supports, or advertises conversion therapy or related program</li>
      <li>
        Content that denies well-documented and violent events have taken place affecting groups with protected
        attributes (e.g., Holocaust denial)
      </li>
      <li>Claims of supremacy over a group of people with reference to other protected attributes</li>
      <li>Conspiracy theories used to justify hateful ideologies</li>
    </ul>
    8. Violent extremism
    <br />
    <br />
    We take a firm stance against enabling violence on or off Slydepost. We do not allow people to use our platform to
    threaten or incite violence, or to promote violent extremist organizations, individuals, or acts. When there is a
    threat to public safety or an account is used to promote or glorify off-platform violence, we ban the account. When
    warranted, we will report threats to relevant legal authorities. To effectively protect our community, we may
    consider off-platform behavior to identify violent extremist organizations and individuals on our platform. If we
    find such organizations or individuals, we will ban their accounts.
    <br />
    <br />
    Threats and incitement to violence
    <br />
    <br />
    We consider incitement to violence as advocating for, directing, or encouraging other people to commit violence. We
    do not allow threats of violence or incitement to violence on our platform that may result in serious physical harm.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Statements of intent to inflict physical injuries on an individual or a group</li>
      <li>Statements or imagery that encourage others to commit or that advocate for violence</li>
      <li>Conditional or aspirational statements that encourage other people to commit violence</li>
      <li>
        Calls to bring weapons to a location with the intent to intimidate or threaten an individual or group with
        violence
      </li>
      <li>Instructions on how to make or use weapons that may incite violence</li>
    </ul>
    Violent extremist organizations and individuals
    <br />
    <br />
    We do not allow organizations or individuals on our platform who promote or engage in violence, including terrorist
    organizations, organized hate groups, criminal organizations, and other non-state armed groups that target
    civilians.
    <br />
    <br />
    Terrorist organizations
    <br />
    <br />
    Terrorists and terrorist organizations are non-state actors that threaten violence, use violence, and/or commit
    serious crimes (such as crimes against humanity) against civilian populations in pursuit of political, religious,
    ethnic, or ideological objectives.
    <br />
    <br />
    Organized hate groups
    <br />
    <br />
    We use the term “organized hate” to refer to those individuals and organizations who attack people based on
    protected attributes, such as race, ethnicity, national origin, religion, caste, sexual orientation, sex, gender,
    gender identity, or immigration status. We consider attacks to include actions that incite violence or hatred, that
    aim to dehumanize individuals or groups, or that embrace a hateful ideology.
    <br />
    <br />
    Criminal organizations
    <br />
    <br />
    Criminal organizations are transnational, national, or local groups that have engaged in serious crimes, including
    violent crimes (e.g., homicide, rape, robbery, assault), trafficking (e.g., human, organ, drug, weapons),
    kidnapping, financial crimes (e.g., extortion, blackmail, fraud, money laundering), or cybercrime.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that praises, promotes, glorifies, or supports violent acts or extremist organizations or individuals
      </li>
      <li>
        Content that encourages participation in, or intends to recruit individuals to, violent extremist organizations
      </li>
      <li>
        Content with names, symbols, logos, flags, slogans, uniforms, gestures, salutes, illustrations, portraits,
        songs, music, lyrics, or other objects meant to represent violent extremist organizations or individuals
      </li>
    </ul>
    9. Integrity and authenticity
    <br />
    <br />
    We believe that trust forms the foundation of our community. We do not allow activities that may undermine the
    integrity of our platform or the authenticity of our users. We remove content or accounts that involve spam or fake
    engagement, impersonation, or misleading information that causes significant harm.
    <br />
    <br />
    Spam and fake engagement
    <br />
    <br />
    Fake engagement includes any content or activity that seeks to artificially inflate popularity on the platform. We
    prohibit any attempts to manipulate the platform to increase interaction metrics.
    <br />
    <br />
    Do not:
    <ul class="list-disc list-outside p-4">
      <li>Share instructions on how to artificially increase views, likes, followers, shares, or comments</li>
      <li>Engage in selling or buying views, likes, followers, shares, or comments</li>
      <li>Promote artificial traffic generation services</li>
      <li>Operate multiple Slydepost accounts under false or fraudulent pretenses to distribute commercial spam</li>
      <li>
        Create malicious software or modify code to artificially increase views, likes, followers, shares, or comments
      </li>
    </ul>
    Impersonation
    <br />
    <br />
    We do not allow accounts that pose as another person or entity in a deceptive manner. When we confirm a report of
    impersonation, we may ask the user to revise the profile or may ban the account. We do allow accounts that are
    clearly parody, commentary, or fan-based, such as where the username indicates that it is a fan, commentary, or
    parody account and not affiliated with the subject of the account.
    <br />
    <br />
    Do not:
    <ul class="list-disc list-outside p-4">
      <li>
        Pose as another person or entity by using someone else's name, biographical details, or profile picture in a
        misleading manner
      </li>
    </ul>
    Harmful misinformation
    <br />
    <br />
    Misinformation is defined as content that is inaccurate or false. We will remove misinformation that causes
    significant harm to individuals, our community, or the larger public regardless of intent. Significant harm includes
    serious physical injury, illness, or death; severe psychological trauma; large-scale property damage, and the
    undermining of public trust in civic institutions and processes such as governments, elections, and scientific
    bodies. This does not include simply inaccurate information, myths, or commercial or reputational harm.
    <br />
    <br />
    Do not post, upload, stream, or share:
    <ul class="list-disc list-outside p-4">
      <li>Misinformation that incites hate or prejudice</li>
      <li>Misinformation related to emergencies that induces panic</li>
      <li>Medical misinformation that can cause harm to an individual's physical health</li>
      <li>Content that misleads community members about elections or other civic processes</li>
      <li>
        Conspiratorial content including content that attacks a specific person or a protected group, includes a violent
        call to action, or denies a violent or tragic event occurred
      </li>
      <li>
        Digital Forgeries (Synthetic Media or Manipulated Media) that mislead users by distorting the truth of events
        and cause significant harm to the subject of the video, other persons, or society
      </li>
      <li>Do not:</li>
      <li>
        Engage in coordinated inauthentic behavior such as the use of multiple accounts to exert influence and sway
        public opinion while misleading individuals, our community, or our systems about the account's identity,
        location, relationships, popularity, or purpose
      </li>
    </ul>
    10. Illegal activities and regulated goods
    <br />
    <br />
    We work to ensure Slydepost does not enable activities that violate laws or regulations. We prohibit the trade,
    sale, promotion, and use of certain regulated goods, as well as the promotion or facilitation of criminal
    activities, including human exploitation. Content may be removed if it relates to activities or goods that are
    regulated or illegal in the majority of the region or world.
    <br />
    <br />
    Criminal activities
    <br />
    <br />
    Criminal activities cover a wide spectrum of acts punishable by law, including theft, assault, human exploitation,
    counterfeiting, and other harmful behavior. To prevent such behavior from being normalized, imitated, or
    facilitated, we remove content that promotes or enables criminal activities.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Content that promotes acts of physical harm, such as assault or kidnapping</li>
      <li>Content that risks the safety of others, including swatting</li>
      <li>
        Content that promotes human exploitation, including human smuggling, bonded labor, domestic servitude, sex
        trafficking, or prostitution
      </li>
      <li>Content that promotes vandalism or damage to property</li>
      <li>Content that promotes the poaching or illegal trade of wildlife</li>
      <li>
        Content that offers the purchase, sale, trade, or solicitation of unlawfully acquired or counterfeit goods
      </li>
      <li>
        Content that provides instructions on how to conduct criminal activities that result in harm to people, animals,
        or property
      </li>
    </ul>
    Drugs, controlled substances, alcohol, and tobacco
    <br />
    <br />
    We do not allow the depiction, promotion, or trade of drugs or other controlled substances.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that depicts or promotes drugs, drug consumption, or encourages others to make, use, or trade drugs or
        other controlled substances
      </li>
      <li>Content that provides information on how to buy illegal or controlled substances</li>
      <li>
        Content that depicts or promotes the misuse of legal substances, or instruction on how to make homemade
        substances, in an effort to become intoxicated
      </li>
    </ul>
    Frauds and scams
    <br />
    <br />
    We do not permit anyone to exploit our platform to take advantage of the trust of users and bring about financial or
    personal harm. We remove content that deceives people in order to gain an unlawful financial or personal advantage,
    including schemes to defraud individuals or steal assets.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Content that promotes phishing</li>
      <li>Content that promotes Ponzi, multi-level marketing, or pyramid schemes</li>
      <li>
        Content that promotes investment schemes with promise of high returns, fixed betting, or any other types of
        scams
      </li>
    </ul>
    Privacy, personal data, and personally identifiable information (PII)
    <br />
    <br />
    We do not allow content that violates the confidentiality of personal data or personally identifiable information
    (e.g., social security information, phone numbers, physical addresses).
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>Content that contains personal data or personally identifiable information (PII)</li>
    </ul>
    11. Violent and graphic content
    <br />
    <br />
    Slydepost is a platform that celebrates creativity but not shock value or violence. We do not allow content that is
    gratuitously shocking, graphic, sadistic, or gruesome or that promotes, normalizes, or glorifies extreme violence or
    suffering on our platform. When it is a threat to public safety, we ban the account and, when warranted, we will
    report it to relevant legal authorities.
    <br />
    <br />
    Do not post, upload, or share:
    <br />
    <br />
    Content of humans that depicts:
    <ul class="list-disc list-outside p-4">
      <li>violent or graphic deaths or accidents</li>
      <li>dismembered, mutilated, charred, or burned human remains</li>
      <li>gore in which an open wound or injury is the core focus</li>
      <li>real-world physical violence, fighting, or torture</li>
      <li>Content of animals that depicts:</li>
      <li>the slaughter or other non-natural death of animals</li>
      <li>dismembered, mutilated, charred, or burned animal remains</li>
      <li>animal cruelty and gore</li>
    </ul>
    12. Copyright and trademark infringement
    <br />
    <br />
    Copyright is a legal right that protects original works of authorship (e.g., music, videos) and original expression
    of an idea (e.g., the specific way a video or music is expressed or created), although it does not protect
    underlying ideas or facts. A trademark is a word, symbol, slogan, or design that identifies and distinguishes the
    source of a product or service. We encourage everyone to create and share original content. Content that infringes
    someone else's intellectual property rights is prohibited on our platform and will be removed if we become aware of
    it. The use of copyrighted work under certain circumstances, such as the fair use doctrine or other applicable laws,
    or the use of a trademark to reference, lawfully comment, criticize, parody, make a fan page, or review a product or
    service, may not be considered a violation of our policies.
    <br />
    <br />
    Do not post, upload, or share:
    <ul class="list-disc list-outside p-4">
      <li>
        Content that violates or infringes someone else's copyrights, trademarks, or other intellectual property rights
      </li>
    </ul>
    13. Platform security
    <br />
    <br />
    A top priority for Slydepost is the safety and security of our users, creators, business partners, vendors, and
    employee data, including their personal information, accounts, profiles, content, and other proprietary information,
    as well as our product and services. We do not allow access to the Slydepost platform -- which includes our website,
    network, and associated infrastructure or networks -- through unauthorized methods, or to obtain sensitive
    confidential commercial or personal information. Any attempt to undermine or abuse the security, integrity or
    reliability of our platform, products, or services is expressly prohibited.
    <br />
    <br />
    Do not:
    <ul class="list-disc list-outside p-4">
      <li>
        Attempt to access the Slydepost platform in an unauthorized manner, and do not create fake or misleading
        versions of the Slydepost platform
      </li>
      <li>
        Create or distribute malicious files, content, or messages that contain viruses, Trojan horses, worms, logic
        bombs, or other materials that may be harmful to the community or platform
      </li>
      <li>
        Use automated scripts, web crawling, software, deceptive techniques, or any other way to attempt to obtain,
        acquire, or request login credentials or other sensitive information, including non-public data, from Slydepost
        or its users
      </li>
      <li>
        Leverage Slydepost accounts under false or fraudulent pretenses to distribute spam, phishing, or smishing
        content in an attempt to perpetrate cybercrime or gain unauthorized access to others’ content, accounts,
        systems, or data
      </li>
      <li>
        Modify, adapt, translate, reverse engineer, disassemble, decompile, or create any derivative products based on
        Slydepost, including any files, tables or documentation, or attempt to regenerate any source code, algorithms,
        methods, or techniques embodied in Slydepost
      </li>
      <li>
        Provide access to your account credentials to others or enable others to conduct activities against our
        Community Guidelines
      </li>
      <li>
        Click on suspicious links or engage in requests for information about your Slydepost account details, passwords,
        verification qualification, financial, or other personal information
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CommunityGuidelines',
};
</script>
