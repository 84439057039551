<template>
  <div class="py-5 px-4 bg-white">
    <p class="text-2xl text-dark-dark-gray font-bold pb-8">Edit Profile</p>
    <div class="flex flex-col items-center">
      <div class="flex flex-col gap-8 md:w-80">
        <div @click="$refs.file.click()" class="flex flex-col cursor-pointer">
          <div class="rounded-xl bg-transparent flex items-center justify-center">
            <img class="object-contain rounded-full h-28 w-28" :src="profilePictureUrl" />
          </div>
          <input type="file" ref="file" class="hidden" @change="selectedFile" multiple />
          <div class="h-full flex bg-transparent items-center justify-center flex-2 -m-4">
            <div class="bg-blue w-8 h-8 flex justify-center items-center rounded-lg">
              <font-awesome-icon class="w-4 h-4" :icon="['fas', 'pencil']" :color="'white'" />
            </div>
          </div>
          <canvas id="canvas" style="display: none"></canvas>
        </div>
        <div v-show="showCropper" class="flex flex-col items-center gap-2">
          <Cropper class="w-64" :src="profilePictureUrl" :stencil-props="{ aspectRatio: 1 / 1 }" ref="cropper" />
          <button @click="doneCropping">Done</button>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Bio</p>
          <div>
            <textarea
              :value="this.bio"
              @input="updateBio"
              class="md:h-36 max-h-40 min-h-32 outline-none text-dark-dark-gray"
              placeholder="Bio"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Username</p>
          <div>
            <input
              :class="usernameStyling"
              :value="this.username"
              @input="updateUsername"
              type="text"
              placeholder="Username"
            />
          </div>
          <p v-if="updateFailureReason === 'username-already-exists'" class="text-red-500 text-sm">
            This username already exists
          </p>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Name</p>
          <div class="flex flex-row gap-3">
            <input :value="this.firstName" @input="updateFirstName" type="text" placeholder="First Name" />
            <input :value="this.lastName" @input="updateLastName" type="text" placeholder="Last Name" />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Gender</p>
          <div class="flex flex-row gap-8">
            <div class="flex flex-row gap-2">
              <input type="radio" value="FEMALE" v-model="gender" class="w-auto" />
              <label for="female">Female</label>
            </div>
            <div class="flex flex-row gap-2">
              <input type="radio" value="MALE" v-model="gender" class="w-auto" />
              <label for="male">Male</label>
            </div>
            <div class="flex flex-row gap-2">
              <input type="radio" :value="genderValue" v-model="gender" class="w-auto" />
              <label for="other">Other</label>
            </div>
          </div>
          <div v-show="isOtherGenderSelected">
            <Dropdown
              :options="this.pronounOptions"
              :overwrite-selected-option="pronoun"
              @selected="setPronoun"
              :disabled="false"
              name="pronouns"
              :maxItem="5"
              placeholder="Pronouns"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Date of Birth</p>
          <div>
            <Datepicker class="w-full" v-model="dateOfBirth" :placeholder="'Date of Birth'" />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Location</p>
          <div>
            <input :value="this.location" @input="updateLocation" type="text" placeholder="City, Country" />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Mobile Phone</p>
          <div>
            <MazPhoneNumberInput class="phone-input" v-model="phone" :preferred-countries="['US']" no-flags />
          </div>
        </div>
        <div>
          <LoaderButton
            @click="saveUserProfile"
            :status="userProfileSaveStatus"
            :waiting-text="'Save'"
            :in-progress-text="'Saving'"
            :done-text="'Saved!'"
            :error-text="'Try Again?'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/store';
import Datepicker from 'vue3-datepicker';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import 'maz-ui/css/main.css';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import Dropdown from '@/components/Utility/Dropdown.vue';

export default {
  name: 'ProfileSettings',
  data() {
    return {
      showCropper: false,
      showPronouns: false,
      pronounOptions: [
        { name: 'She/her', id: 1, value: 'FEMALE' },
        { name: 'He/him', id: 2, value: 'MALE' },
        { name: 'They/them', id: 3, value: 'THEY' },
        { name: 'Prefer not to say', id: 4, value: 'PREFER_NOT_TO_SAY' },
      ],
    };
  },
  components: {
    Datepicker,
    MazPhoneNumberInput,
    Cropper,
    LoaderButton,
    Dropdown,
  },
  computed: {
    ...mapGetters({
      firstName: 'getFirstName',
      lastName: 'getLastName',
      username: 'getUsername',
      location: 'getLocation',
      bio: 'getBio',
      userProfileSaveStatus: 'getUserProfileSaveStatus',
    }),
    updateFailureReason: {
      get() {
        return this.$store.getters.getUpdateFailureReason;
      },
      set(value) {
        this.$store.commit('setUpdateFailureReason', value);
      },
    },
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
      set(value) {
        this.$store.commit('setProfilePictureUrl', value);
      },
    },
    profilePicture: {
      get() {
        return this.$store.getters.getProfilePictureFile;
      },
      set(value) {
        this.$store.commit('setProfilePictureFile', value);
      },
    },
    dateOfBirth: {
      get() {
        return this.$store.getters.getDateOfBirth;
      },
      set(value) {
        this.$store.commit('setDateOfBirth', value);
      },
    },
    gender: {
      get() {
        return this.$store.getters.getGender;
      },
      set(value) {
        this.$store.commit('setGender', value);
      },
    },
    phone: {
      get() {
        return this.$store.getters.getPhone;
      },
      set(value) {
        this.$store.commit('setPhone', value);
      },
    },
    pronoun() {
      for (let i = 0; i < this.pronounOptions.length; i++) {
        if (this.pronounOptions[i].value === this.gender) {
          return this.pronounOptions[i];
        }
      }
      return this.pronounOptions[0];
    },
    genderValue() {
      if (this.gender === 'THEY') {
        return 'THEY';
      } else if (this.gender === 'PREFER_NOT_TO_SAY') {
        return 'PREFER_NOT_TO_SAY';
      } else {
        return '';
      }
    },
    isOtherGenderSelected() {
      return this.gender !== 'MALE' && this.gender !== 'FEMALE';
    },
    usernameStyling() {
      if (this.updateFailureReason === 'username-already-exists') {
        return 'border-red-500';
      }
      return '';
    },
  },
  methods: {
    selectedFile(event) {
      let file = event.target.files[0];
      if (file != null) {
        let url = URL.createObjectURL(file);
        this.profilePicture = url;
        store.commit('setProfilePictureUrl', url);
        store.commit('setProfilePictureFile', file);
        this.showCropper = true;
      }
    },
    updateFirstName(e) {
      this.$store.commit('setFirstName', e.target.value);
    },
    updateLastName(e) {
      this.$store.commit('setLastName', e.target.value);
    },
    updateUsername(e) {
      this.$store.commit('setUsername', e.target.value);
    },
    updateLocation(e) {
      this.$store.commit('setLocation', e.target.value);
    },
    updateBio(e) {
      this.$store.commit('setBio', e.target.value);
    },
    async saveUserProfile() {
      if (this.userProfileSaveStatus === 'saving') {
        return;
      }
      let payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        username: this.username,
        location: this.location,
        bio: this.bio,
        email: this.email,
        gender: this.gender,
        dateOfBirth: this.dateOfBirth,
        phone: this.phone,
        file: this.profilePicture,
      };
      this.$store.dispatch('updateUserProfile', payload);
    },
    async doneCropping() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.showCropper = false;
      this.profilePictureUrl = canvas.toDataURL();
      await canvas.toBlob(async (blob) => {
        // let uuid = await this.uuidv4()
        const resizedBlob = await this.resizeImage(blob, 1080, 1080);
        let uuid = await this.uuidv4();
        this.profilePicture = new File([resizedBlob], uuid);
      });
    },
    setPronoun(pronounOption) {
      // this.pronoun = pronounOption.name;
      // this.selectedPronounIndex = pronounOption.id;
      this.gender = pronounOption.value;
    },
    async uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    async resizeImage(file, maxWidth, maxHeight) {
      return new Promise((resolve, reject) => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          let newWidth, newHeight;
          // if image size is smaller than max, don't change anything
          if (image.width < maxWidth && image.height < maxHeight) {
            newWidth = image.width;
            newHeight = image.height;
          }
          // Calculate new dimensions while maintaining the aspect ratio
          else if (image.width > image.height) {
            newWidth = maxWidth;
            newHeight = (image.height * maxWidth) / image.width;
          } else {
            newHeight = maxHeight;
            newWidth = (image.width * maxHeight) / image.height;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          // Draw the image on the canvas with the new dimensions
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
          // Convert the canvas content to a Blob
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            file.type,
            1.0
          );
        };

        image.src = URL.createObjectURL(file);
      });
    },
  },
  mounted() {
    this.$store.dispatch('getLoggedInUserProfile');
  },
  unmounted() {
    this.$store.commit('setUserProfileSaveStatus', 'save');
  },
};
</script>

<style>
.phone-input {
  --maz-border-width: 1px;
}
</style>
