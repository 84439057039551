<template>
  <div class="w-full">
    <v-range-slider v-model="range" :max="10" :min="0" :step="1" hide-details class="align-center range-slider" />
    <div class="flex flex-row justify-between pl-2 md:pl-1 pr-1 text-xs">
      <p>0</p>
      <p>1</p>
      <p>2</p>
      <p>3</p>
      <p>4</p>
      <p>5</p>
      <p>6</p>
      <p>7</p>
      <p>8</p>
      <p>9</p>
      <p>10</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RefinedSearchScoreSlider',
  data() {
    return {};
  },
  methods: {},
  computed: {
    range: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getRefinedScoreRange'];
      },
      set(range) {
        this.$store.commit('refinedSearchStoreModule/setRefinedScoreRange', range);
      },
    },
  },
};
</script>

<style scoped>
.range-slider >>> .v-slider-thumb__ripple {
  display: none;
}

.range-slider >>> .v-slider-track__fill {
  background: #015d79;
}

.range-slider >>> .v-slider-thumb__surface {
  background-color: #015d79;
}
</style>
