<template>
  <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 md:gap-4 px-2 lg:px-0">
    <TrendingUser
      v-for="user in trendingUsers"
      :key="user.userId"
      :user-id="user.userId"
      :first-name="user.firstName"
      :last-name="user.lastName"
      :username="user.username"
      :profile-picture-url="user.userProfilePicturePresignedUrl"
      :number-of-followers="user.totalFollowers"
      :number-of-slydeposts="user.totalPosts"
      :number-of-lists="user.totalLists"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TrendingUser from '@/components/Trending/Explore/TrendingUser.vue';
export default {
  name: 'TrendingUsers',
  components: {
    TrendingUser,
  },
  computed: {
    ...mapGetters({
      trendingUsers: 'trendingUserStoreModule/getViewableUsers',
    }),
  },
  mounted() {
    this.$store.dispatch('trendingUserStoreModule/loadLast7Days');
  },
};
</script>
