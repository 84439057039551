<template>
  <div
    class="p-2 rounded-xl flex flex-row justify-between items-center"
  >
    <div class='flex flex-col gap-1'>
      <div class='flex flex-row gap-2 items-center'>
        <img class="w-6 h-6 rounded-full" :src="profilePicture" />
        <p>{{ name }}</p>
      </div>
      <p v-if='price != null'>{{formattedPrice}}</p>
    </div>
    <div>
      <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionPayment',
  props: {
    userId: String,
    name: String,
    priceType: String,
    price: Number,
    profilePicture: String
  },
  computed: {
    formattedPrice(){
      const dollars = (this.price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      if(this.priceType === 'MONTHLY'){
        return `${dollars} per month`
      } else if(this.priceType === 'YEARLY'){
        return `${dollars} per year`
      }
    },
  },
  methods: {

  },
};
</script>