<template>
  <div>
    <div class="flex w-screen lg:w-full p-2 justify-between items-center bg-dark-blue">
      <div id="highlightedList" class="z-20 overflow-scroll">
        <div class="flex flex-row gap-4 min-w-0">
          <div v-for="list in this.lists" :key="list.id">
            <div class="flex flex-col">
              <SingleUpdatedList :id="list.id" :title="list.title" :img-url="list.presignedUrl" />
              <p class="text-center text-[12px] font-[400] leading-[15px] text-white mt-2 line-clamp-2 w-20">
                {{ list.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 h-28 flex justify-center items-center bg-blue">
        <router-link :to="{ path: '/lists' }">
          <div class="flex flex-col justify-center gap-1">
            <p class="text-white text-center">Your</p>
            <p class="pb-1 text-white text-center">Lists</p>
            <font-awesome-icon class="text-white text-center" :icon="['fas', 'chevron-right']" style="color: #30c3e3" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SingleUpdatedList from '@/components/UpdatedLists/SingleUpdatedList.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UpdatedLists',
  components: {
    SingleUpdatedList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      lists: 'listHighlightsStoreModule/getHighlightLists',
    }),
  },
  setup() {},
  methods: {},
};
</script>

<style scoped>
#highlightedList::-webkit-scrollbar {
  display: none;
}

#highlightedList {
  scrollbar-width: none;
}
</style>
