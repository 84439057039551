<template>
  <div class="flex flex-col gap-4">
    <div v-for="adjective in adjectives" :key="adjective.id">
      <SlydepostRating
        :id="adjective.id"
        :name="adjective.name"
        :rating="adjective.rating"
        :description="adjective.description"
        :displayDescriptionsProp="this.displayDescriptionsProp"
      />
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import SlydepostRating from './SlydepostRating.vue';

export default {
  // if xs or no aspect descriptions -> SlydepostRating
  // if md and has aspect descriptions -> SlydepostRatingWithDescription
  name: 'SlydepostRatings',
  props: ['adjectives', 'hasDescriptions', 'displayDescriptionsProp'], // id, name, rating, description
  data() {
    return {
      ratingSliderWidth: 0,
    };
  },
  components: { SlydepostRating },
  methods: {},
  computed: {
    displayDescriptions() {
      if (this.adjectives == null) {
        return false;
      }
      for (let i = 0; i < this.adjectives.length; i++) {
        if (this.adjectives[i].description != null && this.adjectives[i].description !== '') {
          return true;
        }
      }
      return false;
    },
    adjectivesToDisplay() {
      return this.adjectives.filter(function (adjective) {
        return adjective.description != null && adjective.description !== '';
      });
    },
  },
};
</script>

<style scoped>
#expend {
  display: none;
}

#expend + .smalldesc {
  max-height: 180px;
  overflow: hidden;
  transition: all 0.3s ease;
}

#expend:checked + .smalldesc {
  max-height: 500px;
}

label {
  color: #015d79;
  cursor: pointer;
}

label:hover {
  text-decoration: none;
}

.adjective-title-for-description {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 0px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: fit-content;
  margin-bottom: 5px;
}

.adjective-description {
  text-align: left;
  margin-bottom: 16px;
}

.adjective-description:last-child {
  margin-bottom: 0px;
}

.rating-descriptions-container {
  width: 405px;
  margin-left: 34px;
}

.ratings-container {
  display: flex;
  padding-bottom: 5px;
}

.read-more-container {
  text-align: left;
}
</style>
