import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const customerStoreModule = {
  namespaced: true,
  state: {
    payments: [],
  },
  getters: {
    getPayments(state){
      return state.payments
    },
  },
  mutations: {
    setPayments(state, payments){
      state.payments = payments
    },
    clear(state){
      state.payments = [];
    }
  },
  actions: {
    async getPayments({ dispatch, commit, getters, rootGetters }){
      await dispatch('getLoggedInUserProfile', null, { root: true });

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const customerId = rootGetters['getCustomerId']
      client
        .get(`/user/insider/payments/${customerId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setPayments', response.data)
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStripeCustomerPortal({dispatch, commit, getters, rootGetters}){
      window.location.href = "https://billing.stripe.com/p/login/test_6oE29lfzv5yG3MQ9AA";
      // const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      // client
      //   .get(`/user/insider/customer/portal/${loggedInUserId}`,{
      //     withCredentials: true,
      //     headers: {
      //       'request-id': uuid.v1(),
      //       'User-Id': loggedInUserId,
      //     }
      //   })
      //   .then((response) => {
      //     let redirectUrl = response.data.link;
      //     window.location.href = redirectUrl;
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
  },
};

// export the entire module
export { customerStoreModule };
