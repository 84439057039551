<template>
  <div class="bg-white border border-[#E0E0E0]">
    <p class="text-blue text-md text-center py-3 border-b border-[#E0E0E0]">Trending Users</p>
    <div class="flex flex-col gap-2 justify-center items-center py-4">
      <div v-for="user in users.slice(0, 6)" :key="user.userId">
        <router-link :to="`/profile/${user.userId}`">
          <div class="w-[312px] relative hover:shadow-xl hover:ring-2 hover:ring-blue-500 hover:rounded-xl">
            <div class="flex items-center gap-2">
              <img
                :src="user.userProfilePicturePresignedUrl || require(`@/assets/avatar.png`)"
                class="w-[50px] h-[50px] flex-shrink-0 object-cover object-center rounded-xl"
              />
              <div class="flex flex-row justify-between w-full">
                <div class="flex flex-col">
                  <p v-if="user.username != null" class="text-sm lowercase">@{{ user.username }}</p>
                  <p v-else class="text-sm">{{ user.firstName }} {{ user.lastName }}</p>
                  <div class="text-xs font-bold text-moonstone">
                    <span>{{ user.totalFollowers }} Follower{{ user.totalFollowers !== 1 ? 's' : '' }}&nbsp;</span>
                    <span>{{ user.totalPosts }} Post{{ user.totalPosts !== 1 ? 's' : '' }}&nbsp;</span>
                    <span>{{ user.totalLists }} List{{ user.totalLists !== 1 ? 's' : '' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <router-link
      @click="setTrendingView"
      :to="{ name: 'Trending' }"
      class="block text-md text-center text-[#012337] pb-4 flex flex-row gap-2 items-center justify-center"
    >
      <p>View Trending Users</p>
      <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
    </router-link>
  </div>
</template>

<script>
import Loading from '@/components/Utility/Loading.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TrendingUsers',
  components: {
    // Loading
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    truncated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      users: 'trendingUserStoreModule/getViewableUsers',
      isLoading: 'trendingUserStoreModule/getIsLoading',
    }),
  },
  methods: {
    setTrendingView() {
      this.$store.commit('trendingStoreModule/setCurrentView', 'users');
    },
  },
  mounted() {
    this.$store.dispatch('trendingUserStoreModule/loadLast7Days');
  },
  unmounted() {
    this.$store.commit('trendingUserStoreModule/clear');
  },
};
</script>
