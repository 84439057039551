<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row justify-between items-center">
      <p class="text-lg font-medium">Create a Product</p>
      <font-awesome-icon
        @click="this.$emit('exit')"
        class="text-xl text-dark-blue"
        :icon="['fas', 'xmark']"
      />
    </div>
    <div class="flex flex-col gap-1">
      <p>Product Type</p>
      <Dropdown :options="productTypeOptions" @selected="setProductType" />
    </div>
    <div
      class="flex flex-col gap-1"
      v-if="productType === `INSIDER_SUBSCRIPTION`"
    >
      <p>Price Type</p>
      <Dropdown
        :options="subscriptionPriceTypeOptions"
        @selected="setPriceType"
      />
    </div>
    <div v-if="productType === `LIST_PURCHASE`">
      <div v-if="insiderLists.length <= 0">No lists available</div>
      <div v-else>
        <p>Select lists</p>
        <div
          class="bg-white border border-light-light-gray rounded-xl text-dark-dark-gray overflow-auto max-h-52 z-10 py-1"
        >
          <div
            class="px-2 flex flex-row gap-2 items-center"
            v-for="list in insiderLists"
            :key="list.id"
          >
            <input
              class="w-3 h-3"
              type="checkbox"
              :id="list.id"
              :checked="isSelected(list)"
              @change="toggleSelection(list)"
            />
            <label :for="list.id">{{ list.title }}</label>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        (productType === `LIST_PURCHASE` && insiderLists.length > 0) ||
        (productType === `INSIDER_SUBSCRIPTION` &&
          subscriptionPriceTypeOptions.length > 0)
      "
      class="flex flex-col gap-1"
    >
      <p>Price</p>
      <CurrencyInput v-model="price" :options="{ currency: 'USD' }" />
    </div>
    <LoaderButton
      v-if="
        (productType === `LIST_PURCHASE` && insiderLists.length > 0) ||
        (productType === `INSIDER_SUBSCRIPTION` &&
          subscriptionPriceTypeOptions.length > 0)
      "
      :status="createProductStatus"
      :waiting-text="`Create Product`"
      :waiting-icon="null"
      :in-progress-text="`Creating...`"
      :done-text="`Created!`"
      :error-text="`Error. Try Again?`"
      @click="create"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Utility/Dropdown.vue';
import CurrencyInput from '@/components/Utility/CurrencyInput.vue';
import { mapGetters } from 'vuex';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';

export default {
  name: 'CreateProduct',
  emits: ['exit'],
  data() {
    return {
      checked: false,
      priceFormatting: {
        decimal: '.',
        separator: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  components: {
    Dropdown,
    CurrencyInput,
    LoaderButton,
  },
  computed: {
    ...mapGetters({
      existingProducts: 'insiderStoreModule/getProducts',
      createProductStatus:
        'insiderCreateProductStoreModule/getCreateProductStatus',
    }),
    insiderLists() {
      const lists = this.$store.getters['listsStoreModule/getMyList'];
      const filteredLists = lists.filter(
        (listItem) =>
          !this.existingProducts.some(
            (existingItem) => existingItem.contentId === listItem.id
          )
      );
      return filteredLists;
    },
    selectedLists: {
      get() {
        return this.$store.getters[
          'insiderCreateProductStoreModule/getSelectedLists'
        ];
      },
      set(lists) {
        this.$store.commit(
          'insiderCreateProductStoreModule/setSelectedLists',
          lists
        );
      },
    },
    productTypeOptions() {
      let products = [
        { name: 'Subscription', id: 1, value: 'INSIDER_SUBSCRIPTION' },
        { name: 'List Access', id: 2, value: 'LIST_PURCHASE' },
      ];
      // if insider already has all subscription price types, remove it from create option
      let hasMonthlySubscription = false;
      let hasYearlySubscription = false;
      for (let i = 0; i < this.existingProducts.length; i++) {
        for (let j = 0; j < this.existingProducts[i].prices.length; j++) {
          if (this.existingProducts[i].productType === 'INSIDER_SUBSCRIPTION') {
            if (this.existingProducts[i].prices[j].priceType === 'MONTHLY') {
              hasMonthlySubscription = true;
            } else if (
              this.existingProducts[i].prices[j].priceType === 'YEARLY'
            ) {
              hasYearlySubscription = true;
            }
          }
        }
      }
      if (hasMonthlySubscription && hasYearlySubscription) {
        products = products.filter(
          (item) => item.value !== 'INSIDER_SUBSCRIPTION'
        );
      }
      return products;
    },
    subscriptionPriceTypeOptions() {
      let prices = [
        { name: 'Monthly', id: 1, value: 'MONTHLY' },
        { name: 'Yearly', id: 2, value: 'YEARLY' },
      ];
      for (let i = 0; i < this.existingProducts.length; i++) {
        for (let j = 0; j < this.existingProducts[i].prices.length; j++) {
          if (this.existingProducts[i].productType === 'INSIDER_SUBSCRIPTION') {
            if (this.existingProducts[i].prices[j].priceType === 'MONTHLY') {
              prices = prices.filter((item) => item.value !== 'MONTHLY');
            } else if (
              this.existingProducts[i].prices[j].priceType === 'YEARLY'
            ) {
              prices = prices.filter((item) => item.value !== 'YEARLY');
            }
          }
        }
      }
      return prices;
    },
    productType: {
      get() {
        return this.$store.getters[
          'insiderCreateProductStoreModule/getProductType'
        ];
      },
      set(type) {
        this.$store.commit(
          'insiderCreateProductStoreModule/setProductType',
          type
        );
      },
    },
    priceType: {
      get() {
        return this.$store.getters[
          'insiderCreateProductStoreModule/getPriceType'
        ];
      },
      set(type) {
        this.$store.commit(
          'insiderCreateProductStoreModule/setPriceType',
          type
        );
      },
    },
    price: {
      get() {
        return this.$store.getters['insiderCreateProductStoreModule/getPrice'];
      },
      set(price) {
        this.$store.commit('insiderCreateProductStoreModule/setPrice', price);
      },
    },
    isFreeTrial: {
      get() {
        return this.$store.getters[
          'insiderCreateProductStoreModule/getIsFreeTrial'
        ];
      },
      set(trial) {
        this.$store.commit(
          'insiderCreateProductStoreModule/setIsFreeTrial',
          trial
        );
      },
    },
    freeTrialDays: {
      get() {
        return this.$store.getters[
          'insiderCreateProductStoreModule/getFreeTrialDays'
        ];
      },
      set(days) {
        this.$store.commit(
          'insiderCreateProductStoreModule/setFreeTrialDays',
          days
        );
      },
    },
  },
  methods: {
    isSelected(item) {
      return this.selectedLists.some((selected) => selected.id === item.id);
    },
    toggleSelection(item) {
      const index = this.selectedLists.findIndex(
        (selected) => selected.id === item.id
      );
      if (index > -1) {
        this.selectedLists.splice(index, 1);
      } else {
        this.selectedLists.push({ listId: item.id, title: item.title });
      }
    },
    setProductType(option) {
      this.productType = option.value;
    },
    setPriceType(option) {
      this.priceType = option.value;
    },
    formatFreeTrialDays() {
      this.freeTrialDays = Math.round(this.freeTrialDays);
    },
    async create() {
      if (this.productType === 'LIST_PURCHASE') {
        this.priceType = 'ONE_TIME';
      }
      await this.$store.dispatch(
        'insiderCreateProductStoreModule/createProduct'
      );
      this.$router.push({ name: 'ViewProducts' });
    },
    onPriceFocus() {
      // Remove formatting when the input gains focus
      if (this.amount) {
        this.amount = this.amount.replace(/[^0-9.]/g, '');
      }
    },
    onPriceBlur() {
      // Format value when the input loses focus
      let numericValue = parseFloat(this.amount.replace(/[^0-9.]/g, ''));
      if (!isNaN(numericValue)) {
        // Round to the nearest cent
        numericValue = Math.round(numericValue * 100) / 100;
        // Format with dollar sign and two decimal places
        this.amount = `$${numericValue.toFixed(2)}`;
      } else {
        this.amount = '';
      }
    },
  },
  async mounted() {
    console.log(`mounted create product`);
    await this.$store.dispatch('listsStoreModule/fetchMyList');
    console.log(`done mounting create product`);
  },
  unmounted() {
    this.productType = '';
    this.priceType = '';
    this.price = 0;
    this.isFreeTrial = false;
    this.freeTrialDays = 0;
    this.selectedLists = [];
  },
};
</script>