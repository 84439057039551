<template>
  <div class="bg-ice w-full pb-2">
    <div class="grid-filter pb-2">
      <div class="flex flex-row justify-end items-center pr-2 lg:justify-end pt-2 lg:pt-0">
        <DropdownOptionsArrow
          v-click-outside="closeNewsfeedFilter"
          :options="this.newsfeedFilterOptions"
          :show-options="this.showNewsfeedFilterOptions"
          :selected-option="this.currentFilter"
          @click="this.showNewsfeedFilterOptions = !this.showNewsfeedFilterOptions"
          @filterPublic="this.currentFilter = 'public'"
          @filterFriends="this.currentFilter = 'friends'"
        />
      </div>
    </div>
    <div class="flex flex-col gap-4 lg:gap-2">
      <div v-for="item in modifiedPosts" :key="item.id">
        <component
          :class="
            item.type === 'SlydepostForExplore' || item.type === 'SlydepostForNewsfeed'
              ? 'border border-light-gray'
              : ''
          "
          :is="item.type"
          v-bind="item.props"
        />
      </div>
    </div>
    <div>
      <InfiniteLoading @infinite="loadMoreSlydeposts" />
    </div>
    <div class="w-full bg-transparent py-5">
      <Loading v-show="this.moreSlydepostsLoading" />
    </div>
  </div>
</template>

<script>
import DropdownOptionsArrow from '@/components/Utility/DropdownOptionsArrow.vue';
import SlydepostForNewsfeed from '@/components/ViewSlydepost/Wrappers/SlydepostForNewsfeed.vue';
import SlydepostForExplore from '@/components/ViewSlydepost/Wrappers/SlydepostForExplore.vue';
import UpdatedLists from '@/components/UpdatedLists/UpdatedLists.vue';
import Loading from '@/components/Utility/Loading.vue';
import { event } from 'vue-gtag';
import { mapGetters } from 'vuex';
import InfiniteLoading from 'v3-infinite-loading';
import TrendingLists from '@/components/Trending/Newsfeed/TrendingLists.vue';
import TrendingUsers from '@/components/Trending/Newsfeed/TrendingUsers.vue';
import TrendingSlydeposts from '@/components/Trending/Newsfeed/TrendingSlydeposts.vue';

export default {
  name: 'Home',
  components: {
    SlydepostForNewsfeed,
    Loading,
    InfiniteLoading,
    UpdatedLists,
    DropdownOptionsArrow,
    SlydepostForExplore,
    TrendingLists,
    TrendingUsers,
    TrendingSlydeposts,
  },
  data() {
    return {
      currentFilter: 'public',
      displayComments: true,
      moreSlydepostsLoading: false,
      showNewsfeedFilterOptions: false,
      newsfeedFilter: 'public',
      newsfeedFilterOptions: [
        {
          text: 'public',
          emit: 'filterPublic',
        },
        {
          text: 'friends',
          emit: 'filterFriends',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      followedLists: 'listsStoreModule/getFollowedLists',
      filteredNewsfeed: 'getNewsfeedSlydeposts',
      publicSlydeposts: 'exploreStoreModule/getSlydeposts',
      loggedInUserId: 'authStoreModule/getUserId',
      listHighlights: 'listHighlightsStoreModule/getHighlightLists',
    }),
    modifiedPosts() {
      let posts;
      // Create a new array to hold the modified list of posts
      let modified = [];
      if (this.currentFilter === 'public') {
        posts = this.$store.getters['exploreStoreModule/getSlydeposts'];
        let hasShownUpdatedLists = false;
        let hasShownTrendingLists = false;
        let hasShownTrendingUsers = false;
        let hasShownTrendingSlydeposts = false;
        posts.forEach((post, index) => {
          // if lists are being followed, show recently updated lists
          // if no lists are being followed, show trending lists
          // show trending users
          // if lists are being followed, show trending lists
          // show trending slydeposts

          if (index === 0 && this.followedLists.length > 0 && hasShownUpdatedLists === false) {
            modified.push({ type: 'UpdatedLists', props: {} });
            hasShownUpdatedLists = true;
          } else if (index === 0 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingLists === false) {
            modified.push({ type: 'TrendingLists', props: {} });
            hasShownTrendingLists = true;
          }

          modified.push({
            type: 'SlydepostForExplore',
            props: {
              id: post.id,
              title: post.title,
              description: post.description,
              aspects: post.tags,
              userId: post.userId,
              createdAt: post.createdAt,
              userFirstName: post.userFirstName,
              userLastName: post.userLastName,
              category: post.category,
              subcategory: post.subcategory,
              comments: post.comments,
              images: post.presignedMediaUrls,
              address: post.address,
              link: post.link,
              metaRating: post.metaRating,
              displayComments: this.displayComments,
              media: post.media,
              likes: post.likes,
              profilePictureUrl: post.userProfilePhotoUrl,
            },
          });

          if (index === 0 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingUsers === false) {
            modified.push({ type: 'TrendingUsers', props: {} });
            hasShownTrendingUsers = true;
          }
          if (index === 1 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingLists === false) {
            modified.push({ type: 'TrendingLists', props: {} });
            hasShownTrendingLists = true;
          } else if (index === 1 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingLists === true) {
            modified.push({ type: 'TrendingSlydeposts', props: {} });
            hasShownTrendingSlydeposts = true;
          }
          if (index === 2 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingSlydeposts === false) {
            modified.push({ type: 'TrendingSlydeposts', props: {} });
            hasShownTrendingSlydeposts = true;
          }
        });
      } else {
        let hasShownUpdatedLists = false;
        let hasShownTrendingLists = false;
        let hasShownTrendingUsers = false;
        let hasShownTrendingSlydeposts = false;
        posts = this.$store.getters['getNewsfeedSlydeposts'];
        posts.forEach((post, index) => {
          if (index === 0 && this.followedLists.length > 0 && hasShownUpdatedLists === false) {
            modified.push({ type: 'UpdatedLists', props: {} });
            hasShownUpdatedLists = true;
          } else if (index === 0 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingLists === false) {
            modified.push({ type: 'TrendingLists', props: {} });
            hasShownTrendingLists = true;
          }

          modified.push({
            type: 'SlydepostForNewsfeed',
            props: {
              id: post.id,
              title: post.title,
              description: post.description,
              aspects: post.tags,
              userId: post.userId,
              createdAt: post.createdAt,
              userFirstName: post.userFirstName,
              userLastName: post.userLastName,
              category: post.category,
              subcategory: post.subcategory,
              comments: post.comments,
              images: post.presignedMediaUrls,
              address: post.address,
              link: post.link,
              metaRating: post.metaRating,
              displayComments: this.displayComments,
              media: post.media,
              likes: post.likes,
              profilePictureUrl: post.userProfilePhotoUrl,
            },
          });
          if (index === 0 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingUsers === false) {
            modified.push({ type: 'TrendingUsers', props: {} });
            hasShownTrendingUsers = true;
          }
          if (index === 1 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingLists === false) {
            modified.push({ type: 'TrendingLists', props: {} });
            hasShownTrendingLists = true;
          } else if (index === 1 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingLists === true) {
            modified.push({ type: 'TrendingSlydeposts', props: {} });
            hasShownTrendingSlydeposts = true;
          }
          if (index === 2 && this.isTrendingNewsfeedEnabled === 'true' && hasShownTrendingSlydeposts === false) {
            modified.push({ type: 'TrendingSlydeposts', props: {} });
            hasShownTrendingSlydeposts = true;
          }
        });
      }
      return modified;
    },
    isTrendingNewsfeedEnabled() {
      let isEnabled = process.env.VUE_APP_IS_TRENDING_NEWSFEED_ENABLED;
      return isEnabled;
    },
  },
  async mounted() {
    const user = this.$store.getters['authStoreModule/getUser']
    if(user == null){
      return;
    }
    this.$store.commit('listHighlightsStoreModule/clear');
    event('newsfeed-loaded', {
      date: new Date().toUTCString(),
    });
    this.$store.dispatch('listsStoreModule/fetchFollowedLists');
    if (this.currentFilter === 'public') {
      this.$store.dispatch('exploreStoreModule/loadMoreSlydeposts');
    } else if (this.currentFilter === 'friends') {
      this.moreSlydepostsLoading = true;
      let userId = this.loggedInUserId;
      let payload = {
        filter: this.newsfeedFilter,
        userId: userId,
        index: this.filteredNewsfeed.length,
      };
      if (this.filteredNewsfeed.length === 0) {
        await this.$store.dispatch('loadMoreSlydeposts', payload);
      }
    }
    this.moreSlydepostsLoading = false;
    this.$store.dispatch('listHighlightsStoreModule/fetchHighlightLists');
    this.$store.dispatch('listsStoreModule/fetchMyList');
  },
  methods: {
    loadMoreSlydeposts() {
      if (this.currentFilter === 'public') {
        this.$store.dispatch('exploreStoreModule/loadMoreSlydeposts');
      } else if (this.currentFilter === 'friends') {
        if (this.moreSlydepostsLoading === true) {
          return;
        }
        let payload = {
          filter: this.newsfeedFilter,
          userId: this.loggedInUserId,
          index: this.filteredNewsfeed.length,
        };
        this.$store.dispatch('loadMoreSlydeposts', payload);
      }
    },
    closeNewsfeedFilter() {
      this.showNewsfeedFilterOptions = false;
    },
    updateNewsfeedFilter(filter) {
      this.$store.commit('updateNewsfeedFilter');
      this.newsfeedFilter = filter;
      this.getNewsfeed();
    },
    async getNewsfeed() {
      this.moreSlydepostsLoading = true;
      let payload = {
        filter: this.newsfeedFilter,
        userId: this.loggedInUserId,
        index: this.filteredNewsfeed.length,
      };
      await this.$store.dispatch('loadMoreSlydeposts', payload);
      this.moreSlydepostsLoading = false;
    },
  },
  unmounted() {
    this.$store.commit('clear');
    this.$store.commit('setEndOfNewsfeedReached', false);
  },
};
</script>
