<template>
  <div v-show="showModal" class="fixed top-0 bottom-0 left-0 right-0 flex justify-center z-50 bg-modal">
    <div class="text-center h-fit mt-16">
      <div class="w-96 bg-white p-4 rounded-lg flex flex-col gap-2">
        <p v-show="this.message != null">{{ this.message }}</p>
        <textarea v-model="this.editedComment"></textarea>
        <div class="flex flex-row gap-2 justify-center">
          <button @click="edit()">Edit</button>
          <button @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditCommentModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
      note: 'Whether modal should be visible or not',
    },
    message: {
      type: String,
      required: false,
      default: '',
      note: 'Any message that should be displayed above the input',
    },
    text: {
      type: String,
      required: true,
      default: '',
      note: 'The existing text of the comment',
    },
  },
  data() {
    return {
      editedComment: '',
    };
  },
  methods: {
    edit() {
      this.$emit('edit', this.editedComment);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  mounted() {
    this.editedComment = this.text;
  },
};
</script>
