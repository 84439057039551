<template>
  <div
    class="p-2 flex flex-row gap-2 justify-between items-center"
  >
    <div>
      <div class='line-clamp-1'>
        <span>List:</span>
        <span class='font-bold'>&nbsp;{{ title }}</span>
      </div>
      <p>Price: {{ formattedPrice(price) }}</p>
    </div>
    <div>
      <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListProduct',
  props: {
    title: String,
    price: Number,
  },
  methods: {
    formattedPrice(price) {
      const dollars = (price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
  },
};
</script>