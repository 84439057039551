<template>
  <div
    v-if="this.metaRating < 5"
    class="bg-dark-orange z-10 rounded-l-full pr-2 w-18 md:w-24 h-16 md:h-20 flex items-center"
  >
    <div
      class="rounded-full h-12 md:h-16 w-12 md:w-16 my-1 p-2 bg-dark-orange border-4 border-dark-blue text-dark-blue items-center ml-2 flex justify-center font-bold text-base md:text-lg"
    >
      {{ this.metaRating }}
    </div>
  </div>
  <div v-else class="bg-chambray z-10 rounded-l-full pr-2 w-18 md:w-24 h-16 md:h-20 flex items-center">
    <div
      class="rounded-full h-12 md:h-16 w-12 md:w-16 my-1 p-2 bg-white border-4 border-dark-blue text-dark-blue items-center ml-2 flex justify-center font-bold text-base md:text-lg"
    >
      {{ this.metaRating }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MetaRating',
  props: {
    metaRating: {
      type: Number,
    },
  },
};
</script>
