<template>
  <div class="flex flex-col gap-4 md:gap-6 w-full px-4 py-4">
    <div class="flex flex-col gap-2">
      <div class="flex flex-col md:flex-row items-start justify-center md:justify-start md:items-center gap-4">
        <img class="hidden md:inline rounded-2xl w-12 h-12" :src="profilePhoto" />
        <div class="flex flex-row justify-between w-full items-center">
          <p class="review-text text-lg text-blue">What do you want to review?</p>
          <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
        </div>
      </div>
      <div v-show="this.showMissingTitleError === true">
        <p class="text-red-500">{{ this.missingTitleErrorMessage }}</p>
      </div>
      <div>
        <input v-model="this.title" type="text" placeholder="Title" class="text-blue" />
      </div>
    </div>
    <div>
      <div class="flex flex-col gap-2">
        <p class="text-start text-lg text-blue">Choose a category</p>
        <div v-show="this.showMissingCategoryError === true">
          <p class="text-red-500">{{ this.missingCategoryErrorMessage }}</p>
        </div>
        <div class="relative h-8">
          <CategoryDropdown class="absolute w-full" :placeholder="'Find a category'" />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        <p class="text-start text-lg text-blue mt-4">Tell us more</p>
      </div>
      <div>
        <input v-model="this.address" class="text-blue" type="text" placeholder="Location" />
      </div>
      <div>
        <input v-model="this.link" class="text-blue" type="text" placeholder="Link" />
      </div>
    </div>
    <div>
      <UploadMedia :text="'Upload Media'" />
    </div>
    <div>
      <div class="relative float-right" @click="nextModal">
        <font-awesome-icon
          class="text-3xl cursor-pointer"
          :color="navigationArrowColor"
          :icon="['fas', 'arrow-right']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store';
import UploadMedia from '@/components/EditSlydepost/EditSlydepostUploadMedia.vue';
import CategoryDropdown from '@/components/EditSlydepost/EditSlydepostCategoryDropdown.vue';

export default {
  name: 'EditSlydepostInfo',
  emits: ['next'],
  data() {
    return {
      showMissingTitleError: false,
      showMissingCategoryError: false,
      missingTitleErrorMessage: 'Please enter a title',
      missingCategoryErrorMessage: 'Please select a category',
    };
  },
  components: { CategoryDropdown, UploadMedia },
  methods: {
    nextModal() {
      if (this.title == null || this.title.trim() === '') {
        this.showMissingTitleError = true;
        return;
      }
      if (this.category == null || this.category === '') {
        this.showMissingCategoryError = true;
        return;
      }
      this.$emit('next');
    },
  },
  computed: {
    title: {
      get() {
        return store.getters['editPostStoreModule/getTitle'];
      },
      set(title) {
        store.commit('editPostStoreModule/setTitle', title);
      },
    },
    category: {
      get() {
        return store.getters['editPostStoreModule/getCategory'];
      },
      set(category) {
        store.commit('editPostStoreModule/setCategory', category);
      },
    },
    subcategory: {
      get() {
        return store.getters['editPostStoreModule/getSubcategory'];
      },
      set(subcategory) {
        store.commit('editPostStoreModule/setSubcategory', subcategory);
      },
    },
    address: {
      get() {
        return store.getters['editPostStoreModule/getAddress'];
      },
      set(address) {
        store.commit('editPostStoreModule/setAddress', address);
      },
    },
    link: {
      get() {
        return store.getters['editPostStoreModule/getLink'];
      },
      set(link) {
        store.commit('editPostStoreModule/setLink', link);
      },
    },
    profilePhoto: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    navigationArrowColor() {
      if (this.title == null || this.title.trim() === '') {
        return '#E0E0E0';
      }
      if (this.category == null || this.category === '') {
        return '#E0E0E0';
      }
      return '#30C3E3';
    },
  },
};
</script>
