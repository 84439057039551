<template>
  <div class='p-5'>
    <CreateProduct
      @exit='back'
    />
  </div>
</template>

<script>
import CreateProduct from '@/components/Insider/Products/CreateProduct.vue';

export default {
  name: 'CreateInsiderProduct',
  components: {
    CreateProduct
  },
  methods: {
    back(){
      this.$router.back();
    }
  }
}
</script>