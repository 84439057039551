<template>
  <div class="md:w-52 bg-white md:border md:border-solid md:border-light-light-gray md:rounded-t-3xl">
    <AreYouSureModal
      :showModal="this.showBlockUserModal"
      :message="'Are you sure you want to block this user?'"
      @accept="this.blockUser"
      @cancel="this.showBlockUserModal = false"
    />
    <AreYouSureModal
      :showModal="this.showReportUserModal"
      :message="'Are you sure you want to report this user?'"
      @accept="this.reportUser"
      @cancel="this.showReportUserModal = false"
    />
    <div class="md:w-52 px-5 py-2 md:px-0 md:py-0 rounded-t-3xl flex flex-row md:flex-col items-center gap-2 md:gap-0">
      <img class="h-10 w-10 md:h-52 md:w-52 rounded-xl md:rounded-b-none md:rounded-t-3xl" :src="getProfilePicture" />
      <div class="flex flex-row md:flex-col justify-between md:pt-3 w-full items-center md:px-5 md:py-2">
        <div class="flex md:flex-row justify-between w-full">
          <div class="flex flex-col gap-1 items-start">
            <p class="text-sm capitalize">{{ this.firstName }} {{ this.lastName }}</p>
            <p class="hidden md:inline text-xs font-bold">{{ numberOfSlydeposts }} Slydeposts</p>
          </div>
          <div class="hidden md:flex md:flex-col gap-4 md:gap-0">
            <router-link :to="{ name: 'UserProfile', params: { userId: this.friendId } }">
              <div class="text-sm text-right text-moonstone font-bold">
                <p>View</p>
                <p>Profile</p>
              </div>
            </router-link>
            <div class="flex flex-row items-start py-1">
              <MoreOptionsButton
                class="text-left"
                v-click-outside="closeOptions"
                @click="this.showOptions = !this.showOptions"
                @reportUser="this.showReportUserModal = true"
                @blockUser="this.showBlockUserModal = true"
                :options="this.options"
                :showOptions="this.showOptions"
                :icon-color="'black'"
              />
            </div>
          </div>
        </div>
        <hr class="hidden md:inline w-full" />
        <div class="h-10 w-full flex flex-row justify-between bg-white items-center gap-2">
          <button @click.prevent="acceptFriendRequest" class="h-6 w-18 p-0 text-sm">Accept</button>
          <div @click="ignoreFriendRequest" class="flex flex-row items-center gap-1 cursor-pointer">
            <p class="text-sm">Ignore</p>
            <font-awesome-icon class="hidden md:inline" icon="fa-solid fa-eye-slash" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
// import ProfileCard from "@/components/UserStates/ProfileCard.vue";

export default {
  name: 'Request',
  props: {
    firstName: {
      type: String,
      required: true,
      default: '',
    },
    lastName: {
      type: String,
      required: true,
      default: '',
    },
    numberOfSlydeposts: {
      type: Number,
      required: true,
      note: 'the number of slydeposts the user has created',
    },
    relationshipId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
    },
    friendId: {
      type: String,
    },
    profilePictureUrl: {
      type: String,
    },
  },
  components: {
    MoreOptionsButton,
    AreYouSureModal,
    // ProfileCard
  },
  data() {
    return {
      showOptions: false,
      options: [
        {
          text: 'Block',
          onClickEmit: 'blockUser',
        },
        {
          text: 'Report',
          onClickEmit: 'reportUser',
        },
      ],
      showBlockUserModal: false,
      showReportUserModal: false,
    };
  },
  methods: {
    closeOptions() {
      this.showOptions = false;
    },
    blockUser() {
      this.$store.dispatch('relationshipStoreModule/blockUser', this.friendId);
      this.$store.commit('relationshipStoreModule/blockUserFromRequests', this.friendId);
      this.showBlockUserModal = false;
    },
    reportUser() {
      this.showReportUserModal = false;
    },
    acceptFriendRequest() {
      let payload = {
        relationshipId: this.relationshipId,
        userId: this.userId,
        friendId: this.friendId,
      };
      this.$store.dispatch('relationshipStoreModule/acceptFriendRequest', payload);
    },
    ignoreFriendRequest() {
      this.$store.dispatch('relationshipStoreModule/ignoreFriendRequest', this.relationshipId);
    },
  },
  computed: {
    getProfilePicture() {
      return this.profilePictureUrl != null
        ? this.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
  },
};
</script>
