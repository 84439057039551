<template>
  <div>
    <router-link class="flex flex-row justify-between items-center" :to="{ name: 'UserProfile', params: { userId: userId } }">
      <div class="flex flex-col gap-1">
        <div class="flex flex-row gap-2 items-center">
          <img class="w-6 h-6 rounded-full" :src="profilePictureUrl" />
          <p>{{ firstName }} {{ lastName }}</p>
        </div>
      </div>
      <div>
        <font-awesome-icon
          :icon="['fas', 'chevron-right']"
          :color="'#30C3E3'"
        />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProductCustomer',
  props: {
    firstName: String,
    lastName: String,
    profilePicture: String,
    userId: String,
  },
  computed: {
    profilePictureUrl(){
      if(this.profilePicture == null) {
        return 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png'
      } else {
        return this.profilePicture
      }
    }
  }
};
</script>