<template>
  <div class="card-carousel">
    <div :class="this.cardImgClass()">
      <div :style="images && images.length ? `height: ${maxHeight}px` : ''" class="flex justify-center">
        <img
          class="main-image"
          :class="images && images.length ? 'max-h-[680px] object-contain' : ''"
          v-lazy="currentImage()"
          alt=""
        />
      </div>
    </div>
    <div v-if="this.images != null && this.images.length > 1" class="thumbnails-container">
      <div class="thumbnails">
        <div
          v-for="(image, index) in sortedImages"
          :key="image.id"
          :class="['thumbnail-image', activeImage == index ? 'active' : '']"
          @click="activateImage(index)"
        >
          <img class="object-contain" v-lazy="this.getImgSrc(image)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaCarousel',
  data() {
    return {
      //Index of the active image
      activeImage: 0,
      maxHeight: 100,
      currentImageFileType: '',
    };
  },
  props: ['startingImage', 'images', 'autoSlideInterval', 'showProgressBar', 'numberOfImages', 'isPreview'],
  computed: {
    sortedImages() {
      let images = [...this.images];
      let sorted = images.sort((a, b) => a.order - b.order);
      return sorted;
    },
  },
  methods: {
    currentImage() {
      // there is at least 1 image to display
      if (this.images && this.images.length) {
        this.currentImageFileType = this.sortedImages[this.activeImage].type;
        return this.sortedImages[this.activeImage].presignedUrl != null
          ? this.sortedImages[this.activeImage].presignedUrl
          : this.sortedImages[this.activeImage].file;
      } // no images are being uploaded, display default background
      else {
        this.currentImageFileType = 'IMAGE';
        return require('@/assets/no-photo-background.png');
      }
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
    cardImgClass() {
      if (this.images == null || this.images.length === 0) {
        return 'card-img-no-img';
      } else if (this.images.length === 1) {
        return 'card-single-img';
      } else {
        return 'card-img';
      }
    },
    getImgSrc(img) {
      if (img.presignedUrl != null) {
        return img.presignedUrl;
      } else {
        return img.file;
      }
    },
    isMobileView() {
      return window.innerWidth < 640;
    },
    calculateMaxHeight() {
      let isMobile = this.isMobileView();
      if (this.isPreview && isMobile) {
        this.maxHeight = 380;
        return;
      } else if (this.isPreview && !isMobile) {
        this.maxHeight = 680;
        return;
      }
      let maxImgHeight = isMobile ? 380 : 680;
      this.images.forEach((image) => {
        const img = new Image();
        img.onload = () => {
          const calculatedHeight = Math.min(img.height, maxImgHeight);
          if (calculatedHeight > this.maxHeight) {
            this.maxHeight = calculatedHeight;
          }
        };
        img.onerror = () => {
          console.error('Could not load image');
        };
        img.src = image.presignedUrl;
      });
    },
  },
  watch: {
    images: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.calculateMaxHeight();
        }
      },
    },
  },
};
</script>

<style scoped>
.main-image {
  margin: 0;
  padding: 0;
}

* {
  background-color: #fff;
}

.thumbnails-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.card-carousel {
  user-select: none;
  position: relative;
  background: rgba(0, 0, 0, 0);
}

.progressbar {
  display: block;
  width: 100%;
  height: 5px;
  position: absolute;
  background-color: rgba(221, 221, 221, 0.25);
  z-index: 1;
}

.progressbar > div {
  background-color: rgba(255, 255, 255, 0.52);
  height: 100%;
}

.thumbnails {
  height: 128px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.thumbnail-image {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.thumbnail-image > img {
  width: 100%;
  height: auto;
  transition: all 250ms;
  max-height: 120px;
}

.thumbnail-image:hover > img,
.thumbnail-image.active > img {
  opacity: 0.6;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.5);
}

.card-img {
  /* position: relative; */
}

/*.card-img-no-img img {*/
/*  width: 680px;*/
/*}*/

.card-img > img {
  display: block;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
  max-height: 680px;
  min-height: 100px;
}

.card-single-img > img {
  display: block;
  margin: 0 auto;
  object-fit: contain;
  /*max-width: 680px;*/
  min-height: 100px;
  max-height: 680px;
}

.actions {
  font-size: 1.5em;
  /* height: 40px; */
  /* position: absolute; */
  top: 50%;
  /* margin-top: -20px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #585858;
  background: rgba(0, 0, 0, 0);
}

.prev {
  margin-right: auto;
  padding-left: 16px;
  font-size: 40px;
  color: #015d79;
}

.next {
  margin-left: auto;
  padding-right: 16px;
  font-size: 40px;
  color: #015d79;
}

.actions > span {
  cursor: pointer;
  transition: all 250ms;
  font-size: 45px;
}

.actions > span.prev {
  margin-left: 5px;
  color: #015d79;
  background: rgba(0, 0, 0, 0);
}

.actions > span.next {
  margin-right: 5px;
  background: rgba(0, 0, 0, 0);
  color: #015d79;
  /* margin-left: -56px; */
}

.actions > span:hover {
  color: #eee;
}
</style>
