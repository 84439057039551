<template>
  <div class="container">
    <h2>Forgot your password?</h2>
    <p>Please enter you email address to get a password reset code</p>
    <form @submit.prevent="forgotPassword">
      <div class="forgot-password-input-container">
        <input type="email" v-model="email" placeholder="Email Address" id="input-email" />
      </div>
      <div id="forgot-password-button-container">
        <button>Reset Password</button>
      </div>
    </form>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import router from '@/router';
import store from '@/store/store';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async forgotPassword() {
      try {
        await Auth.forgotPassword(this.email);
        store.state.userEmail = this.email;
        router.push('forgotPasswordSubmit');
      } catch (error) {
        console.error('error sending forgot password code', error);
      }
    },
  },
};
</script>

<style scoped></style>
