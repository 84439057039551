<template>
  <div>
    <div :class="!this.listDescription ? 'mb-0' : 'md:mb-3'">
      <div
        :style="this.backgroundImage"
        class="relative bg-center bg-no-repeat bg-cover"
      >
        <div class="cursor-pointer absolute top-2 md:top-3 right-2 w-[22px]">
          <div class="flex flex-row gap-2">
            <div>
              <MoreOptionsButton
                class="text-left text-xl"
                v-if="this.isLoggedInUserListOwner"
                v-click-outside="closeOptions"
                @click="this.showOwnerOptions = !this.showOwnerOptions"
                @edit="openEditListModal"
                @delete="this.checkDeleteModal = true"
                :options="this.listOwnerOptions"
                :showOptions="this.showOwnerOptions"
              />
              <MoreOptionsButton
                class="text-left text-xl"
                v-else
                v-click-outside="closeOptions"
                @click="this.showViewerOptions = !this.showViewerOptions"
                @report="this.showReportModal = true"
                :options="this.listViewerOptions"
                :showOptions="this.showViewerOptions"
              />
            </div>
          </div>
        </div>
        <div class="pt-28">
          <div
            class="line-clamp-3 text-center text-[36px] font-black leading-[48px] text-white tracking-[1px]"
          >
            {{ listTitle }}
          </div>
        </div>
        <div
          class="font-[900] text-[12px] tracking-[2px] text-center text-white pt-2"
        >
          by
          <router-link
            v-if="userId != null && userId !== ''"
            :to="{ name: 'UserProfile', params: { userId: userId } }"
          >
            <p class="uppercase underline">{{ displayName }}</p>
          </router-link>
        </div>
        <div
          v-if="!this.isLoggedInUserListOwner"
          class="flex justify-center items-center pt-2"
        >
          <button
            @click.prevent="followList"
            class="uppercase h-9 w-32 text-xs bg-dark-orange border-none"
          >
            {{ followText }}
          </button>
        </div>
        <div class="flex flex-row pt-4 justify-center">
          <div
            @click="this.showFollows()"
            :style="this.followsSelectedStyle()"
            class="w-28 flex flex-col justify-center items-center rounded-t-2xl"
          >
            <p class="font-[500] text-[24px] tracking-[2px] pt-2">
              {{ totalFollows }}
            </p>
            <p class="font-[900] text-[10px] tracking-[2px] uppercase">
              Follows
            </p>
            <p class="font-bold text-xl text-transparent">|</p>
          </div>
          <div
            @click="this.listPosts()"
            :style="this.listPostsSelectedStyle()"
            class="w-28 flex flex-col justify-center items-center bg-white rounded-t-2xl gap-0 text-blue"
          >
            <div class="font-[500] text-[24px] tracking-[2px] pt-2">
              {{ totalPosts }}
            </div>
            <div class="font-[900] text-[10px] tracking-[2px] uppercase">
              Slydeposts
            </div>
            <div class="font-bold text-xl text-blue">|</div>
          </div>
        </div>
      </div>
      <div v-if='status === `SUBSCRIPTION` || status === `ONE_TIME`' class='h-8 w-full bg-dark-blue flex flex-row gap-2 items-center justify-center'>
        <font-awesome-icon class='text-lg text-yellow-400' :icon="['far', 'gem']" />
        <p class='text-white'>PREMIUM LIST</p>
      </div>
      <div class='flex flex-col lg:flex-row items-center justify-center bg-white'>
        <div
          class="lg:w-1/3 flex flex-row items-center justify-center px-4 pt-4 pb-2 lg:pb-4"
        >
          <div class="flex flex-row">
            <div class="pr-2 w-[20%] flex items-center justify-end text-right">
              <p class="font-black text-[10px] tracking-[2px]">LIST BY</p>
            </div>
            <div class="flex items-center">
              <div class="">
                <router-link
                  v-if="userId != null && userId !== ''"
                  :to="{ name: 'UserProfile', params: { userId: userId } }"
                >
                  <img
                    :src="userProfileImage"
                    class="w-[38px] h-[38px] rounded-full md:rounded-[14px]"
                  />
                </router-link>
              </div>
              <div class="ml-2 leading-[14px]">
                <router-link
                  v-if="userId != null && userId !== ''"
                  :to="{ name: 'UserProfile', params: { userId: userId } }"
                >
                  <h2 class="uppercase font-[700] text-[16px] text-blue">
                    {{ displayName }}
                  </h2>
                </router-link>
                <p class="font-[400] text-[12px] text-dark-dark-gray">
                  Updated {{ formattedDate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="lg:w-2/3 text-[14px] font-[500 leading-[24px] pb-4 bg-white"
        >
          <ReadMoreText
            class="line-clamp-4"
            :text="this.listDescription"
            :thisStyle="'text-align: left;'"
            :readMoreCount="250"
            :showLimitedLines="false"
          />
        </div>
      </div>

    </div>
    <div
      v-if="(status === `SUBSCRIPTION` || status === `ONE_TIME`) && productsList != null && productsList.length != 0 && loggedInUserId !== userId"
      class="fixed bottom-0 left-0 right-0"
    >
      <div
        class="bg-gradient-to-b from-transparent to-dark-blue h-12 w-full"
      ></div>
      <div class="flex flex-col gap-2 items-center px-5 py-5 bg-dark-blue">
        <p class="text-2xl text-white font-bold">Unlock full list access by</p>
        <div class="flex flex-row gap-2 items-center pb-1">
          <img
            :src="userProfileImage"
            class="w-[38px] h-[38px] rounded-full md:rounded-[14px]"
          />
          <p class="font-medium text-white" v-if="username != null">
            @{{ username }}
          </p>
          <p class="font-medium text-white" v-else>
            {{ firstName }} {{ lastName }}
          </p>
        </div>
        <div
          v-for="product in productsList"
          :key="product.id"
          @click="selectPurchaseOption(product)"
          class="py-4 px-4 border-2 border-moonstone rounded-xl text-white w-full"
          :class='selectedPurchaseOption != null && product.id === selectedPurchaseOption.id ? `bg-moonstone` : ``'
        >
          <div class="flex flex-row justify-between items-center">
            <p class="text-lg font-bold">{{ productTitleText(product) }}</p>
            <p class="text-lg font-bold">{{ productPriceText(product) }}</p>
          </div>
          <p class="text-sm">{{ productDescriptionText(product) }}</p>
        </div>
        <button @click='subscribeToList' class="bg-[#C4E3ED] text-dark-blue w-full rounded-lg h-12">
          {{ productButtonText }}
        </button>
        <div class='text-center text-white text-sm'>
          <span>By purchasing content, you are accepting our&nbsp;</span>
          <span class='underline cursor-pointer' @click='goToInsiderTermsOfService'>Insider Terms of Service and Policies.</span>
        </div>
      </div>
    </div>
    <div>
      <div v-if="view === 'slydeposts'" class="flex flex-col gap-4 px-2">
        <ListPost
          v-for="post in posts"
          :user-id="post.userId"
          :key="post.id"
          :id="post.id"
          :username="post.username"
          :first-name="post.userFirstName"
          :last-name="post.userLastName"
          :address="post.address"
          :title="post.title"
          :category="post.category"
          :meta-rating="post.metaRating"
          :updated-at="post.updatedAt"
          :media="post.media"
          :aspects="post.tags"
          :list-id="this.listId"
          :lists="post.lists"
          :is-owner="isLoggedInUserListOwner"
        />
      </div>
      <div v-else-if="view === 'follows'" class="flex flex-col gap-2">
        <ListFollower
          v-for="follow in follows"
          :user-id="follow.userId"
          :key="follow.id"
          :first-name="follow.userFirstName"
          :last-name="follow.userLastName"
          :profile-picture-url="follow.userProfilePicturePresignedUrl"
        />
      </div>
    </div>

    <EditListModal ref="editListModal" />
    <AreYouSureModal
      :showModal="checkDeleteModal"
      :message="'Are you sure you want to delete your List?'"
      @accept="this.deleteList()"
      @cancel="checkDeleteModal = false"
    />
  </div>
</template>

<script>
import ListPost from '@/components/ViewLists/ListPost.vue';
import EditListModal from '@/components/Modals/EditListModal.vue';
import { mapGetters } from 'vuex';
import ReadMoreText from '@/components/Utility/ReadMoreText.vue';
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import ListFollower from '@/components/ViewLists/ListFollower.vue';

export default {
  name: 'SingleList',
  data() {
    return {
      showOwnerOptions: false,
      showViewerOptions: false,
      view: 'slydeposts',
      slydepostSelected: true,
      followsSelected: false,
      showEditModalStatus: false,
      showEditListModal: false,
      showDeleteModal: false,
      showReportModal: false,
      showSingleListOptions: false,
      showSuccessModalStatus: false,
      checkDeleteModal: false,
      selectedPurchaseOption: null,
      listOwnerOptions: [
        {
          text: 'Edit',
          onClickEmit: 'edit',
        },
        {
          text: 'Delete',
          onClickEmit: 'delete',
        },
      ],
      listViewerOptions: [
        {
          text: 'Report',
          onClickEmit: 'report',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      listId: 'singleListStoreModule/getListId',
      firstName: 'singleListStoreModule/getFirstName',
      lastName: 'singleListStoreModule/getLastName',
      username: 'singleListStoreModule/getUsername',
      userId: 'singleListStoreModule/getUserId',
      profilePicture: 'singleListStoreModule/getProfilePicture',
      listTitle: 'singleListStoreModule/getTitle',
      listDescription: 'singleListStoreModule/getDescription',
      listUpdatedDate: 'singleListStoreModule/getUpdated',
      follows: 'singleListStoreModule/getFollows',
      totalFollows: 'singleListStoreModule/getTotalFollows',
      totalPosts: 'singleListStoreModule/getTotalPosts',
      userProfileImage:
        'singleListStoreModule/getUserProfilePicturePresignedUrl',
      posts: 'singleListStoreModule/getPosts',
      loggedInUserId: 'authStoreModule/getUserId',
      isFollowing: 'singleListStoreModule/getIsFollowing',
      status: 'singleListStoreModule/getStatus',
      presignedUrl: 'singleListStoreModule/getPresignedUrl',
      products: 'singleListStoreModule/getInsiderProducts',
    }),
    displayName() {
      return this.username != null
        ? this.username
        : `${this.firstName} ${this.lastName}`;
    },
    followText() {
      const userListFollow =
        this.$store.getters['singleListStoreModule/getUserListFollow'];
      if (userListFollow == null) {
        return 'FOLLOW';
      } else {
        return userListFollow.status;
      }
    },
    isLoggedInUserListOwner() {
      const loggedInUserId = this.$store.getters['authStoreModule/getUserId'];
      return loggedInUserId === this.userId;
    },
    backgroundImage() {
      if (this.presignedUrl) {
        return `background-image: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)),url("${this.presignedUrl}")`;
      } else {
        return `background-color: #015D79`;
      }
    },
    formattedDate() {
      if (this.listUpdatedDate == null) {
        return new Date().toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      } else {
        return new Date(this.listUpdatedDate).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      }
    },
    productsList() {
      let allProducts = [];
      if(this.products == null){
        return [];
      }
      for (let i = 0; i < this.products.length; i++) {
        for (let j = 0; j < this.products[i].prices.length; j++) {
          if(this.products[i].productType === `INSIDER_SUBSCRIPTION` || this.products[i].contentId === this.listId){
            let product = {
              id: this.products[i].prices[j].id,
              productType: this.products[i].productType,
              priceType: this.products[i].prices[j].priceType,
              price: this.products[i].prices[j].price,
              priceId: this.products[i].prices[j].priceId,
            };
            allProducts.push(product);
          }
        }
      }
      return allProducts;
    },
    productButtonText() {
      if (this.selectedPurchaseOption == null) {
        return 'Subscribe';
      }
      if (this.selectedPurchaseOption.productType === 'INSIDER_SUBSCRIPTION') {
        if (this.selectedPurchaseOption.priceType === 'MONTHLY') {
          return 'Subscribe';
        } else if (this.selectedPurchaseOption.priceType === 'YEARLY') {
          return 'Subscribe';
        }
      } else if (this.selectedPurchaseOption.productType === 'LIST_PURCHASE') {
        return 'Purchase';
      } else {
        return 'Subscribe';
      }
    },
  },
  mounted() {
    const listId = this.$route.params.listId;
    if (listId != null) {
      this.$store.dispatch('singleListStoreModule/getListById', listId);
    }
  },
  components: {
    ListPost,
    EditListModal,
    ReadMoreText,
    MoreOptionsButton,
    AreYouSureModal,
    ListFollower,
  },
  methods: {
    goToInsiderTermsOfService(){
      this.$store.commit('legalStoreModule/setPage', 'insider-terms-for-customers');
      this.$router.push({ name: 'Legal' });
    },
    subscribeToList(){
      let payload;
      if(this.selectedPurchaseOption.productType === 'LIST_PURCHASE'){
        console.log(`list purchase selected`)
        payload = {
          customerUserId: this.loggedInUserId,
          insiderUserId: this.userId,
          priceId: this.selectedPurchaseOption.priceId,
          contentId: this.listId,
          contentType: 'list'
        }
      } else if (this.selectedPurchaseOption.productType === 'INSIDER_SUBSCRIPTION'){
        payload = {
          customerUserId: this.loggedInUserId,
          insiderUserId: this.userId,
          priceId: this.selectedPurchaseOption.priceId,
          contentId: this.userId,
          contentType: 'subscription'
        }
      }
      this.$store.dispatch('singleListStoreModule/subscribe', payload)
    },
    selectPurchaseOption(product) {
      this.selectedPurchaseOption = product;
    },
    productTitleText(product) {
      if (product.productType === 'INSIDER_SUBSCRIPTION') {
        if (product.priceType === 'MONTHLY') {
          return 'Monthly Subscription';
        } else if (product.priceType === 'YEARLY') {
          return 'Yearly Subscription';
        }
      } else if (product.productType === 'LIST_PURCHASE') {
        return 'One Time Purchase';
      }
    },
    productDescriptionText(product) {
      if (product.productType === 'INSIDER_SUBSCRIPTION') {
        if (product.priceType === 'MONTHLY') {
          return 'Access this list with a monthly subscription';
        } else if (product.priceType === 'YEARLY') {
          return 'Access this list with a yearly subscription';
        }
      } else if (product.productType === 'LIST_PURCHASE') {
        return 'Buy this list once for all access';
      }
    },
    productPriceText(product) {
      let cents = product.price;
      const dollars = (cents / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
    openEditListModal() {
      this.$store.commit('editListStoreModule/setListId', this.listId);
      this.$store.commit('editListStoreModule/setTitle', this.listTitle);
      this.$store.commit(
        'editListStoreModule/setDescription',
        this.listDescription
      );
      this.$store.commit('editListStoreModule/setStatus', this.status);
      this.$store.commit(
        'editListStoreModule/setExistingImageUrl',
        this.presignedUrl
      );
      this.$refs.editListModal.openModal();
    },
    closeOptions() {
      this.showViewerOptions = false;
      this.showOwnerOptions = false;
    },
    backgroundGradient() {
      return 'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))';
    },
    listPosts() {
      this.view = 'slydeposts';
      this.slydepostSelected = true;
      this.followsSelected = false;
    },
    showFollows() {
      this.view = 'follows';
      this.slydepostSelected = false;
      this.followsSelected = true;
    },
    listPostsSelectedStyle() {
      if (this.slydepostSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    followsSelectedStyle() {
      if (this.followsSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    showEditModal() {
      this.showEditModalStatus = true;
    },
    closeSingleListOptionsModal() {
      this.showSingleListOptions = false;
    },
    closeSuccessModal() {
      this.showSuccessModalStatus = false;
    },
    handleFileChange() {
      const selectedFile = this.$refs.fileInput.files[0];
      if (selectedFile) {
        // Do some operations to upload selectedFile
      }
    },
    followList() {
      const userListFollow =
        this.$store.getters['singleListStoreModule/getUserListFollow'];
      if (userListFollow == null) {
        const listId = this.$route.params.listId;
        this.$store.commit('singleListStoreModule/setIsFollowing', true);
        this.$store.dispatch('singleListStoreModule/followList', listId);
      } else {
        this.$store.commit('singleListStoreModule/setIsFollowing', false);
        this.$store.dispatch(
          'singleListStoreModule/unfollowList',
          userListFollow.id
        );
      }
    },
    unfollowList() {
      if (this.isFollowing) {
        this.$store.commit('singleListStoreModule/setIsFollowing', false);
        const userListFollow =
          this.$store.getters['singleListStoreModule/getUserListFollow'];
        this.$store.dispatch(
          'singleListStoreModule/unfollowList',
          userListFollow.id
        );
      }
    },
    deleteList() {
      if (!this.isLoggedInUserListOwner) {
        return;
      }
      this.$router.push({ name: 'Lists' });
      const listId = this.$route.params.listId;
      this.$store.dispatch('singleListStoreModule/deleteList', listId);
    },
  },
};
</script>
<style scoped>
#editListDiv::-webkit-scrollbar {
  display: none;
}

#editListDiv {
  scrollbar-width: none;
}

.modal-animation {
  animation: scaleUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes scaleUp {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>