<template>
  <div class="px-4 lg:px-0 lg:pt-0 flex flex-col gap-2">
    <div class="pt-4 md:hidden">
      <button @click.prevent="back">
        <font-awesome-icon :icon="['fas', 'chevron-left']" color="white" />
        Subscriptions
      </button>
    </div>
    <div class="border-2 border-moonstone rounded-xl p-2">
      <p>Insider: {{ name }}</p>
      <p>Type: {{ formattedType }}</p>
      <p>Cost: {{ formattedPrice }}</p>
      <p class='capitalize'>Status: {{ formattedStatus }}</p>
      <p v-if="status === `CANCELLED`">Expires: {{ formattedEndDate }}</p>
    </div>
    <LoaderButton
      v-if='status === `ACTIVE`'
      :status="cancelSubscriptionStatus"
      :waiting-text="`Cancel Subscription`"
      :waiting-icon="null"
      :in-progress-text="`Cancelling...`"
      :done-text="`Cancelled`"
      :error-text="`Error. Try again?`"
      @click="cancelSubscription"
    />
    <LoaderButton
      v-else-if='status === `CANCELLED`'
      :status='reactivateSubscriptionStatus'
      :waiting-icon='null'
      :waiting-text='`Reactivate Subscription`'
      :in-progress-text='`Reactivating...`'
      :done-text='`Activated!`'
      :error-text='`Error. Try again?`'
      @click='reactivateSubscription'
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';

export default {
  name: 'ManageSubscription',
  data() {
    return {};
  },
  components: {
    LoaderButton,
  },
  computed: {
    ...mapGetters({
      id: 'manageSubscriptionStoreModule/getId',
      name: 'manageSubscriptionStoreModule/getName',
      userId: 'manageSubscriptionStoreModule/getUserId',
      price: 'manageSubscriptionStoreModule/getPrice',
      type: 'manageSubscriptionStoreModule/getType',
      status: 'manageSubscriptionStoreModule/getStatus',
      endDate: 'manageSubscriptionStoreModule/getEndDate',
      cancelSubscriptionStatus:
        'manageSubscriptionStoreModule/getCancelSubscriptionStatus',
      reactivateSubscriptionStatus: 'manageSubscriptionStoreModule/getReactivateSubscriptionStatus',
    }),
    formattedType() {
      if (this.type === 'MONTHLY') {
        return 'Monthly';
      } else if (this.type === 'YEARLY') {
        return 'Yearly';
      }
    },
    formattedEndDate() {
      return new Date(this.endDate).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedStatus(){
      if(this.status === 'ACTIVE'){
        return 'active';
      } else if(this.status === 'CANCELLED'){
        return 'cancelled'
      }
    },
    formattedPrice() {
      const dollars = (this.price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
    async cancelSubscription() {
      await this.$store.dispatch('manageSubscriptionStoreModule/cancelSubscription');
    },
    async reactivateSubscription() {
      await this.$store.dispatch('manageSubscriptionStoreModule/reactivateSubscription')
    }
  },
  unmounted(){
    this.$store.commit('manageSubscriptionStoreModule/clear')
  }
};
</script>