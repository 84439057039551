import client from '@/axios/client';

const trendingPostStoreModule = {
  namespaced: true,
  state: {
    viewablePosts: [],
    posts: [],
    isLoading: false,
    batchAddNumber: 10,
  },
  getters: {
    getViewablePosts(state) {
      return state.viewablePosts;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    addSlydepost(state, post) {
      state.posts.push(post);
    },
    clearPosts(state) {
      state.posts = [];
      state.viewablePosts = [];
    },
    addPostsToViewablePosts(state) {
      let postsLength = state.viewablePosts.length;
      let endLength = state.viewablePosts.length + state.batchAddNumber;
      let newPosts = state.posts.slice(postsLength, endLength);
      for (let i = 0; i < newPosts.length; i++) {
        state.viewablePosts.push(newPosts[i]);
      }
    },
    setIsLoading(state, loading) {
      state.isLoading = loading;
    },
    clear(state) {
      state.posts = [];
      state.viewablePosts = [];
      state.isLoading = false;
    },
  },
  actions: {
    loadLast7Days({ commit, getters, rootGetters }) {
      commit('clearPosts');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-100-posts-last-7-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addSlydepost', response.data.results[i]);
          }
          commit('addPostsToViewablePosts');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast24Hours({ commit, getters, rootGetters }) {
      commit('clearPosts');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-posts-last-24-hours.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addSlydepost', response.data.results[i]);
          }
          commit('addPostsToViewablePosts');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast30Days({ commit, getters, rootGetters }) {
      commit('clearPosts');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-posts-last-30-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addSlydepost', response.data.results[i]);
          }
          commit('addPostsToViewablePosts');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast365Days({ commit, getters, rootGetters }) {
      commit('clearPosts');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-posts-last-365-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addSlydepost', response.data.results[i]);
          }
          commit('addPostsToViewablePosts');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadAllTime({ commit, getters, rootGetters }) {
      commit('clearPosts');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-posts-last-all-time.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addSlydepost', response.data.results[i]);
          }
          commit('addPostsToViewablePosts');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
  },
};

export { trendingPostStoreModule };
