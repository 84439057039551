<template>
  <div>
    <div v-click-outside="closeCreateDropdown" class="flex justify-center items-center relative z-30 cursor-pointer">
      <div
        @click="toggleCreateDropdown"
        class="flex justify-center items-center rounded-full bg-blue w-full relative h-[2.25rem] z-20"
      >
        <p class="uppercase text-[11px] md:text-sm tracking-[1.6px] text-white">Create</p>
        <div
          :style="
            this.openCreateDropdown
              ? 'transition: all .2s ease-in-out; -webkit-transform: rotate(45deg);'
              : 'transition: all .2s ease-in-out;'
          "
          class="border-[1.6px] border-ice w-9 h-9 rounded-full flex bg-blue absolute right-0 top-0"
        >
          <svg class="m-auto" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="+"
              opacity="0.8"
              d="M0.240234 4.88223V7.25554H4.87978V11.9091H7.25783V7.25554H11.8974V4.88223H7.25783V0.251953H4.87978V4.88223H0.240234Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <ul
        :style="
          this.openCreateDropdown
            ? 'transition: all 0.2s ease-in-out 0s, z-index 0s linear 0.01s; visibility: visible; opacity: 1; z-index: 1; transition-delay: 0s, 0s, 0.2s;'
            : 'visibility: hidden; opacity: 0; z-index: -1; transition: all 0.2s ease-in-out 0s, z-index 0s linear 0.01s;'
        "
        class="z-30 border-[1.5px] border-[#AACCD7] rounded-b-xl absolute w-full pt-6 top-[1.25rem] bg-white"
      >
        <router-link :to="{ name: 'new-slydepost' }">
          <li
            @click="close"
            class="flex justify-start items-start gap-5 px-4 text-[10px] font-[700] tracking-[2px] text-blue uppercase pb-3 border-b-[1.5px] border-[#AACCD7]"
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="+"
                opacity="0.8"
                d="M0.240234 4.88223V7.25554H4.87978V11.9091H7.25783V7.25554H11.8974V4.88223H7.25783V0.251953H4.87978V4.88223H0.240234Z"
                fill="#015D79"
              />
            </svg>
            New Slydepost
          </li>
        </router-link>
        <li
          @click="openNewListModal"
          class="flex justify-start items-start gap-5 px-4 text-[10px] font-[700] tracking-[2px] text-blue uppercase py-3"
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="+"
              opacity="0.8"
              d="M0.240234 4.88223V7.25554H4.87978V11.9091H7.25783V7.25554H11.8974V4.88223H7.25783V0.251953H4.87978V4.88223H0.240234Z"
              fill="#015D79"
            />
          </svg>
          New List
        </li>
      </ul>
    </div>
    <AddListModal ref="addListModal" />
  </div>
</template>

<script>
import AddListModal from '@/components/Modals/AddListModal.vue';
export default {
  name: 'CreateButton',
  components: {
    AddListModal,
  },
  data() {
    return {
      openCreateDropdown: false,
    };
  },
  methods: {
    close() {
      this.$emit('clicked');
      this.openCreateDropdown = false;
    },
    openNewListModal() {
      this.$emit('clicked');
      this.$refs.addListModal.openModal();
      this.openCreateDropdown = false;
    },
    closeCreateDropdown() {
      this.openCreateDropdown = false;
    },
    toggleCreateDropdown() {
      this.openCreateDropdown = !this.openCreateDropdown;
    },
  },
};
</script>
