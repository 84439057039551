import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const notificationStoreModule = {
  namespaced: true,
  state: {
    notifications: [],
    loadingNotifications: false,
  },
  getters: {
    getNotifications(state) {
      return state.notifications;
    },
    getLoadingNotifications(state) {
      return state.loadingNotifications;
    },
  },
  mutations: {
    setLoadingNotifications(state, loading) {
      state.loadingNotifications = loading;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    addNotifications(state, notifications) {
      for (let i = 0; i < notifications.length; i++) {
        state.notifications.push(notifications[i]);
      }
    },
    removeNotificationByActionId(state, actionId) {
      for (let i = 0; i < state.notifications.length; i++) {
        if (state.notifications[i].actionId === actionId) {
          state.notifications.splice(i, 1);
        }
      }
    },
  },
  actions: {
    getNotifications({ dispatch, commit, getters, rootGetters }) {
      if (getters['getLoadingNotifications'] === true) {
        return;
      }
      commit('setLoadingNotifications', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const startPosition = getters['getNotifications'].length;
      client
        .get(`/notifications/${loggedInUserId}?startPosition=${startPosition}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          commit('addNotifications', response.data);
          commit('setLoadingNotifications', false);
          commit('setHasNotifications', false, { root: true });
        })
        .catch((error) => {
          console.error('Unable to retrieve user info', error);
        });
    },
  },
};

// export the entire module
export { notificationStoreModule };
