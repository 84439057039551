<template>
  <div @click="clearUserProfile">
    <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
      <div class="flex flex-row gap-2">
        <img :src="url" class="w-10 h-10 rounded-xl" />

        <div class="flex flex-row items-center">
          <p class="text-sm capitalize">{{ firstName }} {{ lastName }}</p>
          <p class="text-sm" v-if="this.username != null">&nbsp;-&nbsp;{{ username }}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'UserSearchResult',
  props: {
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    userId: {
      type: String,
    },
    username: {
      type: String,
    },
    profilePictureUrl: {
      type: String,
    },
  },
  methods: {
    clearUserProfile() {
      this.$store.commit('userStoreModule/navigateAwayFromUserProfile');
    },
  },
  computed: {
    url() {
      return this.profilePictureUrl != null
        ? this.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
  },
};
</script>
