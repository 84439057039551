<template>
  <div class="flex flex-row gap-2 items-center">
    <p class="text-xs pr-1 whitespace-nowrap">{{ text }}</p>
    <div v-for="option in this.options" :key="option.text" class="flex flex-row gap-2 items-center">
      <p @click="this.clickContentType(option.emit)" :class="this.isContentTypeSelectedClass(option.text)">
        {{ option.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentTypeSelector',
  props: {
    text: {
      type: String,
    },
    options: {
      type: Array,
      note: 'should contain array of options: text, emit',
    },
    selectedOption: {
      type: String,
      note: 'this is the text value of the currently selected option',
    },
  },
  computed: {},
  methods: {
    clickContentType(emit) {
      this.$emit(emit);
    },
    isContentTypeSelectedClass(text) {
      if (this.selectedOption === text) {
        return 'capitalize cursor-pointer text-xs text-white px-3 py-2 rounded-full bg-teal';
      }
      return 'capitalize cursor-pointer text-xs px-3 py-2 rounded-full text-[#A6ADB9] border border-[#A6ADB9]';
    },
  },
};
</script>
