<template>
  <div class="p-4 flex flex-col gap-4 bg-white">
    <div class="flex flex-col md:flex-row gap-2">
      <div @click="clickTermsOfService">
        <p class="text-lg underline underline-offset-2 cursor-pointer">Terms of Service</p>
      </div>
      <div @click="clickCommunityGuidelines">
        <p class="text-lg underline underline-offset-2 cursor-pointer">Community Guidelines</p>
      </div>
      <div @click="clickPrivacyPolicy">
        <p class="text-lg underline underline-offset-2 cursor-pointer">Privacy Policy</p>
      </div>
      <div @click="clickInsiderTermsOfService">
        <p class="text-lg underline underline-offset-2 cursor-pointer">Insider Terms Of Service For Creators</p>
      </div>
      <div @click="clickInsiderTermsOfServiceForCustomers">
        <p class="text-lg underline underline-offset-2 cursor-pointer">Insider Terms Of Service For Customers</p>
      </div>
    </div>
    <div>
      <div v-show="page === 'privacy'">
        <PrivacyPolicy />
      </div>
      <div v-show="page === 'community'">
        <CommunityGuidelines />
      </div>
      <div v-show="page === 'terms'">
        <TermsOfService />
      </div>
      <div v-show='page === `insider-terms`'>
        <InsiderTermsOfService/>
      </div>
      <div v-show='page === `insider-terms-for-customers`'>
        <InsiderTermsOfServiceForCustomers/>
      </div>
    </div>
  </div>
</template>

<script>
import PrivacyPolicy from '@/components/Documentation/Legal/PrivacyPolicy.vue';
import TermsOfService from '@/components/Documentation/Legal/TermsOfService.vue';
import CommunityGuidelines from '@/components/Documentation/Legal/CommunityGuidelines.vue';
import InsiderTermsOfService from '@/components/Documentation/Legal/InsiderTermsOfServiceForCreators.vue';
import InsiderTermsOfServiceForCustomers from '@/components/Documentation/Legal/InsiderTermsOfServiceForCustomers.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Legal',
  components: {
    PrivacyPolicy,
    TermsOfService,
    CommunityGuidelines,
    InsiderTermsOfService,
    InsiderTermsOfServiceForCustomers
  },
  data() {
    return {
      showTermsOfService: true,
      showCommunityGuidelines: false,
      showPrivacyPolicy: false,
    };
  },
  computed: {
    ...mapGetters({
      page: 'legalStoreModule/getPage',
    }),
  },
  methods: {
    clickTermsOfService() {
      this.$store.commit('legalStoreModule/setPage', 'terms');
    },
    clickCommunityGuidelines() {
      this.$store.commit('legalStoreModule/setPage', 'community');
    },
    clickPrivacyPolicy() {
      this.$store.commit('legalStoreModule/setPage', 'privacy');
    },
    clickInsiderTermsOfService(){
      this.$store.commit('legalStoreModule/setPage', 'insider-terms');
    },
    clickInsiderTermsOfServiceForCustomers(){
      this.$store.commit('legalStoreModule/setPage', 'insider-terms-for-customers');
    }
  },
};
</script>

<style>
.v-enter-active {
  transition: all 0.3s ease-out;
}

.v-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.v-enter-from,
.v-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
