<template>
  <div class="w-screen h-screen flex justify-center items-center">
    <div class="text-center px-11">
      <div class="flex justify-center mb-7">
        <img alt="" src="@/assets/Like.png" />
      </div>
      <p class="font-bold text-xl text-[#1E1F20] mb-5">Congrats!</p>
      <p class="text-sm font-medium text-[#787979] mb-7">
        You have successfully change password. Please use the new password when logging in.
      </p>
      <button class="rounded-lg" @click="navigate">Log In Now</button>
    </div>
  </div>
</template>
<script>
import router from '@/router';

export default {
  name: 'Conrajs',
  methods: {
    navigate() {
      router.push('/login');
    },
  },
};
</script>
