<template>
  <div class="w-full flex flex-col gap-2 px-4 py-4">
    <div class="flex items-center gap-4">
      <img class="hidden md:inline w-12 h-12 rounded-2xl" :src="profilePictureUrl" />
      <div class="flex flex-row justify-between items-center w-full">
        <p class="text-lg text-blue">Rate your experience</p>
        <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
      </div>
    </div>
    <div class="flex flex-col">
      <hr class="solid mb-3 md:mb-6" />
      <textarea
        v-model="this.description"
        class="h-auto md:h-36 max-h-40 min-h-20 mb-3 md:mb-6"
        placeholder="Tell us what you think (Optional)"
      ></textarea>
      <div class="text-blue font-base text-start uppercase" v-for="aspect in this.aspects" :key="aspect">
        <EditRatingSlider
          class="pb-3 md:pb-6"
          v-if="aspect.selected === true"
          :name="aspect.name"
          :rating="aspect.rating"
          :description="aspect.description"
          ref="aspectRatings"
        />
      </div>
      <div class="flex pb-2 justify-between">
        <div>
          <font-awesome-icon
            @click="previousModal"
            :color="'#015D79'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-left']"
          />
        </div>
        <div>
          <font-awesome-icon
            @click="nextModal"
            :color="'#30C3E3'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-right']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditRatingSlider from '@/components/EditSlydepost/EditRatingSlider.vue';

export default {
  name: 'EditSlydepostRatings',
  emits: ['back', 'next'],
  data() {
    return {
      // selectedAspects: [],
      ratedAspects: [],
      files: [],
      max: 5,
      localFileUrls: [],
    };
  },
  components: { EditRatingSlider },
  computed: {
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    aspects: {
      get() {
        return this.$store.getters['editPostStoreModule/getAspects'];
      },
      set() {
        this.$store.commit('editPostStoreModule/setAspects');
      },
    },
    description: {
      get() {
        return this.$store.getters['editPostStoreModule/getDescription'];
      },
      set(description) {
        this.$store.commit('editPostStoreModule/setDescription', description);
      },
    },
  },
  methods: {
    previousModal() {
      // store the new rated aspects
      this.$refs.aspectRatings.forEach((aspect) => {
        aspect.saveAspectData();
      });
      this.$emit('back');
    },
    nextModal() {
      // store the new rated aspects
      this.$refs.aspectRatings.forEach((aspect) => {
        aspect.saveAspectData();
      });
      this.$emit('next');
    },
  },
};
</script>
