<template>
  <div>
    Slydepost Insider Terms of Service for Creators
    <br/><br/>
    Effective as of: 5/27/2024
    <br/><br/>
    These Slydepost Insider Creator Terms (“Terms”) are between you and Slydepost LLC, on behalf of itself and its Affiliates (collectively, “Slydepost” or “we”) and govern your participation in Slydepost’s Insider Creator Program (the “Program”). In these Terms, “Affiliate” means any entity that directly or indirectly controls, is controlled by, or is under common control with Slydepost, and “you,” “your,” “Creator” or “Insider” means you, an individual, company or any other entity with a Slydepost account participating in the Program. If you are entering into these Terms on behalf of an entity, you represent and warrant you are authorized on such entity’s behalf.
    <br/><br/>
    To participate in the Program, you must be at least 18 years old, a lawful resident of the United States, and meet the other eligibility requirements set forth in Section 2.
    <br/><br/>
    In addition to these Terms, your use of Subscriptions (defined below) is subject to our Terms of Service, our Privacy Policy, our Rules and Policies (including the new Slydepost Insider Policy) and all policies incorporated therein (collectively, the “Slydepost User Agreement”).
    <br/><br/>
    Please read these Slydepost Insider Creator Terms carefully to make sure you understand the applicable terms, conditions, and exceptions. IF YOU LIVE IN THE UNITED STATES, THESE TERMS CONTAIN IMPORTANT INFORMATION THAT APPLY TO YOU ABOUT RESOLUTION OF DISPUTES THROUGH BINDING ARBITRATION RATHER THAN IN COURT, INCLUDING A WAIVER OF YOUR RIGHT TO BRING CLAIMS AS CLASS ACTIONS AND THE RIGHT TO OPT OUT, AND A LIMITATION ON YOUR RIGHT TO BRING CLAIMS AGAINST SLYDEPOST MORE THAN 1 YEAR AFTER THE RELEVANT EVENTS OCCURRED, WHICH IMPACT YOUR RIGHTS AND OBLIGATIONS IF ANY DISPUTE WITH SLYDEPOST ARISES. SEE SECTION 13 FOR DETAILS ON THESE PROVISIONS.
    <br/><br/>
    Acceptance. By participating in the Program (if you are approved by Slydepost) or otherwise indicating your acceptance (for example, by agreeing to these Terms when applying to the Program, clicking “I Agree,” etc.), you represent and warrant that you have read, understand, and agree to be bound by these Terms. If you do not agree, do not participate in the Program or set up a Subscriptions account.
    <br/><br/>
    1. Slydepost Insider Creator Program.
    <br/>
    The Program provides your community the ability to show support for you by paying for a subscription offered by Slydepost for access to your Insider Subscriptions account on the Slydepost platform, which includes, without limitation, the service currently located at www.slydepost.com and any successor site thereto, and all Slydepost owned, operated, and/or controlled applications and APIs (collectively, the “Slydepost Service”). The Slydepost account that you choose to participate in this Program (including any and all features and benefits such as content, community, access, recognition, and deals and discounts that you make available to subscribers in connection with that account) is referred to as your “Subscriptions” account. People using the Slydepost Service may make monthly subscription payments to Slydepost (“Subscription”) to get access to your Subscriptions account.
    <br/><br/>
    When you set up your Subscriptions account, you will determine the specific price(s) of the Subscription(s) (based on the Subscription pricing options from Slydepost that are built into the feature), provided Slydepost reserves the right, in our discretion, to place restrictions or limitations on the Subscriptions we offer to access your account, including, without limitation, the amount that may be charged for a particular Subscription, who may purchase a Subscription (for example, a minimum age requirement to purchase a Subscription), and the number of Subscriptions that may be purchased to a specific Subscriptions account. In addition, Slydepost may from time to time, in its sole discretion, change (e.g., add or remove) types or values of Subscription pricing, payment methods made available to persons purchasing Subscriptions or any other Program-related features.
    <br/><br/>
    You acknowledge that Slydepost may publicly display, or associate, information about your Subscriptions account, including the number of subscribers and identity of the purchasers of Subscriptions, so long as it is available on the Slydepost Service.
    <br/><br/>
    You further acknowledge that Slydepost reserves the right to handle and analyze content from your Subscriptions account in the same manner as content you publicly post outside of your Subscriptions account on the Slydepost Services.
    <br/><br/>
    You hereby authorize Slydepost to use and reuse, and to grant others acting on Slydepost’s behalf the right to use and reuse, your name, photograph, likeness (including caricature), voice, and biographical information, and any reproduction or simulation thereof, in any form of media or technology now known or hereafter developed (including, without limitation, film, video and digital or other electronic media) in connection with Slydepost’s promotional and marketing activities.
    <br/><br/>
    2. Eligibility for the program and other requirements.
    <br/>
    In order for Slydepost to make available and provide Subscriptions to access your account and for you to be eligible to be paid a share of revenue earned by Slydepost for its sale of Subscriptions, you must be approved by Slydepost to be part of the Program. The eligibility requirements for the Program can be found here. By accepting these Terms, you consent to Slydepost’s use of the data that you provide in the application form you submit to consider your acceptance to the Program. Participation in the Program is subject to prior approval by Slydepost.
    <br/><br/>
    Please note that employees of Slydepost, Our Services and Corporate Affiliates are ineligible for participation in the Program, unless otherwise pre-approved by authorized personnel of Slydepost.
    <br/><br/>
    Slydepost uses a third-party payment processor (“Payment Processor”) to make its revenue share payments to you, which are based in part on the Subscriptions sold for access to your account. To be part of the Program, you will first need to create an account with the Payment Processor who will require you to meet certain identity verification requirements, such as providing proof of identity and/or residence. If you provide the Payment Processor an address outside of the State of Washington, you verify that you perform the services under these Terms outside of the State of Washington. You will also be required to submit payment and tax information to the Payment Processor. If Slydepost or the Payment Processor determines as part of the application or payment onboarding process, or later, that payments cannot be made to you for any reason, you cannot be part of the Program. By accepting these Terms, you expressly authorize Slydepost to access data about your Payment Processor connected account activity and transactions and to conduct other activities in connection with the processing and monitoring of revenue share payments under these Terms.
    <br/><br/>
    3. Rules and restrictions.
    <br/>
    Subject to these Terms, you agree that Slydepost has the right to offer, display, and make available access to your Subscriptions account through the Slydepost Service and sell Subscriptions in order for people to access your account, and in exchange for those licensed rights and your providing the benefits and features of your Subscriptions account, you can get paid a share of the revenue Slydepost earns from the Subscriptions purchased from Slydepost, as further described below in Section 4.
    <br/><br/>
    You agree that the following rules and restrictions apply to participation in the Program and your eligibility to get paid a share of the revenue Slydepost earns from the sale of Subscriptions:
    <br/><br/>
    1. You are responsible for your Subscriptions account, which includes, without limitation, ensuring that you provide the benefits and features of your Subscriptions account as represented by you, that you accurately describe the benefits and features of your Subscriptions account, and that the pricing of the Subscription(s) selected by you when you set up your account is commensurate with the value of the benefits and features of your Subscriptions account.
    <br/><br/>
    2. You agree that: (a) nothing in your Subscriptions account is misleading or deceptive; (b) verbal and visual statements made by you are truthful and substantiated; (c) any opinions, findings or experiences you share in your Subscriptions account are honest and accurate; and (d) if applicable, you will include any disclosures required to indicate your participation in a paid partnership (e.g., paid product placements, endorsements, or advertisements) involving your Subscriptions account.
    <br/><br/>
    3. If you do not provide any Subscriptions features or benefits after setting up your Subscriptions accounts, you may not be entitled to get paid any share of the revenue earned by Slydepost from any and all Subscriptions it sold for access to your account. If your Subscriptions account is found to be inactive frequently, then you may be removed from the Program.
    <br/><br/>
    4. You may block people who have purchased Subscriptions from accessing your account for any reason, provided you are not entitled to get paid any share of the revenue earned by Slydepost for any and all Subscriptions it sold to people you blocked.
    <br/><br/>
    5. Only you may get paid a share of the revenue earned by Slydepost from its sale of Subscriptions to access your account.
    <br/><br/>
    6. Subscriptions and the share of the revenue earned by Slydepost from its sale of Subscriptions are not transferable between Slydepost accounts.
    <br/><br/>
    7. Except if expressly permitted as part of the Program and/or under the Subscriptions Policy, you may not provide anyone with anything of monetary value in exchange for their purchase of Subscriptions to access your account.
    <br/><br/>
    8. Your participation in the Program (including, without limitation, offering features and benefits to subscribers through your Subscriptions account) and Slydepost’s payment of a share of the revenue it earns from Subscriptions sold by Slydepost must not violate any applicable laws, rules, or regulations. You are solely responsible for any violation by you or your Subscriptions account of any applicable laws, rules, or regulations.
    <br/><br/>
    9. We may impose additional limits based on anti-money laundering, sanctions or anti-fraud laws or policies, including, without limitation, Slydepost may block any transaction it determines to be, but not limited to, fraudulent or illegal activity or potentially fraudulent or illegal activity. We may withhold from our revenue sharing calculation any Subscriptions that we believe were purchased due to any breach of the Slydepost User Agreement or that we believe may involve a violation of any law and/or any revenue generated from Subscriptions from any blocked transactions.
    <br/><br/>
    10. You may not participate in the Program if you are a person with whom U.S. persons are not permitted to have dealings pursuant to economic sanctions, including, without limitation, sanctions administered by the United States Department of the Treasury's Office of Foreign Assets Control or any other applicable sanctions authority (“Prohibited Person”). This includes, without limitation, persons located in, or a citizen of, or ordinarily resident in, the following countries and regions: Cuba, Iran, the Crimea Region of Ukraine, North Korea and Syria. You represent and warrant that you are not a Prohibited Person.
    <br/><br/>
    Violation of any of the above rules and restrictions may lead to termination of your participation in the Program and/or your eligibility to get paid any share of the revenue attributable to Slydepost’s sale of Subscriptions to access your account.
    <br/><br/>
    4. Revenue share payments and fees.
    <br/>
    We may pay you a share of the revenue we have earned from the sale of Subscriptions to access your account at a rate which will depend, in Slydepost’s discretion, on several factors, including your account  status, the total number and price of Subscriptions purchased for access to your account and the fees and taxes (including, without limitation, sales, use, value-added, goods and services, withholding or transaction taxes or other charges) that Apple, Google, Payment Processor, banks and/or credit card companies may assess in the purchase of Subscriptions and in processing our payments to you. You understand this and should have no expectation that any particular revenue percentage or amount will apply, or continue to apply, over time.
    <br/><br/>
    You are responsible for promptly responding to any inquiries and requests that are sent to you related to Subscriptions we have sold to access your account. You further acknowledge that you are responsible for disputes relating to Subscriptions we have sold to access your account. Notwithstanding the foregoing, you agree that Slydepost’s respective customer support team can make a determination (in its discretion) on refund and/or chargeback requests that are directed to Slydepost in response to customer complaints and to comply with applicable laws. Where a purchase of a Subscription is subject to any chargeback and/or where revenue share payments you have received from Slydepost for the purchase of Subscriptions are subject to a refund/credit process, we or the Payment Processor may suspend, set-off (against any future revenue share payments we decide to make to you), invoice you or otherwise recoup the amount of those payments previously made or credited to you (plus any associated fees) and remove such payments from your receipt history.
    <br/><br/>
    Any and all revenue share payments made to you will be handled by the Payment Processor. The processing of revenue share payments will be subject to, and you agree to comply with, the user agreements, terms of service, and privacy policies of the Payment Processor and your bank. Slydepost will not be responsible or liable for any errors made by the Payment Processor or your bank. Slydepost will make all revenue share payments in US Dollars. If you desire to receive revenue share payments in your local currency, the Payment Processor will handle the conversion, provided you will be responsible for any conversion or transfer fees. In addition, select forms of payment may carry additional processing fees that you are responsible for paying. When we make a revenue share payment to you, we will make such payment to you through the Payment Processor approximately 60 days following the end of the month in which we received the revenue for the Subscriptions we sold that are attributable to such payment, except as otherwise set forth herein.
    <br/><br/>
    Notwithstanding, no revenue share payment will be owed to you unless we first have recorded and attributed to your Subscriptions account an amount to meet a minimum payment threshold of fifty US Dollars (US$50.00) (“Minimum Payment Threshold”). Slydepost at its discretion may determine to provide revenue share payments before the minimum payment threshold. All unpaid amounts below the Minimum Payment Threshold will roll over to the next pay period until the Minimum Payment Threshold has been met or exceeded. We will not pay, and you will not be entitled to, any interest on revenue share payment amounts that are delayed.
    <br/><br/>
    In order to receive revenue share payments, you are responsible for making sure your tax, banking, and/or other payment information provided to the Payment Processor is up-to-date, complete, and accurate. If the Payment Processor is unable to make a payment to you for any reason, we may terminate or suspend your eligibility to receive revenue share payments under these Terms and/or participate in the Program. Any payment to you is always conditioned upon any fraud and other legal checks that we or the Payment Processor may (in our and their discretion) put in place and conduct. Unless otherwise pre-approved in writing by an authorized representative of Slydepost, you may not permit a third party to receive payment on your behalf under these Terms.
    <br/><br/>
    If you intend to dispute the sum of any revenue share payment made to you, you must notify Slydepost in writing within 30 days of the receipt of such payment. Your failure to do this will constitute a waiver of any claim relating to the payment. You may contact the support team to send this notification by navigating to Get support from Settings and privacy > Monetization > Get support in the navigation menu of your Slydepost account. All payments to you are based on revenue share amounts as determined by Slydepost and shall be final and binding.
    <br/><br/>
    5. No guarantee of subscription revenue.
    <br/>
    Subscriptions are not legal tender, currency, currency equivalents, electronic money or any type of financial payment instrument (or linked to any of these things), and Subscriptions have no monetary value and do not constitute property. We do not promise that the purchase of Subscriptions can be used to redeem any guaranteed sum of money, or any money at all. You agree that we have the sole right to manage, regulate, control, terminate, and/or modify the license rights underlying Subscriptions as we see fit, without liability to you. You agree that we will not be liable to you, and you will not assert that we are liable to you, based on the sale of Subscriptions to access your account, including for any share of the revenue we earn from the sale of Subscriptions.
    <br/><br/>
    We also have the right to stop the sale of Subscriptions and redemption of Subscriptions altogether, in our sole discretion, without any liability to you.
    <br/><br/>
    We also do not guarantee that everyone with access to your account will have purchased a Subscription.
    <br/><br/>
    6. You are responsible for paying your taxes.
    <br/>
    You are responsible for paying applicable Taxes (as defined below), if any, due to taxing authorities arising from, or in connection with, your receipt of any payment of a share of the revenue earned by Slydepost from Subscription sales. Slydepost may deduct or withhold any Taxes that it may be legally obligated to deduct or withhold from any amounts payable to you, and payment to you as reduced by such deductions or withholdings will constitute full payment and settlement of the amounts payable under these Terms. Each party will provide the other party with any tax documentation reasonably necessary to make or receive payments hereunder. As used above, “Taxes” means any income, stamp, sales, use, value-added or other taxes, duties, charges, fees, deductions, or withholdings.
    <br/><br/>
    7. Ending these terms and other actions we may take.
    <br/>
    These Terms will commence on the date you click to accept these Terms (regardless of whether or not you are accepted into the Program) and will continue until terminated by either party as provided in this Agreement. You may opt out of participating in the Program at any time by providing written notice to Slydepost by navigating to Get support from Settings in the navigation menu of your Slydepost account. Slydepost may terminate these Terms, at any time, with or without cause, in Slydepost's sole discretion, including, without limitation, if you misrepresent yourself or your Subscriptions account; violate these Terms, the Slydepost User Agreement, or any applicable laws; or no longer meet the eligibility requirements to participate in the Program.
    <br/><br/>
    In addition, we reserve the rights, in our sole discretion, to not make Subscriptions available to a Creator’s account; limit the number of Subscriptions that we may sell to access an account, or limit the share of revenue from Subscriptions that may be paid to a Creator, including based on volume or time frame; suspend, remove or disable access to a Creator’s Subscriptions account or cease providing a Creator with all or part of the Program; remove a Creator from the Program; and/or withhold, suspend, recoup, or set-off revenue share payments (including any amount that may have been attributable to or from past or future sales of Subscription to access a Creator’s account) if we believe the Creator or the Creator’s Subscriptions account is in breach or violation of these Terms, the Slydepost User Agreement, or any applicable laws. We will have no liability for taking any of the above actions.
    <br/><br/>
    Upon termination of these Terms and/or your participation in the Program: (a) the rights and obligations under these Terms will immediately cease, (b) any and all liabilities accrued before the effective date of the termination will survive, subject to the following: (i) if you have met the Minimum Payment Threshold at the time of termination of these Terms, the Payment Processor will attempt to pay you for amounts and unpaid. If, however, the Payment
    <br/><br/>
    Processor is unable to remit payment to you due to circumstances beyond its control, you will automatically forfeit all such amounts and Slydepost will not owe any and all such amounts; or (ii) if you have not yet met the Minimum Payment Threshold, no amounts will be paid or owed to you.
    <br/><br/>
    8. Confidentiality.
    <br/>
    During and after your participation in the Program, you shall not disclose any proprietary and/or non-public information of Slydepost provided to you or to which you have access in connection with the Program (“Confidential Information”), except as authorized in writing by Slydepost or as required by law or court order, provided: (a) you will promptly notify Slydepost in writing of the requirement for disclosure, and (b) disclose only that portion of the Confidential Information legally required. You shall promptly notify Slydepost of any actual or suspected misuse or unauthorized disclosure of Confidential Information. Upon termination of these Terms and/or your participation in the Program, you will promptly destroy or erase any Confidential Information in your possession or control.
    <br/><br/>
    9. Disclaimer.
    <br/>
    YOU EXPRESSLY ACKNOWLEDGE THAT THE PROGRAM AND SUBSCRIPTIONS (OR FEATURES THEREOF) MAY BE EXPERIMENTAL IN NATURE AND/OR IN A BETA PHASE AND AGREE THAT ACCESS TO AND/OR USE OF THE PROGRAM AND SUBSCRIPTIONS IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE PROGRAM AND SUBSCRIPTIONS ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. SLYDEPOST DISCLAIMS ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR ARISING FROM COURSE OF DEALING OR PERFORMANCE. SLYDEPOST MAKES NO WARRANTIES OR REPRESENTATIONS AND DISCLAIMS ALL RESPONSIBILITY AND LIABILITY FOR: (I) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY OR RELIABILITY OF THE PROGRAM AND SUBSCRIPTIONS, AND (II) WHETHER THE PROGRAM OR SUBSCRIPTIONS WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. SLYDEPOST DOES NOT CONTROL, ENDORSE OR ADOPT YOUR SUBSCRIPTIONS ACCOUNT (INCLUDING ANY CONTENT, FEATURES OR BENEFITS THEREIN).
    <br/><br/>
    10. Limitation of liability.
    <br/>
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SLYDEPOST ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO, USE OF OR PARTICIPATION IN (OF INABILITY TO ACCESS, USE OR PARTICIPATE IN) SUBSCRIPTIONS AND THE PROGRAM; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SLYDEPOST SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SLYDEPOST SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE SLYDEPOST ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (US$100.00) OR THE AMOUNT PAID TO YOU BY SLYDEPOST, IF ANY, IN THE PAST SIX MONTHS UNDER THESE TERMS PRIOR TO THE EVENT GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE SLYDEPOST ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. THE “SLYDEPOST ENTITIES” REFERS TO SLYDEPOST, ITS PARENTS, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, AND LICENSORS.
    <br/><br/>
    APPLICABLE LAW IN YOUR JURISDICTION MAY NOT ALLOW FOR CERTAIN LIMITATIONS OF LIABILITY. TO THE EXTENT REQUIRED BY APPLICABLE LAW IN YOUR JURISDICTION, THE ABOVE DOES NOT LIMIT THE SLYDEPOST ENTITIES’ LIABILITY FOR FRAUD, FRAUDULENT MISREPRESENTATION, DEATH OR PERSONAL INJURY CAUSED BY OUR NEGLIGENCE, GROSS NEGLIGENCE, AND/OR INTENTIONAL CONDUCT. TO THE FULLEST EXTENT ALLOWED UNDER APPLICABLE LAW, THE SLYDEPOST ENTITIES’ MAXIMUM AGGREGATE LIABILITY FOR ANY NON-EXCLUDABLE WARRANTIES IS LIMITED TO ONE HUNDRED U.S. DOLLARS (US$100.00).
    <br/><br/>
    11. Representations and warranties.
    <br/>
    You represent and warrant that: (a) you have, or have obtained, and will maintain all rights, authorizations and licenses that are necessary to perform your obligations under these Terms and to offer, host and monetize your Subscriptions account in connection with the Program without further payments by Slydepost to any third party, (b) all of the information provided by you to Slydepost and/or the Payment Processor to enroll and participate in the Program is correct, accurate, complete and current at all times, (c) you are at least eighteen (18) years of age, (d) you have all necessary rights, power, and authority to enter into these Terms, (e) you comply, and will continue to comply, with all applicable laws, rules, statutes, ordinances, regulations and ethical obligations in your performance of any acts hereunder (including, without limitation, any relevant data protection and privacy laws; laws applicable to contests, promotions or giveaways; advertising laws and Federal Trade Commission (FTC) rules and regulations such as the FTC’s Guides Concerning the Use of Endorsements and Testimonials in Advertising), and you will not cause Slydepost and/or the Payment Processor to be in violation of any of the foregoing, (f) you will at all times adhere to the Slydepost User Agreement, and (g) your Subscriptions account will not violate or infringe the rights of any third party (including copyright, trademark, service mark, trade secret, contract, privacy or right of publicity rights) or contain any material that, if relied upon, might cause harm or injury to any person or property.
    <br/><br/>
    12. Indemnity.
    <br/>
    You agree to indemnify, defend and hold the Slydepost Entities (collectively, “Indemnified Persons”) harmless from and against any and all third-party claims, liability, loss, and expense (including damage awards, settlement amounts, and reasonable legal fees), brought against any Indemnified Person(s), arising out of or related to: (a) your participation in the Program, (b) breach of your representations, warranties and obligations as set forth in these Terms, or (c) your negligence or misconduct. Your indemnification obligations hereunder will be subject to: (i) the Indemnified Persons providing prompt written notice of the claim to you, provided that any delay in notification will not relieve you of your indemnification obligations except and solely to the extent that such delay materially impairs your ability to defend such claim, (ii) the Indemnified Persons reasonably cooperating with you with respect to the defense and settlement of such claim, and (iii) you permitting Slydepost, at its option, to participate in and control the defense and settlement of such claim. You will not settle any such claim that may compromise any interest of the Indemnified Persons without the Indemnified Persons’ prior written consent; provided that the Indemnified Persons will reasonably cooperate with such defense or settlement, at your request and expense.
    <br/><br/>
    13. Dispute resolution agreement--Binding arbitration and class action waiver.
    <br/>
    THIS SECTION APPLIES TO YOU ONLY IF YOU LIVE IN THE UNITED STATES. PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
    <br/><br/>
    General. The following provisions are important with respect to the agreement between you and Slydepost regarding Slydepost’s features and services memorialized by these Terms. Subject to the provisions below, including exceptions set forth in this Section 13, you and Slydepost agree to arbitrate any disputes, claims, or controversies arising out of or relating to these Terms, the sale of Subscriptions and/or your participation in the Program (individually a “Dispute,” or more than one, “Disputes”).
    <br/><br/>
    Initial Dispute Resolution. Most disputes between you and Slydepost can be resolved informally. You may contact the support team by navigating to Get support from Settings and privacy > Monetization > Get support in the navigation menu of your Slydepost account. When you contact us, please provide a brief description of the nature and bases for your concerns, your contact information, and the specific relief you seek. The parties shall use their best efforts through this support process to settle any Dispute. You and we agree that good faith participation in this informal process is required and must be completed as set forth above before either party can initiate arbitration regarding any Dispute.
    <br/><br/>
    BINDING ARBITRATION. If we cannot reach an agreed-upon resolution with you regarding a Dispute within a period of thirty (30) days from the time informal dispute resolution commences under the Initial Dispute Resolution provision above, then either you or we may initiate binding arbitration, which will be the sole means to resolve any Dispute, subject to the terms set forth below and except for claims brought in small claims court or unless you opt out. Specifically, all Disputes shall be finally resolved exclusively through binding arbitration. Arbitration shall be conducted in accordance with the JAMS office located in Seattle, Washington, or such other recognized arbitration association mutually selected by the parties to the dispute, and the case shall be administered by the same authority in accordance with its procedures, unless the parties mutually determine otherwise. YOU AND SLYDEPOST HEREBY EXPRESSLY WAIVE THE RIGHT TO A TRIAL BY JURY OR JUDGE.
    <br/><br/>
    The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any Dispute, including, but not limited to, any claim that all or any part of these Terms are void or voidable, or whether a Dispute is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity, provided that such relief (including injunctive relief) is limited to your individual circumstances. Notwithstanding the requirements of this arbitration provision, if the Dispute involves a claim for public injunctive relief, you may choose to sever that claim from the arbitration proceeding and bring it in any court of proper jurisdiction. The arbitrator’s award shall be written and binding on the parties and may be entered as a judgment in any court of competent jurisdiction.
    <br/><br/>
    YOU HAVE ONE YEAR TO BRING A CLAIM AGAINST SLYDEPOST, UNLESS YOU OPT OUT. You must bring any claim against Slydepost within one (1) year after the date on which the claim arose or, unless applicable law provides that the normal statute of limitations for that claim may not be shortened by agreement. If you do not bring a claim within this period, you waive, to the fullest extent permitted by law, all rights you have to such claim and Slydepost will have no liability with respect to such claim.
    <br/><br/>
    Filing Process and Costs. If you wish to begin an arbitration proceeding, after following the informal dispute resolution procedure, you must send a letter requesting arbitration and describing your claim to: info@slydepost.com or write us at Slydepost LLC, P.O. Box 773, Bellevue, WA 98009-0773. If, the arbitrator determines that claims were filed for the purposes of harassment or were patently frivolous, the arbitrator can reallocate the arbitrator’s compensation and administrative fees, including the filing and hearing fees, as set forth in Arbitration Rules. The arbitration rules also permit you to recover attorneys’ fees in certain cases. The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court.
    <br/><br/>
    Location. Arbitration will take place on a documents-only basis or you can choose to conduct the proceedings by telephone, video, or in-person. For in-person arbitration, the proceedings will be in the city or county where you reside or, if you do not reside in the United States, in the State of Washington.
    <br/><br/>
    Class Action Waiver. YOU AND WE FURTHER AGREE THAT ANY CLAIMS MAY ONLY BE BROUGHT IN OUR INDIVIDUAL CAPACITIES AND NOT ON BEHALF OF, OR AS PART OF, A CLASS ACTION OR OTHER REPRESENTATIVE ACTION. THE PARTIES EXPRESSLY WAIVE THEIR RIGHT TO FILE A CLASS ACTION OR SEEK RELIEF ON A CLASS BASIS. Unless you and we both agree in writing, the arbitrator may not consolidate proceedings or more than one person’s claims, and may not otherwise preside over any form of representative or class proceeding.
    <br/><br/>
    Severability. If it is determined that any part of this Dispute Resolution Agreement cannot be enforced as to a particular claim for relief or remedy (such as injunctive relief), then that claim or remedy (and only that claim or remedy) shall be severed and must be brought in a court of proper jurisdiction and any other claims must be arbitrated.
    <br/><br/>
    Exception - Litigation of Small Claims Court Claims. Notwithstanding the parties’ decision to resolve all disputes through arbitration, either party may seek relief in a small claims court for disputes or claims within the scope of that court's jurisdiction.
    <br/><br/>
    30-Day Right to Opt Out. If you wish to opt out and not be bound by these arbitration provisions set forth above, you can send written notice of your decision to opt out to the following address:
    <br/><br/>
    Slydepost LLC
    Attn: Legal Department - Arbitration Opt Out
    Slydepost LLC, P.O. Box 773, Bellevue, WA 98009-0773
    <br/><br/>
    If you have already been accepted to the Program, you may submit your opt out notice by contacting Slydepost.
    <br/><br/>
    Your written notification must include your name, Slydepost account handle, the email address or phone number associated with your account handle, and a clear statement that you do not wish to resolve Disputes with Slydepost.
    <br/><br/>
    The notice must be sent within 30 days of your acceptance of these Terms, otherwise you shall be bound to arbitrate disputes in accordance with the terms of those provisions. Your decision to opt out of this arbitration provision will have no adverse effect on your relationship with Slydepost or the delivery of features or services to you by Slydepost. If you opt out of those provisions, Slydepost also will not be bound by them.
    <br/><br/>
    Changes to this Section. We will provide 60-days’ notice of any material change to this Dispute Resolution Agreement. Changes will become effective on the 60th day and will apply prospectively only to any claims arising after the 60th day.
    <br/><br/>
    Choice of Law. THESE TERMS MEMORIALIZE A TRANSACTION IN INTERSTATE COMMERCE. THE FEDERAL ARBITRATION ACT AND INTERPRETING CASE LAW GOVERN THE INTERPRETATION AND ENFORCEMENT OF THESE ARBITRATION PROVISIONS, WITHOUT REGARD TO STATE LAW. To the extent state substantive law applies to any Dispute, the law of the State of [State] shall apply, without regard to conflict of law provisions.
    <br/><br/>
    Survival. This Dispute Resolution Agreement survives the end of the relationship between you and Slydepost, including cancellation of or unsubscribing from any services or communications provided by Slydepost.
    <br/><br/>
    14. Modification of terms.
    <br/>
    We reserve the right to modify these Terms, from time to time. We will notify you of material revisions, for example via a service notification or an email to the email associated with your account. Your continued participation in the Program after changes have become effective will be deemed as your acceptance of such changes. If any changes are not acceptable to you, you may terminate your participation in the Program by providing written notice to Slydepost and stopping use of the Subscriptions features.
    <br/><br/>
    The Slydepost Insider Creator Terms are written in English but are made available in multiple languages through translations. Slydepost strives to make the translations as accurate as possible to the original English version. However, in case of any discrepancies or inconsistencies, the English language version of the Slydepost Insider Creator Terms shall take precedence. You acknowledge that English shall be the language of reference for interpreting and constructing the terms of the Slydepost Insider Creator Terms.
    <br/><br/>
    15. You are not our employee.
    <br/>
    You understand that your relationship with us is solely that of a user of our services, as an independent entity. You are not our employee. No agency, partnership, or joint venture is intended or created by these Terms or your participation in the Program. You maintain all rights and responsibilities for the nature and legality of your content, the manner in which it is created and offered, and whether you decide to offer it at all.
    <br/><br/>
    16. Miscellaneous.
    <br/>
    a. Feedback. Creator may provide Slydepost with comments concerning the Program and/or Creator’s evaluation and use of features and services provided by Slydepost (“Feedback”). Creator agrees that Slydepost and its designees will be free to copy, modify, create derivative works, publicly display, disclose, distribute, license and sublicense, incorporate, and otherwise use the Feedback, including derivative works thereto, for any and all commercial and non-commercial purposes with no expectation of payment, or attribution, of any kind by Creator. Nothing in this Agreement will prevent Slydepost from developing features, products or services that may be competitive with Creator’s or any Creator’s clients or end users’ features, products, or services.
    <br/><br/>
    b. Governing Law. These Terms will be governed by the laws of the State of [State] and any Dispute that arises between you and Slydepost will be subject to Section 13 above (Dispute Resolution Agreement -- Binding Arbitration and Class Action Waiver).
    <br/><br/>
    c. Notices. Any notices to Slydepost must be sent to: at Slydepost LLC, P.O. Box 773, Bellevue, WA 98009-0773, Attn: Legal Department, via first class or air mail or overnight courier, with a copy via email to [email address] and are deemed given upon receipt. Notice to you may be provided by sending email to the email address associated with your Slydepost account, or by posting a message to your Slydepost account or the Slydepost Service, and is deemed received when sent (for email) or posted.
    <br/><br/>
    d. Waiver. The failure of Slydepost to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. Slydepost’s waiver of any default is not a waiver of any subsequent default.
    <br/><br/>
    e. Survival; Severability. Any obligations, which expressly or by their nature continue after termination of these Terms, shall survive and remain in effect after such happening. Unenforceable provisions will be modified to reflect the parties’ intention, and remaining provisions of these Terms will remain in full effect.
    <br/><br/>
    f. Assignment. You may not assign or transfer these Terms, or any of your rights or obligations hereunder, and any such attempt will be null and void.
    <br/><br/>
    g. Force Majeure. Neither party will be liable for any delay or failure of or in performance of its obligations under these Terms due to causes beyond its reasonable control, including, but not limited to, acts of God, acts of the public enemy, government acts, fire, floods, epidemics (including COVID-19), quarantine restrictions, strikes, civil commotions, act(s) or threatened act(s) of terrorism, failure or delay of telecommunications or technical equipment or infrastructure, or freight embargoes (“Force Majeure Event”), and each party shall be excused from performance of its obligations hereunder for the duration of such Force Majeure Event. If the Force Majeure Event precludes you from providing your Subscriptions account as represented by you, you will not be eligible to receive revenue for any of Subscriptions to your Subscriptions account that are canceled or otherwise not carried out as represented by you.
    <br/><br/>
    h. Entire Agreement. These Terms (including the Slydepost User Agreement referenced herein) constitute the entire understanding between Slydepost and you with respect to the subject matter of these Terms and merges and supersedes all prior communications, understanding, and agreements between the parties concerning the subject matter, whether written or oral.
  </div>
</template>

<script>
export default {
  name: 'InsiderTermsOfServiceForCreators',
};
</script>
