<template>
  <div class="bg-blue">
    <div class="flex justify-center pt-8">
      <div class="w-[92px] h-[92px] rounded-full p-1 overflow-hidden">
        <img class="w-full w-full rounded-full" :src="profilePictureUrl" />
      </div>
    </div>
    <div class="capitalize flex items-center gap-2 justify-center pt-2 text-[28px] font-[500] text-white">
      <font-awesome-icon v-if='isInsider' class='text-lg text-yellow-400' :icon="['far', 'gem']" />
      <p>{{ firstName }} {{ lastName }}</p>
    </div>
    <div
      v-if="this.username != null && this.username !== ''"
      class="uppercase flex justify-center text-[12px] font-[900] text-white tracking-[2px] opacity-[50%]"
    >
      @{{ username }}
    </div>
    <div class="flex justify-center pt-2">
      <p v-if="totalFollowers !== 1" class="uppercase text-[10px] font-[900] text-white tracking-[2px] opacity-[50%]">
        {{ totalFollowers }} followers
      </p>
      <p v-else class="uppercase text-[10px] font-[900] text-white tracking-[2px] opacity-[50%]">
        {{ totalFollowers }} follower
      </p>
    </div>
    <div class="flex justify-center pt-2 px-4 text-[14px] font-[500] text-white">
      <ReadMoreText class="md:w-96" :text="this.bio" />
    </div>
    <div class="flex flex-row pt-4 justify-center">
      <div
        @click="this.selectLikes()"
        :style="this.likesSelectedStyle()"
        class="cursor-pointer w-28 flex flex-col justify-center items-center text-white rounded-t-[20px]"
      >
        <p class="text-[24px] font-[500] tracking-[2px] pt-2">
          {{ totalLikedSlydeposts }}
        </p>
        <p
          :style="this.likesSelected ? 'opacity: 1' : 'opacity: 0.5'"
          class="text-[10px] font-[900] tracking-[2px] uppercase tracking-[2px]"
        >
          Likes
        </p>
        <p class="font-bold text-3xl text-blue">|</p>
      </div>
      <div
        @click="this.selectSlydeposts()"
        :style="this.slydepostSelectedStyle()"
        class="cursor-pointer w-28 flex flex-col justify-center items-center bg-white rounded-t-[20px] gap-0 text-blue"
      >
        <div class="text-[24px] font-[500] tracking-[2px] pt-2">
          {{ totalSlydeposts }}
        </div>
        <div :style="this.slydepostSelected ? 'opacity: 1' : 'opacity: 0.5'" class="">
          <p class="text-[10px] font-[900] tracking-[2px] uppercase">Slydeposts</p>
        </div>
        <div class="font-bold text-3xl text-blue">|</div>
      </div>
      <div
        @click="this.selectLists()"
        :style="this.listSelectedStyle()"
        class="cursor-pointer w-28 flex flex-col justify-center items-center text-white rounded-t-[20px]"
      >
        <p class="text-[24px] font-[500] tracking-[2px] pt-2">
          {{ this.myListSize }}
        </p>
        <p
          :style="this.listSelected ? 'opacity: 1' : 'opacity: 0.5'"
          class="text-[10px] font-[900] tracking-[2px] uppercase tracking-[2px]"
        >
          Lists
        </p>
        <p class="font-bold text-3xl text-blue">|</p>
      </div>
    </div>
    <div class="bg-ice pb-4">
      <div v-if="this.view === 'likes'" class="flex flex-col gap-4">
        <div
          v-for="slydepost in postsLikedByUser"
          :key="slydepost.id"
          class="first:border-t-0 border border-light-gray"
        >
          <SlydepostLikedByLoggedInUser
            :id="slydepost.id"
            :title="slydepost.title"
            :description="slydepost.description"
            :aspects="slydepost.tags"
            :user-id="slydepost.userId"
            :created-at="slydepost.createdAt"
            :user-first-name="slydepost.userFirstName"
            :user-last-name="slydepost.userLastName"
            :category="slydepost.category"
            :subcategory="slydepost.subcategory"
            :comments="slydepost.comments"
            :images="slydepost.presignedMediaUrls"
            :address="slydepost.address"
            :link="slydepost.link"
            :meta-rating="slydepost.metaRating"
            :display-comments="true"
            :media="slydepost.media"
            :likes="slydepost.likes"
            :profile-picture-url="slydepost.userProfilePhotoUrl"
          />
        </div>
        <InfiniteLoading @infinite="loadMoreLikedPosts" />
      </div>
      <div v-else-if="view === 'slydeposts'" class="flex flex-col gap-4">
        <div v-for="slydepost in userSlydeposts" :key="slydepost.id" class="first:border-t-0 border border-light-gray">
          <SlydepostCreatedByLoggedInUser
            :id="slydepost.id"
            :title="slydepost.title"
            :description="slydepost.description"
            :aspects="slydepost.tags"
            :user-id="slydepost.userId"
            :created-at="slydepost.createdAt"
            :user-first-name="slydepost.userFirstName"
            :user-last-name="slydepost.userLastName"
            :category="slydepost.category"
            :subcategory="slydepost.subcategory"
            :comments="slydepost.comments"
            :images="slydepost.presignedMediaUrls"
            :address="slydepost.address"
            :link="slydepost.link"
            :meta-rating="slydepost.metaRating"
            :display-comments="true"
            :media="slydepost.media"
            :likes="slydepost.likes"
            :profile-picture-url="slydepost.userProfilePhotoUrl"
          />
        </div>
        <InfiniteLoading @infinite="loadMoreUserPosts" />
      </div>
      <div v-else-if="view === 'lists'">
        <div class="flex justify-end items-center pt-2 px-4">
          <div class="mr-2" @click="gridView = 'list'">
            <font-awesome-icon class="text-2xl" :icon="['fas', 'pause']" rotation="90" />
          </div>
          <div @click="gridView = 'grid'">
            <font-awesome-icon class="text-2xl" :icon="['fas', 'grip']" />
          </div>
        </div>
        <div
          :class="
            gridView === 'list'
              ? 'grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 px-4 md:px-0 pt-2 pb-4'
              : 'grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 md:px-0 pt-2 pb-4'
          "
        >
          <ListCard
            v-for="list in myList"
            :key="list.id"
            :id="list.id"
            :cover="list.presignedUrl"
            :title="list.title"
            :post-count="list.totalPosts"
            :follow-count="list.totalFollows"
            :profile-picture-url="list.userProfilePicturePresignedUrl"
            :status='list.status'
          />
          <ListCardV1 :addPost="true" :gridView="gridView" />
        </div>
        <InfiniteLoading @infinite="loadMoreLists" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfiniteLoading from 'v3-infinite-loading';
import ReadMoreText from '@/components/Utility/ReadMoreText.vue';
import ListCard from '@/components/ViewLists/ListCardV2.vue';
import ListCardV1 from '@/components/ViewLists/ListCard.vue';
import SlydepostLikedByLoggedInUser from '@/components/ViewSlydepost/Wrappers/SlydepostLikedByLoggedInUser.vue';
import SlydepostCreatedByLoggedInUser from '@/components/ViewSlydepost/Wrappers/SlydepostCreatedByLoggedInUser.vue';

export default {
  name: 'LoggedInUserProfile',
  data() {
    return {
      view: 'slydeposts',
      slydepostSelected: true,
      likesSelected: false,
      listSelected: false,
      gridView: 'grid',
    };
  },
  components: {
    InfiniteLoading,
    ReadMoreText,
    ListCard,
    ListCardV1,
    SlydepostLikedByLoggedInUser,
    SlydepostCreatedByLoggedInUser,
  },
  computed: {
    ...mapGetters({
      firstName: 'getFirstName',
      lastName: 'getLastName',
      username: 'getUsername',
      bio: 'getBio',
      profilePicture: 'getProfilePictureFile',
      profilePictureUrl: 'getProfilePictureUrl',
      userSlydeposts: 'currentUserStoreModule/getPosts',
      totalSlydeposts: 'currentUserStoreModule/getTotalPosts',
      postsLikedByUser: 'getPostsLikedByLoggedInUser',
      totalLikedSlydeposts: 'getTotalPostsLikedByLoggedInUser',
      totalFollowers: 'getTotalFollowersByLoggedInUser',
      myList: 'listsStoreModule/getMyList',
      myListSize: 'listsStoreModule/getMyListsSize',
      isInsider: 'getIsInsider'
    }),
  },
  methods: {
    loadMoreUserPosts() {
      let payload = {
        index: this.userSlydeposts.length,
      };
      this.$store.dispatch('currentUserStoreModule/loadMorePosts', payload);
    },
    loadMoreLikedPosts() {
      if (this.postsLikedByUser == null) {
        return;
      }
      let payload = {
        index: this.postsLikedByUser.length,
      };
      this.$store.dispatch('loadMorePostsLikedByLoggedInUser', payload);
    },
    loadMoreLists() {
      this.$store.dispatch('listsStoreModule/fetchMyList');
    },
    selectSlydeposts() {
      this.view = 'slydeposts';
      this.slydepostSelected = true;
      this.likesSelected = false;
      this.listSelected = false;
    },
    selectLikes() {
      this.view = 'likes';
      this.likesSelected = true;
      this.slydepostSelected = false;
      this.listSelected = false;
    },
    selectLists() {
      this.view = 'lists';
      this.listSelected = true;
      this.likesSelected = false;
      this.slydepostSelected = false;
    },
    slydepostSelectedStyle() {
      if (this.slydepostSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    likesSelectedStyle() {
      if (this.likesSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    listSelectedStyle() {
      if (this.listSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
  },
  mounted() {
    this.$store.dispatch('getTotalNumberOfFollowersByLoggedInUser');
    this.$store.dispatch('getLoggedInUserProfile');
    let postsPayload = {
      index: this.userSlydeposts.length,
    };
    this.$store.dispatch('currentUserStoreModule/loadMorePosts', postsPayload);
    let likesPayload = {
      index: this.postsLikedByUser.length,
    };
    this.$store.dispatch('loadMorePostsLikedByLoggedInUser', likesPayload);
    this.$store.dispatch('listsStoreModule/fetchMyList');
  },
  unmounted() {},
};
</script>
