import axios from 'axios';
import store from '@/store/store';
import router from '@/router/index';

// Create an Axios instance
const client = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

// Add a response interceptor to handle 403 status codes
client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 403) {
      console.log(`403, must login`);
      store.commit('authStoreModule/setUser', null);
      router.push({ name: 'Login' });
    }
    return Promise.reject(error);
  }
);

export default client;
