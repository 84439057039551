<template>
  <AreYouSureModal
    :showModal="checkDeleteModal"
    :message="`Are you sure you want to delete your Draft?`"
    @accept="this.deleteDraft()"
    @cancel="checkDeleteModal = false"
  />
  <div class="flex items-center p-4 border-gray-200 hover:bg-slate-300" @click="continueCreation(draft)">
    <img v-lazy="photoUrl" alt="Draft Thumbnail" class="w-14 h-14 rounded-xl mr-4" />
    <div class="flex-grow">
      <h3 class="text-md font-semibold">{{ draft.title }}</h3>
      <p class="text-sm text-gray-500">{{ formattedDate }}</p>
    </div>
    <div class="flex items-start h-full z-20 relative cursor-pointer bg-transparent">
      <MoreOptionsButton
        class="text-left text-lg"
        v-click-outside="this.closeDraftOptions"
        @click.stop="this.showDraftOptions = !this.showDraftOptions"
        @delete="this.checkDeleteModal = true"
        :options="postOwnerOptions"
        :showOptions="this.showDraftOptions"
        iconColor="black"
      />
    </div>
  </div>
</template>

<script>
import DropdownEmitContent from '@/components/DropdownMenu/DropdownEmitContent.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';
export default {
  name: 'DraftListItem',
  emits: ['deleteDraft', 'continueCreation'],
  components: {
    AreYouSureModal,
    MoreOptionsButton,
  },
  props: {
    draft: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDraftOptions: false,
      checkDeleteModal: false,
      postOwnerOptions: [
        {
          text: 'Delete',
          onClickEmit: 'delete',
        },
      ],
    };
  },
  computed: {
    formattedDate() {
      //create a new date object from this.createdAt
      const date = new Date(this.draft.createdAt);
      //return the date in the Jan 30, 2021 format
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    photoUrl() {
      if (this.draft.presignedUrl) {
        return this.draft.presignedUrl;
      }
      if (this.draft.media && this.draft.media.length > 0) {
        return this.draft.media[0].presignedUrl;
      }
      return require(`@/assets/no-photo-background-square.png`);
    },
  },
  methods: {
    draftBelongsToUser() {
      return true;
    },
    closeDraftOptions() {
      this.showDraftOptions = false;
    },
    checkDelete() {
      this.checkDeleteModal = true;
      this.showDraftOptions = false;
    },
    deleteDraft() {
      this.$emit('deleteDraft', this.draft.id, this.draft.type);
      this.checkDeleteModal = false;
    },
    continueCreation(draft) {
      this.$emit('continueCreation', draft);
      this.checkDeleteModal = false;
    },
  },
};
</script>
