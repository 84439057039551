import client from '@/axios/client';

const trendingStoreModule = {
  namespaced: true,
  state: {
    currentView: 'slydeposts',
    currentFilter: '7 Days',
  },
  getters: {
    getCurrentView(state) {
      return state.currentView;
    },
    getCurrentFilter(state) {
      return state.currentFilter;
    },
  },
  mutations: {
    setCurrentView(state, view) {
      state.currentView = view;
    },
    setCurrentFilter(state, filter) {
      state.currentFilter = filter;
    },
  },
  actions: {},
};

export { trendingStoreModule };
