<template>
  <div
    class="p-2 flex flex-row gap-2 justify-between items-center"
  >
    <div>
      <div class='line-clamp-1'>
        <p class='font-bold'>{{ title }}</p>
      </div>
      <div class='flex flex-row gap-2'>
        <img class="w-6 h-6 rounded-full" :src="profilePicture" />
        <p>{{ name }}</p>
      </div>

    </div>
    <div>
      <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListPayment',
  props: {
    title: String,
    listId: String,
    price: Number,
    name: String,
    profilePicture: String
  },
  methods: {
    formattedPrice(price) {
      const dollars = (price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
  },
};
</script>