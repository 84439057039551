<template>
  <div class='flex flex-col gap-2'>
    <div>
      <div @click='showMonthlySubscribers = !showMonthlySubscribers' :class='showMonthlySubscribers ? `rounded-t-xl border-2 border-b-0` : `rounded-xl border-2`' class='p-2 flex flex-row justify-between items-center gap-2 border-moonstone'>
        <p class='text-lg font-medium'>Monthly Subscribers ( {{monthlySubscriptions.length}} )</p>
        <div>
          <font-awesome-icon
            v-show="this.showMonthlySubscribers === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showMonthlySubscribers === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if='showMonthlySubscribers' class='flex flex-col'>
        <div v-if='monthlySubscriptions.length <= 0' class='rounded-b-xl border-2 border-moonstone p-2'>
          <p>No subscriptions yet</p>
        </div>
        <div :class='showMonthlySubscribers ? `last:rounded-b-xl last:border-b-2 border-b-0` : `rounded-xl`' class='border-2 border-moonstone' v-for='product in monthlySubscriptions' :key='product.id'>
          <ProductCustomer
            class='p-2'
            :first-name='product.customer.customerFirstName'
            :last-name='product.customer.customerLastName'
            :profile-picture='product.customer.profilePictureUrl'
            :user-id='product.customer.customerUserId'
          />
        </div>
      </div>
    </div>
    <div>
      <div @click='showYearlySubscribers = !showYearlySubscribers' :class='showYearlySubscribers ? `rounded-t-xl border-2 border-b-0` : `rounded-xl border-2`' class='p-2 flex flex-row justify-between items-center gap-2 border-moonstone'>
        <p class='text-lg font-medium'>Yearly Subscribers ( {{yearlySubscriptions.length}} )</p>
        <div>
          <font-awesome-icon
            v-show="this.showYearlySubscribers === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showYearlySubscribers === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if='showYearlySubscribers' class='flex flex-col'>
        <div v-if='yearlySubscriptions.length <= 0' class='rounded-b-xl border-2 border-moonstone p-2'>
          <p>No subscriptions yet</p>
        </div>
        <div :class='showYearlySubscribers ? `last:rounded-b-xl last:border-b-2 border-b-0` : `rounded-xl`' class='border-2 border-moonstone' v-for='product in yearlySubscriptions' :key='product.id'>
          <ProductCustomer
            class='p-2'
            :first-name='product.customer.customerFirstName'
            :last-name='product.customer.customerLastName'
            :profile-picture='product.customer.profilePictureUrl'
            :user-id='product.customer.customerUserId'
          />
        </div>
      </div>
    </div>
    <div>
      <div @click='showListCustomers = !showListCustomers' :class='showListCustomers ? `rounded-t-xl border-2 border-b-0` : `rounded-xl border-2`' class='p-2 flex flex-row justify-between items-center gap-2 border-moonstone'>
        <p class='text-lg font-medium'>List Purchases ( {{listPurchases.length}} )</p>
        <div>
          <font-awesome-icon
            v-show="this.showListCustomers === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showListCustomers === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if='showListCustomers' class='flex flex-col'>
        <div v-if='listPurchases.length <= 0' class='rounded-b-xl border-2 border-moonstone p-2'>
          <p>No subscriptions yet</p>
        </div>
        <div :class='showListCustomers ? `last:rounded-b-xl last:border-b-2 border-b-0` : `rounded-xl`' class='border-2 border-moonstone' v-for='product in listPurchases' :key='product.id'>
          <ProductCustomer
            class='p-2'
            :first-name='product.customer.customerFirstName'
            :last-name='product.customer.customerLastName'
            :profile-picture='product.customer.profilePictureUrl'
            :user-id='product.customer.customerUserId'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCustomer from '@/components/Insider/InsiderDashboardCustomers/ProductCustomer.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Customers',
  data(){
    return {
      showMonthlySubscribers: false,
      showYearlySubscribers: false,
      showListCustomers: false,
    }
  },
  components: {
    ProductCustomer
  },
  computed: {
    ...mapGetters({
      monthlySubscriptions: 'insiderStoreModule/getMonthlySubscriptions',
      yearlySubscriptions: 'insiderStoreModule/getYearlySubscriptions',
      listPurchases: 'insiderStoreModule/getListPurchases',
    }),
  },
  mounted(){
    this.$store.dispatch('insiderStoreModule/getInsiderPayments')
  },
}
</script>