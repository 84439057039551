<template>
  <div class="relative cursor-pointer bg-transparent px-4 py-1" @click="activate">
    {{ this.optionName }}
    <div class="absolute top-0 right-0 w-2 h-2 bg-teal rounded-full" v-if="this.hasNotifications"></div>
  </div>
</template>

<script>
import router from '@/router/index';
import store from '@/store/store';
import axios from 'axios';

export default {
  name: 'AppDropdownItem',
  props: {
    optionName: {
      type: String,
      required: true,
      note: 'Name of the dropdown menu item',
    },
    optionLink: {
      type: String,
      required: true,
      note: 'Link used when the item is clicked',
    },
    pushLink: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Whether or not router will push to the link or make a direct call to the link',
    },
    method: {
      type: String,
      required: false,
      default: 'get',
      note: 'Method to be used in axios API call if link is not a router push',
    },
    isSignOut: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Allows option to sign out user using custom function inside this component',
    },
    notificationGetter: {
      type: String,
      required: false,
      default: '',
      note: 'whether or not notification bubble should be displayed',
    },
  },
  components: {},
  computed: {
    hasNotifications() {
      if (this.notificationGetter != null) {
        return this.$store.getters[this.notificationGetter];
      } else {
        return false;
      }
    },
  },
  mounted() {},
  methods: {
    async activate() {
      if (this.isSignOut === true) {
        await this.signOut();
        return;
      }

      if (this.pushLink === true) {
        router.push(this.optionLink);
        return;
      }

      const response = await axios({
        method: this.method,
        url: this.optionLink,
      });
    },
    async signOut() {
      try {
        await store.dispatch('authStoreModule/signOut');
        store.commit('clearLoggedInUserState');
        store.commit('authStoreModule/setUser', null);
        store.commit('listsStoreModule/clear')
        await router.push({ name: 'Login' });
      } catch (error) {
        console.error('error signing out: ', error);
      }
    },
  },
};
</script>
