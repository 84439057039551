<template>
  <div class="relative inline-block m-1">
    <p v-if="isLoading">Loading</p>
    <img class="object-contain max-h-28" :src="this.file" />
  </div>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {
    file: {
      type: String,
      required: true,
      note: 'photo',
    },
    isLoading: {
      type: Boolean,
    },
  },
  emits: [],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
