<template>
  <div v-if="this.active" class="text-end right-0 bg-white border border-dark-blue rounded-xl w-36">
    <div
      v-for="option in this.options"
      :key="option.text"
      class="bg-white hover:bg-chambray hover:text-dark-blue first:rounded-t-xl last:rounded-b-xl"
    >
      <div class="cursor-pointer bg-transparent px-4 py-1" @click="emitOption(option.onClickEmit)">
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownEmitContent',
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
      note: 'Whether or not the content is being shown',
    },
    options: {
      type: Array,
      required: true,
      note: 'Options of the dropdown. text, onClickEmit',
    },
  },
  methods: {
    emitOption(event) {
      this.$emit(event);
    },
  },
};
</script>
<style scoped>
.modal-animation {
  animation: scaleUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@keyframes scaleUp {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
