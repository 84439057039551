<template>
  <div class="w-screen h-screen flex justify-center items-center">
    <div class="px-11 text-center">
      <div class="flex justify-center mb-7">
        <img alt="" src="@/assets/Group106.png" />
      </div>
      <div>
        <p class="text-xl font-bold text-[#1E1F20] mb-5">Welcome to Slydepost</p>
        <p class="text-sm font-medium text-[#787979] mb-7">
          You can now complete your profile or go to your home page to explore reviews on Slydepost.
        </p>
      </div>
      <div>
        <button
          class="bg-[#015D79] rounded-lg w-[311px] mb-5 text-base font-bold border-0 py-3"
          @click="this.$router.push('/complete/profile')"
        >
          Complete My Profile
        </button>
      </div>
      <div>
        <button
          class="bg-[#F0F0F0] rounded-lg w-[311px] text-black text-base font-bold border-0 py-3"
          @click="this.$router.push('/newsfeed')"
        >
          Go Home Page
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Welcome',
};
</script>
