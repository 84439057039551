<template>
  <div class="w-full">
    <div class="w-full flex flex-row gap-2 items-center">
      <EditCommentModal
        :showModal="this.showEditModal"
        :text="this.comment"
        :message="'Edit your comment'"
        @edit="this.updateComment"
        @cancel="this.showEditModal = false"
      />
      <AreYouSureModal
        :showModal="this.showDeleteModal"
        :message="'Are you sure you want to delete your comment?'"
        @accept="this.deleteComment"
        @cancel="this.showDeleteModal = false"
      />
      <div>
        <img v-lazy="getProfilePictureUrl" alt="Avatar" class="h-10 w-10 rounded-full" />
      </div>
      <div class="w-full flex flex-col gap-1">
        <div class="flex justify-between">
          <router-link :to="`/profile/${this.userId}`">
            <div class="capitalize font-bold">
              <p class="text-blue text-sm">{{ this.userFirstName }} {{ this.userLastName }}</p>
            </div>
          </router-link>
          <div class="flex items-center gap-2">
            <MoreOptionsButton
              v-click-outside="closeOptions"
              @click="this.showOptions = !this.showOptions"
              @edit="this.showEditModal = true"
              @delete="this.showDeleteModal = true"
              :options="this.userOwnsComment"
              :showOptions="this.showOptions"
              :icon-color="'black'"
            />
            <p class="text-sm">{{ this.formatCommentDate(this.createdAt) }}</p>
          </div>
        </div>
        <div class="flex w-full justify-between">
          <div class="flex justify-center items-center">
            <p class="text-sm">{{ this.existingComment }}</p>
          </div>
          <div class="flex items-center">
            <div @click="this.showReplyInput = !this.showReplyInput" class="italic font-normal mr-2 cursor-pointer">
              <p class="text-blue text-sm">Reply</p>
            </div>
            <div @click="this.like()" class="h-6 w-6 cursor-pointer">
              <div
                class="rounded-full border border-solid border-blue flex items-center justify-center text-blue h-6 w-6"
              >
                <div class="bg-transparent flex justify-center align-middle">
                  <font-awesome-icon v-if="userInLikesList" class="text-xs text-blue" icon="heart" />
                  <font-awesome-icon v-else class="text-xs" icon="fa-regular fa-heart" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="relative">
          <input
            v-if="this.showReplyInput === true"
            v-model="this.replyComment"
            type="text"
            class="comment-input pr-14"
            :placeholder="'Reply'"
            @keydown.enter="this.replyToComment()"
          />
          <p
            v-if="replyComment.length > 0"
            @click="this.replyToComment"
            class="absolute bottom-2 right-4 text-blue font-bold"
          >
            Post
          </p>
        </div>
      </div>
    </div>
    <div v-if="this.childComments != null && this.childComments.length > 0" class="flex flex-col gap-2 pt-2">
      <div>
        <div class="flex flex-col gap-2 pt-2">
          <div v-for="childComment in this.limitedReplies" :key="childComment.id">
            <SlydepostCommentReply
              :id="childComment.id"
              :comment="childComment.comment"
              :createdAt="childComment.createdAt"
              :likes="childComment.likes"
              :parentId="childComment.parentId"
              :postId="childComment.postId"
              :userFirstName="childComment.userFirstName"
              :userLastName="childComment.userLastName"
              :profile-photo-url="childComment.presignedUrl"
              :user-id="childComment.userId"
              :post-owner-id="this.postOwnerId"
              @reply="this.replyToReply"
              @deleteReply="this.removeChildComment"
              @likeReply="likeReply"
              @deleteLikeReply="deleteLikeReply"
            />
          </div>
        </div>
      </div>
      <div v-if="this.childComments != null && this.childComments.length > MAX_NUM_REPLIES()" class="pl-12">
        <div v-if="this.showMoreReplies === true" class="flex flex-row items-center gap-2">
          <p @click="this.showMoreReplies = false" class="text-sm text-blue">View less replies</p>
          <font-awesome-icon class="bg-transparent text-teal" icon="angle-up" />
        </div>
        <div v-else class="flex flex-row items-center gap-2">
          <p @click="this.showMoreReplies = true" class="text-sm text-blue">View more replies</p>
          <font-awesome-icon class="bg-transparent text-teal" icon="angle-down" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlydepostCommentReply from './SlydepostCommentReply.vue';
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';
import EditCommentModal from '@/components/Modals/EditCommentModal.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import client from '@/axios/client';
import { uuid } from 'vue-uuid';
import { MAX_NUM_REPLIES } from './constants';
import { event } from 'vue-gtag';
import { mapGetters } from 'vuex';

export default {
  name: 'SlydepostComment',
  emits: [
    'reply',
    'deleteComment',
    'likeComment',
    'deleteCommentLike',
    'replyToComment',
    'deleteReply',
    'replyToReply',
    'likeReply',
    'deleteLikeReply',
  ],
  props: [
    'id',
    'parentId',
    'postId',
    'userId',
    'profilePhotoUrl',
    'userFirstName',
    'userLastName',
    'createdAt',
    'comment',
    'likes', // array
    'childComments',
    'postOwnerId',
  ],
  data() {
    return {
      existingComment: '',
      replyComment: '',
      showReplyInput: false,
      showOptions: false,
      showEditModal: false,
      showDeleteModal: false,
      showMoreReplies: false,
      childCommentList: [],
      isCreatingReply: false,
      isUpdatingComment: false,
      isLikingComment: false,
      // likes: [],
      userOwnsCommentOptions: [
        {
          text: 'Edit',
          onClickEmit: 'edit',
        },
        {
          text: 'Delete',
          onClickEmit: 'delete',
        },
      ],
      userNotOwnsCommentOptions: [],
    };
  },
  components: {
    SlydepostCommentReply,
    MoreOptionsButton,
    EditCommentModal,
    AreYouSureModal,
  },
  methods: {
    deleteLikeReply(payload) {
      this.$emit('deleteLikeReply', payload);
    },
    likeReply(payload) {
      this.$emit('likeReply', payload);
    },
    removeChildComment(commentId) {
      const payload = {
        commentId: commentId,
        parentId: this.id,
        postId: this.postId,
      };
      this.$emit('deleteReply', payload);
    },
    closeOptions() {
      this.showOptions = false;
    },
    MAX_NUM_REPLIES() {
      return MAX_NUM_REPLIES;
    },
    formatCommentDate(created) {
      let createdAt = new Date(this.createdAt);
      const createdAtUtc = Date.UTC(
        createdAt.getFullYear(),
        createdAt.getMonth(),
        createdAt.getDate(),
        createdAt.getHours(),
        createdAt.getMinutes(),
        createdAt.getSeconds()
      );
      createdAt = new Date(createdAtUtc);

      const currentTime = new Date();
      const difference = currentTime - createdAt;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      if (days > 0) {
        return `${days}d`;
      }
      if (hours > 0) {
        return `${hours}h`;
      }
      if (minutes > 0) {
        return `${minutes}m`;
      }
      return `${seconds}s`;
    },
    updateComment(editedComment) {
      if (this.isUpdatingComment === true) {
        return;
      }
      if (this.existingComment.trim() === '') {
        return;
      }
      this.isUpdatingComment = true;
      this.existingComment = editedComment;
      this.showEditModal = false;
      const id = this.id;
      const postId = this.postId;
      const userId = this.loggedInUserId;
      const comment = this.existingComment;
      const jsonBody = JSON.stringify({
        id,
        postId,
        userId,
        comment,
      });
      client
        .put(`/post/comment`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          this.isUpdatingComment = false;
          event('update-slydepost-comment', {
            date: new Date().toUTCString(),
          });
        })
        .catch((res) => {
          console.error(`Error update comment response: ${res.data}`);
          this.isUpdatingComment = false;
        });
    },
    deleteComment() {
      this.showDeleteModal = false;
      const userId = this.loggedInUserId;
      client
        .delete(`/post/comment/${this.id}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          const payload = {
            postId: this.postId,
            commentId: this.id,
          };
          this.$emit('deleteComment', payload);
          event('delete-slydepost-comment', {
            date: new Date().toUTCString(),
          });
        })
        .catch((res) => {
          console.error(`Error delete comment response: ${res.data}`);
        });
    },
    replyToReply(payload) {
      this.$emit('replyToReply', payload);
      if (this.childCommentList.length === MAX_NUM_REPLIES + 1) {
        this.showMoreReplies = true;
      }
    },
    replyToComment() {
      if (this.isCreatingReply === true) {
        return;
      }
      if (this.replyComment.trim() === '') {
        return;
      }
      this.isCreatingReply = true;
      const postId = this.postId;
      const userId = this.loggedInUserId;
      const comment = this.replyComment;

      let notification = {
        postId: this.postId,
        friendId: this.loggedInUserId,
        parentComment: this.comment,
        comment: this.replyComment,
        userId: this.userId,
        friendProfilePictureUrl: this.$store.getters['getS3Url'],
        friendFirstName: this.$store.getters['getFirstName'],
        friendLastName: this.$store.getters['getLastName'],
        resized: this.$store.getters['getIsResized'],
      };

      // if the parentId is not null, the reply will go under the existing parent meaning this
      // comment is already a reply.
      // if parentId is null, the reply is going as a parent of this comment
      const parentId = this.parentId != null ? this.parentId : this.id;
      const jsonBody = JSON.stringify({
        parentId,
        postId,
        userId,
        comment,
        notification,
      });
      client
        .post(`/post/comment`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          response.data.userFirstName = this.loggedInUserFirstName;
          response.data.userLastName = this.loggedInUserLastName;
          response.data.presignedUrl = this.$store.getters['getProfilePictureUrl'];
          this.$emit('replyToComment', response.data);
          // this.childCommentList.push(response.data);
          this.showReplyInput = false;
          this.replyComment = '';
          this.isCreatingReply = false;
          event('slydepost-comment-reply', {
            date: new Date().toUTCString(),
          });
        })
        .catch((res) => {
          console.error(`Error comment response: ${res.data}`);
          this.isCreatingReply = false;
        });
    },
    like() {
      const userId = this.loggedInUserId;

      // if likes has current user in it, remove the like
      if (this.likes != null) {
        for (let i = 0; i < this.likes.length; i++) {
          if (this.likes[i].userId == userId) {
            const likeId = this.likes[i].id;
            const payload = {
              likeId: likeId,
              commentId: this.id,
              postId: this.postId,
            };
            this.$emit('deleteCommentLike', payload);
            this.deleteLikeComment(likeId);
            return;
          }
        }
      }
      // user is not in likes list, add them
      this.likeComment();
    },
    likeComment() {
      if (this.isLikingComment === true) {
        return;
      }
      if (this.id == null || this.id === '') {
        return;
      }
      this.isLikingComment = true;
      const commentId = this.id;
      const userId = this.loggedInUserId;
      let notification = {
        commentId: this.id,
        friendId: this.loggedInUserId,
        comment: this.comment,
        userId: this.userId,
        friendProfilePictureUrl: this.$store.getters['getS3Url'],
        friendFirstName: this.$store.getters['getFirstName'],
        friendLastName: this.$store.getters['getLastName'],
        resized: this.$store.getters['getIsResized'],
      };
      const jsonBody = JSON.stringify({
        commentId,
        userId,
        notification,
      });
      client
        .post(`/post/comment/like`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          const payload = {
            id: response.data.id,
            commentId: response.data.commentId,
            userId: response.data.userId,
            postId: this.postId,
          };
          this.isLikingComment = false;
          this.$emit('likeComment', payload);
          event('like-slydepost-comment', {
            date: new Date().toUTCString(),
          });
        })
        .catch((res) => {
          console.error(`Error like response`, res);
          this.isLikingComment = false;
        });
    },
    deleteLikeComment(likeId) {
      if (likeId == null || likeId === '') {
        return;
      }
      const userId = this.loggedInUserId;
      client
        .delete(`/post/comment/like/${likeId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          event('delete-slydepost-comment-like', {
            date: new Date().toUTCString(),
          });
        })
        .catch((response) => {
          console.error(`Unable to remove like.`, response);
        });
    },
  },
  computed: {
    ...mapGetters({
      getCommentsByUser: 'userStoreModule/getPublicUserPostsCommentsByPostId',
      getCommentsByUserLiked: 'userStoreModule/getPublicLikedPostsCommentsByPostId',
      getCommentsByLoggedInUser: 'getLoggedInUserPostsCommentsByPostId',
      getCommentsByLoggedInUserLiked: 'getLoggedInUserLikedPostsCommentsByPostId',
    }),
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
    loggedInUserFirstName: {
      get() {
        return this.$store.getters['authStoreModule/getFirstName'];
      },
    },
    loggedInUserLastName: {
      get() {
        return this.$store.getters['authStoreModule/getLastName'];
      },
    },
    getProfilePictureUrl() {
      if (this.profilePhotoUrl != null && this.profilePhotoUrl !== '') {
        return this.profilePhotoUrl;
      } else {
        return 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
      }
    },
    userInLikesList() {
      if (this.likes == null) {
        return false;
      }
      const userId = this.loggedInUserId;
      return this.likes.filter((like) => like.userId === userId).length > 0;
    },
    userOwnsComment() {
      const userId = this.loggedInUserId;
      return this.userId === userId ? this.userOwnsCommentOptions : this.userNotOwnsCommentOptions;
    },
    limitedReplies() {
      if (this.showMoreReplies === true) {
        return this.childComments;
      } else {
        return this.childComments.slice(0, MAX_NUM_REPLIES);
      }
    },
  },
  mounted() {
    this.existingComment = this.comment;
  },
};
</script>
