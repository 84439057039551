<template>
  <div>
    <p
      @click="clicked"
      :style="
        isSelected ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;' : ''
      "
      class="cursor-pointer text-dark-gray"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    isSelected: Boolean,
    text: String,
    id: Number,
  },
  methods: {
    clicked() {
      this.$emit('clicked', this.id);
    },
  },
};
</script>
