<template>
  <div>
    <div v-show="showMenu" class="text-dark-dark-gray p-5 lg:p-0">
      <div class="md:hidden">
        <p class="text-2xl font-bold pb-5">Insider Dashboard</p>
        <div
          v-if="!isActive"
          @click="goToStripeLogin"
          class="border-2 border-red-500 p-2 rounded-xl flex flex-row gap-4 items-center bg-oatmeal"
        >
          <font-awesome-icon :icon="['fa', 'circle-exclamation']" />
          <p>
            Your Stripe account is inactive. Click here to complete your setup.
          </p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
        <div
          @click="clickIncomeOverview"
          class="py-2 text-lg flex flex-row justify-between items-center"
        >
          <p>Income Overview</p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
        <hr />
        <div
          @click="clickProducts"
          class="py-2 text-lg flex flex-row justify-between items-center"
        >
          <p>Products</p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
        <hr />
        <div
          @click="clickCustomers"
          class="py-2 text-lg flex flex-row justify-between items-center"
        >
          <p>Customers</p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
        <hr/>
        <div
          @click="goToStripeLogin"
          class="py-2 text-lg flex flex-row justify-between items-center"
        >
          <p>Stripe Dashboard</p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
      </div>
    </div>
    <div class="hidden md:inline px-4 lg:px-0">
      <p class="text-2xl font-bold pb-5">Insider Dashboard</p>
      <div class="flex flex-row gap-4 pb-2">
        <div class="cursor-pointer" @click="showIncomeOverview">
          <p :style="getIncomeStyling">Income Overview</p>
        </div>
        <div class="cursor-pointer" @click="showProduct">
          <p :style="getProductStyling">Products</p>
        </div>
        <div class="cursor-pointer" @click="showCustomer">
          <p :style="getCustomerStyling">Customers</p>
        </div>
        <div class="cursor-pointer" @click="showStripe">
          <p>Stripe Dashboard</p>
        </div>
      </div>
      <div v-if='showIncome'>
        <IncomeDashboard/>
      </div>
      <div v-if='showProducts'>
        <Products/>
      </div>
      <div v-if='showCustomers'>
        <Customers/>
      </div>
    </div>
  </div>
</template>

<script>
import Products from '@/components/Insider/Products/Products.vue';
import IncomeDashboard from '@/components/Insider/IncomeDashboard.vue';
import Customers from '@/views/Insider/Customers.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'InsiderDashboard',
  components: {
    Products,
    IncomeDashboard,
    Customers
  },
  data() {
    return {
      showMenu: true,
      showOptions: false,
      showProducts: false,
      showIncome: true,
      showCustomers: false,
    };
  },
  methods: {
    clickProducts() {
      this.$router.push({ name: 'ViewProducts' });
    },
    clickIncomeOverview() {
      this.$router.push({ name: 'IncomeOverview' });
    },
    clickCustomers() {
      this.$router.push({ name: 'InsiderCustomers' });
    },
    goToStripeLogin() {
      this.$store.dispatch('insiderStoreModule/goToStripeLogin');
    },
    showProduct(){
      this.showProducts = true;
      this.showIncome = false;
      this.showCustomers = false;
    },
    showIncomeOverview(){
      this.showProducts = false;
      this.showIncome = true;
      this.showCustomers = false;
    },
    showCustomer(){
      this.showProducts = false;
      this.showIncome = false;
      this.showCustomers = true;
    },
    showStripe(){
      this.$store.dispatch('insiderStoreModule/goToStripeLogin')
    }
  },
  computed: {
    ...mapGetters({
      isActive: 'getIsInsiderActive',
    }),
    getProductStyling() {
      return this.showProducts
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getIncomeStyling() {
      return this.showIncome
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getCustomerStyling() {
      return this.showCustomers
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
  },
};
</script>
