<template>
  <div>
    <ViewSlydepostLikesModal :likes="likes" :show-modal="showLikes" @close="closeLikes" />
    <div class="relative bg-gradient-to-r from-white to-chambray h-12 w-24 rounded-full flex z-10">
      <div
        @click="viewLikes"
        class="bg-transparent flex w-1/2 items-center justify-center font-bold pl-4 text-blue cursor-pointer hover:underline"
      >
        {{ this.numberOfLikes }}
      </div>
      <div
        class="rounded-full border-4 border-solid border-blue flex justify-center blue bg-transparent w-1/2 cursor-pointer"
        @click="this.like()"
      >
        <div class="bg-transparent flex items-center justify-center">
          <font-awesome-icon v-if="this.isLiked" class="bg-transparent text-xl text-blue" icon="heart" />
          <font-awesome-icon v-else class="bg-transparent text-xl text-blue" icon="fa-regular fa-heart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewSlydepostLikesModal from '@/components/Modals/ViewSlydepostLikesModal.vue';

export default {
  name: 'LikeButton',
  components: { ViewSlydepostLikesModal },
  props: ['numberOfLikes', 'isLiked', 'likes'],
  emits: ['like'],
  data() {
    return {
      showLikes: false,
    };
  },
  methods: {
    like() {
      this.liked = !this.liked;
      this.$emit('like');
    },
    viewLikes() {
      // dont display modal if there are no likes on the post
      if (this.likes == null || this.likes.length === 0) {
        return;
      }
      this.showLikes = true;
    },
    closeLikes() {
      this.showLikes = false;
    },
  },
};
</script>
