<template>
  <div class="px-4 lg:px-0 lg:pt-0 flex flex-col gap-2">
    <div class="pt-4 md:hidden">
      <button @click.prevent="backToDashboard">
        <font-awesome-icon :icon="['fas', 'chevron-left']" color="white" />
        Dashboard
      </button>
    </div>
    <p class="text-2xl pb-2 text-dark-gray">Your Products</p>
    <Products/>
  </div>
</template>

<script>
import Products from '@/components/Insider/Products/Products.vue';

export default {
  name: 'ViewProduct',
  components: {
    Products
  },
  methods: {
    backToDashboard(){
      this.$router.push({ name: 'Insider' });
    },
  }
}
</script>