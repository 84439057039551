import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const exploreStoreModule = {
  namespaced: true,
  state: {
    slydeposts: [],
    isLoading: false,
  },
  getters: {
    getSlydeposts(state) {
      return state.slydeposts.map((slydepost) => {
        // Check if there are comments to sort
        if (slydepost.comments) {
          let commentsCopy = [...slydepost.comments];

          // Filter and sort parent comments
          commentsCopy = commentsCopy
            .filter((comment) => !comment.parentId)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

          // Sort child comments for each parent comment
          commentsCopy.forEach((parentComment) => {
            if (parentComment.childComments) {
              parentComment.childComments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            }
          });

          // Return a new object to ensure the state is not mutated
          return {
            ...slydepost,
            comments: commentsCopy,
          };
        }

        // Return the post as is if there are no comments to sort
        return slydepost;
      });
    },
    getIsLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    clear(state) {
      state.slydeposts = [];
      state.isLoading = false;
    },
    deleteSlydepost(state, postId) {
      state.slydeposts = state.slydeposts.filter((obj) => obj.id !== postId);
    },
    replaceSlydepost(state, payload) {
      for (let i = 0; i < state.slydeposts.length; i++) {
        if (state.slydeposts[i].id === payload.id) {
          let comments = state.slydeposts[i].comments;
          let likes = state.slydeposts[i].likes;
          payload.comments = comments;
          payload.likes = likes;
          state.slydeposts.splice(i, 1, payload);
        }
      }
    },
    addSlydepost(state, slydepost) {
      state.slydeposts.push(slydepost);
    },
    addSlydepostToBeginning(state, slydepost) {
      state.slydeposts.unshift(slydepost);
    },
    setIsLoading(state, loading) {
      state.isLoading = loading;
    },
    removeLikeFromSlydepost(state, payload) {
      const postId = payload.postId;
      const likeId = payload.id;
      const slydepost = state.slydeposts.find((item) => item.id === postId);
      const objWithIdIndex = slydepost.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        slydepost.likes.splice(objWithIdIndex, 1);
      }
    },
    likeSlydepost(state, payload) {
      const postId = payload.postId;
      const slydepost = state.slydeposts.find((item) => item.id === postId);
      if (slydepost.likes == null) {
        slydepost.likes = [];
      }
      slydepost.likes.push(payload);
    },
    removeLikeReply(state, payload) {
      const likeId = payload.id;
      const parentId = payload.parentId;
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.slydeposts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find((item) => item.id === commentId);
      const objWithIdIndex = childComment.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        childComment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeReply(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;
      const likeId = payload.id;
      const userId = payload.userId;
      const likePayload = {
        id: likeId,
        commentId: commentId,
        userId: userId,
      };
      const slydepost = state.slydeposts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find((item) => item.id === commentId);
      if (childComment.likes == null) {
        childComment.likes = [];
      }
      childComment.likes.push(likePayload);
    },
    removeReplyFromComment(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;

      const slydepost = state.slydeposts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const objWithIdIndex = comment.childComments.findIndex((obj) => obj.id === commentId);
      if (objWithIdIndex > -1) {
        comment.childComments.splice(objWithIdIndex, 1);
      }
    },
    replyToComment(state, payload) {
      const postId = payload.postId;
      const parentCommentId = payload.parentId;

      const slydepost = state.slydeposts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentCommentId);
      if (comment.childComments == null) {
        comment.childComments = [];
      }
      comment.childComments.push(payload);
    },
    removeLikeComment(state, payload) {
      const likeId = payload.likeId;
      const commentId = payload.commentId;
      const postId = payload.postId;

      const slydepost = state.slydeposts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === commentId);
      const objWithIdIndex = comment.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        comment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeComment(state, payload) {
      const postId = payload.postId;
      const commentId = payload.commentId;

      const slydepost = state.slydeposts.find((item) => item.id === postId);

      if(slydepost == null || slydepost.comments == null){
        return;
      }

      const comment = slydepost.comments.find((item) => item.id === commentId);
      if (comment.likes == null) {
        comment.likes = [];
      }
      comment.likes.push(payload);
    },
    removeComment(state, payload) {
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.slydeposts.find((item) => item.id === postId);
      const objWithIdIndex = slydepost.comments.findIndex((obj) => obj.id === commentId);
      if (objWithIdIndex > -1) {
        slydepost.comments.splice(objWithIdIndex, 1);
      }
    },
    addComment(state, payload) {
      const comment = payload.comment;
      const postId = payload.postId;
      const slydepost = state.slydeposts.find((item) => item.id === postId);
      if (slydepost.comments == null) {
        slydepost.comments = [];
      }
      slydepost.comments.push(comment);
    },
  },
  actions: {
    loadMoreSlydeposts({ commit, getters, rootGetters }) {
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const index = getters['getSlydeposts'].length;
      client
        .get(`/post/public/${loggedInUserId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
          params: { index: index },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            commit('addSlydepost', response.data[i]);
          }
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
  },
};

// export the entire module
export { exploreStoreModule };
