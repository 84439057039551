import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const listHighlightsStoreModule = {
  namespaced: true,
  state: {
    highlightLists: [],
    loadingHighlightLists: false,
  },
  getters: {
    getHighlightLists(state) {
      return state.highlightLists;
    },
    getLoadingHighlightLists(state) {
      return state.loadingHighlightLists;
    },
  },
  mutations: {
    setLoadingHighlightLists(state, loading) {
      state.loadingHighlightLists = loading;
    },
    addToHighlightLists(state, list) {
      state.highlightLists.push(list);
    },
    clear(state) {
      state.loadingHighlightLists = false;
      state.highlightLists = [];
    },
  },
  actions: {
    async fetchHighlightLists({ dispatch, commit, getters, rootGetters }) {
      commit('setLoadingHighlightLists', true);
      const userId = rootGetters['authStoreModule/getUserId'];
      await client
        .get(`/post/list/highlights/${userId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.lists.length; i++) {
            commit('addToHighlightLists', response.data.lists[i]);
          }
          commit('setLoadingHighlightLists', false);
        })
        .catch((error) => {});
    },
  },
};

// export the entire module
export { listHighlightsStoreModule };
