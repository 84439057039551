<template>
  <div class="flex justify-center md:max-w-2xl w-full bg-white rounded-lg">
    <EditSlydepostInfo
      v-if="this.showInfo === true"
      @next="(this.showInfo = false), (this.showAspects = true)"
      @exit="this.exit()"
    />
    <EditSlydepostAspects
      v-if="this.showAspects === true"
      @next="(this.showAspects = false), (this.showRatings = true)"
      @back="(this.showAspects = false), (this.showInfo = true)"
      @exit="this.exit()"
    />
    <EditSlydepostRatings
      v-if="this.showRatings === true"
      @next="(this.showRatings = false), (this.showPreview = true)"
      @back="(this.showRatings = false), (this.showAspects = true)"
      @exit="this.exit()"
    />
    <EditSlydepostPreview
      v-if="this.showPreview === true"
      :is-editing="this.isEditing"
      @next="closeModal"
      @back="(this.showPreview = false), (this.showRatings = true)"
      @exit="this.exit()"
    />
  </div>
</template>

<script>
import EditSlydepostAspects from './EditSlydepostAspects.vue';
import EditSlydepostRatings from './EditSlydepostRatings.vue';
import EditSlydepostPreview from './EditSlydepostPreview.vue';
import EditSlydepostInfo from './EditSlydepostInfo.vue';
import router from '@/router';
import store from '@/store/store';

export default {
  name: 'EditSlydepost',
  components: {
    EditSlydepostInfo,
    EditSlydepostPreview,
    EditSlydepostRatings,
    EditSlydepostAspects,
  },
  props: ['postId', 'createdAt'],
  data() {
    return {
      showInfo: true,
      showAspects: false,
      showRatings: false,
      showPreview: false,
    };
  },
  methods: {
    // reset modal so it starts at first step next time it is opened
    closeModal() {
      this.$emit('slydepostEdited');
      this.showInfo = false;
      this.showAspects = false;
      this.showRatings = false;
      this.showPreview = false;
      store.state.newSlydepost = {};
      router.push('/');
    },
    exit() {
      // this.showInfo = false;
      // this.showAspects = false;
      // this.showRatings = false;
      // this.showPreview = false;
      store.state.newSlydepost = {};
      router.push('/');
    },
  },
  mounted() {
    this.showInfo = true;
    this.showAspects = false;
    this.showRatings = false;
    this.showPreview = false;
  },
  unmounted() {
    store.commit('editPostStoreModule/clear');
  },
};
</script>
