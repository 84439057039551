import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const manageSubscriptionStoreModule = {
  namespaced: true,
  state: {
    id: '',
    name: '',
    userId: '',
    price: 0,
    type: '',
    status: '',
    endDate: '',
    cancelSubscriptionStatus: 'waiting', // waiting, inProgress, done, error
    reactivateSubscriptionStatus: 'waiting', // waiting, inProgress, done, error
  },
  getters: {
    getId(state){
      return state.id
    },
    getName(state){
      return state.name
    },
    getUserId(state){
      return state.userId
    },
    getPrice(state){
      return state.price
    },
    getType(state){
      return state.type
    },
    getStatus(state){
      return state.status
    },
    getEndDate(state){
      return state.endDate
    },
    getCancelSubscriptionStatus(state){
      return state.cancelSubscriptionStatus
    },
    getReactivateSubscriptionStatus(state){
      return state.reactivateSubscriptionStatus
    }
  },
  mutations: {
    setId(state, id){
      state.id = id
    },
    setName(state, name){
      state.name = name
    },
    setUserId(state, id){
      state.userId = id
    },
    setPrice(state, price){
      state.price = price
    },
    setType(state, type){
      state.type = type
    },
    setStatus(state, status){
      state.status = status;
    },
    setEndDate(state, date){
      state.endDate = date;
    },
    setCancelSubscriptionStatus(state, status){
      state.cancelSubscriptionStatus = status;
    },
    setReactivateSubscriptionStatus(state, status){
      state.reactivateSubscriptionStatus = status
    },
    clear(state){
      state.id = '';
      state.name = '';
      state.userId = '';
      state.price = 0;
      state.type = '';
      state.status = '';
      state.endDate = '';
      state.cancelSubscriptionStatus = 'waiting';
      state.reactivateSubscriptionStatus = 'waiting'
    }
  },
  actions: {
    async cancelSubscription({ dispatch, commit, getters, rootGetters }){
      if(getters.getCancelSubscriptionStatus === 'inProgress'){
        return;
      }
      commit('setCancelSubscriptionStatus', 'inProgress');

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      return client
        .delete(`/user/insider/subscription/${getters.getId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setCancelSubscriptionStatus', 'done')
        })
        .catch((error) => {
          console.error(error);
          commit('setCancelSubscriptionStatus', 'error')
        });
    },
    async reactivateSubscription({ dispatch, commit, getters, rootGetters }){
      if(getters.getReactivateSubscriptionStatus === 'inProgress'){
        return;
      }
      commit('setReactivateSubscriptionStatus', 'inProgress');

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      console.log(`user for reactivating subscription ${loggedInUserId}`)
      return client
        .put(`/user/insider/subscription/${getters.getId}`, null,{
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setReactivateSubscriptionStatus', 'done')
        })
        .catch((error) => {
          console.error(error);
          commit('setReactivateSubscriptionStatus', 'error')
        });
    },
  },
};

// export the entire module
export { manageSubscriptionStoreModule };
