<template>
  <div>
    <!-- Dropdown Input -->
    <div class="relative">
      <input
        class="cursor-pointer border border-teal text-dark-dark-gray h-9 rounded-none text-sm"
        @focus="showAspects()"
        v-model="aspectSearchFilter"
        :placeholder="placeholder"
      />
      <font-awesome-icon class="p-3 text-center -right-1 absolute bg-transparent text-sm" icon="fas fa-search" />
    </div>
    <!-- Selected Categories -->
    <div v-show="showOptions">
      <div
        class="bg-white border-x border-b border-teal text-dark-dark-gray overflow-auto max-h-52 z-10"
        v-show="selectedAspects.length > 0"
      >
        <ul>
          <li
            v-for="(option, index) in selectedAspects"
            :key="index"
            class="flex flex-row py-1 px-3 text-blue no-underline cursor-pointer text-start text-xs items-center gap-2"
          >
            <input class="w-3 h-3" type="checkbox" :id="index" :value="option" v-model="selectedAspects" />
            <label :for="index">{{ option }}</label>
          </li>
        </ul>
      </div>

      <!-- Dropdown Menu -->
      <div class="bg-white border border-t-0 border-teal text-dark-dark-gray overflow-auto max-h-52 z-10">
        <ul>
          <li
            v-for="(option, index) in aspects"
            :key="index"
            class="flex flex-row py-1 px-3 text-blue no-underline cursor-pointer text-start text-xs items-center gap-2"
          >
            <input
              class="w-3 h-3"
              type="checkbox"
              :id="index"
              :value="option"
              v-model="selectedAspects"
              :disabled="selectedAspects.length >= 5"
            />
            <label :for="index">{{ option }}</label>
          </li>
        </ul>
      </div>
      <div class="bg-white border border-t-0 border-teal">
        <p @click="this.clearAspects" class="text-teal underline text-xs py-1 px-3 cursor-pointer">
          Clear All Selections
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import categoryAspectMap from '@/constants/category-aspect-map.json';
import defaultAspects from '@/constants/default-aspects.json';
import { mapGetters } from 'vuex';

export default {
  name: 'RefinedSearchAspectDropdown',
  props: {
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      // defaultAspects: ['aspect1', 'aspect2'],
      aspectSearchFilter: '',
      showOptions: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedCategories: 'refinedSearchStoreModule/getRefinedCategories',
    }),
    selectedAspects: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getRefinedAspects'];
      },
      set(aspects) {
        this.$store.commit('refinedSearchStoreModule/setRefinedAspects', aspects);
      },
    },
    aspects() {
      if (this.selectedCategories.length === 0) {
        if (this.aspectSearchFilter != null || this.aspectSearchFilter !== '') {
          let filteredAspects = [];
          let filter = this.aspectSearchFilter.toLowerCase();
          for (let i = 0; i < defaultAspects.length; i++) {
            if (defaultAspects[i].toLowerCase().includes(filter)) {
              filteredAspects.push(defaultAspects[i]);
            }
          }
          return filteredAspects;
        } else {
          return defaultAspects;
        }
      }
      let aspects = [];
      for (let i = 0; i < this.selectedCategories.length; i++) {
        let key = this.selectedCategories[i];
        let category = '';
        if (key.includes(' > ')) {
          category = key.replace(' > ', '.');
        } else {
          category = key;
        }
        if (categoryAspectMap[category] == null) {
          continue;
        }
        for (let j = 0; j < categoryAspectMap[category].length; j++) {
          aspects.push(categoryAspectMap[category][j]);
        }
      }
      let categoryAspects = aspects.filter((item, index) => aspects.indexOf(item) === index);
      if (this.aspectSearchFilter != null || this.aspectSearchFilter !== '') {
        let filteredAspects = [];
        let filter = this.aspectSearchFilter.toLowerCase();
        for (let i = 0; i < categoryAspects.length; i++) {
          if (categoryAspects[i].toLowerCase().includes(filter)) {
            filteredAspects.push(categoryAspects[i]);
          }
        }
        return filteredAspects;
      } else {
        return categoryAspects;
      }
    },
  },
  methods: {
    showAspects() {
      this.aspectSearchFilter = '';
      this.showOptions = true;
    },
    clearAspects() {
      this.selectedAspects = [];
    },
  },
};
</script>
