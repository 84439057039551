<template>
  <div class="flex flex-col gap-2 w-80">
    <div class="bg-[#F0F0F0] rounded-lg">
      <button class="bg-white border-2 border-[#015D79] text-[#015D79] rounded-lg w-1/2 hover:bg-cyan-700">
        LOG IN
      </button>
      <button
        class="bg-transparent text-gray border-0 rounded-lg w-1/2 hover:border-2 hover:border-[#015D79] hover:bg-cyan-700"
        @click="createAccount"
      >
        REGISTER
      </button>
    </div>
    <p class="font-bold text-sm my-3">Welcome back</p>
    <div>
      <p class="font-bold text-sm text-gray mx-2 mt-3">EMAIL</p>
      <div class="relative w-full">
        <input @keydown.enter='logIn' v-model="email" placeholder="Email Address" type="email" />
        <div class="absolute top-2 right-2">
          <img
            v-show="email.includes('@')"
            alt="..."
            class="h-[24px] w-[24px]"
            src="@/assets/ic_radio_active.png"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="flex justify-between">
        <p class="font-bold text-sm text-gray mx-2 mt-3">PASSWORD</p>
        <p class="font-bold text-sm text-gray mx-2 mt-4 underline cursor-pointer" @click="forgotPassword">
          Forgot Password
        </p>
      </div>
      <div class="relative w-full">
        <input @keydown.enter='logIn' v-model="password" :type="showPassword ? 'text' : 'password'" placeholder="Password" />
        <div class="absolute top-3 right-2">
          <svg
            v-show="showPassword"
            class="w-5 h-5"
            fill="gray"
            stroke="white"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click="showPassword = false"
          >
            <path
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <svg
            v-show="!showPassword"
            class="w-5 h-5"
            fill="gray"
            stroke="gray"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click="showPassword = true"
          >
            <path
              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="errorMessage" class="flex items-center justify-center text-red-500 gap-2">
      <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
      <p v-if="errorMessage === 'invalid-credentials'">You have entered an invalid username or password</p>
      <p v-else-if="errorMessage === 'missing-parameters'">Both username and password are required to login</p>
      <p v-else-if="errorMessage === 'too-many-verification-emails-sent'">
        Your account has not been verified yet. We tried sending you a verification code, but too many have been sent in
        a row. Please try again later.
      </p>
    </div>
    <div class="mt-5">
      <LoaderButton
        :icon-left-side="false"
        :show-error-icon="false"
        :status="loginStatus"
        :waiting-text="'Sign In'"
        :in-progress-text="'Signing In...'"
        :done-text="'Success!'"
        :error-text="'Try Again?'"
        waitingIcon=""
        @click="logIn"
      />
    </div>
    <div v-show="false">
      <p class="text-sm text-gray text-center my-10">or login with social account</p>
      <div class="flex justify-around w-full">
        <button
          class="text-black bg-[#F0F0F0] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 rounded-lg text-base font-bold px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2 border-0"
          type="button"
        >
          <img alt="" class="h-[24px] w-[24px] me-2" src="@/assets/ic_facebook.png" />
          Facebook
        </button>
        <button
          class="text-black bg-[#F0F0F0] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 text-base font-bold rounded-lg px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2 border-0"
          type="button"
        >
          <img alt="" class="h-[24px] w-[24px] me-2" src="@/assets/ic_twitter.png" />
          Twitter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router/index';
import { mapGetters } from 'vuex';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import store from '@/store/store';

export default {
  name: 'Login',
  components: {
    LoaderButton,
  },
  emits: ['createAccount'],
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'authStoreModule/getErrorMessage',
      loginStatus: 'authStoreModule/getLoginStatus',
    }),
  },
  mounted() {
    this.$store.commit('authStoreModule/setErrorMessage', null);
    this.$store.commit('authStoreModule/setLoginStatus', 'waiting');
  },
  methods: {
    async logIn() {
      const payload = {
        email: this.email,
        password: this.password,
      };
      this.$store.commit('authStoreModule/setLoginCredentials', payload);
      await this.$store.dispatch('authStoreModule/signin', payload);
      if (this.errorMessage === 'user-not-verified') {
        store.state.userEmail = this.email;
        this.$emit('verify');
      } else {
        this.$store.commit('authStoreModule/setLoginCredentials', {});
        router.push({ name: 'Newsfeed' });
      }
    },
    forgotPassword() {
      this.$emit('forgotPassword');
    },
    createAccount() {
      this.$emit('createAccount');
    },
  },
};
</script>
