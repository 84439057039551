<template>
  <div v-if="isLoading" class="flex flex-row gap-2">
    <div v-for="image in totalImages" :key="image">
      <font-awesome-icon class="text-xl" :icon="['fas', 'spinner']" spin />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageLoadingSpinner',
  props: {
    isLoading: Boolean,
    totalImages: Number,
  },
  components: {},
};
</script>
