<template>
  <div>
    <div class="relative">
      <div v-if="this.isSelected">
        <font-awesome-icon
          class="cursor-pointer absolute -top-2 -right-1 text-dark-blue"
          icon="fa-regular fa-circle-xmark"
          @click="this.removeAspect(name)"
        />
        <div
          @click="selectAspect"
          class="text-base h-10 border border-light-light-gray rounded-xl text-blue text-center cursor-pointer bg-chambray flex items-center px-5"
        >
          {{ name }}
        </div>
      </div>
      <div
        v-else
        @click="selectAspect"
        class="text-base h-10 border border-light-light-gray rounded-xl text-blue text-center cursor-pointer bg-transparent flex items-center px-5"
      >
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditSlydepostAspect',
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {
      maxAspects: 5,
    };
  },
  methods: {
    removeAspect(aspectName) {
      // if aspect is part of suggested aspects, just mark it as not selected
      for (let i = 0; i < this.suggestedAspects.length; i++) {
        if (this.suggestedAspects[i].name === aspectName) {
          for (let j = 0; j < this.aspects.length; j++) {
            if (this.aspects[j].name === aspectName) {
              this.aspects[j].selected = false;
              return;
            }
          }
        }
      }
      // if aspect is not part of suggested aspects, remove it
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === aspectName) {
          this.aspects.splice(i, 1);
          return;
        }
      }
    },
    selectAspect() {
      // if aspect is already selected, remove it when selected again
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === this.name && this.aspects[i].selected === true) {
          this.aspects[i].selected = false;
          return;
        }
      }
      // if aspect wasn't already selected, add it to aspects array
      // only add aspect if the max number of aspects has not been met
      if (this.totalAspectsSelected >= this.maxAspects) {
        return;
      }
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === this.name) {
          this.aspects[i].selected = true;
          return;
        }
      }
    },
  },
  computed: {
    aspects: {
      get() {
        return this.$store.getters['editPostStoreModule/getAspects'];
      },
      set(aspects) {
        this.$store.commit('editPostStoreModule/setAspects', aspects);
      },
    },
    suggestedAspects: {
      get() {
        return this.$store.getters['editPostStoreModule/getSuggestedAspects'];
      },
    },
    isSelected() {
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === this.name && this.aspects[i].selected === true) {
          return true;
        }
      }
      return false;
    },
    totalAspectsSelected: {
      get() {
        return this.$store.getters['editPostStoreModule/getTotalAspectsSelected'];
      },
    },
  },
};
</script>
