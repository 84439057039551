<template>
  <div className="flex flex-col md:w-full">
    <div
      className="flex flex-col md:flex-row items-start justify-center md:justify-start md:items-center gap-4 px-4 py-4"
    >
      <img className="hidden md:inline w-12 h-12 rounded-2xl" :src="profilePictureUrl" />
      <div className="flex flex-row justify-between w-full items-center h-full">
        <div>
          <p className="review-text text-lg text-blue ">Review your Slydepost</p>
        </div>
        <div className="flex items-start h-full">
          <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
        </div>
      </div>
    </div>
    <Slydepost
      :category="this.category"
      :subcategory="this.subcategory"
      :title="this.title"
      :address="this.address"
      :description="this.description"
      :images="this.images"
      :media="this.images"
      :adjectives="this.selectedAspects"
      :userId="this.loggedInUserId"
      :link="this.link"
      :metaRating="this.metaRating"
      :displayComments="false"
      :user-first-name="this.loggedInUserFirstName"
      :userLastName="this.loggedInUserLastName"
      :profile-picture-url="this.profilePictureUrl"
      :isPreview="true"
    />
    <div v-if="this.editSlydepostStatus === 'error'" className="px-4 pb-2 text-red-500 flex flex-row gap-1">
      <span>
        <font-awesome-icon :icon="['fa', 'circle-exclamation']" />
      </span>
      <span>
        <p>There was an error on our end when trying to edit your Slydepost.</p>
      </span>
    </div>
    <div className="flex justify-between items-center px-4 pb-4">
      <div className="flex items-center gap-4">
        <div className="flex items-center">
          <font-awesome-icon
            @click="previousModal"
            :color="'#015D79'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-left']"
          />
        </div>
        <div className="flex items-center">
          <div className="z-10 w-50">
            <Dropdown
              :options="this.categoryOptions"
              @selected="getCategoryFromOptions"
              :disabled="false"
              name="sharedWithOptions"
              :maxItem="5"
              :initial-option="this.defaultSharedWithOption"
              placeholder="Who can see this"
            />
          </div>
        </div>
      </div>
      <div>
        <LoaderButton
          @click="this.editPost"
          :status="editSlydepostStatus"
          :text="this.getPostStatusButtonText()"
          :waiting-text="'Publish'"
          :in-progress-text="'Publishing'"
          :done-text="'Done!'"
          :error-text="'Try Again?'"
          :waiting-icon="'arrow-right'"
          :icon-left-side="false"
          :show-error-icon="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Slydepost from '@/components/ViewSlydepost/Slydepost.vue';
import Dropdown from '@/components/Utility/Dropdown.vue';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EditSlydepostPreview',
  props: [],
  components: {
    Dropdown,
    Slydepost,
    LoaderButton,
  },
  emits: ['back', 'next'],
  data() {
    return {
      categoryOptions: [
        { id: 1, name: 'Public', value: 'PUBLIC' },
        { id: 2, name: 'Private', value: 'PRIVATE' },
        // {id: 3, name: 'Friends of friends', value: 'friendsOfFriends'},
        // {id: 4, name: 'Only me', value: 'private'},
      ],
      defaultSharedWithOption: { id: 1, name: 'Public', value: 'PUBLIC' },
    };
  },
  methods: {
    getPostStatusButtonText() {
      if (this.editSlydepostStatus === 'waiting') {
        return 'Edit';
      } else if (this.editSlydepostStatus === 'inProgress') {
        return 'Editing';
      } else if (this.editSlydepostStatus === 'done') {
        return 'Edited!';
      } else if (this.editSlydepostStatus === 'error') {
        return 'Try again?';
      } else {
        return 'Edit';
      }
    },
    previousModal() {
      this.$emit('back');
    },
    async editPost() {
      if (this.editSlydepostStatus === 'inProgress') {
        return;
      }
      let payload = {
        id: this.id,
        category: this.category,
        title: this.title,
        link: this.link,
        address: this.address,
        description: this.description,
        images: this.images,
        files: this.files,
        subcategory: this.subcategory,
        aspects: this.selectedAspects,
        userId: this.loggedInUserId,
        metaRating: this.metaRating,
        sharedWith: this.sharedWith,
        userFirstName: this.loggedInUserFirstName,
        userLastName: this.loggedInUserLastName,
        userProfilePhotoUrl: this.profilePictureUrl,
        existingS3Images: this.existingS3Images,
      };
      await this.$store.dispatch('editPostStoreModule/editSlydepost', payload);
      // close modal
      if (this.editSlydepostStatus === 'done') {
        this.$emit('next');
      }
    },
    getCategoryFromOptions(selectedCategory) {
      this.sharedWith = selectedCategory.value;
    },
  },
  computed: {
    ...mapGetters({
      id: 'editPostStoreModule/getId',
      category: 'editPostStoreModule/getCategory',
      subcategory: 'editPostStoreModule/getSubcategory',
      title: 'editPostStoreModule/getTitle',
      link: 'editPostStoreModule/getLink',
      address: 'editPostStoreModule/getAddress',
      files: 'editPostStoreModule/getFiles',
      description: 'editPostStoreModule/getDescription',
      aspects: 'editPostStoreModule/getAspects',
      selectedAspects: 'editPostStoreModule/getSelectedAspects',
      metaRating: 'editPostStoreModule/getMetaRating',
      images: 'editPostStoreModule/getImages',
      existingS3Images: 'editPostStoreModule/getExistingS3Images',
    }),
    selectedAspects() {
      let aspects = [];
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].selected === true) {
          aspects.push(this.aspects[i]);
        }
      }
      return aspects;
    },
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
    loggedInUserFirstName: {
      get() {
        return this.$store.getters['authStoreModule/getFirstName'];
      },
    },
    loggedInUserLastName: {
      get() {
        return this.$store.getters['authStoreModule/getLastName'];
      },
    },
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    editSlydepostStatus: {
      get() {
        return this.$store.getters['editPostStoreModule/getEditSlydepostStatus'];
      },
    },
    sharedWith: {
      get() {
        return this.$store.getters['editPostStoreModule/getSharedWith'];
      },
      set(shared) {
        this.$store.commit('editPostStoreModule/setSharedWith', shared);
      },
    },
    formattedCreatedAtDate() {
      return new Date().toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  },
};
</script>
