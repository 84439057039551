<template>
  <div class="px-8 h-screen bg-white">
    <div class="py-5">
      <p class="text-2xl">Advanced Search</p>
    </div>
    <div v-if="this.showRefineMobile === false" class="md:hidden">
      <div class="pb-2 relative">
        <input
          @keydown.enter="findSearchResults"
          class="border-2 border-blue"
          placeholder="Keyword(s)"
          v-model="refinedKeywords"
        />
        <font-awesome-icon
          class="text-sm absolute right-4 top-4"
          @click="findSearchResults"
          :icon="['fas', 'search']"
          :color="'#30C3E3'"
        />
      </div>
      <div class="flex flex-row justify-between items-center pb-2">
        <div class="flex flex-row items-center gap-2">
          <button
            @click="showRefinements"
            class="bg-teal rounded-xl border-0 text-[13px] flex flex-row gap-1 items-center"
          >
            <p>Refine</p>
            <font-awesome-icon :icon="['fas', 'filter']" color="white" />
          </button>
        </div>
        <p class="text-sm">Found {{ totalResults }} Results</p>
      </div>
    </div>
    <RefinedSearch class="hidden md:block" @showResults="showRefinedResults" />
    <RefinedSearchMobile class="block md:hidden" v-show="showRefineMobile" @showResults="showRefinedResults" />
    <SearchResults v-show="showResults" />
  </div>
</template>

<script>
import RefinedSearch from '@/views/Search/RefinedSearch.vue';
import RefinedSearchMobile from '@/views/Search/RefinedSearchMobile.vue';
import SearchResults from '@/views/Search/SearchResults.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Search',
  components: {
    SearchResults,
    RefinedSearch,
    RefinedSearchMobile,
  },
  data() {
    return {
      showRefineMobile: false,
      showRefine: true,
      showResults: true,
    };
  },
  computed: {
    ...mapGetters({
      totalSlydepostSearchResults: 'refinedSearchStoreModule/getTotalSearchResults',
      totalPeopleSearchResults: 'searchStoreModule/getTotalPeopleSearchResults',
      contentType: 'refinedSearchStoreModule/getFilterContentType',
    }),
    refinedKeywords: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getRefinedKeywords'];
      },
      set(keywords) {
        this.$store.commit('refinedSearchStoreModule/setRefinedKeywords', keywords);
      },
    },
    totalResults() {
      if (this.contentType === 'slydeposts') {
        return this.totalSlydepostSearchResults;
      } else if (this.contentType === 'people') {
        return this.totalPeopleSearchResults;
      } else {
        return this.totalPeopleSearchResults + this.totalSlydepostSearchResults;
      }
    },
  },
  methods: {
    showRefinedResults() {
      this.showRefineMobile = false;
      this.showResults = true;
    },
    showRefinements() {
      this.showRefineMobile = true;
      this.showResults = false;
    },
    findSearchResults() {
      this.$store.dispatch('refinedSearchStoreModule/findRefinedResults');
    },
  },
};
</script>
