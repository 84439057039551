<template>
  <div class="container">
    <div class="verification-container">
      <div v-if="incorrectCode">
        <p>The code you have entered is incorrect</p>
        <p>Please enter the correct verification code</p>
      </div>
      <form @submit.prevent="verify">
        <h2>Enter Your One Time Code</h2>
        <div class="verify-container">
          <input type="text" v-model="code" placeholder="Code" id="input-code" />
        </div>
        <div id="verify-button-container">
          <button>Confirm</button>
        </div>
      </form>
      <button @click="resendCode">Request another code</button>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import store from '@/store/store';

export default {
  name: 'VerifyAccount',
  components: {},
  data() {
    return {
      code: '',
      incorrectCode: false,
    };
  },
  computed: {},
  setup() {},
  methods: {
    async verify() {
      if (store.state.userEmail == null || store.state.userEmail === '') {
        return;
      }
      try {
        const user = await Auth.confirmSignUp(store.state.userEmail, this.code);
        this.incorrectCode = false;
      } catch (error) {
        this.incorrectCode = true;
      }
    },
    async resendCode() {
      if (store.state.userEmail == null || store.state.userEmail === '') {
        return;
      }
      try {
        await Auth.resendSignUp(store.state.userEmail);
      } catch (error) {
        console.error('Unable to resend verification code');
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: auto;
  display: flex;
  justify-content: center;
}

.verification-container {
  width: 580px;
}

.verify-container {
  display: flex;
}

#input-code {
  flex: 1;
}

#verify-button-container {
  display: flex;
  justify-content: center;
}
</style>
