<template>
  <div v-show="showModal" class="fixed top-0 bottom-0 left-0 right-0 flex justify-center z-50 bg-modal">
    <div class="text-center h-fit mt-16">
      <div class="w-full bg-white p-4 rounded-lg flex flex-col gap-2">
        {{ this.message }}
        <div class="flex flex-row gap-2 justify-center">
          <button id="accept-button" @click="accept">Yes</button>
          <button id="cancel-button" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AreYouSureModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
      note: 'Whether modal should be visible or not',
    },
    message: {
      type: String,
      required: true,
      default: 'Are you sure?',
      note: 'Message displayed when modal appears',
    },
  },
  data() {
    return {};
  },
  methods: {
    accept() {
      this.$emit('accept');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
