import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const settingsStoreModule = {
  namespaced: true,
  state: {
    showMenu: false,
    showSettings: true,
    showProfileSettings: true,
    showPrivacySettings: false,
    showNotificationSettings: false,
    showAccountManagementSettings: false,
    showFAQ: false,
    showLegal: false,
    showCropper: false,
  },
  getters: {
    getShowMenu(state) {
      return state.showMenu;
    },
    getShowSettings(state) {
      return state.showSettings;
    },
    getShowProfileSettings(state) {
      return state.showProfileSettings;
    },
    getShowPrivacySettings(state) {
      return state.showPrivacySettings;
    },
    getShowNotificationSettings(state) {
      return state.showNotificationSettings;
    },
    getShowAccountManagementSettings(state) {
      return state.showAccountManagementSettings;
    },
    getShowFaq(state) {
      return state.showFAQ;
    },
    getShowLegal(state) {
      return state.showLegal;
    },
    getShowCropper(state) {
      return state.showCropper;
    },
  },
  mutations: {
    setShowMenu(state, show) {
      state.showMenu = show;
    },
    setShowSettings(state, show) {
      state.showSettings = show;
    },
    setShowProfileSettings(state, show) {
      state.showProfileSettings = show;
    },
    setShowPrivacySettings(state, show) {
      state.showPrivacySettings = show;
    },
    setShowNotificationSettings(state, show) {
      state.showNotificationSettings = show;
    },
    setShowAccountManagementSettings(state, show) {
      state.showAccountManagementSettings = show;
    },
    setShowFaq(state, show) {
      state.showFAQ = show;
    },
    setShowLegal(state, show) {
      state.showLegal = show;
    },
    setShowCropper(state, show) {
      state.showCropper = show;
    },
    clear(state) {
      state.showMenu = false;
      state.showSettings = true;
      state.showProfileSettings = true;
      state.showPrivacySettings = false;
      state.showNotificationSettings = false;
      state.showAccountManagementSettings = false;
      state.showFAQ = false;
      state.showLegal = false;
      state.showCropper = false;
    },
  },
  actions: {},
};

// export the entire module
export { settingsStoreModule };
