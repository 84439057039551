<template>
  <div v-show="showModal" class="fixed top-0 bottom-0 left-0 right-0 flex justify-center z-50 bg-modal">
    <AreYouSureDraftModal
      :message="'Do you want to save your progress as a draft?'"
      :show-modal="showSaveDraftModal"
      @accept="createListDraft"
      @cancel="navigateAwayWithoutSaving"
      @close="closeModalWithoutAction"
    />
    <AreYouSureDraftModal
      :message="'Do you want to save your progress and update this draft?'"
      :show-modal="showUpdateDraftModal"
      @accept="updateListDraft"
      @cancel="navigateAwayWithoutSaving"
      @close="closeModalWithoutAction"
    />
    <div
      class="modal-animation bg-white box-border m-auto absolute bottom-0 w-full rounded-t-[16px] sm:static sm:bottom-unset sm:top-50 sm:w-[580px] sm:rounded-[16px]"
    >
      <div class="p-5">
        <div class="flex justify-end mr-2 cursor-pointer" @click="closeModal">
          <img class="w-[20px] h-[20px]" src="@/assets/close.svg" alt="" />
        </div>
        <p class="text-[16px] text-blue font-[700] mb-3">Add List</p>
        <div class="mb-2">
          <p class="text-[12px] font-bold uppercase text-[#787979] mb-1">Name of list</p>
          <div v-show="this.validations.showMissingNameError === true">
            <p class="text-red-500">
              {{ this.validations.missingNameErrorMessage }}
            </p>
          </div>
          <input v-model="title" type="text" class="text-[14px] font-bold border-[2px] rounded-sm" />
        </div>

        <div class="mb-2">
          <p class="text-[12px] font-bold uppercase text-[#787979] mb-1">Description</p>
          <textarea
            v-model="description"
            class="text-[14px] font-bold h-full w-full border-[2px] rounded-sm"
            rows="6"
          ></textarea>
        </div>

        <p class="text-dark-blue text-[16px] mb-2">Select Cover Photo (optional)</p>
        <UploadListCoverPhoto @imageSelected="imageSelected" />
      </div>

      <div class="bg-[#E5FAFB] flex flex-wrap items-center p-2 rounded-b-[16px] sm:px-5">
        <div class="w-[50%] flex flex-wrap items-center">
          <div class="w-[80%] pl-1">
            <select
              v-model="status"
              class="border border-light-light-gray bg-white rounded-[10px] text-[18px] sm:text-[20px] font-[400] p-1 sm:p-2"
            >
              <option value="PUBLIC">Public</option>
              <option value="PRIVATE">Private</option>
              <option v-if='isInsider' value="SUBSCRIPTION">Subscription</option>
            </select>
          </div>
        </div>

        <div class="w-[50%] flex gap-2 items-center justify-end">
          <button
            @click="this.listDraftId !== '' ? updateListDraft() : createListDraft()"
            class="px-4 py-2 sm:px-5 sm:py-3 text-[16px] font-[700] cursor-pointer w-[121px] rounded-[12px]"
          >
            Save Draft
          </button>
          <button
            @click="this.listDraftId !== '' ? createListFromDraft() : createList()"
            class="px-4 py-2 sm:px-5 sm:py-3 text-[16px] font-[700] cursor-pointer w-[121px] rounded-[12px]"
          >
            Publish
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadListCoverPhoto from '@/components/MediaUpload/UploadListCoverPhoto.vue';
import AreYouSureDraftModal from '@/components/Modals/AreYouSureDraftModal.vue';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore.js';
import { mapGetters } from 'vuex';

export default {
  name: 'AddListModal',
  components: { UploadListCoverPhoto, AreYouSureDraftModal },
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  data() {
    return {
      showModal: false,
      showSaveDraftModal: false,
      showUpdateDraftModal: false,
      validations: {
        showMissingNameError: false,
        showMissingDescriptionError: false,
        missingNameErrorMessage: 'Please enter a title',
        missingDescriptionErrorMessage: 'Please select a description',
      },
    };
  },
  computed: {
    ...mapGetters({
      isInsider: 'getIsInsider'
    }),
    title: {
      get() {
        return this.$store.getters['listsStoreModule/getTitle'];
      },
      set(title) {
        this.$store.commit('listsStoreModule/setTitle', title);
      },
    },
    listDraftId: {
      get() {
        return this.$store.getters['listsStoreModule/getListDraftId'];
      },
    },
    description: {
      get() {
        return this.$store.getters['listsStoreModule/getDescription'];
      },
      set(description) {
        this.$store.commit('listsStoreModule/setDescription', description);
      },
    },
    file: {
      get() {
        return this.$store.getters['listsStoreModule/getFile'];
      },
      set(file) {
        this.$store.commit('listsStoreModule/setFile', file);
      },
    },
    existingImageUrl: {
      get() {
        return this.$store.getters['listsStoreModule/getExistingImageUrl'];
      },
      set(url) {},
    },
    status: {
      get() {
        return this.$store.getters['listsStoreModule/getStatus'];
      },
      set(status) {
        this.$store.commit('listsStoreModule/setStatus', status);
      },
    },
    url: {
      get() {
        return this.$store.getters['listsStoreModule/getUrl'];
      },
    },
  },
  methods: {
    closeModalWithoutAction() {
      this.showSaveDraftModal = false;
      this.showUpdateDraftModal = false;
    },
    navigateAwayWithoutSaving() {
      this.$store.commit('listsStoreModule/clearDraft');
      this.showModal = false;
      this.showSaveDraftModal = false;
      this.showUpdateDraftModal = false;
    },
    closeModal() {
      if (this.showSaveDraftModal === false && this.showUpdateDraftModal === false) {
        let title = this.$store.getters['listsStoreModule/getTitle'];
        if (title === '' || title === null || title === undefined) {
          this.$store.commit('listsStoreModule/clearDraft');
          this.showModal = false;
        }
        let draftId = this.$store.getters['listsStoreModule/getListDraftId'];
        if (draftId !== null && draftId !== '') {
          this.showUpdateDraftModal = true;
          return;
        }
        this.showSaveDraftModal = true;
        return;
      }
      this.$store.commit('listsStoreModule/clearDraft');
      this.showSaveDraftModal = false;
      this.showUpdateDraftModal = false;
      this.showModal = false;
    },
    updateListDraft() {
      const payload = {
        id: this.listDraftId,
        title: this.title,
        description: this.description,
        status: this.status,
        file: this.existingImageUrl || this.file,
      };
      this.slydepostDraftStore.updateListDraft(payload, this.$store.getters['authStoreModule/getUserId']);
      this.$store.commit('listsStoreModule/clearDraft');
      this.showSaveDraftModal = false;
      this.showUpdateDraftModal = false;
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    imageSelected(e) {
      this.file = e;
    },
    async createNewList() {
      let payload = {
        title: this.title,
        status: this.status,
        file: this.file,
      };
      if (this.description !== '') {
        payload.description = this.description;
      }
      await this.$store.dispatch('listsStoreModule/createListByLoggedInUser', payload);
      this.name = '';
      this.description = '';
      this.status = 'PUBLIC';
      this.$store.commit('listsStoreModule/clearDraft');
      this.showSaveDraftModal = false;
      this.showUpdateDraftModal = false;
      this.showModal = false;
    },
    createList() {
      if (this.title == null || this.title.trim() === '') {
        this.validations.showMissingNameError = true;
        return;
      }
      this.createNewList();
    },
    async createListFromDraft() {
      this.isFromDraft = true;
      const createPost = await this.$store.dispatch('listsStoreModule/createListByLoggedInUser', this);
      if (createPost) {
        this.slydepostDraftStore.deleteListDraft(this.listDraftId, this.$store.getters['authStoreModule/getUserId']);
      }
      this.$store.commit('listsStoreModule/clearDraft');
      this.showSaveDraftModal = false;
      this.showUpdateDraftModal = false;
      this.showModal = false;
    },
    createListDraft() {
      if (this.title == null || this.title.trim() === '') {
        this.validations.showMissingNameError = true;
        return;
      }
      let payload = {
        title: this.title,
        description: this.description,
        status: this.status,
        file: this.file,
      };
      let filteredPayload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null && v !== ''));
      const userId = this.$store.getters['authStoreModule/getUserId'];
      this.slydepostDraftStore.createListDraft(filteredPayload, userId);
      this.name = '';
      this.description = '';
      this.status = 'PUBLIC';
      this.$store.commit('listsStoreModule/clearDraft');
      this.showSaveDraftModal = false;
      this.showUpdateDraftModal = false;
      this.showModal = false;
    },
  },
  unmounted() {
    this.showSaveDraftModal = false;
    this.showUpdateDraftModal = false;
  },
};
</script>

<style scoped>
.modal-animation {
  animation: scaleUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@keyframes scaleUp {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
