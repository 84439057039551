<template>
  <div
    class="p-2 rounded-xl flex flex-row justify-between items-center"
  >
    <div>
      <div>
        <span>Subscription:&nbsp;</span>
        <span class="capitalize font-bold">{{ priceType.toLowerCase() }}</span>
      </div>
      <p>Price: {{ formattedPrice(price) }}</p>
    </div>
    <div>
      <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionProduct',
  props: {
    priceType: String,
    price: Number,
  },
  methods: {
    formattedPrice(price) {
      const dollars = (price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
  },
};
</script>