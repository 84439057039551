<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <div class="relative">
      <input
        class="cursor-pointer border border-light-light-gray text-blue"
        @focus="showCategories()"
        @blur="exit()"
        @keyup.enter="selectOptionOnEnter()"
        @keyup.down="this.increaseOptionsIndex()"
        @keyup.up="this.decreaseOptionsIndex()"
        ref="dropdownInputRef"
        v-model="searchFilter"
        :placeholder="placeholder"
      />
      <font-awesome-icon
        class="absolute bg-transparent -right-4 text-blue p-4 text-center min-w-[40px]"
        icon="fas fa-search"
      />
    </div>

    <!-- Dropdown Menu -->
    <div
      class="bg-white border border-light-light-gray rounded-xl w-full shadow overflow-auto max-h-52 z-10"
      v-show="showOptions"
      ref="dropdownContentMenu"
    >
      <RecycleScroller class="h-56" :items="filteredOptions" :item-size="32" key-field="id" v-slot="{ item, index }">
        <div
          class="text-blue p-2 no-underline cursor-pointer text-start text-[15px] flex flex-row"
          :class="{ active: index == this.optionsIndex }"
          @mousedown="selectOption(item, index)"
        >
          <p v-if="item.parent != null" class="bg-transparent text-[15px]">{{ item.parent.name }}&nbsp;|&nbsp;</p>
          <p class="bg-transparent text-[15px]">{{ item.name }}</p>
        </div>
      </RecycleScroller>
    </div>
  </div>
</template>

<script>
import categoriesList from '@/constants/categories-list.json';

export default {
  name: 'CategoryDropdown',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
  },
  data() {
    return {
      showOptions: false,
      options: [],
      maxOptions: 0,
    };
  },
  emits: ['selected'],
  computed: {
    searchFilter: {
      get() {
        return this.$store.getters['createPostStoreModule/getCategoryDropdownSearchFilter'];
      },
      set(filter) {
        this.$store.commit('createPostStoreModule/setCategoryDropdownSearchFilter', filter);
      },
    },
    category: {
      get() {
        return this.$store.getters['createPostStoreModule/getCategory'];
      },
      set(category) {
        this.$store.commit('createPostStoreModule/setCategory', category);
      },
    },
    subcategory: {
      get() {
        return this.$store.getters['createPostStoreModule/getSubcategory'];
      },
      set(subcategory) {
        this.$store.commit('createPostStoreModule/setSubcategory', subcategory);
      },
    },
    optionsIndex: {
      get() {
        return this.$store.getters['createPostStoreModule/getCategoryDropdownSelectedOptionIndex'];
      },
      set(index) {
        this.$store.commit('createPostStoreModule/setCategoryDropdownSelectedOptionIndex', index);
      },
    },
    filteredOptions() {
      if (this.searchFilter == null || this.searchFilter === '') {
        return this.options;
      }
      let filteredCategories = [];
      let filter = this.searchFilter.toLowerCase();
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].name.toLowerCase().includes(filter)) {
          filteredCategories.push(this.options[i]);
        } else if (this.options[i].parent != null && this.options[i].parent.name.toLowerCase().includes(filter)) {
          filteredCategories.push(this.options[i]);
        }
      }
      return filteredCategories;
    },
  },
  methods: {
    selectOption(option, index) {
      this.optionsIndex = index;
      this.subcategory = '';
      this.category = '';
      this.searchFilter = option.name;
      if (option.parent != null) {
        this.category = option.parent.name;
        this.subcategory = option.name;
      } else {
        this.category = option.name;
        this.subcategory = '';
      }
      this.$emit('selected', option);
    },
    showCategories() {
      this.searchFilter = '';
      this.showOptions = true;
    },
    exit() {
      if ((this.category === '' || this.category == null) && (this.subcategory === '' || this.subcategory == null)) {
        this.searchFilter = '';
      } else if (this.subcategory != null && this.subcategory !== '') {
        this.searchFilter = this.subcategory;
      } else {
        this.searchFilter = this.category;
      }
      this.showOptions = false;
    },
    selectOptionOnEnter() {
      this.selectOption(this.filteredOptions[this.optionsIndex]);
      this.$refs['dropdownInputRef'].blur();
    },
    increaseOptionsIndex() {
      if (this.optionsIndex < this.maxOptions) {
        this.optionsIndex += 1;
        this.$refs['dropdownContentMenu'].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    },
    decreaseOptionsIndex() {
      if (this.optionsIndex > 0) {
        this.optionsIndex -= 1;
        this.$refs['dropdownContentMenu'].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    },
  },
  mounted() {
    this.options = categoriesList;
    this.maxOptions = this.options.length;
  },
};
</script>

<style scoped>
.active {
  background: #f2f2f2;
}
</style>
