<template>
  <div class="flex flex-col gap-1">
    <div class="flex justify-between">
      <div class="text-left uppercase text-sm font-bold text-dark-gray">
        {{ this.name }}
      </div>
      <div v-if="this.rating > 4" class="text-base font-bold text-moonstone">
        {{ this.rating }}
      </div>
      <div v-else class="text-base text-dark-gray font-bold text-dark-orange">
        {{ this.rating }}
      </div>
    </div>
    <div v-if="this.rating > 4" ref="ratingSliderGood" class="h-2.5 bg-chambray rounded-full">
      <div class="w-7 md:w-4 h-2.5 rounded-full relative bg-moonstone" :style="`left: ${this.getSliderWidth}`"></div>
    </div>
    <div v-else ref="ratingSliderBad" class="h-2.5 bg-light-orange rounded-full">
      <div class="w-7 md:w-4 h-2.5 rounded-full relative bg-dark-orange" :style="`left: ${this.getSliderWidth}`"></div>
    </div>
    <div v-if="this.description != null && this.displayDescriptionsProp !== false" class="text-sm md:text-base">
      {{ this.description }}
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

export default {
  name: 'SlydepostRating',
  props: ['id', 'name', 'rating', 'description', 'displayDescriptionsProp'],
  data() {
    return {
      ratingSliderWidth: 0,
    };
  },
  setup() {
    let ratingSliderGood = ref();
    let ratingSliderBad = ref();
    onMounted(() => {
      if (ratingSliderGood.value != null) {
        const sliderWidth = ratingSliderGood.value.clientWidth;
      } else if (ratingSliderBad.value != null) {
        const sliderWidth = ratingSliderBad.value.clientWidth;
      }
    });
    return { ratingSliderGood, ratingSliderBad };
  },
  methods: {},
  computed: {
    displayDescriptions() {
      if (this.adjectives == null) {
        return false;
      }
      for (let i = 0; i < this.adjectives.length; i++) {
        if (this.adjectives[i].description != null && this.adjectives[i].description !== '') {
          return true;
        }
      }
      return false;
    },
    adjectivesToDisplay() {
      return this.adjectives.filter(function (adjective) {
        return adjective.description != null && adjective.description !== '';
      });
    },
    getSliderWidth() {
      if (this.ratingSliderGood != null) {
        let section = Math.round(this.ratingSliderGood.clientWidth / 11);
        let location = section * this.rating;
        let styleString = location.toString() + 'px';
        return styleString;
      } else if (this.ratingSliderBad != null) {
        let section = Math.round(this.ratingSliderBad.clientWidth / 11);
        let location = section * this.rating;
        let styleString = location.toString() + 'px';
        return styleString;
      }
      return 0 + 'px';
    },
  },
};
</script>
