<template>
  <div @click="this.clickHeader" :class="hasValueClass">
    <p :class="isSelectedText">{{ text }}</p>
    <font-awesome-icon
      v-show="this.showInput === false"
      :color="isSelectedArrowColor"
      class="cursor-pointer text-lg md:text-sm"
      :icon="['fas', 'chevron-down']"
    />
    <font-awesome-icon
      v-show="this.showInput === true"
      :color="isSelectedArrowColor"
      class="cursor-pointer text-lg md:text-sm"
      :icon="['fas', 'chevron-up']"
    />
  </div>
</template>

<script>
export default {
  name: 'RefinedSearchTextHeader',
  data() {
    return {
      showInput: false,
    };
  },
  props: {
    text: {
      type: String,
    },
    hasValue: {
      type: Boolean,
    },
  },
  methods: {
    clickHeader() {
      this.showInput = !this.showInput;
      this.$emit('clickHeader');
    },
  },
  computed: {
    hasValueClass() {
      let valueClass = 'flex flex-row justify-between md:justify-start md:gap-2 w-full items-center ';
      if (this.hasValue) {
        valueClass += 'md:rounded-full md:bg-teal md:py-1 md:px-2';
      }
      return valueClass;
    },
    isSelectedArrowColor() {
      if (this.hasValue) {
        return 'white';
      }
      return '#015D79';
    },
    isSelectedText() {
      let text = 'text-xl md:text-sm md:whitespace-nowrap ';
      if (this.hasValue) {
        text += 'md:text-white';
      }
      return text;
    },
  },
};
</script>
