<template>
  <div class="lg:px-0 pt-4 lg:pt-0">
    <p class="text-2xl px-4 text-dark-gray">Become An Insider</p>
    <img class='px-0 py-4' src='https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/growingboymedia/GROWINGBOYMEDIALLC-2023-12.jpg'/>
    <div class='px-4'>
      <p class='text-lg font-bold'>What is a Slydepost Insider?</p>
      <p class='text-lg'>Unlock the potential of your content.</p>
      <p class='text-sm'>
        Becoming a Slydepost Insider allows you to earn money by sharing exclusive content with your most dedicated
        followers. Whether it’s through curated Lists, private posts, or unique insights, Slydepost Insiders can
        monetize their influence and creativity. As an Insider, you’ll gain access to tools and resources to help you
        grow your audience and maximize your earnings.
      </p>
    </div>
    <div class='flex flex-row py-4'>
      <img class='w-1/2' src='https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/growingboymedia/GROWINGBOYMEDIALLC-2023-91-square.jpg'/>
      <img class='w-1/2' src='https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/growingboymedia/GROWINGBOYMEDIALLC-2023-45.jpg'/>
    </div>
    <div class='px-4'>
      <p class='text-lg font-bold'>How to Become a Slydepost Insider</p>
      <p class='text-lg'>Ready to start earning? Follow these simple steps:</p>
      <ol type='1' class='list-decimal px-4 text-sm'>
        <li>Sign Up or Log In: Make sure you have a Slydepost account. If not, you can sign up quickly and easily.</li>
        <li>Connect to Stripe: Click the "Become an Insider" button below to link your Slydepost account with Stripe, our secure payment partner.</li>
        <li>Complete Your Profile: Add the finishing touches to your Insider profile to attract subscribers. This includes setting up your pricing, content preferences, and payment details.</li>
        <li>Start Creating and Sharing: Once your account is approved, you’re ready to start posting exclusive content that only your subscribers can access.</li>
      </ol>
    </div>
    <div class='px-4 py-4'>
      <p class='text-lg text-center'>Join a community of creators who are transforming their passions into profit with Slydepost Insider</p>
    </div>
    <LoaderButton
      class='px-4'
      :status="upgradeStatus"
      :waiting-icon='null'
      :waiting-text="'Become an Insider'"
      :in-progress-text="'In progress'"
      :error-text="'Error'"
      :done-text="'Done!'"
      @click='upgrade'
    />
    <div class='px-4 pt-2 pb-4 text-sm'>
      <span>By become an insider, you are accepting our&nbsp;</span>
      <span class='underline cursor-pointer' @click='goToInsiderTermsOfService'>Insider Terms of Service and Policies.</span>
    </div>
  </div>
</template>

<script>
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import router from '@/router';
import { mapGetters } from 'vuex';

export default {
  name: 'BecomeAnInsider',
  components: {
    LoaderButton
  },
  computed: {
    ...mapGetters({
      upgradeStatus: 'insiderStoreModule/getUpgradeStatus'
    })
  },
  methods: {
    upgrade(){
      this.$store.dispatch('insiderStoreModule/upgrade')
        .then((response) => {
          // Handle success
          const stripeUrl = response.data.link;
          window.location.href = stripeUrl; // Or redirect to Stripe URL if needed
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          if(error.response.status === 409){
            console.log(`409 error!`)
            router.push({name: 'Insider'})
          } else {
            console.log(`other error!`)
          }
        });
    },
    goToInsiderTermsOfService(){
      this.$store.commit('legalStoreModule/setPage', 'insider-terms');
      this.$router.push({ name: 'Legal' });
    }
  }
}
</script>