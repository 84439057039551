<template>
  <div class="h-full flex flex-col items-end justify-center cursor-pointer relative">
    <div class="cursor-pointer flex justify-center items-center gap-3">
      <div
        class="uppercase text-sm text-dark-blue"
        :class="underlineSelectedOption ? 'underline underline-offset-2' : ''"
      >
        {{ this.selectedOption }}
      </div>
      <div v-if="this.showOptions === false">
        <font-awesome-icon class="text-xl" :color="'#30C3E3'" icon="fa-solid fa-angle-down" />
      </div>
      <div v-else>
        <font-awesome-icon class="text-xl" :color="'#30C3E3'" icon="fa-solid fa-angle-up" />
      </div>
    </div>
    <div
      v-show="this.showOptions === true && this.options != null && this.options.length > 0"
      class="flex flex-col bg-white items-end z-30 absolute top-6 w-36 bg-white border-solid border-dark-blue border rounded-xl"
    >
      <div
        class="first:rounded-t-xl last:rounded-b-xl w-full hover:bg-hover text-end pr-2"
        v-for="option in this.options"
        :key="option"
      >
        <div class="capitalize" @click="this.emitOption(option)">
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownOptionsArrow',
  props: {
    // text, emit,
    options: {
      type: Array,
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: String,
      note: 'the option that is currently selected',
    },
    underlineSelectedOption: {
      type: Boolean,
      note: 'Whether or not to underline the currently selected option',
    },
  },
  methods: {
    emitOption(option) {
      this.$emit(option.emit);
    },
  },
};
</script>
