<template>
  <div class="flex flex-col gap-2">
    <p class="font-bold text-center">Forgot your password?</p>
    <p class="text-center">Please enter you email address to get a password reset code</p>
    <form @submit.prevent>
      <input type="email" v-model="email" placeholder="Email Address" />
    </form>
    <div class="flex justify-center gap-2">
      <button @click="cancel">Cancel</button>
      <button @click="forgotPassword">Reset Password</button>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import store from '@/store/store';

export default {
  name: 'ForgotPassword',
  components: {},
  emits: ['cancel', 'forgotPasswordSubmit'],
  data() {
    return {
      email: '',
      incorrectCode: false,
    };
  },
  computed: {},
  setup() {},
  methods: {
    async forgotPassword() {
      try {
        await Auth.forgotPassword(this.email);
        store.state.userEmail = this.email;
        this.$emit('forgotPasswordSubmit');
      } catch (error) {
        console.error('error sending forgot password code', error);
      }
    },
  },
};
</script>
