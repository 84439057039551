<template>
  <div class="flex flex-row justify-between items-center gap-2">
    <div class="flex flex-row gap-2 items-center">
      <img class="w-10 h-10 rounded-2xl" :src="this.profilePicture" />
      <div>
        <p class="inline capitalize">
          <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
            {{ firstName }} {{ lastName }}
          </router-link>
        </p>
        <p class="inline">&nbsp;requested to follow you.</p>
      </div>
    </div>
    <div class="flex flex-row gap-3 items-center">
      <font-awesome-icon
        @click="acceptFriendRequest"
        class="text-xl text-green-600 cursor-pointer"
        :icon="['fas', 'check']"
      />
      <font-awesome-icon
        @click="ignoreFriendRequest"
        class="text-md text-red-500 cursor-pointer"
        :icon="['fas', 'x']"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserFollowRequestNotification',
  props: {
    userId: String,
    firstName: String,
    lastName: String,
    profilePictureUrl: String,
    friendId: String,
    actionId: String,
    createdAt: String,
  },
  emits: ['removeNotification'],
  computed: {
    profilePicture() {
      return this.profilePictureUrl != null
        ? this.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
  },
  methods: {
    acceptFriendRequest() {
      let payload = {
        relationshipId: this.actionId,
        userId: this.userId,
        friendId: this.friendId,
      };
      this.$store.dispatch('relationshipStoreModule/acceptFriendRequest', payload);
      this.$emit('removeNotification', this.actionId);
    },
    ignoreFriendRequest() {
      this.$store.dispatch('relationshipStoreModule/ignoreFriendRequest', this.actionId);
      this.$emit('removeNotification', this.actionId);
    },
  },
};
</script>
