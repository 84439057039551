<template>
  <div>
    <div>
      <p>Total monthly subscriptions: {{ monthlySubscriptions.length }}</p>
      <p>Value: {{ totalMonthlyIncome }}</p>
    </div>
    <div>
      <p>Total yearly subscriptions: {{ yearlySubscriptions.length }}</p>
      <p>Value: {{ totalYearlyIncome }}</p>
    </div>
    <div>
      <p>Total list purchases: {{ listPurchases.length }}</p>
      <p>Value: {{ totalListIncome }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IncomeDashboard',
  computed: {
    ...mapGetters({
      monthlySubscriptions: 'insiderStoreModule/getMonthlySubscriptions',
      yearlySubscriptions: 'insiderStoreModule/getYearlySubscriptions',
      listPurchases: 'insiderStoreModule/getListPurchases',
    }),
    totalMonthlyIncome(){
      let total = 0
      for(let i = 0; i < this.monthlySubscriptions.length; i++){
        total += this.monthlySubscriptions[i].price.price
      }
      const dollars = (total / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
    totalYearlyIncome(){
      let total = 0;
      for(let i = 0; i < this.yearlySubscriptions.length; i++){
        total += this.yearlySubscriptions[i].price.price
      }
      const dollars = (total / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
    totalListIncome(){
      let total = 0;
      for(let i = 0; i < this.listPurchases.length; i++){
        total += this.listPurchases[i].price.price
      }
      const dollars = (total / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return dollars;
    },
  },
  mounted(){
    this.$store.dispatch('insiderStoreModule/getInsiderPayments')
  }
}
</script>