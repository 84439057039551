<template>
  <div class="flex flex-col md:w-full">
    <div class="flex flex-col md:flex-row items-start justify-center md:justify-start md:items-center gap-4 px-4 py-4">
      <img :src="profilePictureUrl" class="hidden md:inline w-12 h-12 rounded-2xl" />
      <div class="flex flex-row justify-between w-full items-center h-full">
        <div>
          <p class="review-text text-lg text-blue">Review your Slydepost</p>
        </div>
        <div class="flex items-start h-full">
          <font-awesome-icon class="cursor-pointer text-sm" icon="fa-solid fa-x" @click="$emit('exit')" />
        </div>
      </div>
    </div>
    <Slydepost
      :address="this.address"
      :adjectives="this.selectedAspects"
      :category="this.category"
      :description="this.description"
      :displayComments="false"
      :images="this.images"
      :isNewSlydePost="isNewSlydePost"
      :isPreview="true"
      :link="this.link"
      :media="this.images"
      :metaRating="this.metaRating"
      :subcategory="this.subcategory"
      :title="this.title"
      :user-first-name="this.loggedInUserFirstName"
      :userId="this.loggedInUserId"
      :userLastName="this.loggedInUserLastName"
      :is-create-post="true"
    />
    <div v-if="this.createSlydepostStatus === 'error'" class="px-4 pb-2 text-red-500 flex flex-row gap-1">
      <span>
        <font-awesome-icon :icon="['fa', 'circle-exclamation']" />
      </span>
      <span>
        <p>There was an error on our end when trying to create your Slydepost.</p>
      </span>
    </div>
    <div class="flex justify-between items-center px-4 pb-4">
      <div class="flex items-center gap-4">
        <div class="flex items-center">
          <font-awesome-icon
            :color="'#015D79'"
            :icon="['fas', 'arrow-left']"
            class="cursor-pointer text-3xl"
            @click="previousModal"
          />
        </div>
        <div class="flex items-center">
          <div class="z-10 w-32">
            <Dropdown
              :disabled="false"
              :initial-option="defaultSharedWithOption"
              :maxItem="5"
              :options="this.categoryOptions"
              name="sharedWithOptions"
              placeholder="Who can see this"
              @selected="getCategoryFromOptions"
            />
          </div>
        </div>
      </div>
      <div class="flex gap-2">
        <LoaderButton
          :icon-left-side="false"
          :show-error-icon="false"
          :status="createSlydepostStatus"
          :waiting-text="''"
          :in-progress-text="'Saving'"
          :done-text="'Saved!'"
          :error-text="'Try Again?'"
          :waiting-icon="'save'"
          @click="this.createDraft"
        />
        <LoaderButton
          :icon-left-side="false"
          :show-error-icon="false"
          :status="createSlydepostStatus"
          :waiting-text="'Post'"
          :in-progress-text="'Posting'"
          :done-text="'Posted!'"
          :error-text="'Try Again?'"
          :waiting-icon="'arrow-right'"
          @click="this.draftId != null && this.draftId !== '' ? createPostFromDraft() : createPost()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Slydepost from '@/components/ViewSlydepost/Slydepost.vue';
import Dropdown from '@/components/Utility/Dropdown.vue';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import { mapGetters } from 'vuex';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore.js';

export default {
  name: 'CreateSlydepostPreview',
  props: ['isNewSlydePost'],
  components: {
    Dropdown,
    Slydepost,
    LoaderButton,
  },
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  emits: ['back', 'next', 'draft'],
  data() {
    return {
      // sharedWith: '',
      categoryOptions: [
        { id: 1, name: 'Public', value: 'PUBLIC' },
        { id: 2, name: 'Private', value: 'PRIVATE' },
        // {id: 3, name: 'Friends of friends', value: 'friendsOfFriends'},
        // {id: 4, name: 'Only me', value: 'private'},
      ],
      createDraftStatus: 'waiting',
    };
  },
  methods: {
    previousModal() {
      this.$emit('back');
    },
    async createPost() {
      if (this.createSlydepostStatus === 'inProgress') {
        return;
      }
      let payload = {
        category: this.category,
        title: this.title,
        link: this.link,
        address: this.address,
        description: this.description,
        images: this.images,
        files: this.files,
        subcategory: this.subcategory,
        aspects: this.selectedAspects,
        userId: this.loggedInUserId,
        metaRating: this.metaRating,
        sharedWith: this.sharedWith.toUpperCase(),
        userFirstName: this.loggedInUserFirstName,
        userLastName: this.loggedInUserLastName,
        userProfilePhotoUrl: this.profilePictureUrl,
        lists: this.lists,
      };
      await this.$store.dispatch('createPostStoreModule/createSlydepost', payload);
      // close modal
      if (this.createSlydepostStatus === 'done') {
        this.$emit('next');
      }
    },
    async createDraft() {
      if (this.createDraftStatus === 'inProgress') {
        return;
      }
      this.createDraftStatus = 'inProgress';
      // check if draft should be created or updated
      let draftId = this.$store.getters['createPostStoreModule/getDraftId'];
      // create draft if there is no draft id
      if (draftId == null || draftId === '') {
        let payload = {
          category: this.category,
          title: this.title,
          link: this.link,
          address: this.address,
          description: this.description,
          images: this.images,
          files: this.files,
          subcategory: this.subcategory,
          aspects: this.selectedAspects,
          userId: this.loggedInUserId,
          metaRating: this.metaRating,
          sharedWith: this.sharedWith,
          userFirstName: this.loggedInUserFirstName,
          userLastName: this.loggedInUserLastName,
          userProfilePhotoUrl: this.profilePictureUrl,
        };
        let newDraft = await this.slydepostDraftStore.addDraft(payload);
        if (newDraft !== undefined) {
          this.slydepostDraftStore.slydepostDrafts.unshift(newDraft);
        }
        this.createDraftStatus = 'done';
        // push drafts to router
        this.$emit('draft');
      }
      // update draft
      else {
        const draftData = this.getCurrentSlydepostData();
        draftData.id = this.$store.getters['createPostStoreModule/getDraftId'];
        const draftStore = useSlydepostDraftStore();
        await draftStore.updateDraft(draftData);
        this.$emit('draft');
      }
    },
    async createPostFromDraft() {
      if (this.createSlydepostStatus === 'inProgress') {
        return;
      }
      let payload = {
        category: this.category,
        title: this.title,
        link: this.link,
        address: this.address,
        description: this.description,
        files: this.files,
        subcategory: this.subcategory,
        aspects: this.selectedAspects,
        userId: this.loggedInUserId,
        metaRating: this.metaRating,
        sharedWith: this.sharedWith,
        userFirstName: this.loggedInUserFirstName,
        userLastName: this.loggedInUserLastName,
        userProfilePhotoUrl: this.profilePictureUrl,
        lists: this.lists,
        draftId: this.draftId,
      };
      if (this.files.length > 0) {
        payload.images = this.images;
        payload.media = this.images;
      } else {
        payload.draftMedia = this.images;
        payload.media = this.images;
      }
      try {
        let newSlydepost = await this.$store.dispatch('createPostStoreModule/createSlydepost', payload);
        if (newSlydepost !== undefined) {
          this.createSlydepostStatus = 'done';
          //this.slydepostStore.slydeposts.unshift(newSlydepost);
          this.slydepostDraftStore.slydepostDrafts = this.slydepostDraftStore.slydepostDrafts.filter(
            (draft) => draft.id !== this.draftId
          );
          this.$emit('next');
        }
      } catch (error) {
        this.createSlydepostStatus = 'error';
      }
    },
    getCategoryFromOptions(selectedCategory) {
      this.sharedWith = selectedCategory.value;
    },
    getCurrentSlydepostData() {
      return {
        title: this.$store.getters['createPostStoreModule/getTitle'],
        address: this.$store.getters['createPostStoreModule/getAddress'],
        link: this.$store.getters['createPostStoreModule/getLink'],
        category: this.$store.getters['createPostStoreModule/getCategory'],
        subcategory: this.$store.getters['createPostStoreModule/getSubcategory'],
        files: this.$store.getters['createPostStoreModule/getFiles'],
        aspects: this.$store.getters['createPostStoreModule/getSelectedAspects'],
        description: this.$store.getters['createPostStoreModule/getDescription'],
        sharedWith: this.$store.getters['createPostStoreModule/getSharedWith'],
        metaRating: this.$store.getters['createPostStoreModule/getMetaRating'],
        userId: this.$store.getters['authStoreModule/getUserId'],
        userFirstName: this.$store.getters['authStoreModule/getFirstName'],
        userLastName: this.$store.getters['authStoreModule/getLastName'],
        suggestedAspects: this.$store.getters['createPostStoreModule/getSuggestedAspects'],
        categoryDropdownSelectedOptionIndex:
          this.$store.getters['createPostStoreModule/getCategoryDropdownSelectedOptionIndex'],
        username: this.$store.getters['authStoreModule/getUser'].username,
        userProfilePictureUrl: this.$store.getters['authStoreModule/getProfilePhoto'],
        images: this.$store.getters['createPostStoreModule/getExistingS3Images'],
      };
    },
  },
  computed: {
    ...mapGetters({
      category: 'createPostStoreModule/getCategory',
      subcategory: 'createPostStoreModule/getSubcategory',
      title: 'createPostStoreModule/getTitle',
      link: 'createPostStoreModule/getLink',
      address: 'createPostStoreModule/getAddress',
      files: 'createPostStoreModule/getFiles',
      description: 'createPostStoreModule/getDescription',
      aspects: 'createPostStoreModule/getAspects',
      selectedAspects: 'createPostStoreModule/getSelectedAspects',
      metaRating: 'createPostStoreModule/getMetaRating',
      images: 'createPostStoreModule/getImages',
      draftId: 'createPostStoreModule/getDraftId',
      addToListModelPayload: 'listsStoreModule/getAddToListModalPayload',
    }),
    defaultSharedWithOption() {
      if (this.sharedWith != null && this.sharedWith !== '') {
        let option = {
          id: 1,
          name: this.sharedWith,
          value: this.sharedWith.toUpperCase(),
        };
        return option;
      }
      return { id: 1, name: 'Public', value: 'public' };
    },
    lists() {
      return this.addToListModelPayload.selectedListsForNewSlydepost;
    },
    selectedAspects() {
      let aspects = [];
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].selected === true) {
          aspects.push(this.aspects[i]);
        }
      }
      return aspects;
    },
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
    loggedInUserFirstName: {
      get() {
        return this.$store.getters['authStoreModule/getFirstName'];
      },
    },
    loggedInUserLastName: {
      get() {
        return this.$store.getters['authStoreModule/getLastName'];
      },
    },
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    createSlydepostStatus: {
      get() {
        return this.$store.getters['createPostStoreModule/getCreateSlydepostStatus'];
      },
    },
    sharedWith: {
      get() {
        return this.$store.getters['createPostStoreModule/getSharedWith'];
      },
      set(shared) {
        this.$store.commit('createPostStoreModule/setSharedWith', shared);
      },
    },
    formattedCreatedAtDate() {
      return new Date().toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  },
  mounted() {
    this.createDraftStatus = 'waiting';
  },
};
</script>
