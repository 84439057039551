<template>
  <div
    class="flex w-full h-screen justify-center from-ice to-oatmeal bg-cover bg-no-repeat"
    :style="`background-image: url(${backgroundImage})`"
  >
    <div class="flex flex-col w-full items-center justify-center max-w-lg relative">
      <div
        v-show="showForgotPassword || showForgotPasswordSubmit || showVerify"
        class="flex flex-row w-full justify-between items-center mb-5"
      >
        <button
          class="p-0 border-0"
          @click="
            showForgotPassword = false;
            showForgotPasswordSubmit = false;
            showVerify = false;
            showLogin = true;
          "
        >
          <img alt="" src="@/assets/ic_base.png" />
        </button>
      </div>
      <div class="flex flex-col mt-auto items-center mb-1">
        <img
          v-show="showLogin === true || showSignUp === true"
          src="@/assets/slydepost-logo-and-text-oatmeal.png"
          alt="logo"
          class="mb-1 w-72"
        />
        <p v-show="showLogin === true || showSignUp === true" class="text-white text-xl text-center">
          Your community, your reviews
        </p>
      </div>
      <div
        :class="
          showVerify
            ? 'p-7 border-2 border-blue backdrop-blur-lg'
            : 'bg-[#F3FBFF] p-7 rounded-[32px] border-2 border-blue'
        "
      >
        <LoginComponent
          v-if="showLogin === true"
          @createAccount="
            showSignUp = true;
            showLogin = false;
          "
          @forgotPassword="
            showLogin = false;
            showForgotPassword = true;
          "
          @verify="
            showLogin = false;
            showVerify = true;
          "
        />
        <SignUpComponent
          v-if="showSignUp === true"
          @signIn="
            showSignUp = false;
            showLogin = true;
          "
          @verify="
            showSignUp = false;
            showVerify = true;
          "
        />
        <VerifyComponent
          v-if="showVerify === true"
          @signIn="
            showVerify = false;
            showLogin = true;
          "
        />
        <ForgotPasswordComponent
          v-if="showForgotPassword === true"
          @forgotPasswordSubmit="
            showForgotPasswordSubmit = true;
            showForgotPassword = false;
          "
        />
        <ForgotPasswordResetComponent v-if="showForgotPasswordSubmit === true" />
      </div>
      <div class='mt-auto mb-2'>
        <div class='text-white text-sm'>
          <span class='underline cursor-pointer' @click='goToHelp'>Help</span>
          <span>&nbsp;|&nbsp;</span>
          <span class='underline cursor-pointer' @click='goToSupport'>Support</span>
          <span>&nbsp;|&nbsp;</span>
          <span class='underline cursor-pointer' @click='goToLegal'>Legal</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import router from '@/router';
import LoginComponent from '@/components/SignInSignUp/Login.vue';
import SignUpComponent from '@/components/SignInSignUp/SignUp.vue';
import VerifyComponent from '@/components/SignInSignUp/Verify.vue';
import ForgotPasswordComponent from '@/components/SignInSignUp/ForgotPassword.vue';
import ForgotPasswordResetComponent from '@/components/SignInSignUp/ForgotPasswordReset.vue';
import backgroundGroup from '@/assets/prelaunch-landing-page.png';
import store from '@/store/store';

export default {
  name: 'Login',
  components: {
    LoginComponent,
    SignUpComponent,
    VerifyComponent,
    ForgotPasswordComponent,
    ForgotPasswordResetComponent,
  },
  data() {
    return {
      showLogin: true,
      showSignUp: false,
      showVerify: false,
      showForgotPassword: false,
      showForgotPasswordSubmit: false,
    };
  },
  computed: {
    socialQuery() {
      return this.$route.query.social;
    },
    userQuery() {
      return this.$route.query.user;
    },
    backgroundImage() {
      return backgroundGroup;
    },
  },
  setup() {},
  methods: {
    forgotPassword() {
      router.push('forgotPassword');
    },
    goToHelp(){
      store.commit('helpStoreModule/setPage', 'faq')
      router.push({name: 'Help'})
    },
    goToSupport(){
      store.commit('helpStoreModule/setPage', 'support')
      router.push({name: 'Help'})
    },
    goToLegal(){
      router.push({name: 'Legal'})
    }
  },
  mounted() {
    if (this.socialQuery != null && this.socialQuery !== '') {
      this.$store.commit('authStoreModule/setSocialReferral', this.socialQuery);
    } else if (this.userQuery != null && this.userQuery !== '') {
      this.$store.commit('authStoreModule/setUserReferral', this.userQuery);
    }
  },
};
</script>
