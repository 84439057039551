<template>
  <div class="lg:hidden flex flex-col w-screen bg-dark-blue px-4 py-4">
    <p class="text-xl text-white pb-2">Trending Users</p>
    <div id="trending-users" class="z-20 overflow-scroll">
      <div class="flex flex-row gap-4 min-w-0">
        <div v-for="user in users" :key="user.userId">
          <router-link :to="`/profile/${user.userId}`">
            <div class="flex flex-col">
              <div class="m-auto w-20 h-20">
                <img
                  class="w-full h-full rounded-xl bg-dark-blue bg-center object-cover object-center"
                  :src="user.userProfilePicturePresignedUrl"
                />
              </div>
              <div class="text-start text-[12px] font-[400] text-white pt-1 w-20">
                <div class="line-clamp-1">
                  <p v-if="user.username != null">@{{ user.username }}</p>
                  <p v-else>{{ user.firstName }} {{ user.lastName }}</p>
                </div>
                <p class="text-moonstone">
                  {{ user.totalFollowers }} Follower{{ user.totalFollowers !== 1 ? 's' : '' }}&nbsp;
                </p>
                <p class="text-moonstone">{{ user.totalPosts }} Post{{ user.totalPosts !== 1 ? 's' : '' }}</p>
                <p class="text-moonstone">{{ user.totalLists }} List{{ user.totalLists !== 1 ? 's' : '' }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <p @click="goToTrendingUsers" class="block text-md font-bold text-white pt-2">View Trending Users</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrendingUsers',
  computed: {
    ...mapGetters({
      users: 'trendingUserStoreModule/getViewableUsers',
    }),
  },
  methods: {
    goToTrendingUsers() {
      this.$store.commit('trendingStoreModule/setCurrentView', 'users');
      this.$router.push({ name: 'Trending' });
    },
  },
};
</script>

<style scoped>
#trending-users::-webkit-scrollbar {
  display: none;
}

#trending-users {
  scrollbar-width: none;
}
</style>
