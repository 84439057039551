<template>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 px-2">
    <ListCardV2
      v-for="list in lists"
      :key="list.id"
      :profile-picture-url="list.userProfilePicturePresignedUrl"
      :id="list.listId"
      :title="list.title"
      :cover="list.presignedUrl"
      :post-count="list.totalPosts"
      :follow-count="list.totalFollows"
    />
  </div>
  <InfiniteLoading @infinite="getMoreLists" />
  <div class="w-full bg-transparent py-5">
    <Loading v-show="isLoading" />
  </div>
</template>

<script>
import ListCardV2 from '@/components/ViewLists/ListCardV2.vue';
import { mapGetters } from 'vuex';
import InfiniteLoading from 'v3-infinite-loading';
import Loading from '@/components/Utility/Loading.vue';

export default {
  name: 'TrendingLists',
  components: {
    ListCardV2,
    InfiniteLoading,
    Loading,
  },
  computed: {
    ...mapGetters({
      lists: 'trendingListStoreModule/getViewableLists',
      isLoading: 'trendingListStoreModule/getIsLoading',
    }),
  },
  methods: {
    getMoreLists() {
      this.$store.commit('trendingListStoreModule/addListsToViewableLists');
    },
  },
  mounted() {
    this.$store.dispatch('trendingListStoreModule/loadLast7Days');
  },
};
</script>
