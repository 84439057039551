<template>
  <div class="h-full flex flex-col items-end justify-center cursor-pointer relative">
    <div class="cursor-pointer flex items-center">
      <font-awesome-icon :color="this.iconColor" class="options-button font-black" icon="fa-solid fa-ellipsis" />
    </div>
    <div
      v-show="this.showOptions === true && this.options != null && this.options.length > 0"
      class="flex flex-col bg-white items-end z-10 absolute top-7 w-36 bg-white border-solid border-dark-blue border rounded-xl"
    >
      <div
        v-for="option in this.options"
        :key="option"
        class="hover:bg-hover w-full first:rounded-t-xl last:rounded-b-xl text-end pr-2"
      >
        <div @click="this.emitOption(option.onClickEmit)">
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoreOptionsButton',
  props: {
    // text, onClickEmit,
    options: {
      type: Array,
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'white',
    },
  },
  methods: {
    emitOption(event) {
      this.$emit(event);
    },
  },
};
</script>

<style scoped>
.options-button {
  float: right;
}

.options {
  cursor: pointer;
}

.options-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.options-menu-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 3;
  position: absolute;
  top: 20px;
  width: 150px;
  background: transparent;
  border: 1px solid #012337;
  border-radius: 10px;
  padding-right: 10px;
}
</style>
