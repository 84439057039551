<template>
  <div>
    <p class="text-2xl pb-4 text-dark-gray pt-4 px-2 md:px-4 lg:px-0">Trending</p>
    <div class="flex flex-row gap-4 items-center pb-4 px-2 md:px-4 lg:px-0">
      <DropdownOptionsArrow
        v-click-outside="closeTrendingFilter"
        :options="trendingFilterOptions"
        :show-options="showTrendingFilterOptions"
        :selected-option="currentFilter"
        :underline-selected-option="true"
        @click="this.showTrendingFilterOptions = !this.showTrendingFilterOptions"
        @filter24Hours="filter24Hours"
        @filter7Days="filter7Days"
        @filter30Days="filter30Days"
        @filter1Year="filter365Days"
        @filterAllTime="filterAllTime"
      />
      <ToggleButtonMenu :current-option="selectedOption" :options="menuOptions" @selected="selectOption" />
    </div>
    <TrendingUsers v-if="selectedOption === 'users'" />
    <TrendingSlydeposts v-if="selectedOption === 'slydeposts'" />
    <TrendingLists v-if="selectedOption === 'lists'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TrendingUsers from '@/components/Trending/Explore/TrendingUsers.vue';
import TrendingSlydeposts from '@/components/Trending/Explore/TrendingSlydeposts.vue';
import TrendingLists from '@/components/Trending/Explore/TrendingLists.vue';
import ToggleButtonMenu from '@/components/Utility/ToggleButtonMenu.vue';
import DropdownOptionsArrow from '@/components/Utility/DropdownOptionsArrow.vue';

export default {
  name: 'Explore',
  components: {
    TrendingUsers,
    TrendingSlydeposts,
    DropdownOptionsArrow,
    TrendingLists,
    ToggleButtonMenu,
  },
  data() {
    return {
      showTrendingFilterOptions: false,
      selectedId: 0,
      menuOptions: [
        {
          id: 0,
          text: 'Slydeposts',
          name: 'slydeposts',
        },
        {
          id: 1,
          text: 'Users',
          name: 'users',
        },
        {
          id: 2,
          text: 'Lists',
          name: 'lists',
        },
      ],
      trendingFilterOptions: [
        {
          text: '24 Hours',
          emit: 'filter24Hours',
        },
        {
          text: '7 Days',
          emit: 'filter7Days',
        },
        {
          text: '30 Days',
          emit: 'filter30Days',
        },
        {
          text: '1 Year',
          emit: 'filter1Year',
        },
        {
          text: 'All Time',
          emit: 'filterAllTime',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'trendingPostStoreModule/getIsLoading',
    }),
    currentFilter: {
      get() {
        return this.$store.getters['trendingStoreModule/getCurrentFilter'];
      },
      set(filter) {
        this.$store.commit('trendingStoreModule/setCurrentFilter', filter);
      },
    },
    selectedOption: {
      get() {
        return this.$store.getters['trendingStoreModule/getCurrentView'];
      },
      set(view) {
        this.$store.commit('trendingStoreModule/setCurrentView', view);
      },
    },
  },
  methods: {
    selectOption(name) {
      this.selectedOption = name;
      if (this.currentFilter === '24 Hours') {
        this.filter24Hours();
      } else if (this.currentFilter === '7 Days') {
        this.filter7Days();
      } else if (this.currentFilter === '30 Days') {
        this.filter30Days();
      } else if (this.currentFilter === '1 Year') {
        this.filter365Days();
      } else if (this.currentFilter === 'All Time') {
        this.filterAllTime();
      }
    },
    closeTrendingFilter() {
      this.showTrendingFilterOptions = false;
    },
    filter24Hours() {
      this.currentFilter = '24 Hours';
      if (this.selectedOption === 'slydeposts') {
        this.$store.dispatch('trendingPostStoreModule/loadLast24Hours');
      } else if (this.selectedOption === 'lists') {
        this.$store.dispatch('trendingListStoreModule/loadLast24Hours');
      } else if (this.selectedOption === 'users') {
        this.$store.dispatch('trendingUserStoreModule/loadLast24Hours');
      }
    },
    filter7Days() {
      this.currentFilter = '7 Days';
      if (this.selectedOption === 'slydeposts') {
        this.$store.dispatch('trendingPostStoreModule/loadLast7Days');
      } else if (this.selectedOption === 'lists') {
        this.$store.dispatch('trendingListStoreModule/loadLast7Days');
      } else if (this.selectedOption === 'users') {
        this.$store.dispatch('trendingUserStoreModule/loadLast7Days');
      }
    },
    filter30Days() {
      this.currentFilter = '30 Days';
      if (this.selectedOption === 'slydeposts') {
        this.$store.dispatch('trendingPostStoreModule/loadLast30Days');
      } else if (this.selectedOption === 'lists') {
        this.$store.dispatch('trendingListStoreModule/loadLast30Days');
      } else if (this.selectedOption === 'users') {
        this.$store.dispatch('trendingUserStoreModule/loadLast30Days');
      }
    },
    filter365Days() {
      this.currentFilter = '1 Year';
      if (this.selectedOption === 'slydeposts') {
        this.$store.dispatch('trendingPostStoreModule/loadLast365Days');
      } else if (this.selectedOption === 'lists') {
        this.$store.dispatch('trendingListStoreModule/loadLast365Days');
      } else if (this.selectedOption === 'users') {
        this.$store.dispatch('trendingUserStoreModule/loadLast365Days');
      }
    },
    filterAllTime() {
      this.currentFilter = 'All Time';
      if (this.selectedOption === 'slydeposts') {
        this.$store.dispatch('trendingPostStoreModule/loadAllTime');
      } else if (this.selectedOption === 'lists') {
        this.$store.dispatch('trendingListStoreModule/loadAllTime');
      } else if (this.selectedOption === 'users') {
        this.$store.dispatch('trendingUserStoreModule/loadAllTime');
      }
    },
  },
  unmounted() {
    // this.$store.commit('trendingListStoreModule/clear');
  },
};
</script>
