<template>
  <div>
    <div>
      <p class="text-2xl font-bold mb-3">Change Password</p>
      <p class="text-sm my-2 font-medium text-[#616262]">
        Reset code was sent to your email. Please enter the code and create new password.
      </p>
    </div>
    <div>
      <div>
        <p class="font-bold text-xs mx-2 mt-5 mb-2 text-[#787979]">RESET CODE</p>
        <input
          v-model="code"
          class="focus:border-2 focus:border-cyan-900"
          placeholder="Verification Code"
          type="text"
        />
      </div>
      <div>
        <p class="font-bold text-xs mx-2 mt-5 mb-2 text-[#787979]">NEW PASSWORD</p>
        <div class="relative w-full">
          <input
            v-model="newPassword"
            :type="showPassword ? 'text' : 'password'"
            class="focus:border-2 focus:border-cyan-900"
            placeholder="New Password"
            @focus="this.showPasswordChecker = true"
          />
          <div class="absolute top-3 right-2">
            <svg
              v-show="showPassword"
              class="w-5 h-5"
              fill="gray"
              stroke="white"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              @click="showPassword = false"
            >
              <path
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg
              v-show="!showPassword"
              class="w-5 h-5"
              fill="gray"
              stroke="gray"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              @click="showPassword = true"
            >
              <path
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <p class="font-bold text-xs mx-2 mt-5 mb-2 text-[#787979]">CONFIRM PASSWORD</p>
        <div class="relative w-full">
          <input
            v-model="confirmNewPassword"
            class="focus:border-2 focus:border-cyan-900"
            placeholder="Confirm Password"
            type="password"
          />
          <div class="absolute top-2 right-2">
            <img
              v-show="confirmNewPassword === newPassword && confirmNewPassword !== ''"
              alt="..."
              class="w-[24px] h-[24px]"
              src="@/assets/ic_radio_active.png"
            />
          </div>
        </div>
      </div>
      <PasswordChecker
        :check-password="this.confirmNewPassword"
        :password="this.newPassword"
        :show-checker="this.showPasswordChecker"
      />
    </div>
    <div class="mt-5">
      <button
        :disabled="!(confirmNewPassword === newPassword) || confirmNewPassword === ''"
        class="w-full rounded-lg mt-3 bg-[#F37653] border-0 hover:bg-[#F37653]"
        @click="forgotPasswordSubmit"
      >
        Change Password
      </button>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import store from '@/store/store';
import PasswordChecker from '@/components/SignInSignUp/PasswordChecker.vue';
import router from '@/router';

export default {
  name: 'ForgotPasswordReset',
  components: {
    PasswordChecker,
  },
  data() {
    return {
      code: '',
      incorrectCode: false,
      newPassword: '',
      confirmNewPassword: '',
      showPasswordChecker: false,
      showPassword: false,
    };
  },
  computed: {},
  setup() {},
  methods: {
    async forgotPasswordSubmit() {
      try {
        if (store.state.userEmail == null || store.state.userEmail === '') {
          return;
        }
        await Auth.forgotPasswordSubmit(store.state.userEmail, this.code, this.newPassword);
        router.push('/congrats');
      } catch (error) {
        console.error('Error resetting forgotten password', error);
      }
    },
  },
};
</script>
