<template>
  <div>
    <p>Confirming Signup...</p>
  </div>
</template>

<script>
export default {
  name: 'ConfirmInsiderSignup',
  mounted(){
    const accountId = this.$route.query.account_id;
    const insiderId = this.$route.query.insider_id;
    const payload = {
      accountId,
      insiderId
    }
    this.$store.dispatch('insiderStoreModule/confirmInsiderSignup', payload);
  }
}
</script>