<template>
  <!--aspects have descriptions-->
  <div v-if="this.hasAspectDescriptions">
    <!--show on mobile-->
    <div class="bg-white flex md:hidden flex-wrap gap-4">
      <div class="w-full">
        <SlydepostInfo
          :address="this.address"
          :category="this.category"
          :description="this.description"
          :isPreview="false"
          :likesProp="this.likes"
          :link="this.link"
          :post-owner-id="this.userId"
          :postId="this.id"
          :subcategory="this.subcategory"
          :title="this.title"
          @likeSlydepost="likeSlydepost"
          @deleteLikeSlydepost="deleteLikeSlydepost"
          @showAddToListModal="showAddToListModal"
        ></SlydepostInfo>
      </div>
      <hr
        v-if="this.hasAspectDescriptions"
        class="hidden md:inline border-solid border-b-1 border-b-black my-2 w-full"
      />
      <div class="w-full">
        <SlydepostRatings :adjectives="this.adjectives" :hasDescriptions="this.hasAspectDescriptions" />
      </div>
    </div>
    <!--show on tablet/desktop-->
    <div class="hidden bg-white md:flex flex-wrap gap-4">
      <div class="w-full">
        <SlydepostInfo
          :address="this.address"
          :category="this.category"
          :description="this.description"
          :isPreview="false"
          :likesProp="this.likes"
          :link="this.link"
          :post-owner-id="this.userId"
          :postId="this.id"
          :subcategory="this.subcategory"
          :title="this.title"
          @likeSlydepost="likeSlydepost"
          @deleteLikeSlydepost="deleteLikeSlydepost"
          @showAddToListModal="showAddToListModal"
        ></SlydepostInfo>
      </div>
      <hr
        v-if="this.hasAspectDescriptions"
        class="hidden md:inline border-solid border-b-1 border-b-black my-2 w-full"
      />
      <div class="grid grid-cols-4 gap-6">
        <div class="col-span-1">
          <SlydepostRatings
            :adjectives="this.adjectives"
            :displayDescriptionsProp="false"
            :hasDescriptions="this.hasAspectDescriptions"
          />
        </div>
        <div class="text-start col-span-3 flex flex-col gap-1">
          <div v-for="aspect in aspectsWithOnlyDescriptions" :key="aspect.id" class="flex flex-col gap-0.5">
            <div class="underline underline-offset-2">
              {{ aspect.name }}
            </div>
            <div>
              {{ aspect.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--aspects do not have descriptions-->
  <div v-else>
    <!--display on mobile-->
    <div class="md:hidden w-full bg-white flex flex-col-reverse gap-4 md:gap-6">
      <div class="flex-auto">
        <SlydepostRatings :adjectives="this.adjectives" :hasDescriptions="this.hasAspectDescriptions" />
      </div>
      <div class="flex-4-auto">
        <SlydepostInfo
          :address="this.address"
          :category="this.category"
          :description="this.description"
          :isPreview="false"
          :likesProp="this.likes"
          :link="this.link"
          :post-owner-id="this.userId"
          :postId="this.id"
          :subcategory="this.subcategory"
          :title="this.title"
          @likeSlydepost="likeSlydepost"
          @deleteLikeSlydepost="deleteLikeSlydepost"
          @showAddToListModal="showAddToListModal"
        ></SlydepostInfo>
      </div>
    </div>
    <!--display on tablet/desktop-->
    <div class="hidden md:grid grid-cols-4 w-full bg-white gap-6">
      <div class="col-span-1">
        <SlydepostRatings :adjectives="this.adjectives" :hasDescriptions="this.hasAspectDescriptions" />
      </div>
      <div class="col-span-3">
        <SlydepostInfo
          :address="this.address"
          :category="this.category"
          :description="this.description"
          :isNewSlydePost="isNewSlydePost"
          :isPreview="false"
          :likesProp="this.likes"
          :link="this.link"
          :post-owner-id="this.userId"
          :postId="this.id"
          :subcategory="this.subcategory"
          :title="this.title"
          @likeSlydepost="likeSlydepost"
          @deleteLikeSlydepost="deleteLikeSlydepost"
          @showAddToListModal="showAddToListModal"
        ></SlydepostInfo>
      </div>
    </div>
  </div>
</template>

<script>
import SlydepostRatings from './SlydepostRatings.vue';
import SlydepostInfo from './SlydepostInfo.vue';

export default {
  name: 'SlydepostBody',
  components: { SlydepostRatings, SlydepostInfo },
  emits: ['likeSlydepost', 'deleteLikeSlydepost', 'showAddToListModal'],
  props: {
    id: {
      type: String,
      note: 'id of the slydepost',
    },
    category: {
      type: String,
    },
    subcategory: {
      type: String,
    },
    title: {
      type: String,
    },
    address: {
      type: String,
    },
    link: {
      type: String,
    },
    description: {
      type: String,
    },
    isPreview: {
      type: Boolean,
      note: 'If this is a preview version of the slydepost or not',
    },
    likes: {
      type: Array,
    },
    adjectives: {
      type: Array,
    },
    hasAspectDescriptions: {
      type: Boolean,
      note: 'Whether or not there are any descriptions on any of the aspects',
    },
    userId: {
      type: String,
      note: 'id of the user the post belongs to',
    },
    isNewSlydePost: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    likeSlydepost(payload) {
      this.$emit('likeSlydepost', payload);
    },
    deleteLikeSlydepost(payload) {
      this.$emit('deleteLikeSlydepost', payload);
    },
    showAddToListModal() {
      this.$emit('showAddToListModal');
    },
  },
  computed: {
    aspectsWithOnlyDescriptions() {
      let aspects = [];
      for (let i = 0; i < this.adjectives.length; i++) {
        if (this.adjectives[i].description != null && this.adjectives[i].description !== '') {
          aspects.push(this.adjectives[i]);
        }
      }
      return aspects;
    },
  },
};
</script>
