<template>
  <div class="m-auto p-1 w-20 h-20" :style="dynamicStyles">
    <router-link :to="{ name: 'SingleList', params: { listId: this.id } }" class="w-full">
      <img class="w-full h-full rounded-full bg-dark-blue bg-center object-cover object-center" :src="this.thumbnail" />
    </router-link>
  </div>
</template>

<script>
import slydepostLogo from '@/assets/no-photo-background-square.png';

export default {
  name: 'SingleUpdatedList',
  components: {},
  data() {
    return {};
  },
  props: {
    imgUrl: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    dynamicStyles() {
      // const colors = this.generateRandomColor(); // Assuming this method generates colors
      let randomColor1 = '';
      let randomColor2 = '';
      while (randomColor1 == randomColor2) {
        randomColor1 = '#' + Math.floor(Math.random() * 16777215).toString(16);
        randomColor2 = '#' + Math.floor(Math.random() * 16777215).toString(16);
      }
      return {
        // position: 'relative',
        background: `linear-gradient(to right, ${randomColor1}, ${randomColor2})`,
        borderRadius: '50%',
      };
    },
    thumbnail() {
      if (this.imgUrl == null || this.imgUrl === '') {
        return slydepostLogo;
      } else {
        return this.imgUrl;
      }
    },
  },
};
</script>
