<template>
  <div class="flex flex-row items-center gap-2 justify-between">
    <div class="flex flex-row items-center gap-2">
      <img class="w-10 h-10 rounded-2xl" :src="this.profilePicture" />
      <div>
        <p class="inline capitalize">
          <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
            {{ firstName }} {{ lastName }}
          </router-link>
        </p>
        <p class="inline">&nbsp;liked your comment&nbsp;</p>
        <p class="inline font-bold">
          <router-link
            :to="{
              name: 'ViewSlydepostByCommentId',
              params: { commentId: postId },
            }"
          >
            {{ comment }}
          </router-link>
        </p>
      </div>
    </div>
    <div>
      <p class="text-sm">{{ formatCommentDate }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlydepostCommentLikeNotification',
  props: {
    userId: String,
    firstName: String,
    lastName: String,
    profilePictureUrl: String,
    comment: String,
    postId: String,
    createdAt: String,
  },
  computed: {
    profilePicture() {
      return this.profilePictureUrl != null
        ? this.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
    formatCommentDate() {
      let createdAt = new Date(this.createdAt);
      const createdAtUtc = Date.UTC(
        createdAt.getFullYear(),
        createdAt.getMonth(),
        createdAt.getDate(),
        createdAt.getHours(),
        createdAt.getMinutes(),
        createdAt.getSeconds()
      );
      createdAt = new Date(createdAtUtc);

      const currentTime = new Date();
      const difference = currentTime - createdAt;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      if (days > 0) {
        return `${days}d`;
      }
      if (hours > 0) {
        return `${hours}h`;
      }
      if (minutes > 0) {
        return `${minutes}m`;
      }
      return `${seconds}s`;
    },
  },
};
</script>
