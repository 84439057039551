<template>
  <div>
    <div class="hidden md:flex flex-row justify-between items-center pb-4">
      <ContentTypeSelector
        :text="'Content Type'"
        :selected-option="this.selectedContentType"
        :options="this.options"
        @clickAll="this.selectedContentType = 'all'"
        @clickSlydeposts="this.selectedContentType = 'slydeposts'"
        @clickPeople="this.selectedContentType = 'people'"
      />
      <div class="flex flex-row items-center">
        <input @keydown.enter="getResults" v-model="keywords" class="w-36 h-8 rounded-none border-2 border-blue" />
        <button @click="getResults" class="w-16 h-8 rounded-none text-sm py-1 px-2">Search</button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row md:gap-2 md:items-center">
      <p class="hidden md:block text-xs">Refine</p>
      <div class="flex flex-col gap-2 md:hidden">
        <RefinedSearchTextHeader :text="'Keyword(s)'" @clickHeader="showKeywordInput = !showKeywordInput" />
        <div v-show="this.showKeywordInput">
          <input
            class="border-gray focus:border-blue focus:border-2"
            type="text"
            v-model="keywords"
            placeholder="Keyword"
          />
        </div>
      </div>
      <hr class="border-chambray my-4" />
      <div class="flex flex-col gap-2 md:relative">
        <RefinedSearchTextHeader
          class="md:hidden"
          :text="'Score Range'"
          @clickHeader="showScoreRangeInput = !showScoreRangeInput"
        />
        <RefinedSearchTextHeader
          class="hidden md:flex"
          :text="'Score'"
          :has-value="this.refinedScore[0] !== 0 || this.refinedScore[1] !== 10"
          @clickHeader="showScoreRangeInput = !showScoreRangeInput"
        />
        <div
          class="flex flex-col md:absolute md:bg-white md:top-8 md:w-64 md:shadow-lg md:p-2"
          v-show="showScoreRangeInput"
        >
          <p>Select a Range</p>
          <RefinedSearchScoreSlider />
        </div>
      </div>
      <hr class="border-chambray my-4" />
      <div class="flex flex-col gap-2 md:relative">
        <RefinedSearchMultiTextHeader
          :text="'Categories'"
          :selected-options="this.refinedCategories"
          @clickHeader="this.clickCategories"
        />
        <div class="md:absolute md:bg-white md:top-8 md:w-64 md:shadow-lg md:p-2" v-show="this.showCategories">
          <RefinedSearchCategoryDropdown :placeholder="'Find categories'" />
        </div>
      </div>
      <hr class="border-chambray my-4" />
      <div class="flex flex-col gap-2 md:relative">
        <RefinedSearchMultiTextHeader
          :text="'Aspects'"
          :selected-options="this.refinedAspects"
          @clickHeader="this.clickAspects"
        />
        <div class="md:absolute md:bg-white md:top-8 md:w-64 md:shadow-lg md:p-2" v-show="this.showAspects">
          <RefinedSearchAspectDropdown :placeholder="'Find aspects'" />
        </div>
      </div>
      <hr class="border-chambray my-4" />
      <div class="flex flex-col gap-2 md:relative">
        <RefinedSearchTextHeader
          :text="'Date Posted'"
          @clickHeader="showDatePosted = !showDatePosted"
          :has-value="refinedDateRange.length > 0"
        />
        <div class="md:absolute md:bg-white md:top-8 md:w-64 md:shadow-lg md:p-2" v-show="showDatePosted">
          <RefinedSearchDate />
        </div>
      </div>
      <hr class="border-chambray my-4" />
      <div class="flex flex-col gap-2 md:relative pb-4 md:pb-0">
        <RefinedSearchTextHeader
          :text="'Location'"
          @clickHeader="showLocation = !showLocation"
          :has-value="this.location != null && this.location.length > 0"
        />
        <div class="md:absolute md:bg-white md:top-8 md:w-64 md:shadow-lg md:p-2" v-show="this.showLocation">
          <input
            class="border-gray focus:border-blue focus:border-2 md:text-sm"
            type="text"
            v-model="location"
            placeholder="Location"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center py-4 md:hidden">
      <p @click="reset" class="text-sm text-gray">Reset All</p>
      <button @click.prevent="this.getResults" class="bg-blue rounded-2xl py-3 px-6 font-bold">Show Results</button>
    </div>
  </div>
</template>

<script>
import RefinedSearchTextHeader from '@/components/Search/RefinedSearch/RefinedSearchTextHeader.vue';
import RefinedSearchScoreSlider from '@/components/Search/RefinedSearch/RefinedSearchScoreSlider.vue';
import RefinedSearchCategoryDropdown from '@/components/Search/RefinedSearch/RefinedSearchCategoryDropdown.vue';
import RefinedSearchDate from '@/components/Search/RefinedSearch/RefinedSearchDate.vue';
import RefinedSearchAspectDropdown from '@/components/Search/RefinedSearch/RefinedSearchAspectDropdown.vue';
import RefinedSearchMultiTextHeader from '@/components/Search/RefinedSearch/RefinedSearchMultiTextHeader.vue';
import ContentTypeSelector from '@/components/Search/ContentTypeSelector.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'RefinedSearchMobile',
  emits: ['showResults'],
  data() {
    return {
      showKeywordInput: true,
      showScoreRangeInput: false,
      showCategories: false,
      showAspects: false,
      showDatePosted: false,
      showLocation: false,
      selectedContentType: 'all',
      options: [
        {
          text: 'all',
          emit: 'clickAll',
        },
        {
          text: 'slydeposts',
          emit: 'clickSlydeposts',
        },
        {
          text: 'people',
          emit: 'clickPeople',
        },
      ],
    };
  },
  components: {
    RefinedSearchTextHeader,
    RefinedSearchScoreSlider,
    RefinedSearchCategoryDropdown,
    RefinedSearchDate,
    RefinedSearchAspectDropdown,
    RefinedSearchMultiTextHeader,
    ContentTypeSelector,
  },
  computed: {
    ...mapGetters({
      refinedCategories: 'refinedSearchStoreModule/getRefinedCategories',
      refinedAspects: 'refinedSearchStoreModule/getRefinedAspects',
      refinedScore: 'refinedSearchStoreModule/getRefinedScoreRange',
      refinedDateRange: 'refinedSearchStoreModule/getRefinedDateRange',
    }),
    isSlydepostLimit: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getIsLimitSlydepostsShown'];
      },
      set(value) {
        this.$store.commit('refinedSearchStoreModule/setIsLimitSlydepostsShown', value);
      },
    },
    isPeopleLimit: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getIsLimitPeopleShown'];
      },
      set(value) {
        this.$store.commit('refinedSearchStoreModule/setIsLimitPeopleShown', value);
      },
    },
    contentType: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getFilterContentType'];
      },
      set(type) {
        this.$store.commit('refinedSearchStoreModule/setFilterContentType', type);
      },
    },
    keywords: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getRefinedKeywords'];
      },
      set(keywords) {
        this.$store.commit('refinedSearchStoreModule/setRefinedKeywords', keywords);
      },
    },
    location: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getRefinedLocation'];
      },
      set(location) {
        this.$store.commit('refinedSearchStoreModule/setRefinedLocation', location);
      },
    },
  },
  methods: {
    clickCategories() {
      this.showCategories = !this.showCategories;
    },
    clickAspects() {
      this.showAspects = !this.showAspects;
    },
    formatCategory(category) {
      if (!category.includes(' > ')) {
        return category;
      }
      let subcategory = category.split(' > ')[1];
      return subcategory;
    },
    reset() {
      this.$store.commit('refinedSearchStoreModule/resetValues');
    },
    getResults() {
      this.$store.dispatch('refinedSearchStoreModule/findRefinedResults');
      let payload = {
        text: this.keywords,
        index: 'user',
      };
      this.$store.dispatch('searchStoreModule/indexSearch', payload);
      this.$emit('showResults');
    },
  },
};
</script>
