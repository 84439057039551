<template>
  <div class="flex justify-center h-screen">
    <div class="relative flex flex-col w-full items-center gap-4 max-w-lg">
      <div class="w-full pt-10 pb-3 grid grid-cols-4 gap-3">
        <div class="col-start-1 col-end-2 flex justify-center">
          <font-awesome-icon
            @click="$router.go(-1)"
            class="cursor-pointer text-md text-dark-blue bg-white p-2 rounded-lg border border-dark-blue"
            icon="fa-solid fa-x"
          />
        </div>
        <div class="col-start-2 col-end-4 flex items-center justify-center">
          <p class="text-[#1E1F20] text-sm uppercase font-bold">My Information</p>
        </div>
      </div>
      <div class="w-full">
        <div class="pt-12 pb-10 flex flex-col justify-center items-center">
          <div class="flex flex-col cursor-pointer" @click="$refs.file.click()">
            <div class="rounded-xl bg-transparent flex items-center justify-center">
              <img :src="profilePictureUrl" class="object-contain rounded-full h-28 w-28" />
            </div>
            <input ref="file" class="hidden" type="file" @change="selectedFile" />
            <div class="h-full flex bg-transparent items-center justify-center flex-2 -m-4">
              <div class="bg-blue w-8 h-8 flex justify-center items-center rounded-lg">
                <font-awesome-icon :color="'white'" :icon="['fas', 'pencil']" class="w-4 h-4" />
              </div>
            </div>
          </div>
          <div v-show="showCropper" class="flex flex-col items-center gap-2">
            <Cropper ref="cropper" :src="profilePictureUrl" :stencil-props="{ aspectRatio: 1 / 1 }" class="w-64" />
            <button @click="doneCropping">Done</button>
          </div>
        </div>
        <div class="px-7">
          <div class="mt-5">
            <p class="font-bold text-xs text-gray mb-2 mx-1">USERNAME</p>
            <input v-model="payload.username" class="px-4" placeholder="Username" type="text" />
          </div>
          <div class="mt-5">
            <p class="font-bold text-xs text-gray mb-2 mx-1">COUNTRY/REGION</p>
            <input v-model="location.region" class="px-4" placeholder="United States of America" type="text" />
          </div>
          <div class="mt-5">
            <p class="font-bold text-xs text-gray mb-2 mx-1">CITY</p>
            <input v-model="location.city" class="px-4" placeholder="New York" type="text" />
          </div>
          <div class="mt-5">
            <p class="font-bold text-xs text-gray mb-2 mx-1">MOBILE PHONE</p>
            <input
              v-model="payload.phone"
              class="px-4"
              placeholder="(414) 753-4679"
              type="text"
              @input="acceptNumber"
            />
          </div>
        </div>
        <div class="mt-5 px-7">
          <div class="flex w-full mb-3 relative">
            <p class="text-xs font-bold text-[#787979]">CATEGORIES</p>
          </div>
          <div>
            <div
              v-for="item in selectedCategories"
              v-show="selectedCategories.length"
              :key="item"
              class="inline-flex bg-white px-2 py-1 mt-3 mr-3 border-2 border-[#F0F0F0]"
            >
              <p class="text-xs font-bold mr-2 text-[#787979]">{{ item }}</p>
              <button class="p-0 bg-transparent border-0 hover:bg-transparent" @click="removeCategory(item)">
                <img alt="" src="@/assets/ic_close_16.png" />
              </button>
            </div>
          </div>
        </div>
        <div class="mt-7 px-7">
          <h1 class="text-center font-bold text-3xl">Featured Categories</h1>
          <p class="text-center text-sm mt-2 font-semibold">
            Start with these popular categories. You can always add more later.
          </p>
          <div class="grid grid-cols-2 gap-4 mt-5 text-sm">
            <div v-for="category in categories" :key="category" class="flex items-center">
              <template v-if="selectedCategories.includes(category)">
                <img
                  alt="..."
                  class="h-[20px] w-[20px] mx-3"
                  src="@/assets/ic_close_16.png"
                  @click="removeCategory(category)"
                />
              </template>
              <template v-else>
                <img
                  alt="..."
                  class="mx-3 h-[20px] w-[20px]"
                  src="@/assets/ic_add.png"
                  @click="addCategory(category)"
                />
              </template>
              <p class="ml-2">{{ category }}</p>
            </div>
          </div>
        </div>
        <div class="mt-5 px-7">
          <div class="relative h-8">
            <CategoryDropdown
              class="absolute w-full"
              :placeholder="'Search all categories'"
              @selected="addCategoryFromDropdown"
            />
          </div>
        </div>
        <div class="mt-3 px-7">
          <p v-if="errorMessage" class="text-red-500">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="flex flex-column h-screen w-full">
        <div class="mt-5 px-7 rounded-tr-3xl rounded-tl-3xl pt-5 w-full pb-10 myLoaderBtn">
          <LoaderButton
            :icon-left-side="true"
            :show-error-icon="false"
            :status="getUserProfileButtonStatus"
            :text="getPostStatusButtonText"
            waiting-icon="check"
            @click="completeProfile"
          />
          <div class="w-full overflow-hidden"></div>
        </div>
      </div>
      <div v-show="selectCategories" class="transition-opacity w-full z-50 bg-[#F3FBFF] absolute min-h-screen p-7">
        <div>
          <button class="p-0 border-0 bg-transparent" @click="selectCategories = false">
            <img alt="" src="@/assets/L.Icon.png" />
          </button>
        </div>
        <div class="mt-10">
          <p class="text-3xl font-bold text-black">Categories</p>
          <p class="text-sm font-medium text-[#787979] mt-1">Select the categories you like</p>
        </div>
        <div class="relative w-full mt-7">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <img alt="..." class="h-[20px] w-[20px]" src="@/assets/ic_search_normal.png" />
          </div>
          <input
            v-model="search"
            class="w-full ps-10 pr-3 text-sm border-2 border-[#F0F0F0]"
            placeholder="Search..."
            type="search"
          />
        </div>
        <div>
          <div v-if="categoryErrorMessage" class="mt-3 px-5">
            <p class="text-red-500">
              {{ categoryErrorMessage }}
            </p>
          </div>
          <div v-for="item in categoriesToList" :key="item" class="flex my-10">
            <div class="mt-1">
              <template v-if="selectedCategories.includes(item)">
                <img
                  alt="..."
                  class="h-[20px] w-[20px] mx-3"
                  src="@/assets/ic_close_16.png"
                  @click="removeCategory(item)"
                />
              </template>
              <template v-else>
                <img alt="..." class="mx-3 h-[20px] w-[20px]" src="@/assets/ic_add.png" @click="addCategory(item)" />
              </template>
            </div>
            <p class="test-base font-medium">{{ item }}</p>
          </div>
        </div>
      </div>
      <div
        v-if="showDialogue"
        class="fixed top-0 bottom-0 left-0 right-0 flex justify-center z-50 bg-modal"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
      >
        <dialog
          class="top-[35%] h-[200px] w-[200px] flex flex-col justify-center items-center rounded-3xl bg-white"
          open
        >
          <img alt="" class="h-[58px] w-[58px]" src="@/assets/ic_completed.png" />
          <p class="text-sm font-medium text-center mt-5">Saved Your Profile!</p>
        </dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import { uuidv4 } from '@/helpers/uuid';
import { LoaderButtonStates } from '@/helpers/loaderButtonStates';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import CategoryDropdown from '@/components/CreateSlydepost/CategoryDropdown.vue';

export default {
  name: 'CompleteProfile',
  components: {
    FontAwesomeIcon,
    LoaderButton,
    Cropper,
    CategoryDropdown,
  },
  data() {
    return {
      showDialogue: false,
      buttonState: LoaderButtonStates.initial,
      errorMessage: '',
      categoryErrorMessage: '',
      profilePictureUrl: 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png',
      profilePictureFile: null,
      showCropper: false,
      search: '',
      selectCategories: false,
      selectedCategories: [],
      categories: [
        'Active Life',
        'Baby Gear & Furniture',
        'Beauty & Spas',
        'Books & Print Media',
        'Clothing, Shoes & Accessories',
        'Contractors & Local Services',
        'Drinks & Beverages',
        'Electronics',
        'Event Planning & Services',
        'Food',
        'Hotels & Lodging',
        'Movies, Television (TV) & Video Media',
        'Music, Radio & Audio Media',
        'Nature, Parks, Trails & Hiking',
        'Nightlife & Bars',
        'Performing Arts & Entertainment',
        'Pets & Pet Supplies',
        'Professional Services',
        'Restaurants',
        'Sporting Goods',
        'Stores & Shopping',
        'Travel, Activities & Attractions',
      ],
      location: {
        region: '',
        city: '',
      },
      payload: {
        phone: '',
        location: '',
        username: '',
      },
    };
  },
  computed: {
    categoriesToList() {
      return this.search.length
        ? this.categories.filter((e) => e.toLowerCase().includes(this.search.toLowerCase()))
        : this.categories;
    },
    getUserProfileButtonStatus() {
      if (this.$store.getters['getUserProfileSaveStatus'] === 'save') {
        return 'waiting';
      }
      return this.$store.getters['getUserProfileSaveStatus'];
    },
    getPostStatusButtonText() {
      if (this.getUserProfileButtonStatus === LoaderButtonStates.initial) {
        return 'Complete Profile';
      } else if (this.getUserProfileButtonStatus === LoaderButtonStates.loading) {
        return 'Loading...';
      } else if (this.getUserProfileButtonStatus === LoaderButtonStates.done) {
        return 'Profile Completed';
      } else if (this.getUserProfileButtonStatus === LoaderButtonStates.error) {
        return 'Try again?';
      } else {
        return 'Complete Profile';
      }
    },
  },
  methods: {
    activeSelectCategories() {
      this.categoryErrorMessage = '';
      this.selectCategories = true;
    },
    removeCategory(item) {
      this.categoryErrorMessage = '';
      this.selectedCategories = this.selectedCategories.filter((e) => e !== item);
    },
    addCategory(item) {
      this.categoryErrorMessage = '';
      if (!this.selectedCategories.includes(item)) {
        if (this.selectedCategories.length > 9) {
          this.categoryErrorMessage = 'You can select up to 10 categories';
          return;
        }
        this.selectedCategories.push(item);
      }
    },
    addCategoryFromDropdown(item) {
      this.categoryErrorMessage = '';
      if (this.selectedCategories.length > 9) {
        this.categoryErrorMessage = 'You can select up to 10 categories';
        return;
      }
      this.selectedCategories.push(item.name);
    },
    //image selection part
    selectedFile(event) {
      let file = event.target.files[0];
      if (file != null) {
        this.profilePictureUrl = URL.createObjectURL(file);
        this.profilePictureFile = file;
        this.showCropper = true;
      }
    },
    async doneCropping() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.showCropper = false;
      this.profilePictureUrl = canvas.toDataURL();
      await canvas.toBlob(async (blob) => {
        let uuid = await uuidv4();
        this.profilePictureFile = new File([blob], uuid);
      });
    },

    //phone number section
    acceptNumber() {
      if (this.payload.phone) {
        let x = this.payload.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.payload.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      }
    },
    //handle submit
    async completeProfile() {
      this.errorMessage = '';
      // if (!this.location.region) {
      //   this.errorMessage = 'Please enter your region'
      //   return
      // }
      // if (!this.location.city) {
      //   this.errorMessage = 'Please enter your city'
      //   return
      // }
      // if (!this.payload.phone) {
      //   this.errorMessage = 'Please enter your phone number'
      //   return
      // }

      const payload = {
        file: this.profilePictureFile || undefined,
        location: this.location.city + ', ' + this.location.region,
        phone: this.payload.phone,
        categories: this.selectedCategories,
        username: this.payload.username,
      };
      this.$store.dispatch('updateUserProfile', payload);
      this.showDialogue = true;
      setTimeout(() => {
        this.$router.push('/newsfeed');
      }, 1000);
    },
  },
};
</script>
<style lang="css" scoped>
.myLoaderBtn >>> div button {
  font-weight: 700;
}
</style>
