<template>
  <div class="grid grid-cols-1 gap-4">
    <SlydepostForExplore
      v-for="slydepost in slydeposts"
      :key="slydepost.id"
      :id="slydepost.id"
      :title="slydepost.title"
      :description="slydepost.description"
      :aspects="slydepost.tags"
      :user-id="slydepost.userId"
      :created-at="slydepost.createdAt"
      :user-first-name="slydepost.userFirstName"
      :user-last-name="slydepost.userLastName"
      :category="slydepost.category"
      :subcategory="slydepost.subcategory"
      :comments="slydepost.comments"
      :images="slydepost.presignedMediaUrls"
      :address="slydepost.address"
      :link="slydepost.link"
      :meta-rating="slydepost.metaRating"
      :display-comments="true"
      :media="slydepost.media"
      :likes="slydepost.likes"
      :profile-picture-url="slydepost.userProfilePhotoUrl"
    />
    <InfiniteLoading @infinite="getMoreSlydeposts" />
    <div class="w-full bg-transparent py-5">
      <Loading v-show="isLoading" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SlydepostForExplore from '@/components/ViewSlydepost/Wrappers/SlydepostForExplore.vue';
import InfiniteLoading from 'v3-infinite-loading';
import Loading from '@/components/Utility/Loading.vue';

export default {
  name: 'TrendingSlydeposts',
  components: {
    SlydepostForExplore,
    InfiniteLoading,
    Loading,
  },
  computed: {
    ...mapGetters({
      slydeposts: 'trendingPostStoreModule/getViewablePosts',
      isLoading: 'trendingPostStoreModule/getIsLoading',
    }),
  },
  methods: {
    getMoreSlydeposts() {
      this.$store.commit('trendingPostStoreModule/addPostsToViewablePosts');
    },
  },
  mounted() {
    this.$store.dispatch('trendingPostStoreModule/loadLast7Days');
  },
};
</script>
