<template>
  <div class="w-full flex flex-col gap-2 px-4 py-4">
    <div class="flex items-center gap-4">
      <img class="hidden md:inline w-12 h-12 rounded-2xl" :src="profilePictureUrl" />
      <div class="flex flex-row justify-between items-center w-full">
        <p class="text-lg text-blue">Rate your experience</p>
        <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
      </div>
    </div>
    <div class="flex flex-col">
      <hr class="solid mb-3 md:mb-6" />
      <textarea
        v-model="this.description"
        class="h-auto md:h-36 max-h-40 min-h-20 mb-3 md:mb-6"
        placeholder="Tell us what you think (Optional)"
      ></textarea>
      <div class="text-blue font-base text-start uppercase" v-for="aspect in this.aspects" :key="aspect">
        <RatingSlider
          class="pb-3 md:pb-6"
          v-if="aspect.selected === true"
          :name="aspect.name"
          :rating="aspect.rating"
          :description="aspect.description"
          ref="aspectRatings"
        />
      </div>
      <div class="flex pb-2 justify-between items-center">
        <div>
          <font-awesome-icon
            @click="previousModal"
            :color="'#015D79'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-left']"
          />
        </div>
        <div class="flex flex-row gap-2 items-center">
          <LoaderButton
            :icon-left-side="false"
            :show-error-icon="false"
            :status="createDraftStatus"
            :waiting-text="''"
            :in-progress-text="'Saving'"
            :done-text="'Saved!'"
            :error-text="'Try Again?'"
            :waiting-icon="'save'"
            @click="createDraft"
          />
          <font-awesome-icon
            @click="nextModal"
            :color="'#30C3E3'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-right']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RatingSlider from './RatingSlider.vue';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';

export default {
  name: 'CreateSlydepostRatings',
  emits: ['back', 'next'],
  data() {
    return {
      // selectedAspects: [],
      ratedAspects: [],
      files: [],
      max: 5,
      localFileUrls: [],
    };
  },
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  components: { RatingSlider, LoaderButton },
  computed: {
    createDraftStatus: {
      get() {
        return this.$store.getters['createPostStoreModule/getCreateDraftStatus'];
      },
      set(status) {
        this.$store.commit('createPostStoreModule/setCreateDraftStatus', status);
      },
    },
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    aspects: {
      get() {
        return this.$store.getters['createPostStoreModule/getAspects'];
      },
      set() {
        this.$store.commit('createPostStoreModule/setAspects');
      },
    },
    description: {
      get() {
        return this.$store.getters['createPostStoreModule/getDescription'];
      },
      set(description) {
        this.$store.commit('createPostStoreModule/setDescription', description);
      },
    },
  },
  methods: {
    getCurrentSlydepostData() {
      return {
        title: this.$store.getters['createPostStoreModule/getTitle'],
        address: this.$store.getters['createPostStoreModule/getAddress'],
        link: this.$store.getters['createPostStoreModule/getLink'],
        category: this.$store.getters['createPostStoreModule/getCategory'],
        subcategory: this.$store.getters['createPostStoreModule/getSubcategory'],
        files: this.$store.getters['createPostStoreModule/getFiles'],
        aspects: this.$store.getters['createPostStoreModule/getSelectedAspects'],
        description: this.$store.getters['createPostStoreModule/getDescription'],
        sharedWith: this.$store.getters['createPostStoreModule/getSharedWith'],
        metaRating: this.$store.getters['createPostStoreModule/getMetaRating'],
        userId: this.$store.getters['authStoreModule/getUserId'],
        userFirstName: this.$store.getters['authStoreModule/getFirstName'],
        userLastName: this.$store.getters['authStoreModule/getLastName'],
        suggestedAspects: this.$store.getters['createPostStoreModule/getSuggestedAspects'],
        categoryDropdownSelectedOptionIndex:
          this.$store.getters['createPostStoreModule/getCategoryDropdownSelectedOptionIndex'],
        images: this.$store.getters['createPostStoreModule/getExistingS3Images'],
        username: this.$store.getters['authStoreModule/getUser'].username,
        userProfilePictureUrl: this.$store.getters['authStoreModule/getProfilePhoto'],
      };
    },
    async createDraft() {
      if (this.createDraftStatus === 'inProgress') {
        return;
      }
      this.createDraftStatus = 'inProgress';
      // save the current aspects to the store
      await this.$refs.aspectRatings.forEach((aspect) => {
        aspect.saveAspectData();
      });

      // check if draft should be created or updated
      let draftId = this.$store.getters['createPostStoreModule/getDraftId'];
      // create draft if there is no draft id
      if (draftId == null || draftId === '') {
        let payload = this.getCurrentSlydepostData();
        let newDraft = await this.slydepostDraftStore.addDraft(payload);
        if (newDraft !== undefined) {
          this.slydepostDraftStore.slydepostDrafts.unshift(newDraft);
        }
        this.createDraftStatus = 'done';
        // push drafts to router
        this.$emit('draft');
      }
      // update draft
      else {
        const draftData = this.getCurrentSlydepostData();
        draftData.id = this.$store.getters['createPostStoreModule/getDraftId'];
        const draftStore = useSlydepostDraftStore();
        await draftStore.updateDraft(draftData);
        this.$emit('draft');
      }
    },
    previousModal() {
      // store the new rated aspects
      this.$refs.aspectRatings.forEach((aspect) => {
        aspect.saveAspectData();
      });
      this.$emit('back');
    },
    nextModal() {
      // store the new rated aspects
      this.$refs.aspectRatings.forEach((aspect) => {
        aspect.saveAspectData();
      });
      this.$emit('next');
    },
  },
};
</script>
