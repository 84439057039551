<template>
  <div class="container">
    <h2>Reset Your Password</h2>
    <p>Please enter your verification code along with your new password</p>
    <form @submit.prevent="forgotPasswordSubmit">
      <div class="forgot-password-input-container">
        <input type="text" v-model="code" placeholder="Verification Code" id="input-code" />
        <input type="password" v-model="newPassword" placeholder="New Password" id="input-password" />
      </div>
      <div id="forgot-password-button-container">
        <button>Reset Password</button>
      </div>
    </form>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import router from '@/router';
import store from '@/store/store';

export default {
  name: 'forgotPasswordSubmit',
  data() {
    return {
      newPassword: '',
      code: '',
    };
  },
  methods: {
    async forgotPasswordSubmit() {
      try {
        if (store.state.userEmail == null || store.state.userEmail === '') {
          return;
        }
        await Auth.forgotPasswordSubmit(store.state.userEmail, this.code, this.newPassword);
        router.push('login');
      } catch (error) {
        console.error('Error resetting forgotten password', error);
      }
    },
  },
};
</script>

<style scoped></style>
