<template>
  <div class="bg-white md:border md:border-solid md:border-light-light-gray md:rounded-t-3xl">
    <div class="py-2 md:py-0 rounded-t-3xl flex flex-row md:flex-col items-center gap-2 md:gap-0">
      <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
        <img
          class="rounded-xl md:rounded-b-none md:rounded-t-3xl object-cover object-center h-12 md:h-[214px] w-12 md:w-full"
          :src="profilePictureUrl"
        />
      </router-link>
      <div class="flex flex-row justify-between md:pt-3 w-full items-start px-2 pb-2">
        <div class="flex flex-col gap-1 items-start">
          <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
            <p class="text-sm capitalize">{{ this.firstName }} {{ this.lastName }}</p>
          </router-link>
          <div class="flex flex-row gap-2 md:flex-col md:gap-1">
            <p class="text-xs font-bold">{{ numberOfSlydeposts }} Slydeposts</p>
            <p class="text-xs font-bold">{{ numberOfFollowers }} Followers</p>
            <p class="text-xs font-bold">{{ numberOfLists }} Lists</p>
          </div>
        </div>
        <div class="flex md:flex-col flex-row gap-4 md:gap-0">
          <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
            <div class="text-sm text-right text-moonstone font-bold">
              <p>View</p>
              <p>Profile</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrendingUser',
  props: {
    userId: String,
    profilePictureUrl: {
      type: String,
      default: () => require('@/assets/avatar.png'),
    },
    firstName: String,
    lastName: String,
    username: String,
    numberOfSlydeposts: Number,
    numberOfLists: Number,
    numberOfFollowers: Number,
  },
};
</script>
