import { uuid } from 'vue-uuid';
import { event } from 'vue-gtag';
import client from '@/axios/client';

const createPostStoreModule = {
  namespaced: true,
  state: {
    title: '',
    address: '',
    link: '',
    category: '',
    subcategory: '',
    categoryDropdownSearchFilter: '',
    categoryDropdownSelectedOptionIndex: -1,
    draftId: '',
    files: [],
    aspects: [],
    existingS3Images: [],
    suggestedAspects: [],
    description: '',
    sharedWith: '',
    createSlydepostStatus: 'waiting', // waiting, inProgress, done, error
    createDraftStatus: 'waiting', // waiting, inProgress, done, error
  },
  getters: {
    getCreateDraftStatus(state) {
      return state.createDraftStatus;
    },
    getTitle(state) {
      return state.title;
    },
    getExistingS3Images(state) {
      return state.existingS3Images;
    },
    getAddress(state) {
      return state.address;
    },
    getLink(state) {
      return state.link;
    },
    getCategory(state) {
      return state.category;
    },
    getSubcategory(state) {
      return state.subcategory;
    },
    getCategoryDropdownSearchFilter(state) {
      return state.categoryDropdownSearchFilter;
    },
    getCategoryDropdownSelectedOptionIndex(state) {
      return state.categoryDropdownSelectedOptionIndex;
    },
    getFiles(state) {
      return state.files;
    },
    getAspects(state) {
      return state.aspects;
    },
    getSuggestedAspects(state) {
      return state.suggestedAspects;
    },
    getTotalAspectsSelected(state) {
      let selectedAspectLength = 0;
      for (let i = 0; i < state.aspects.length; i++) {
        if (state.aspects[i].selected === true) {
          selectedAspectLength += 1;
        }
      }
      return selectedAspectLength;
    },
    getSelectedAspects(state) {
      let selectedAspects = [];
      for (let i = 0; i < state.aspects.length; i++) {
        if (state.aspects[i].selected === true) {
          selectedAspects.push(state.aspects[i]);
        }
      }
      return selectedAspects;
    },
    getDescription(state) {
      return state.description;
    },
    getMetaRating(state) {
      let totalAspectRating = 0;
      let numberOfAspects = 0;
      state.aspects.forEach((aspect) => {
        if (aspect.selected === true) {
          totalAspectRating += parseInt(aspect.rating);
          numberOfAspects += 1;
        }
      });
      const finalRating = Math.round((totalAspectRating / numberOfAspects) * 10) / 10;
      return finalRating;
    },
    getImages(state) {
      let images = [];
      if (state.files.length > 0) {
        for (let i = 0; i < state.files.length; i++) {
          images.push({
            file: URL.createObjectURL(state.files[i].file),
            order: i,
            type: state.files[i].type,
          });
        }
      } else if (state.existingS3Images.length > 0) {
        images = state.existingS3Images;
      }
      return images;
    },
    getCreateSlydepostStatus(state) {
      return state.createSlydepostStatus;
    },
    getSharedWith(state) {
      return state.sharedWith;
    },
    getDraftId(state) {
      return state.draftId;
    },
  },
  mutations: {
    setCreateDraftStatus(state, status) {
      state.createDraftStatus = status;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setLink(state, link) {
      state.link = link;
    },
    setCategory(state, category) {
      state.category = category;
    },
    setSubcategory(state, subcategory) {
      state.subcategory = subcategory;
    },
    setExistingS3Images(state, images) {
      state.existingS3Images = images;
    },
    setCategoryDropdownSearchFilter(state, filter) {
      state.categoryDropdownSearchFilter = filter;
    },
    setCategoryDropdownSelectedOptionIndex(state, index) {
      state.categoryDropdownSelectedOptionIndex = index;
    },
    setFiles(state, files) {
      state.files = files;
    },
    setAspects(state, aspects) {
      state.aspects = aspects;
    },
    setSuggestedAspects(state, aspects) {
      state.suggestedAspects = aspects;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setCreateSlydepostStatus(state, status) {
      state.createSlydepostStatus = status;
    },
    setSharedWith(state, shared) {
      state.sharedWith = shared;
    },
    setDraftId(state, id) {
      state.draftId = id;
    },
    setDraft(state, draft) {
      state.draftId = draft.id;
      state.title = draft.title;
      state.address = draft.address;
      state.link = draft.link;
      state.category = draft.category;
      state.subcategory = draft.subcategory;
      state.categoryDropdownSearchFilter = draft.subcategory || draft.category;
      state.categoryDropdownSelectedOptionIndex = draft.categoryDropdownSelectedOptionIndex;
      state.existingS3Images = draft.media || [];
      state.aspects = draft.aspects.filter((aspect) => aspect.selected === true);
      state.suggestedAspects = draft.suggestedAspects || [];
      state.description = draft.description;
      state.sharedWith = draft.sharedWith;
    },
    clear(state) {
      state.title = '';
      state.address = '';
      state.link = '';
      state.category = '';
      state.subcategory = '';
      state.categoryDropdownSearchFilter = '';
      state.categoryDropdownSelectedOptionIndex = -1;
      state.files = [];
      state.aspects = [];
      state.suggestedAspects = [];
      state.description = '';
      state.sharedWith = '';
      state.existingS3Images = [];
      state.createSlydepostStatus = 'waiting';
      state.createDraftStatus = 'waiting';
      state.draftId = '';
    },
  },
  actions: {
    async createSlydepost({ dispatch, commit, getters, rootGetters }, payload) {
      if (getters.getCreateSlydepostStatus === 'inProgress') {
        return;
      }
      commit('setCreateSlydepostStatus', 'inProgress');

      const media = [];
      for (let i = 0; i < payload.files.length; i++) {
        const photoUrl = URL.createObjectURL(payload.files[i].file);
        const image = new Image();

        const imageDimensions = await new Promise((resolve) => {
          image.onload = () => {
            const dimensions = {
              height: image.height,
              width: image.width,
            };
            resolve(dimensions);
          };
          image.src = photoUrl;
        });
        const readyToUploadMedia = {
          name: payload.files[i].file.name,
          order: payload.files[i].order,
          width: imageDimensions.width,
        };
        media.push(readyToUploadMedia);
      }

      const category = payload.category;
      const title = payload.title;
      const link = payload.link;
      const address = payload.address;
      const description = payload.description;
      const files = payload.files;
      const subcategory = payload.subcategory;
      const aspects = payload.aspects;
      const userId = payload.userId;
      const metaRating = payload.metaRating;
      const sharedWith = payload.sharedWith;
      const userFirstName = payload.userFirstName;
      const userLastName = payload.userLastName;
      const lists = payload.lists;
      let jsonBody = '';
      if (payload.draftMedia) {
        const draftMedia = [];
        for (let i = 0; i < payload.draftMedia.length; i++) {
          draftMedia.push({
            url: payload.draftMedia[i].url,
            order: payload.draftMedia[i].order.toString(),
          });
        }
        const isFromDraft = true;
        const draftId = payload.draftId;
        jsonBody = JSON.stringify({
          category,
          subcategory,
          title,
          link,
          address,
          description,
          aspects,
          userId,
          metaRating,
          sharedWith,
          userFirstName,
          userLastName,
          lists,
          draftMedia,
          isFromDraft,
          draftId,
        });
      } else {
        jsonBody = JSON.stringify({
          media,
          category,
          subcategory,
          title,
          link,
          address,
          description,
          aspects,
          userId,
          metaRating,
          sharedWith,
          userFirstName,
          userLastName,
          lists,
        });
      }
      let fileUrls = [];
      for (let i = 0; i < files.length; i++) {
        fileUrls.push({
          presignedUrl: URL.createObjectURL(files[i].file),
          order: i,
          type: getFileType(files[i].file),
        });
      }
      if (fileUrls != null && fileUrls.length > 0) {
        payload.media = fileUrls;
      }
      payload.tags = payload.aspects;
      const loggedInUserId = payload.userId;
      return await client
        .post(`/post`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then(async (response) => {
          for (const file of payload.files) {
            for (const url of response.data.presignedUrlsForUpload) {
              if (url.includes(file.file.name)) {
                await client
                  .put(url, file.file, {
                    headers: {
                      'Content-Type': 'image/jpeg',
                    },
                  })
                  .then((response) => {})
                  .catch((response) => {
                    console.error(`could not upload`, response);
                  });
              }
            }
          }
          if (response.data.lists != null) {
            for (let i = 0; i < response.data.lists.length; i++) {
              const addListPostToListPayload = {
                id: response.data.lists[i].id,
                listId: response.data.lists[i].listId,
                postId: response.data.lists[i].postId,
                // listUserId,
              };
              commit('listsStoreModule/addPostIds', addListPostToListPayload, {
                root: true,
              });
            }
          }

          commit('setCreateSlydepostStatus', 'done');
          event('slydepost-created', {
            date: new Date().toUTCString(),
          });
          commit('exploreStoreModule/addSlydepostToBeginning', response.data, {
            root: true,
          });
          commit('addNewSlydepost', payload, { root: true });
          return response.data;
        })
        .catch((res) => {
          console.error('there was an error creating the slydepost', res);
          commit('setCreateSlydepostStatus', 'error');
          return undefined;
        });
    },
  },
};

function getFileType(file) {
  if (file.type.match('image.*')) return 'IMAGE';
  if (file.type.match('video.*')) return 'VIDEO';
}

export { createPostStoreModule };
