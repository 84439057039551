import client from '@/axios/client';

const trendingUserStoreModule = {
  namespaced: true,
  state: {
    users: [],
    viewableUsers: [],
    isLoading: false,
    batchAddNumber: 12,
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getViewableUsers(state) {
      return state.viewableUsers;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    addUser(state, user) {
      state.users.push(user);
    },
    setIsLoading(state, loading) {
      state.isLoading = loading;
    },
    clear(state) {
      state.users = [];
      state.viewableUsers = [];
      state.isLoading = false;
    },
    clearUsers(state) {
      state.users = [];
      state.viewableUsers = [];
    },
    addUsersToViewableUsers(state) {
      let usersLength = state.viewableUsers.length;
      let endLength = state.viewableUsers.length + state.batchAddNumber;
      let newUsers = state.users.slice(usersLength, endLength);
      for (let i = 0; i < newUsers.length; i++) {
        state.viewableUsers.push(newUsers[i]);
      }
    },
  },
  actions: {
    loadLast7Days({ commit, getters, rootGetters }) {
      commit('clearUsers');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-users-last-7-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addUser', response.data.results[i]);
          }
          commit('addUsersToViewableUsers');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast30Days({ commit, getters, rootGetters }) {
      commit('clearUsers');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-users-last-30-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addUser', response.data.results[i]);
          }
          commit('addUsersToViewableUsers');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast365Days({ commit, getters, rootGetters }) {
      commit('clearUsers');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-users-last-365-days.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addUser', response.data.results[i]);
          }
          commit('addUsersToViewableUsers');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadLast24Hours({ commit, getters, rootGetters }) {
      commit('clearUsers');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-users-last-24-hours.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addUser', response.data.results[i]);
          }
          commit('addUsersToViewableUsers');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
    loadAllTime({ commit, getters, rootGetters }) {
      commit('clearUsers');
      if (getters['getIsLoading'] === true) {
        return;
      }
      commit('setIsLoading', true);
      client
        .get(`https://d2dvj11rfqjgjx.cloudfront.net/top-users-all-time.json`)
        .then((response) => {
          for (let i = 0; i < response.data.results.length; i++) {
            commit('addUser', response.data.results[i]);
          }
          commit('addUsersToViewableUsers');
          commit('setIsLoading', false);
        })
        .catch((error) => {
          console.error(error);
          commit('setIsLoading', false);
        });
    },
  },
};

// export the entire module
export { trendingUserStoreModule };
