<template>
  <div class="lg:hidden flex flex-col w-screen bg-dark-blue px-4 py-4">
    <p class="text-xl text-white pb-2">Trending Slydeposts</p>
    <div id="trending-posts" class="z-20 overflow-scroll">
      <div class="flex flex-row gap-4 min-w-0">
        <div v-for="post in posts" :key="post.postId">
          <router-link :to="{ name: 'ViewSlydepost', params: { postId: post.postId } }">
            <div class="flex flex-col">
              <div class="m-auto w-20 h-20 relative">
                <img
                  class="w-full h-full rounded-xl bg-dark-blue bg-center object-cover object-center"
                  :src="getMediaUrl(post.media)"
                />
                <div class="absolute text-white inset-0 flex items-center justify-center rounded-xl">
                  <MetaRatingCircle :meta-rating="post.metaRating" />
                </div>
              </div>
              <div class="text-start text-[12px] font-[400] text-white pb-1">
                <p class="leading-[15px] mt-2 line-clamp-2 w-20">
                  {{ post.title }}
                </p>
                <div class="line-clamp-1 text-moonstone">
                  <p v-if="post.username != null">@{{ post.username }}</p>
                  <p v-else>{{ post.userFirstName }} {{ post.userLastName }}</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <p @click="goToTrendingSlydeposts" class="block text-md font-bold text-white pt-2">View Trending Posts</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import slydepostLogo from '@/assets/no-photo-background-square.png';
import MetaRatingCircle from '@/components/ViewSlydepost/MetaRatingCircle.vue';

export default {
  name: 'TrendingSlydeposts',
  computed: {
    ...mapGetters({
      posts: 'trendingPostStoreModule/getViewablePosts',
    }),
  },
  components: {
    MetaRatingCircle,
  },
  methods: {
    getMediaUrl(media) {
      if (media != null && media.length > 0) {
        let mediaObject = media.filter((object) => {
          if (object.order === '0') {
            return object;
          }
        });
        if (mediaObject != null && mediaObject.length > 0) {
          return mediaObject[0].presignedUrl;
        } else {
          return media[0].presignedUrl;
        }
      } else {
        return slydepostLogo;
      }
    },
    goToTrendingSlydeposts() {
      this.$store.commit('trendingStoreModule/setCurrentView', 'slydeposts');
      this.$router.push({ name: 'Trending' });
    },
  },
};
</script>

<style scoped>
#trending-posts::-webkit-scrollbar {
  display: none;
}

#trending-posts {
  scrollbar-width: none;
}
</style>
