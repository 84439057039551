<template>
  <div>
    <Slydepost
      :id="id"
      :title="title"
      :description="description"
      :adjectives="aspects"
      :userId="userId"
      :createdAt="createdAt"
      :userFirstName="userFirstName"
      :userLastName="userLastName"
      :category="category"
      :subcategory="subcategory"
      :comments="comments"
      :images="images"
      :address="address"
      :link="link"
      :metaRating="metaRating"
      :displayComments="displayComments"
      :media="media"
      :likes="likes"
      :profile-picture-url="profilePictureUrl"
      @deleteComment="deleteComment"
      @createComment="createComment"
      @likeComment="likeComment"
      @deleteCommentLike="deleteCommentLike"
      @replyToComment="replyToComment"
      @deleteReply="deleteReply"
      @replyToReply="replyToReply"
      @likeReply="likeReply"
      @deleteLikeReply="deleteLikeReply"
      @likeSlydepost="likeSlydepost"
      @deleteLikeSlydepost="deleteLikeSlydepost"
      @deleteSlydepost="deleteSlydepost"
    />
  </div>
</template>

<script>
import Slydepost from '@/components/ViewSlydepost/Slydepost.vue';

export default {
  name: 'SlydepostForExplore',
  components: {
    Slydepost,
  },
  props: {
    id: String,
    title: String,
    description: String,
    aspects: Array,
    userId: String,
    createdAt: String,
    userFirstName: String,
    userLastName: String,
    category: String,
    subcategory: String,
    comments: Array,
    images: Array,
    address: String,
    link: String,
    metaRating: Number,
    displayComments: Boolean,
    media: Array,
    likes: Array,
    profilePictureUrl: String,
  },
  methods: {
    deleteSlydepost() {
      this.$store.commit('exploreStoreModule/deleteSlydepost', this.id);
      this.$store.commit('deleteSlydepost', this.id);
    },
    deleteLikeSlydepost(payload) {
      this.$store.commit('exploreStoreModule/removeLikeFromSlydepost', payload);
    },
    likeSlydepost(payload) {
      this.$store.commit('exploreStoreModule/likeSlydepost', payload);
    },
    deleteLikeReply(payload) {
      this.$store.commit('exploreStoreModule/removeLikeReply', payload);
    },
    likeReply(payload) {
      this.$store.commit('exploreStoreModule/likeReply', payload);
    },
    replyToReply(payload) {
      this.$store.commit('exploreStoreModule/replyToComment', payload);
    },
    deleteReply(payload) {
      this.$store.commit('exploreStoreModule/removeReplyFromComment', payload);
    },
    replyToComment(payload) {
      this.$store.commit('exploreStoreModule/replyToComment', payload);
    },
    deleteCommentLike(payload) {
      this.$store.commit('exploreStoreModule/removeLikeComment', payload);
    },
    likeComment(payload) {
      this.$store.commit('exploreStoreModule/likeComment', payload);
    },
    deleteComment(payload) {
      this.$store.commit('exploreStoreModule/removeComment', payload);
    },
    createComment(payload) {
      this.$store.commit('exploreStoreModule/addComment', payload);
    },
  },
};
</script>
