import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const currentUserStoreModule = {
  namespaced: true,
  state: {
    isLoadingMorePosts: false,
    endOfPosts: false,
    posts: [],
    totalPosts: 0,
  },
  getters: {
    getPosts(state) {
      return state.posts.map((slydepost) => {
        // Check if there are comments to sort
        if (slydepost.comments) {
          let commentsCopy = [...slydepost.comments];

          // Filter and sort parent comments
          commentsCopy = commentsCopy
            .filter((comment) => !comment.parentId)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

          // Sort child comments for each parent comment
          commentsCopy.forEach((parentComment) => {
            if (parentComment.childComments) {
              parentComment.childComments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            }
          });

          // Return a new object to ensure the state is not mutated
          return {
            ...slydepost,
            comments: commentsCopy,
          };
        }

        // Return the post as is if there are no comments to sort
        return slydepost;
      });
    },
    getIsLoadingMorePosts(state) {
      return state.isLoadingMorePosts;
    },
    getTotalPosts(state) {
      return state.totalPosts;
    },
    getEndOfPosts(state) {
      return state.endOfPosts;
    },
  },
  mutations: {
    setTotalPosts(state, total) {
      state.totalPosts = total;
    },
    addToPosts(state, payload) {
      state.posts.push(payload);
    },
    setEndOfPosts(state, end) {
      state.endOfPosts = end;
    },
    setIsLoadingMorePosts(state, loading) {
      state.isLoadingMorePosts = loading;
    },
    removeLikeFromSlydepost(state, payload) {
      const postId = payload.postId;
      const likeId = payload.id;
      const slydepost = state.posts.find((item) => item.id === postId);
      const objWithIdIndex = slydepost.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        slydepost.likes.splice(objWithIdIndex, 1);
      }
    },
    likeSlydepost(state, payload) {
      const postId = payload.postId;
      const slydepost = state.posts.find((item) => item.id === postId);
      if (slydepost.likes == null) {
        slydepost.likes = [];
      }
      slydepost.likes.push(payload);
    },
    removeLikeReply(state, payload) {
      const likeId = payload.id;
      const parentId = payload.parentId;
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.posts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find((item) => item.id === commentId);
      const objWithIdIndex = childComment.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        childComment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeReply(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;
      const likeId = payload.id;
      const userId = payload.userId;
      const likePayload = {
        id: likeId,
        commentId: commentId,
        userId: userId,
      };
      const slydepost = state.posts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find((item) => item.id === commentId);
      if (childComment.likes == null) {
        childComment.likes = [];
      }
      childComment.likes.push(likePayload);
    },
    removeReplyFromComment(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;

      const slydepost = state.posts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const objWithIdIndex = comment.childComments.findIndex((obj) => obj.id === commentId);
      if (objWithIdIndex > -1) {
        comment.childComments.splice(objWithIdIndex, 1);
      }
    },
    replyToComment(state, payload) {
      const postId = payload.postId;
      const parentCommentId = payload.parentId;

      const slydepost = state.posts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === parentCommentId);
      if (comment.childComments == null) {
        comment.childComments = [];
      }
      comment.childComments.push(payload);
    },
    removeLikeComment(state, payload) {
      const likeId = payload.likeId;
      const commentId = payload.commentId;
      const postId = payload.postId;

      const slydepost = state.posts.find((item) => item.id === postId);
      const comment = slydepost.comments.find((item) => item.id === commentId);
      const objWithIdIndex = comment.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        comment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeComment(state, payload) {
      const postId = payload.postId;
      const commentId = payload.commentId;

      const slydepost = state.posts.find((item) => item.id === postId);

      if(slydepost == null || slydepost.comments == null){
        return;
      }

      const comment = slydepost.comments.find((item) => item.id === commentId);
      if (comment.likes == null) {
        comment.likes = [];
      }
      comment.likes.push(payload);
    },
    removeComment(state, payload) {
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.posts.find((item) => item.id === postId);
      const objWithIdIndex = slydepost.comments.findIndex((obj) => obj.id === commentId);
      if (objWithIdIndex > -1) {
        slydepost.comments.splice(objWithIdIndex, 1);
      }
    },
    addComment(state, payload) {
      const comment = payload.comment;
      const postId = payload.postId;
      const slydepost = state.posts.find((item) => item.id === postId);
      if (slydepost.comments == null) {
        slydepost.comments = [];
      }
      slydepost.comments.push(comment);
    },
    removeSlydepost(state, postId) {
      state.posts = state.posts.filter((obj) => obj.id !== postId);
      state.totalPosts = state.totalPosts - 1;
    },
  },
  actions: {
    loadMorePosts({ dispatch, commit, getters, rootGetters }, payload) {
      if (getters['getIsLoadingMorePosts'] === true) {
        return;
      }
      commit('setIsLoadingMorePosts', true);
      if (getters['getEndOfPosts'] === true) {
        return;
      }
      const userId = rootGetters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      let index = payload.index;
      client
        .get(`/post/profile/${userId}?loggedInUserId=${userId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
          params: { index: index },
        })
        .then((response) => {
          if (response.data.length === 0) {
            commit('setEndOfPosts', true);
          }
          for (let i = 0; i < response.data.posts.length; i++) {
            commit('addToPosts', response.data.posts[i]);
          }
          commit('setTotalPosts', response.data.total);
          commit('setIsLoadingMorePosts', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
          commit('setIsLoadingMorePosts', false);
        });
    },
  },
};

// export the entire module
export { currentUserStoreModule };
