<template>
  <div class="bg-white px-6 md:border md:border-[#E0E0E0] shadow-xl">
    <div class="md:hidden pt-4 flex flex-row items-center justify-between w-full">
      <div class="w-10 h-10 bg-[#F6F6F6] flex items-center justify-center rounded-xl">
        <font-awesome-icon
          @click="this.closeOmnisearch"
          class="text-lg text-dark-blue cursor-pointer"
          :icon="['fas', 'xmark']"
        />
      </div>
      <p>Search</p>
      <div></div>
    </div>
    <div class="py-4">
      <input
        class="border-2 border-blue"
        placeholder="Keywords"
        v-model="keywords"
        @keyup.enter="this.findSearchResults"
      />
    </div>
    <div class="hidden md:block py-2">
      <div class="flex flex-row justify-between items-center">
        <ContentTypeSelector
          :text="'Filter by'"
          :options="this.options"
          :selected-option="this.selectedContentType"
          @clickAll="filterResultsAll"
          @clickSlydeposts="filterResultsSlydeposts"
          @clickPeople="filterResultsPeople"
          @clickLists="filterResultsLists"
        />
        <font-awesome-icon
          @click="this.closeOmnisearch"
          class="text-lg text-dark-blue cursor-pointer"
          :icon="['fas', 'xmark']"
        />
      </div>
    </div>
    <div v-if="showResults">
      <div>
        <div v-show="this.showSlydeposts">
          <div class="flex flex-row justify-between items-center gap-2 pb-3">
            <p class="text-sm font-bold">Slydeposts ({{ totalSlydepostResults }})</p>
            <p @click="openRefinedSearchForSlydeposts" class="md:hidden text-teal text-[13px]">(View All)</p>
          </div>
          <div v-if="!isSlydepostResultsFound">
            <p class="py-1 h-12 flex items-center">No results found.</p>
          </div>
          <div v-else class="flex flex-col gap-3">
            <PostSearchResult
              @click="closeOmnisearch"
              v-for="post in this.postSearchResults"
              :key="post.postId"
              :id="post.id"
              :image-url="post.presignedUrl"
              :title="post.title"
              :subcategory="post.subcategory"
              :category="post.category"
              :meta-rating="post.metaRating"
              :location="post.address"
              :date="this.epochToDate(post.createdAt)"
              :aspects="post.aspects"
            />
          </div>
          <p
            v-show="isSlydepostResultsFound"
            @click="openRefinedSearchForSlydeposts"
            class="hidden md:block text-xs font-bold text-teal underline py-2 cursor-pointer"
          >
            See all Slydeposts
          </p>
        </div>
        <hr v-show="this.showPeople" class="my-4" />
        <div v-show="this.showPeople">
          <div class="flex flex-row justify-between gap-2 items-center pb-3">
            <p class="text-sm font-bold">People ({{ totalPeopleResults }})</p>
            <p @click="openRefinedSearchForPeople" class="md:hidden text-teal text-[13px]">(View All)</p>
          </div>
          <div v-if="!isPeopleResultsFound">
            <p class="py-1 h-12 flex items-center">No results found.</p>
          </div>
          <div v-else>
            <div class="flex flex-col gap-2 pb-2" v-for="user in this.userSearchResults" :key="user.userId">
              <UserSearchResult
                v-if='user.userId != null'
                @click="closeOmnisearch"
                :user-id="user.userId"
                :first-name="user.firstName"
                :last-name="user.lastName"
                :profile-picture-url="user.presignedUrl"
              />
            </div>
          </div>
          <p
            v-show="isPeopleResultsFound"
            @click="openRefinedSearchForPeople"
            class="hidden md:block text-xs font-bold text-teal underline py-2 cursor-pointer"
          >
            See all People
          </p>
        </div>
        <hr v-show="this.showLists" class="my-4" />
        <div v-show="this.showLists">
          <div class="flex flex-row justify-between gap-2 items-center pb-3">
            <p class="text-sm font-bold">Lists ({{ totalListResults }})</p>
            <p @click="openRefinedSearchForLists" class="md:hidden text-teal text-[13px]">(View All)</p>
          </div>
          <div v-if="!isListResultsFound">
            <p class="py-1 h-12 flex items-center">No results found.</p>
          </div>
          <div v-else class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-6">
            <div v-for="list in limitedListResults" :key="list.id" @click="closeOmnisearch">
              <ListCard
                :profile-picture-url="list.userProfilePicturePresignedUrl"
                :id="list.listId"
                :title="list.title"
                :cover="list.presignedUrl"
                :post-count="list.totalPosts"
                :follow-count="list.totalFollows"
              />
            </div>
          </div>
          <p
            v-show="isListResultsFound"
            @click="openRefinedSearchForLists"
            class="hidden md:block text-xs font-bold text-teal underline py-2 cursor-pointer"
          >
            See all Lists
          </p>
        </div>
      </div>
    </div>
    <div class="h-24 md:h-16 flex flex-row justify-between items-center">
      <div @click="openRefinedSearchForAll" class="flex flex-row items-center gap-1">
        <p class="md:hidden text-blue">Refine Search</p>
        <font-awesome-icon class="md:hidden" :icon="['fas', 'filter']" color="#015D79" />
        <p class="hidden md:block text-xs font-bold text-teal underline py-2 cursor-pointer">Advanced Search</p>
      </div>
      <div>
        <button @click="this.findSearchResults" class="bg-blue py-4 md:py-2 px-9 md:px-4 rounded-2xl">Search</button>
      </div>
    </div>
  </div>
</template>

<script>
import ContentTypeSelector from '@/components/Search/ContentTypeSelector.vue';
import PostSearchResult from '@/components/Search/SearchResults/PostSearchResult.vue';
import UserSearchResult from '@/components/Search/SearchResults/UserSearchResult.vue';
import ListCard from '@/components/ViewLists/ListCardV2.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Omnisearch',
  components: {
    ContentTypeSelector,
    PostSearchResult,
    UserSearchResult,
    ListCard,
  },
  computed: {
    ...mapGetters({
      isSlydepostResultsFound: 'searchStoreModule/getIsSlydepostResultsFound',
      isPeopleResultsFound: 'searchStoreModule/getIsPeopleResultsFound',
      isListResultsFound: 'searchStoreModule/getIsListResultsFound',
      isSearchInProgress: 'searchStoreModule/getIsSearchInProgress',
      userSearchResults: 'searchStoreModule/getUserSearchResults',
      postSearchResults: 'searchStoreModule/getPostSearchResults',
      listResults: 'searchStoreModule/getListSearchResults',
      totalSlydepostResults: 'refinedSearchStoreModule/getTotalSearchResults',
      totalPeopleResults: 'searchStoreModule/getTotalPeopleSearchResults',
      totalListResults: 'searchStoreModule/getTotalListSearchResults',
    }),
    keywords: {
      get() {
        return this.$store.getters['searchStoreModule/getKeyword'];
      },
      set(keyword) {
        this.$store.commit('searchStoreModule/setKeyword', keyword);
      },
    },
    limitedListResults() {
      return this.isPeopleLimit ? this.listResults.slice(0, this.listLimit) : this.listResults;
    },
  },
  data() {
    return {
      showResults: false,
      showSlydeposts: true,
      showPeople: true,
      showLists: true,
      selectedContentType: 'all',
      options: [
        {
          text: 'all',
          emit: 'clickAll',
        },
        {
          text: 'slydeposts',
          emit: 'clickSlydeposts',
        },
        {
          text: 'people',
          emit: 'clickPeople',
        },
        {
          text: 'lists',
          emit: 'clickLists',
        },
      ],
    };
  },
  methods: {
    epochToDate(epoch) {
      let date = new Date(epoch);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return date.toLocaleDateString('en-us', options);
    },
    closeOmnisearch() {
      this.$emit('close');
    },
    openRefinedSearchForSlydeposts() {
      this.$emit('openRefinedSearch');
      this.$store.commit('refinedSearchStoreModule/setRefinedKeywords', this.keywords);
      this.$store.commit('refinedSearchStoreModule/setFilterContentType', 'slydeposts');
      this.$store.dispatch('refinedSearchStoreModule/findRefinedResults');
      this.$router.push('/search');
    },
    openRefinedSearchForPeople() {
      this.$emit('openRefinedSearch');
      this.$store.commit('refinedSearchStoreModule/setRefinedKeywords', this.keywords);
      this.$store.commit('refinedSearchStoreModule/setFilterContentType', 'people');
      let payload = {
        text: this.keywords,
        index: 'user',
      };
      this.$store.dispatch('searchStoreModule/indexSearch', payload);
      this.$router.push('/search');
    },
    openRefinedSearchForLists() {
      this.$emit('openRefinedSearch');
      this.$store.commit('refinedSearchStoreModule/setRefinedKeywords', this.keywords);
      this.$store.commit('refinedSearchStoreModule/setFilterContentType', 'lists');
      let payload = {
        text: this.keywords,
        index: 'list',
      };
      this.$store.dispatch('searchStoreModule/indexSearch', payload);
      this.$router.push('/search');
    },
    openRefinedSearchForAll() {
      // close omnisearch in partment component
      this.$emit('openRefinedSearch');
      // set keyword so it will be prepopulated in refined search
      this.$store.commit('refinedSearchStoreModule/setRefinedKeywords', this.keywords);
      // set content type to be all
      this.$store.commit('refinedSearchStoreModule/setFilterContentType', 'all');
      // API call to get post search results
      this.$store.dispatch('refinedSearchStoreModule/findRefinedResults');
      // API call to get user search results
      let payload = {
        text: this.keywords,
        index: 'user',
      };
      this.$store.dispatch('searchStoreModule/indexSearch', payload);
      let listPayload = {
        text: this.keywords,
        index: 'list',
      };
      this.$store.dispatch('searchStoreModule/indexSearch', listPayload);
      // navigate to search page
      this.$router.push('/search');
    },
    findSearchResults() {
      this.showPeople = true;
      this.showSlydeposts = true;
      this.showLists = true;
      this.$store.dispatch('searchStoreModule/omnisearch', this.keywords);
      this.showResults = true;
    },
    filterResultsAll() {
      this.selectedContentType = 'all';
      this.showSlydeposts = true;
      this.showPeople = true;
      this.showLists = true;
    },
    filterResultsSlydeposts() {
      this.selectedContentType = 'slydeposts';
      this.showSlydeposts = true;
      this.showPeople = false;
      this.showLists = false;
    },
    filterResultsPeople() {
      this.selectedContentType = 'people';
      this.showSlydeposts = false;
      this.showPeople = true;
      this.showLists = false;
    },
    filterResultsLists() {
      this.selectedContentType = 'lists';
      this.showSlydeposts = false;
      this.showPeople = false;
      this.showLists = true;
    },
  },
};
</script>
