<template>
  <div class="py-5 px-4 bg-white">
    <p class="text-2xl text-dark-dark-gray font-bold pb-8">Edit Privacy & Security</p>
    <div class="md:flex flex-col items-center">
      <div class="flex flex-col gap-8 md:w-80">
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Profile Visibility</p>
          <div class="flex flex-row gap-8">
            <div class="flex flex-row gap-2">
              <input type="radio" value="PUBLIC" v-model="visibility" />
              <label for="public">Public</label>
            </div>
            <div class="flex flex-row gap-2">
              <input type="radio" value="PRIVATE" v-model="visibility" />
              <label for="private">Private</label>
            </div>
          </div>
        </div>
        <div class="">
          <form @submit.prevent="resetPassword">
            <div class="flex flex-col gap-2">
              <p class="text-xs uppercase">Reset Your Password</p>
              <input
                @focus="this.resetPasswordButtonText = 'Reset Password'"
                type="password"
                v-model="oldPassword"
                placeholder="Current Password"
                id="old-password-email"
              />
              <input
                @focus="this.showPasswordChecker = true"
                type="password"
                v-model="newPassword"
                placeholder="New Password"
                id="new-password-password"
              />
              <input
                type="password"
                v-model="confirmNewPassword"
                placeholder="Confirm New Password"
                id="confirm-new-password-password"
              />
              <PasswordChecker
                :password="this.newPassword"
                :check-password="this.confirmNewPassword"
                :show-checker="showPasswordChecker"
              />
              <button>{{ resetPasswordButtonText }}</button>
            </div>
          </form>
        </div>
        <div>
          <LoaderButton
            @click="savePrivacySettings"
            :status="privacySettingsSaveStatus"
            :waiting-text="'Save'"
            :in-progress-text="'Saving'"
            :done-text="'Saved!'"
            :error-text="'Try Again?'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import PasswordChecker from '@/components/SignInSignUp/PasswordChecker.vue';

export default {
  name: 'PrivacySettings',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      showPasswordChecker: false,
      resetPasswordButtonText: 'Reset Password',
    };
  },
  components: {
    LoaderButton,
    PasswordChecker,
  },
  computed: {
    privacySettingsSaveStatus: {
      get() {
        return this.$store.getters.getPrivacySettingsSaveStatus;
      },
      set(value) {
        this.$store.commit('setPrivacySettingsSaveStatus', value);
      },
    },
    visibility: {
      get() {
        return this.$store.getters.getVisibility;
      },
      set(value) {
        this.$store.commit('setVisibility', value);
      },
    },
  },
  methods: {
    savePrivacySettings() {
      let payload = {
        visibility: this.visibility,
      };
      this.$store.dispatch('updatePrivacySettings', payload);
    },
    async resetPassword() {
      try {
        const payload = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        this.$store.dispatch('authStoreModule/resetPassword', payload);
        this.resetPasswordButtonText = 'Password Reset!';
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
        this.showPasswordChecker = false;
      } catch (error) {
        // TODO: handle case where password is reset too many times in a row
        // LimitExceededException: Attempt limit exceeded, please try after some time
        this.resetPasswordButtonText = 'Reset Password';
        console.error('error updating user password', error);
      }
    },
  },
  mounted() {},
};
</script>
