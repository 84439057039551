<template>
  <div @click="this.clickHeader" :class="isSelectedStyle">
    <div class="flex flex-row items-center gap-2 md:gap-0">
      <p class="text-xl md:text-sm">{{ text }}</p>
      <div
        v-if="this.selectedOptions.length > 0"
        class="flex flex-row gap-1 text-sm items-center px-3 md:px-0 md:pr-2 py-2 md:py-1 rounded-full bg-teal text-white"
      >
        <p class="hidden md:block">:</p>
        <p class="md:whitespace-nowrap">
          {{ this.formatOption(this.selectedOptions[0]) }}
        </p>
        <div
          v-if="this.selectedOptions.length > 1"
          class="w-5 h-5 rounded-full flex items-center justify-center bg-blue"
        >
          <p style="font-size: 11px">+{{ this.selectedOptions.length - 1 }}</p>
        </div>
        <div class="hidden md:block">
          <font-awesome-icon
            v-show="this.showInput === false"
            color="white"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showInput === true"
            color="white"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
    </div>
    <div :class="dropdownClass">
      <font-awesome-icon
        v-show="this.showInput === false"
        :color="'#015D79'"
        class="cursor-pointer text-lg md:text-sm"
        :icon="['fas', 'chevron-down']"
      />
      <font-awesome-icon
        v-show="this.showInput === true"
        :color="'#015D79'"
        class="cursor-pointer text-lg md:text-sm"
        :icon="['fas', 'chevron-up']"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RefinedSearchMultiTextHeader',
  data() {
    return {
      showInput: false,
    };
  },
  props: {
    text: {
      type: String,
    },
    selectedOptions: {
      type: Array,
    },
  },
  methods: {
    clickHeader() {
      this.showInput = !this.showInput;
      this.$emit('clickHeader');
    },
    formatOption(category) {
      if (!category.includes(' > ')) {
        return category;
      }
      let subcategory = category.split(' > ')[1];
      return subcategory;
    },
  },
  computed: {
    isSelectedStyle() {
      let divClass = 'flex justify-between md:justify-start md:gap-2 w-full items-center ';
      if (this.selectedOptions.length > 0) {
        divClass += 'md:rounded-full md:bg-teal md:text-white md:pl-2';
      }
      return divClass;
    },
    dropdownClass() {
      if (this.selectedOptions.length > 0) {
        return 'md:hidden';
      }
      return '';
    },
  },
};
</script>
