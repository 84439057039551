<template>
  <div class="px-4 lg:px-0 lg:pt-0 flex flex-col gap-2 pt-4">
    <p class="text-2xl pb-2 text-dark-gray">Your Products</p>
    <div>
      <div @click='showSubscriptions = !showSubscriptions' :class='showSubscriptions ? `rounded-t-xl border-2 border-b-0` : `rounded-xl border-2`' class='p-2 flex flex-row justify-between items-center gap-2 border-moonstone'>
        <p class='text-lg font-medium'>Subscriptions</p>
        <div>
          <font-awesome-icon
            v-show="this.showSubscriptions === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showSubscriptions === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if='showSubscriptions' class='flex flex-col'>
        <div v-if='subscriptionPayments.length <= 0' class='rounded-b-xl border-2 border-moonstone p-2'>
          <p>No subscriptions yet</p>
        </div>
        <div :class='showSubscriptions ? `last:rounded-b-xl last:border-b-2 border-b-0` : `rounded-xl`' class='border-2 border-moonstone' v-for='payment in subscriptionPayments' :key='payment.id'>
          <router-link :to="{ name: 'UserProfile', params: { userId: payment.price.product.contentOwnerId } }">
          <SubscriptionPayment
            :price-type='payment.price.priceType'
            :user-id='payment.price.product.contentId'
            :name='payment.price.product.contentName'
            :profile-picture='payment.price.product.contentOwnerProfilePicturePresignedUrl'
          />
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <div @click='showLists = !showLists' :class='showLists ? `rounded-t-xl border-2 border-b-0` : `rounded-xl border-2`' class='p-2 flex flex-row justify-between items-center border-moonstone'>
        <p class='text-lg font-medium'>Lists</p>
        <div>
          <font-awesome-icon
            v-show="this.showLists === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showLists === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if='showLists' class='flex flex-col'>
        <div v-if='listPayments.length <= 0' class='rounded-b-xl border-2 border-moonstone p-2'>
          <p>No list payments yet</p>
        </div>
        <div :class='showLists ? `last:rounded-b-xl last:border-b-2 border-b-0` : `rounded-xl`' class='border-2 border-moonstone' v-for='payment in listPayments' :key='payment.id'>
          <router-link :to="{ name: 'SingleList', params: { listId: payment.price.product.contentId } }" class="w-full line-clamp-2">
            <ListPayment
              :price='payment.price.price'
              :title='payment.price.product.contentName'
              :list-id='payment.price.product.contentId'
              :profile-picture='payment.price.product.contentOwnerProfilePicturePresignedUrl'
              :name='payment.price.product.contentOwnerName'
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListPayment from '@/components/Insider/Products/ListPayment.vue';
import SubscriptionPayment from '@/components/Insider/Products/SubscriptionPayment.vue';

export default {
  name: 'ViewPayments',
  data(){
    return {
      showSubscriptions: false,
      showLists: false
    }
  },
  components: {
    ListPayment,
    SubscriptionPayment
  },
  computed: {
    ...mapGetters({
      payments: 'customerStoreModule/getPayments'
    }),
    subscriptionPayments(){
      return this.payments.filter(item => item.price.product.productType === 'INSIDER_SUBSCRIPTION');
    },
    listPayments(){
      return this.payments.filter(item => item.price.product.productType === 'LIST_PURCHASE');
    },
  },
  methods: {
    back(){
      this.$router.back();
    },
  },
  mounted(){
    this.$store.dispatch('customerStoreModule/getPayments')
  },
  unmounted(){
    this.$store.commit('customerStoreModule/clear')
  }
}
</script>