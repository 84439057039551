<template>
  <div class="bg-white p-1.5 w-full fixed z-[60]">
    <div class="grid-nav-bar-sm lg:grid-nav-bar gap-4 h-10 content-center bg-transparent items-center">
      <div class="grid-logo cursor-pointer bg-transparent">
        <router-link :to="{ name: 'Home' }">
          <img
            class="text-left object-contain bg-transparent h-10 p-1.5"
            src="@/assets/SP_Logo_Color-4x.png"
            alt=""
          />
        </router-link>
      </div>
      <div class="grid-icons bg-white" v-click-outside="closeOmniSearch">
        <div class="flex flex-row items-center justify-end gap-6 sm:gap-8">
          <div>
            <CreateButtonCondensed class="lg:hidden" />
          </div>
          <div>
            <input
              class="border-light-light-gray rounded-full h-10 pt-4 pb-4 pl-4 pr-10"
              v-show="this.showSearchInput"
              v-model="searchInput"
              placeholder="Find People"
              @keyup.enter="omnisearch()"
            />
            <font-awesome-icon
              @click="openOmniSearch"
              class="cursor-pointer text-center -right-0 text-blue text-lg"
              icon="fas fa-search"
            />
            <div v-show="this.showOmnisearch" class="absolute md:relative left-0 w-full z-50 shadow-lg">
              <Omnisearch
                class="md:absolute md:w-135 right-0 top-2 overflow-y-scroll max-h-screen pb-12"
                @close="this.showOmnisearch = false"
                @openRefinedSearch="this.openRefinedSearch"
              />
            </div>
          </div>
          <div class="z-50">
            <div
              v-click-outside="closeProfileMenu"
              @click="toggleProfileMenu"
              class="w-8 h-8 relative cursor-pointer bg-transparent"
            >
              <div class="w-8 h-8 rounded-[13px] overflow-hidden">
                <img :src="profilePictureUrl" class="w-8 h-8" />
              </div>
              <div class="absolute top-0 right-0 w-2 h-2 bg-teal rounded-full" v-if="hasNotifications"></div>
              <AppDropdownContent
                class="absolute bg-transparent z-10"
                :active="this.showProfileMenu"
                :options="this.profilePicDropdownMenuOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ProfileOptionsSidebar
    v-click-outside="closeProfileMenuSidebar"
    :isOpen="this.showProfileMenuSidebar"
    :signOutData="signOutData"
    :closeProfileMenuSidebarFunc="closeProfileMenuSidebarFunc"
    :profileData="profileData"
    @close="closeProfileMenuSidebar"
  />
</template>

<script>
import AppDropdownContent from '@/components/DropdownMenu/DropdownContent.vue';
import Omnisearch from '@/components/Search/Omnisearch/Omnisearch.vue';
import { mapGetters } from 'vuex';
import ProfileOptionsSidebar from '@/components/Sidebar/ProfileOptionsSidebar.vue';
import CreateButtonCondensed from '@/components/Buttons/CreateButtonCondensed.vue';
import { useDisplay } from 'vuetify';

export default {
  name: 'NavigationBar',
  components: {
    AppDropdownContent,
    // UserSearchResult,
    Omnisearch,
    ProfileOptionsSidebar,
    CreateButtonCondensed,
  },
  computed: {
    ...mapGetters({
      profilePictureUrl: 'getProfilePictureUrl',
      searchResults: 'searchStoreModule/getUserSearchResults',
      isResultsFound: 'searchStoreModule/getIsSlydepostResultsFound',
      isSearchInProgress: 'searchStoreModule/getIsSearchInProgress',
      hasNotifications: 'getHasNotifications',
    }),
  },
  data() {
    return {
      showSearchInput: false,
      showOmnisearch: false,
      searchInput: '',
      showModal: false,
      showDev: false,
      showProfileMenu: false,
      showProfileMenuSidebar: false,
      signOutData: {
        itemName: 'Sign Out',
        link: '/signout',
        isSignOut: true,
      },
      profileData: {
        itemName: 'Profile',
        link: '/profile',
        icon: '',
      },
      profilePicDropdownMenuOptions: [
        {
          itemName: 'Profile',
          link: '/profile',
          icon: '',
        },
        {
          itemName: 'Notifications',
          link: '/notifications',
          notificationGetter: 'getHasNotifications',
          icon: 'bell',
        },
        {
          itemName: 'Settings',
          link: '/settings',
          icon: 'gear',
        },
        {
          itemName: 'Help',
          link: '/help?page=help',
          icon: 'life-ring',
        },
        {
          itemName: 'Sign Out',
          link: '/signout',
          isSignOut: true,
        },
      ],
    };
  },
  methods: {
    toggleProfileMenu() {
      if (this.mdAndDown) {
        this.showProfileMenuSidebar = !this.showProfileMenuSidebar;
      } else {
        this.showProfileMenu = !this.showProfileMenu;
      }
    },
    closeProfileMenu() {
      this.showProfileMenu = false;
    },
    omnisearch() {
      this.showOmnisearch = true;
      this.$store.dispatch('searchStoreModule/omnisearch', this.searchInput);
    },
    openRefinedSearch() {
      this.showOmnisearch = false;
    },
    closeProfileMenuSidebar() {
      this.showProfileMenuSidebar = false;
    },
    closeProfileMenuSidebarFunc() {
      this.showProfileMenuSidebar = false;
    },
    closeOmniSearch() {
      this.showOmnisearch = false;
    },
    openOmniSearch() {
      this.showOmnisearch = true;
    },
  },
  mounted() {
    this.$store.dispatch('getLoggedInUserProfile');
  },
  setup() {
    // Destructure only the keys we want to use
    const { mdAndDown, lgAndUp } = useDisplay();
    return { mdAndDown, lgAndUp };
  },
};
</script>
