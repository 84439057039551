import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const viewSlydepostStoreModule = {
  namespaced: true,
  state: {
    title: '',
    description: '',
    metaRating: 0,
    category: '',
    subcategory: '',
    userId: '',
    images: [],
    profilePictureUrl: '',
    link: '',
    createdAt: '',
    userFirstName: '',
    aspects: [],
    address: '',
    likes: null,
    id: '',
    comments: null,
    userLastName: '',
  },
  getters: {
    getTitle(state) {
      return state.title;
    },
    getDescription(state) {
      return state.description;
    },
    getMetaRating(state) {
      return state.metaRating;
    },
    getCategory(state) {
      return state.category;
    },
    getSubcategory(state) {
      return state.subcategory;
    },
    getUserId(state) {
      return state.userId;
    },
    getImages(state) {
      return state.images;
    },
    getProfilePictureUrl(state) {
      return state.profilePictureUrl;
    },
    getLink(state) {
      return state.link;
    },
    getCreatedAt(state) {
      return state.createdAt;
    },
    getUserFirstName(state) {
      return state.userFirstName;
    },
    getAspects(state) {
      return state.aspects;
    },
    getAddress(state) {
      return state.address;
    },
    getLikes(state) {
      return state.likes;
    },
    getId(state) {
      return state.id;
    },
    getComments(state) {
      if (state.comments != null) {
        let slydepostComments = [...state.comments];
        // filter out any child comments in the array, as they are already part of the parent comment
        slydepostComments = slydepostComments.filter((comment) => comment.parentId == null);

        // 1. loop through to sort parent comments
        slydepostComments.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        // 2. loop through each parents' child comments and sort those
        for (let i = 0; i < slydepostComments.length; i++) {
          if (slydepostComments[i].childComments != null) {
            slydepostComments[i].childComments.sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt);
            });
          }
        }
        return slydepostComments;
      }
      return state.comments;
    },
    getUserLastName(state) {
      return state.userLastName;
    },
  },
  mutations: {
    removeLike(state, payload) {
      const likeId = payload.id;

      const objWithIdIndex = state.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        state.likes.splice(objWithIdIndex, 1);
      }
    },
    likeSlydepost(state, payload) {
      if (state.likes == null) {
        state.likes = [];
      }
      state.likes.push(payload);
    },
    removeLikeReply(state, payload) {
      const likeId = payload.id;
      const parentId = payload.parentId;
      const commentId = payload.commentId;

      const comment = state.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find((item) => item.id === commentId);
      const objWithIdIndex = childComment.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        childComment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeReply(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const likeId = payload.id;
      const userId = payload.userId;
      const likePayload = {
        id: likeId,
        commentId: commentId,
        userId: userId,
      };

      const comment = state.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find((item) => item.id === commentId);
      if (childComment.likes == null) {
        childComment.likes = [];
      }
      childComment.likes.push(likePayload);
    },
    removeReplyFromComment(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;

      const comment = state.comments.find((item) => item.id === parentId);
      const objWithIdIndex = comment.childComments.findIndex((obj) => obj.id === commentId);
      if (objWithIdIndex > -1) {
        comment.childComments.splice(objWithIdIndex, 1);
      }
    },
    replyToComment(state, payload) {
      const parentCommentId = payload.parentId;

      const comment = state.comments.find((item) => item.id === parentCommentId);
      if (comment.childComments == null) {
        comment.childComments = [];
      }
      comment.childComments.push(payload);
    },
    removeLikeComment(state, payload) {
      const likeId = payload.likeId;
      const commentId = payload.commentId;

      const comment = state.comments.find((item) => item.id === commentId);
      const objWithIdIndex = comment.likes.findIndex((obj) => obj.id === likeId);
      if (objWithIdIndex > -1) {
        comment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeComment(state, payload) {
      const commentId = payload.commentId;
      if(state.comments == null){
        return;
      }
      const comment = state.comments.find((item) => item.id === commentId);
      if (comment.likes == null) {
        comment.likes = [];
      }
      comment.likes.push(payload);
    },
    removeComment(state, payload) {
      const commentId = payload.commentId;

      const objWithIdIndex = state.comments.findIndex((obj) => obj.id === commentId);
      if (objWithIdIndex > -1) {
        state.comments.splice(objWithIdIndex, 1);
      }
    },
    addComment(state, payload) {
      const comment = payload.comment;
      if (state.comments == null) {
        state.comments = [];
      }
      state.comments.push(comment);
    },
    setTitle(state, title) {
      state.title = title;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setMetaRating(state, metaRating) {
      state.metaRating = metaRating;
    },
    setCategory(state, category) {
      state.category = category;
    },
    setSubcategory(state, subcategory) {
      state.subcategory = subcategory;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setImages(state, images) {
      state.images = images;
    },
    setProfilePictureUrl(state, url) {
      state.profilePictureUrl = url;
    },
    setLink(state, link) {
      state.link = link;
    },
    setCreatedAt(state, createdAt) {
      state.createdAt = createdAt;
    },
    setUserFirstName(state, name) {
      state.userFirstName = name;
    },
    setAspects(state, aspects) {
      state.aspects = aspects;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setLikes(state, likes) {
      state.likes = likes;
    },
    setId(state, id) {
      state.id = id;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setUserLastName(state, name) {
      state.userLastName = name;
    },
    clearSlydepost(state) {
      state.title = '';
      state.description = '';
      state.metaRating = 0;
      state.category = '';
      state.subcategory = '';
      state.userId = '';
      state.images = [];
      state.profilePictureUrl = '';
      state.link = '';
      state.createdAt = '';
      state.userFirstName = '';
      state.aspects = [];
      state.address = '';
      state.likes = [];
      state.id = '';
      state.comments = [];
      state.userLastName = '';
    },
  },
  actions: {
    async getSlydepost({ commit, getters, rootGetters }, postId) {
      commit('clearSlydepost', postId);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(`/post/${postId}`, {
          withCredentials: true,
          headers: {
            'User-Id': loggedInUserId,
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          commit('setAddress', response.data.address);
          commit('setCategory', response.data.category);
          commit('setCreatedAt', response.data.createdAt);
          commit('setDescription', response.data.description);
          commit('setId', response.data.id);
          commit('setLikes', response.data.likes);
          commit('setLink', response.data.link);
          commit('setImages', response.data.media);
          commit('setSubcategory', response.data.subcategory);
          commit('setMetaRating', response.data.metaRating);
          commit('setAspects', response.data.tags);
          commit('setTitle', response.data.title);
          commit('setUserFirstName', response.data.userFirstName);
          commit('setUserLastName', response.data.userLastName);
          commit('setProfilePictureUrl', response.data.userProfilePhotoUrl);
          commit('setUserId', response.data.userId);
          commit('setComments', response.data.comments);
        })
        .catch((error) => {
          console.error(`Failed call to get slydepost. Response: ${error.message}`);
        });
    },
    getSlydepostByCommentId({ commit, getters, rootGetters }, commentId) {
      commit('clearSlydepost');
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(`/post?contentType=comment&contentId=${commentId}`, {
          withCredentials: true,
          headers: {
            'User-Id': loggedInUserId,
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          commit('setAddress', response.data.address);
          commit('setCategory', response.data.category);
          commit('setCreatedAt', response.data.createdAt);
          commit('setDescription', response.data.description);
          commit('setId', response.data.id);
          commit('setLikes', response.data.likes);
          commit('setLink', response.data.link);
          commit('setImages', response.data.media);
          commit('setSubcategory', response.data.subcategory);
          commit('setMetaRating', response.data.metaRating);
          commit('setAspects', response.data.tags);
          commit('setTitle', response.data.title);
          commit('setUserFirstName', response.data.userFirstName);
          commit('setUserLastName', response.data.userLastName);
          commit('setProfilePictureUrl', response.data.userProfilePhotoUrl);
          commit('setUserId', response.data.userId);
          commit('setComments', response.data.comments);
        })
        .catch((error) => {
          console.error(`Failed call to get slydepost. Response: ${error.message}`);
        });
    },
  },
};

// export the entire module
export { viewSlydepostStoreModule };
