<template>
  <div class="px-4 lg:px-0 pt-4 lg:pt-0">
    <TrendingUsers v-if="isMobileView" :mobile="true" />
    <p class="text-2xl pb-4 text-dark-gray">My Community</p>
    <div class="flex flex-row gap-5 pb-2 text-dark-gray">
      <div @click="clickRequests" :style="getRequestsStyling" class="flex flex-row gap-1">
        <p class="cursor-pointer">Requests</p>
        <p>({{ totalPendingRequests }})</p>
      </div>
      <p @click="clickFollowers" :style="getFollowersStyling" class="cursor-pointer">Followers</p>
      <p @click="clickFollowing" :style="getFollowingStyling" class="cursor-pointer">Following</p>
      <p @click="clickMuted" :style="getMutedStyling" class="hidden md:inline cursor-pointer">Muted</p>
      <p @click="clickBlocked" :style="getBlockedStyling" class="hidden md:inline cursor-pointer">Blocked</p>
      <div class="inline md:hidden">
        <MoreOptionsButton
          v-click-outside="closeOptions"
          @click="this.showOptions = !this.showOptions"
          @showMutedUsers="clickMuted"
          @showBlockedUsers="clickBlocked"
          :options="this.options"
          :icon-color="'black'"
          :showOptions="this.showOptions"
        />
      </div>
    </div>
    <hr class="text-chambray" />
    <div v-show="showRequests">
      <div class="pt-4 pb-5">
        <p>Requests</p>
      </div>
      <div class="flex flex-row flex-wrap md:gap-5 pb-2">
        <div
          class="w-full md:w-auto md:border-none border border-solid border-light-light-gray"
          v-for="request in pendingRequests"
          :key="request.id"
        >
          <Request
            :first-name="request.user.firstName"
            :last-name="request.user.lastName"
            :number-of-slydeposts="request.numberOfSlydeposts"
            :relationship-id="request.id"
            :user-id="request.friendId"
            :friend-id="request.userId"
            :profile-picture-url="request.user.presignedUrl"
          />
        </div>
        <InfiniteLoading @infinite="loadMorePendingRequests" />
      </div>
    </div>
    <div v-show="showFollowers">
      <div class="pt-4 pb-5">
        <p>Followers</p>
      </div>
      <div class="flex flex-row flex-wrap md:gap-5 pb-2">
        <div
          class="md:border-none w-full md:w-auto border border-solid border-light-light-gray"
          v-for="follower in followers"
          :key="follower.id"
        >
          <Follower
            :first-name="follower.user.firstName"
            :last-name="follower.user.lastName"
            :friend-id="follower.userId"
            :user-id="follower.friendId"
            :relationship-id="follower.id"
            :number-of-slydeposts="follower.numberOfSlydeposts"
            :profile-picture-url="follower.user.presignedUrl"
          />
        </div>
        <InfiniteLoading @infinite="loadMoreFollowers" />
      </div>
    </div>
    <div v-show="showFollowing">
      <div class="pt-4 pb-5">
        <p>Following</p>
      </div>
      <div class="flex flex-row flex-wrap md:gap-5 pb-2">
        <div
          class="md:border-none w-full md:w-auto border border-solid border-light-light-gray"
          v-for="follow in following"
          :key="follow.id"
        >
          <Following
            :first-name="follow.friend.firstName"
            :last-name="follow.friend.lastName"
            :friend-id="follow.friendId"
            :relationship-id="follow.id"
            :number-of-slydeposts="follow.numberOfSlydeposts"
            :profile-picture-url="follow.friend.presignedUrl"
          />
        </div>
        <InfiniteLoading @infinite="loadMoreFollowing" />
      </div>
    </div>
    <div v-show="showMuted">
      <div class="pt-4 pb-5">
        <p>Muted</p>
      </div>
      <div class="flex flex-row flex-wrap md:gap-5 pb-2">
        <div
          class="md:border-none w-full md:w-auto border border-solid border-light-light-gray"
          v-for="mute in muted"
          :key="mute.id"
        >
          <Muted
            :first-name="mute.friend.firstName"
            :last-name="mute.friend.lastName"
            :friend-id="mute.friendId"
            :relationship-id="mute.id"
            :number-of-slydeposts="mute.numberOfSlydeposts"
            :profile-picture-url="mute.friend.presignedUrl"
          />
        </div>
        <InfiniteLoading @infinite="loadMoreMuted" />
      </div>
    </div>
    <div v-show="showBlocked">
      <div class="pt-4 pb-5">
        <p>Blocked</p>
      </div>
      <div class="flex flex-row flex-wrap md:gap-5 pb-2">
        <div
          class="md:border-none w-full md:w-auto border border-solid border-light-light-gray"
          v-for="block in blocked"
          :key="block.id"
        >
          <Blocked
            :first-name="block.friend.firstName"
            :last-name="block.friend.lastName"
            :friend-id="block.friendId"
            :relationship-id="block.id"
            :number-of-slydeposts="block.numberOfSlydeposts"
            :profile-picture-url="block.friend.presignedUrl"
          />
        </div>
        <InfiniteLoading @infinite="loadMoreBlocked" />
      </div>
    </div>
  </div>
</template>

<script>
import Request from '@/components/UserStates/Request.vue';
import Follower from '@/components/UserStates/Follower.vue';
import Following from '@/components/UserStates/Following.vue';
import Blocked from '@/components/UserStates/Blocked.vue';
import Muted from '@/components/UserStates/Muted.vue';
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';
import InfiniteLoading from 'v3-infinite-loading';
import store from '@/store/store';
import { Buffer } from 'buffer';
import { mapGetters } from 'vuex';
import TrendingUsers from '@/components/Trending/Sidebar/TrendingUsers.vue';

export default {
  name: 'Community',
  components: {
    Request,
    Follower,
    Following,
    Muted,
    Blocked,
    MoreOptionsButton,
    TrendingUsers,
    InfiniteLoading,
  },
  computed: {
    ...mapGetters({
      pendingRequests: 'relationshipStoreModule/getPendingRequests',
      followers: 'relationshipStoreModule/getFollowers',
      totalPendingRequests: 'relationshipStoreModule/getTotalPendingRequests',
      following: 'relationshipStoreModule/getFollowing',
      blocked: 'relationshipStoreModule/getBlocked',
      muted: 'relationshipStoreModule/getMuted',
    }),
    getRequestsStyling() {
      return this.showRequests
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getFollowersStyling() {
      return this.showFollowers
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getFollowingStyling() {
      return this.showFollowing
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getMutedStyling() {
      return this.showMuted
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getBlockedStyling() {
      return this.showBlocked
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
    isMobileView() {
      return window.innerWidth < 640;
    },
  },
  data() {
    return {
      showRequests: true,
      showFollowers: false,
      showFollowing: false,
      showMuted: false,
      showBlocked: false,
      showOptions: false,
      options: [
        {
          text: 'Muted',
          onClickEmit: 'showMutedUsers',
        },
        {
          text: 'Blocked',
          onClickEmit: 'showBlockedUsers',
        },
      ],
    };
  },
  methods: {
    loadMorePendingRequests() {
      this.$store.dispatch('relationshipStoreModule/loadMorePendingRequests');
    },
    loadMoreFollowing() {
      this.$store.dispatch('relationshipStoreModule/loadMoreFollowing');
    },
    loadMoreFollowers() {
      this.$store.dispatch('relationshipStoreModule/loadMoreFollowers');
    },
    loadMoreMuted() {
      this.$store.dispatch('relationshipStoreModule/loadMoreMuted');
    },
    loadMoreBlocked() {
      this.$store.dispatch('relationshipStoreModule/loadMoreBlocked');
    },
    closeOptions() {
      this.showOptions = false;
    },
    clickRequests() {
      this.showRequests = true;
      this.showFollowers = false;
      this.showFollowing = false;
      this.showMuted = false;
      this.showBlocked = false;
    },
    clickFollowers() {
      this.showRequests = false;
      this.showFollowers = true;
      this.showFollowing = false;
      this.showMuted = false;
      this.showBlocked = false;
    },
    clickFollowing() {
      this.showRequests = false;
      this.showFollowers = false;
      this.showFollowing = true;
      this.showMuted = false;
      this.showBlocked = false;
    },
    clickMuted() {
      this.showRequests = false;
      this.showFollowers = false;
      this.showFollowing = false;
      this.showMuted = true;
      this.showBlocked = false;
    },
    clickBlocked() {
      this.showRequests = false;
      this.showFollowers = false;
      this.showFollowing = false;
      this.showMuted = false;
      this.showBlocked = true;
    },
  },
  mounted() {
    this.$store.dispatch('relationshipStoreModule/getRelationships');
  },
};
</script>
