<template>
  <div class="flex flex-row gap-5 text-dark-gray items-center">
    <ToggleButton
      v-for="option in options"
      :key="option.id"
      :is-selected="currentOption === option.name"
      :text="option.text"
      :id="option.id"
      @clicked="selectOption"
    />
  </div>
</template>

<script>
import ToggleButton from '@/components/Utility/ToggleButton.vue';

export default {
  name: 'ToggleButtonMenu',
  components: {
    ToggleButton,
  },
  data() {
    return {};
  },
  props: {
    options: Array,
    currentOption: String,
  },
  methods: {
    selectOption(id) {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].id === id) {
          this.$emit('selected', this.options[i].name);
          return;
        }
      }
    },
  },
};
</script>
