<template>
  <div class="bg-white border border-[#E0E0E0]">
    <p class="text-blue text-md text-center py-3 border-b border-[#E0E0E0]">Trending Slydeposts</p>
    <div class="flex flex-col gap-2 justify-center items-center py-4">
      <div v-for="post in slydeposts.slice(0, 4)" :key="post.postId">
        <router-link :to="{ name: 'ViewSlydepost', params: { postId: post.postId } }">
          <div class="w-[312px] relative hover:shadow-xl hover:ring-2 hover:ring-blue-500 hover:rounded-xl">
            <div class="flex items-center">
              <img
                :src="post.media[0].presignedUrl"
                class="w-[110px] h-[110px] flex-shrink-0 object-cover object-center rounded-l-xl"
              />
              <div
                class="relative flex-1 h-[110px] flex items-center rounded-r-xl"
                :class="post.metaRating >= 5.0 ? 'bg-[#C4E3ED]' : 'bg-[#FEE2DA]'"
              >
                <div
                  :class="{
                    'bg-[#C4E3ED]': post.metaRating >= 5.0,
                    'bg-[#FEE2DA]': post.metaRating < 5.0,
                  }"
                  class="absolute flex items-center justify-center top-1/2 left-0 transform -translate-y-1/2 -ml-[25px] h-[50px] w-[50px] p-1 rounded-full"
                >
                  <svg class="circular-rating-bar" viewBox="0 0 20 20">
                    <circle
                      class="stroke-white stroke-2"
                      cx="10"
                      cy="10"
                      r="9"
                      :fill="post.metaRating >= 5.0 ? '#C4E3ED' : '#FEE2DA'"
                    />
                    <circle
                      class="stroke-[#012337] stroke-2"
                      cx="10"
                      cy="10"
                      r="9"
                      :fill="post.metaRating >= 5.0 ? '#C4E3ED' : '#FEE2DA'"
                      stroke-dasharray="56.5487"
                      :stroke-dashoffset="getDashOffset(post.metaRating)"
                    />
                    <text
                      x="10"
                      y="10"
                      font-family="Verdana"
                      font-size="7"
                      text-anchor="middle"
                      alignment-baseline="middle"
                      font-weight="bold"
                    >
                      {{ Number.isInteger(post.metaRating) ? post.metaRating : post.metaRating.toFixed(1) }}
                    </text>
                  </svg>
                </div>
                <div class="pl-[25px] flex items-center justify-center">
                  <div class="flex flex-col gap-0">
                    <div class="line-clamp-1">
                      <span class="text-xs font-medium">{{ post.category }}</span>
                      <span v-if="post.subcategory != null && post.subcategory !== ''" class="text-xs font-medium">
                        > {{ post.subcategory }}
                      </span>
                    </div>
                    <p class="font-bold line-clamp-2">{{ post.title }}</p>
                    <p v-if="post.address" class="text-sm line-clamp-1">
                      {{ post.address }}
                    </p>
                    <p v-if="post.username" class="text-sm line-clamp-1">@{{ post.username }}</p>
                    <p v-else class="text-sm line-clamp-1">{{ post.userFirstName }} {{ post.userLastName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <router-link
      @click="setTrendingView"
      :to="{ name: 'Trending' }"
      class="block text-md text-center text-[#012337] pb-4 flex flex-row gap-2 items-center justify-center"
    >
      <p>View Trending Slydeposts</p>
      <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
    </router-link>
  </div>
</template>

<script>
import Loading from '@/components/Utility/Loading.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'TrendingSlydeposts',
  components: {
    // Loading,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    truncated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      slydeposts: 'trendingPostStoreModule/getViewablePosts',
      isLoading: 'trendingPostStoreModule/getIsLoading',
    }),
  },
  methods: {
    setTrendingView() {
      this.$store.commit('trendingStoreModule/setCurrentView', 'slydeposts');
    },
    getDashOffset(rating) {
      const radius = 10;
      const circumference = radius * 2 * Math.PI;
      const offset = circumference - ((rating * 10) / 100) * circumference;
      return offset;
    },
  },
  mounted() {
    this.$store.dispatch('trendingPostStoreModule/loadLast7Days');
  },
  unmounted() {},
};
</script>
