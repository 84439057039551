<template>
  <div class="px-4 pb-2 lg:px-0 pt-4 lg:pt-0">
    <p class="text-2xl pb-4 text-dark-gray">Notifications</p>
    <div class="flex flex-col gap-2">
      <div v-for="notification in notifications" :key="notification.actionId">
        <SlydepostLikeNotification
          v-if="notification.type === 'SLYDEPOST_LIKE'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :post-title="notification.contentText"
          :post-id="notification.contentId"
          :profile-picture-url="notification.presignedUrl"
          :created-at="notification.createdAt"
        />
        <UserFollowRequestNotification
          v-if="notification.type === 'USER_FOLLOW_REQUEST'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :friend-id="notification.userId"
          :action-id="notification.actionId"
          @removeNotification="removeNotification"
          :created-at="notification.createdAt"
        />
        <SlydepostCommentLikeNotification
          v-if="notification.type === 'SLYDEPOST_COMMENT_LIKE'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :post-id="notification.contentId"
          :comment="notification.contentText"
          :created-at="notification.createdAt"
        />
        <SlydepostCommentNotification
          v-if="notification.type === 'SLYDEPOST_COMMENT'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :post-id="notification.contentId"
          :comment="notification.contentText"
          :created-at="notification.createdAt"
        />
        <SlydepostCommentReplyNotification
          v-if="notification.type === 'SLYDEPOST_COMMENT_REPLY'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :post-id="notification.contentId"
          :comment="notification.contentText"
          :created-at="notification.createdAt"
        />
        <UserFollowNotification
          v-if="notification.type === 'USER_FOLLOW'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :created-at="notification.createdAt"
        />
        <ListFollowNotification
          v-if="notification.type === 'LIST_FOLLOW'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :created-at="notification.createdAt"
          :list-title="notification.contentText"
          :list-id="notification.contentId"
        />
        <ListFollowRequestNotification
          v-if="notification.type === 'LIST_FOLLOW_REQUEST'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :created-at="notification.createdAt"
          :list-title="notification.contentText"
          :list-id="notification.contentId"
        />
        <PostAddedToListNotification
          v-if="notification.type === 'LIST_POST'"
          :user-id="notification.friendId"
          :first-name="notification.friendFirstName"
          :last-name="notification.friendLastName"
          :profile-picture-url="notification.presignedUrl"
          :created-at="notification.createdAt"
          :list-title="notification.contentText"
          :list-id="notification.contentId"
        />
      </div>
    </div>
    <InfiniteLoading @infinite="loadMoreNotifications" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserFollowRequestNotification from '@/components/Notifications/UserFollowRequestNotification.vue';
import SlydepostLikeNotification from '@/components/Notifications/SlydepostLikeNotification.vue';
import SlydepostCommentLikeNotification from '@/components/Notifications/SlydepostCommentLikeNotification.vue';
import SlydepostCommentNotification from '@/components/Notifications/SlydepostCommentNotification.vue';
import SlydepostCommentReplyNotification from '@/components/Notifications/SlydepostCommentReplyNotification.vue';
import UserFollowNotification from '@/components/Notifications/UserFollowNotification.vue';
import ListFollowNotification from '@/components/Notifications/ListFollowNotification.vue';
import PostAddedToListNotification from '@/components/Notifications/PostAddedToListNotification.vue';
import ListFollowRequestNotification from '@/components/Notifications/ListFollowRequestNotification.vue';
import InfiniteLoading from 'v3-infinite-loading';

export default {
  name: 'Notifications',
  components: {
    UserFollowNotification,
    SlydepostCommentReplyNotification,
    SlydepostCommentNotification,
    SlydepostCommentLikeNotification,
    UserFollowRequestNotification,
    SlydepostLikeNotification,
    ListFollowNotification,
    PostAddedToListNotification,
    ListFollowRequestNotification,
    InfiniteLoading,
  },
  computed: {
    ...mapGetters({
      notifications: 'notificationStoreModule/getNotifications',
    }),
  },
  mounted() {
    this.$store.dispatch('notificationStoreModule/getNotifications');
  },
  methods: {
    removeNotification(actionId) {
      this.$store.commit('notificationStoreModule/removeNotificationByActionId', actionId);
    },
    loadMoreNotifications() {
      this.$store.dispatch('notificationStoreModule/getNotifications');
    },
  },
};
</script>
