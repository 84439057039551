<template>
  <div v-click-outside="closeCreateDropdown" class="flex justify-center items-center relative z-30 cursor-pointer">
    <div class="relative inline-block" :style="openRight === true ? 'float:left;' : 'float:right;'">
      <div
        @click="toggleCreateDropdown"
        class="flex justify-center items-center rounded-full bg-blue w-full relative h-[2.25rem] z-20"
      >
        <div
          :style="
            this.openCreateDropdown
              ? 'transition: all .2s ease-in-out; -webkit-transform: rotate(45deg);'
              : 'transition: all .2s ease-in-out;'
          "
          class="border border-blue border-opacity-80 w-9 h-9 rounded-full flex bg-white absolute right-0 top-0"
        >
          <svg class="m-auto" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="+"
              opacity="1.0"
              d="M0.240234 4.88223V7.25554H4.87978V11.9091H7.25783V7.25554H11.8974V4.88223H7.25783V0.251953H4.87978V4.88223H0.240234Z"
              fill="#015D79"
            />
          </svg>
        </div>
      </div>
      <div
        :style="
          this.openCreateDropdown
            ? 'transition: all 0.2s ease-in-out 0s, z-index 0s linear 0.01s; visibility: visible; opacity: 1; z-index: 1; transition-delay: 0s, 0s, 0.2s;'
            : 'visibility: hidden; opacity: 0; z-index: -1; transition: all 0.2s ease-in-out 0s, z-index 0s linear 0.01s;'
        "
        class="z-30 rounded-b-xl absolute w-full pt-6 top-[1.25rem] bg-white"
      >
        <div
          class="block absolute right-0 bg-white min-w-max border border-[#AACCD7] rounded-xl"
          :style="openRight === true ? 'left:0;' : ''"
        >
          <div class="block no-underline px-4 py-3 hover:bg-hover">
            <router-link :to="{ name: 'new-slydepost' }">
              <li
                @click="close"
                class="flex flex-row items-start gap-5 text-[10px] font-[700] tracking-[2px] text-blue uppercase border-[#AACCD7]"
              >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    id="+"
                    opacity="0.8"
                    d="M0.240234 4.88223V7.25554H4.87978V11.9091H7.25783V7.25554H11.8974V4.88223H7.25783V0.251953H4.87978V4.88223H0.240234Z"
                    fill="#015D79"
                  />
                </svg>
                <p>New Slydepost</p>
              </li>
            </router-link>
          </div>
          <div class="block no-underline px-4 py-3 hover:bg-hover">
            <li
              @click="openNewListModal"
              class="flex flex-row items-start gap-5 text-[10px] font-[700] tracking-[2px] text-blue uppercase border-[#AACCD7]"
            >
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  id="+"
                  opacity="0.8"
                  d="M0.240234 4.88223V7.25554H4.87978V11.9091H7.25783V7.25554H11.8974V4.88223H7.25783V0.251953H4.87978V4.88223H0.240234Z"
                  fill="#015D79"
                />
              </svg>
              New List
            </li>
          </div>
        </div>
      </div>
    </div>
    <AddListModal ref="addListModal" />
  </div>
</template>

<script>
import AddListModal from '@/components/Modals/AddListModal.vue';
export default {
  name: 'CreateButtonCondensed',
  components: {
    AddListModal,
  },
  props: {
    openRight: Boolean,
  },
  data() {
    return {
      openCreateDropdown: false,
    };
  },
  methods: {
    close() {
      this.$emit('clicked');
      this.openCreateDropdown = false;
    },
    openNewListModal() {
      this.$emit('clicked');
      this.$refs.addListModal.openModal();
      this.openCreateDropdown = false;
    },
    closeCreateDropdown() {
      this.openCreateDropdown = false;
    },
    toggleCreateDropdown() {
      this.openCreateDropdown = !this.openCreateDropdown;
    },
  },
};
</script>

<style>
/*.dropbtn {*/
/*  background-color: #4CAF50;*/
/*  color: white;*/
/*  padding: 16px;*/
/*  font-size: 16px;*/
/*  border: none;*/
/*  cursor: pointer;*/
/*}*/

/*.dropdown {*/
/*  position: relative;*/
/*  display: inline-block;*/
/*}*/

/*.dropdown-content a {*/
/*  color: black;*/
/*  padding: 12px 16px;*/
/*  text-decoration: none;*/
/*  display: block;*/
/*}*/

/*.dropdown-content a:hover {background-color: #f1f1f1;}*/

/*.dropdown:hover .dropdown-content {*/
/*  display: block;*/
/*}*/

/*.dropdown:hover .dropbtn {*/
/*  background-color: #3e8e41;*/
/*}*/
</style>
