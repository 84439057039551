import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const userStoreModule = {
  namespaced: true,
  state: {
    // viewing public profile of another user
    publicFirstName: '',
    publicLastName: '',
    publicProfilePictureUrl: '',
    publicLikedPosts: [],
    totalPublicLikedPosts: 0,
    publicUserProfileSlydeposts: [],
    totalPublicUserProfileSlydeposts: 0,
    publicUserProfileVisibility: '',
    publicUsername: '',
    publicBio: '',
    isInsider: false,
    lists: [],
    totalLists: 0,
    isLoadingMorePosts: false,
    isLoadingMoreLikes: false,
  },
  getters: {
    getIsInsider(state) {
      return state.isInsider;
    },
    getListSize(state) {
      return state.totalLists;
    },
    getLists(state) {
      return state.lists;
    },
    getPublicUserPostsCommentsByPostId: (state) => (id) => {
      if (state.publicUserProfileSlydeposts == null) {
        return null;
      }
      const item = state.publicUserProfileSlydeposts.find(
        (item) => item.id === id
      );
      return item ? item.comments : null;
    },
    getPublicLikedPostsCommentsByPostId: (state) => (id) => {
      if (state.publicLikedPosts == null) {
        return null;
      }
      const item = state.publicLikedPosts.find((item) => item.id === id);
      return item ? item.comments : null;
    },
    getPublicUsername(state) {
      return state.publicUsername;
    },
    getPublicBio(state) {
      return state.publicBio;
    },
    getPublicUserProfileSlydeposts(state) {
      return state.publicUserProfileSlydeposts.map((slydepost) => {
        // Check if there are comments to sort
        if (slydepost.comments) {
          let commentsCopy = [...slydepost.comments];

          // Filter and sort parent comments
          commentsCopy = commentsCopy
            .filter((comment) => !comment.parentId)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

          // Sort child comments for each parent comment
          commentsCopy.forEach((parentComment) => {
            if (parentComment.childComments) {
              parentComment.childComments.sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
              );
            }
          });

          // Return a new object to ensure the state is not mutated
          return {
            ...slydepost,
            comments: commentsCopy,
          };
        }

        // Return the post as is if there are no comments to sort
        return slydepost;
      });
    },
    getPublicProfileFirstName(state) {
      return state.publicFirstName;
    },
    getPublicProfileLastName(state) {
      return state.publicLastName;
    },
    getPublicProfileProfilePictureUrl(state) {
      return state.publicProfilePictureUrl != null
        ? state.publicProfilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
    getPublicLikedPosts(state) {
      return state.publicLikedPosts.map((slydepost) => {
        // Check if there are comments to sort
        if (slydepost.comments) {
          let commentsCopy = [...slydepost.comments];

          // Filter and sort parent comments
          commentsCopy = commentsCopy
            .filter((comment) => !comment.parentId)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

          // Sort child comments for each parent comment
          commentsCopy.forEach((parentComment) => {
            if (parentComment.childComments) {
              parentComment.childComments.sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
              );
            }
          });

          // Return a new object to ensure the state is not mutated
          return {
            ...slydepost,
            comments: commentsCopy,
          };
        }

        // Return the post as is if there are no comments to sort
        return slydepost;
      });
    },
    getTotalPublicLikedPosts(state) {
      return state.totalPublicLikedPosts;
    },
    getTotalPublicPosts(state) {
      return state.totalPublicUserProfileSlydeposts;
    },
    getPublicUserProfileVisibility(state) {
      return state.publicUserProfileVisibility;
    },
    getIsLoadingMorePosts(state) {
      return state.isLoadingMorePosts;
    },
    getIsLoadingMoreLikes(state) {
      return state.isLoadingMoreLikes;
    },
  },
  mutations: {
    setIsInsider(state, isInsider) {
      state.isInsider = isInsider;
    },
    setListSize(state, size) {
      state.totalLists = size;
    },
    addListToLists(state, list) {
      state.lists.push(list);
    },
    deleteSlydepost(state, postId) {
      state.publicUserProfileSlydeposts =
        state.publicUserProfileSlydeposts.filter((obj) => obj.id !== postId);
      state.totalPublicUserProfileSlydeposts =
        state.totalPublicUserProfileSlydeposts - 1;
    },
    deleteLikedSlydepost(state, postId) {
      state.publicLikedPosts = state.publicLikedPosts.filter(
        (obj) => obj.id !== postId
      );
      state.totalPublicLikedPosts = state.totalPublicLikedPosts - 1;
    },
    removeLikeFromSlydepostFromUsersLikedPost(state, payload) {
      const postId = payload.postId;
      const likeId = payload.id;
      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      const objWithIdIndex = slydepost.likes.findIndex(
        (obj) => obj.id === likeId
      );
      if (objWithIdIndex > -1) {
        slydepost.likes.splice(objWithIdIndex, 1);
      }
    },
    removeLikeFromSlydepostFromUsersPost(state, payload) {
      const postId = payload.postId;
      const likeId = payload.id;
      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      const objWithIdIndex = slydepost.likes.findIndex(
        (obj) => obj.id === likeId
      );
      if (objWithIdIndex > -1) {
        slydepost.likes.splice(objWithIdIndex, 1);
      }
    },
    likeSlydepostFromUsersLikedPost(state, payload) {
      const postId = payload.postId;
      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      if (slydepost.likes == null) {
        slydepost.likes = [];
      }
      slydepost.likes.push(payload);
    },
    likeSlydepostFromUsersPost(state, payload) {
      const postId = payload.postId;
      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      if (slydepost.likes == null) {
        slydepost.likes = [];
      }
      slydepost.likes.push(payload);
    },
    removeLikeReplyFromUsersLikedPost(state, payload) {
      const likeId = payload.id;
      const parentId = payload.parentId;
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find(
        (item) => item.id === commentId
      );
      const objWithIdIndex = childComment.likes.findIndex(
        (obj) => obj.id === likeId
      );
      if (objWithIdIndex > -1) {
        childComment.likes.splice(objWithIdIndex, 1);
      }
    },
    removeLikeReplyFromUsersPost(state, payload) {
      const likeId = payload.id;
      const parentId = payload.parentId;
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find(
        (item) => item.id === commentId
      );
      const objWithIdIndex = childComment.likes.findIndex(
        (obj) => obj.id === likeId
      );
      if (objWithIdIndex > -1) {
        childComment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeReplyFromUsersLikedPost(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;
      const likeId = payload.id;
      const userId = payload.userId;
      const likePayload = {
        id: likeId,
        commentId: commentId,
        userId: userId,
      };
      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find(
        (item) => item.id === commentId
      );
      if (childComment.likes == null) {
        childComment.likes = [];
      }
      childComment.likes.push(likePayload);
    },
    likeReplyFromUsersPost(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;
      const likeId = payload.id;
      const userId = payload.userId;
      const likePayload = {
        id: likeId,
        commentId: commentId,
        userId: userId,
      };
      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const childComment = comment.childComments.find(
        (item) => item.id === commentId
      );
      if (childComment.likes == null) {
        childComment.likes = [];
      }
      childComment.likes.push(likePayload);
    },
    removeReplyFromCommentFromUsersLikedPost(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;

      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const objWithIdIndex = comment.childComments.findIndex(
        (obj) => obj.id === commentId
      );
      if (objWithIdIndex > -1) {
        comment.childComments.splice(objWithIdIndex, 1);
      }
    },
    removeReplyFromCommentFromUsersPost(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;

      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === parentId);
      const objWithIdIndex = comment.childComments.findIndex(
        (obj) => obj.id === commentId
      );
      if (objWithIdIndex > -1) {
        comment.childComments.splice(objWithIdIndex, 1);
      }
    },
    replyToCommentFromUsersLikedPost(state, payload) {
      const postId = payload.postId;
      const parentCommentId = payload.parentId;

      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find(
        (item) => item.id === parentCommentId
      );
      if (comment.childComments == null) {
        comment.childComments = [];
      }
      comment.childComments.push(payload);
    },
    replyToCommentFromUsersPost(state, payload) {
      const postId = payload.postId;
      const parentCommentId = payload.parentId;

      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find(
        (item) => item.id === parentCommentId
      );
      if (comment.childComments == null) {
        comment.childComments = [];
      }
      comment.childComments.push(payload);
    },
    removeLikeCommentFromUsersLikedPost(state, payload) {
      const likeId = payload.likeId;
      const commentId = payload.commentId;
      const postId = payload.postId;

      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === commentId);
      const objWithIdIndex = comment.likes.findIndex(
        (obj) => obj.id === likeId
      );
      if (objWithIdIndex > -1) {
        comment.likes.splice(objWithIdIndex, 1);
      }
    },
    removeLikeCommentFromUsersPost(state, payload) {
      const likeId = payload.likeId;
      const commentId = payload.commentId;
      const postId = payload.postId;

      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      const comment = slydepost.comments.find((item) => item.id === commentId);
      const objWithIdIndex = comment.likes.findIndex(
        (obj) => obj.id === likeId
      );
      if (objWithIdIndex > -1) {
        comment.likes.splice(objWithIdIndex, 1);
      }
    },
    likeCommentFromUsersLikedPost(state, payload) {
      const postId = payload.postId;
      const commentId = payload.commentId;

      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );

      if (slydepost == null || slydepost.comments == null) {
        return;
      }

      const comment = slydepost.comments.find((item) => item.id === commentId);
      if (comment.likes == null) {
        comment.likes = [];
      }
      comment.likes.push(payload);
    },
    likeCommentFromUsersPost(state, payload) {
      const postId = payload.postId;
      const commentId = payload.commentId;

      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );

      if (slydepost == null || slydepost.comments == null) {
        return;
      }

      const comment = slydepost.comments.find((item) => item.id === commentId);
      if (comment.likes == null) {
        comment.likes = [];
      }
      comment.likes.push(payload);
    },
    removeCommentFromUsersPost(state, payload) {
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      const objWithIdIndex = slydepost.comments.findIndex(
        (obj) => obj.id === commentId
      );
      if (objWithIdIndex > -1) {
        slydepost.comments.splice(objWithIdIndex, 1);
      }
    },
    addCommentToUsersPost(state, payload) {
      const comment = payload.comment;
      const postId = payload.postId;
      const slydepost = state.publicUserProfileSlydeposts.find(
        (item) => item.id === postId
      );
      if (slydepost.comments == null) {
        slydepost.comments = [];
      }
      slydepost.comments.push(comment);
    },
    removeCommentFromUsersLikedPost(state, payload) {
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      const objWithIdIndex = slydepost.comments.findIndex(
        (obj) => obj.id === commentId
      );
      if (objWithIdIndex > -1) {
        slydepost.comments.splice(objWithIdIndex, 1);
      }
    },
    addCommentToUsersLikedPost(state, payload) {
      const comment = payload.comment;
      const postId = payload.postId;
      const slydepost = state.publicLikedPosts.find(
        (item) => item.id === postId
      );
      if (slydepost.comments == null) {
        slydepost.comments = [];
      }
      slydepost.comments.push(comment);
    },
    setPublicUsername(state, username) {
      state.publicUsername = username;
    },
    setPublicBio(state, bio) {
      state.publicBio = bio;
    },
    setPublicProfileFirstName(state, firstName) {
      state.publicFirstName = firstName;
    },
    setPublicProfileLastName(state, lastName) {
      state.publicLastName = lastName;
    },
    setPublicProfilePictureUrl(state, url) {
      state.publicProfilePictureUrl = url;
    },
    navigateAwayFromUserProfile(state) {
      state.publicFirstName = '';
      state.publicLastName = '';
      state.publicProfilePictureUrl = '';
      state.publicLikedPosts = [];
      state.totalPublicLikedPosts = 0;
      state.publicUserProfileSlydeposts = [];
      state.totalPublicUserProfileSlydeposts = 0;
      state.lists = [];
      state.totalLists = 0;
      state.publicUserProfileVisibility = '';
      state.publicUsername = '';
      state.publicBio = '';
      state.isInsider = false;
      state.isLoadingMorePosts = false;
      state.isLoadingMoreLikes = false;
    },
    addToPublicLikedPosts(state, payload) {
      if (state.publicLikedPosts == null) {
        state.publicLikedPosts = [];
      }
      state.publicLikedPosts.push(payload);
    },
    addToPublicUserProfileSlydeposts(state, payload) {
      if (state.publicUserProfileSlydeposts == null) {
        state.publicUserProfileSlydeposts = [];
      }
      state.publicUserProfileSlydeposts.push(payload);
    },
    setTotalPostsLikedByPublicUser(state, total) {
      state.totalPublicLikedPosts = total;
    },
    setTotalPostsByPublicUser(state, total) {
      state.totalPublicUserProfileSlydeposts = total;
    },
    setPublicUserProfileVisibility(state, visibility) {
      state.publicUserProfileVisibility = visibility;
    },
    setIsLoadingMorePosts(state, loading) {
      state.isLoadingMorePosts = loading;
    },
    setIsLoadingMoreLikes(state, loading) {
      state.isLoadingMoreLikes = loading;
    },
  },
  actions: {
    loadMorePublicUserProfileSlydeposts(
      { dispatch, commit, getters, rootGetters },
      payload
    ) {
      if (getters['getIsLoadingMorePosts'] === true) {
        return;
      }
      commit('setIsLoadingMorePosts', true);
      const userId = payload.userId;
      const index = payload.index;
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(
          `/post/profile/${userId}?index=${index}&loggedInUserId=${loggedInUserId}`,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
            params: { index: index },
          }
        )
        .then((response) => {
          console.log(`public posts`, response.data);
          if (response.data.posts != null) {
            for (let i = 0; i < response.data.posts.length; i++) {
              commit(
                'addToPublicUserProfileSlydeposts',
                response.data.posts[i]
              );
            }
            commit('setTotalPostsByPublicUser', response.data.total);
          }
          commit('setIsLoadingMorePosts', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
          commit('setIsLoadingMorePosts', false);
        });
    },
    getPublicUserProfile({ dispatch, commit, getters, rootGetters }, userId) {
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(`/user/${userId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          console.log(`user response`, response.data);
          commit('setPublicProfileFirstName', response.data.firstName);
          commit('setPublicProfileLastName', response.data.lastName);
          commit('setPublicProfilePictureUrl', response.data.presignedUrl);
          commit('setPublicUserProfileVisibility', response.data.visibility);
          commit('setPublicUsername', response.data.username);
          commit('setPublicBio', response.data.bio);
          commit('setIsInsider', response.data.isInsider);
        })
        .catch((error) => {
          console.error('Unable to retrieve user info', error);
        });
    },
    loadMorePostsLikedByUser(
      { dispatch, commit, getters, rootGetters },
      payload
    ) {
      if (getters.getIsLoadingMoreLikes === true) {
        return;
      }
      commit('setIsLoadingMoreLikes', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const userId = payload.userId;
      const index = payload.index;
      client
        .get(
          `/post/liked/${userId}?index=${index}&loggedInUserId=${loggedInUserId}`,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
            params: { index: index },
          }
        )
        .then((response) => {
          console.log(`liked posts response`, response.data);
          if (response.data.likes != null) {
            for (let i = 0; i < response.data.likes.length; i++) {
              commit('addToPublicLikedPosts', response.data.likes[i]);
            }
            commit('setTotalPostsLikedByPublicUser', response.data.total);
          }
          commit('setIsLoadingMoreLikes', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
          commit('setIsLoadingMoreLikes', false);
        });
    },
    async getUsersLists({ dispatch, commit, getters, rootGetters }, userId) {
      // commit('setLoadingMyLists', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      await client
        .get(
          `/post/list/user/${userId}?startingIndex=${getters.getLists.length}`,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
          }
        )
        .then((response) => {
          console.log(`users lists`, response.data);
          if (response.data.lists != null) {
            for (let i = 0; i < response.data.lists.length; i++) {
              commit('addListToLists', response.data.lists[i]);
            }
            commit('setListSize', response.data.total);
          }
          // commit('setLoadingMyLists', false);
        })
        .catch((error) => {});
    },
  },
};

// export the entire module
export { userStoreModule };
