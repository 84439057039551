<template>
  <div class="md:w-52 bg-white md:border md:border-solid md:border-light-light-gray md:rounded-t-3xl">
    <AreYouSureModal
      :showModal="this.showUnblockModal"
      :message="'Are you sure you want to unblock this user?'"
      @accept="this.unblockUser"
      @cancel="this.showUnblockModal = false"
    />
    <AreYouSureModal
      :showModal="this.showReportUserModal"
      :message="'Are you sure you want to report this user?'"
      @accept="this.reportUser"
      @cancel="this.showReportUserModal = false"
    />
    <div class="md:w-52 px-5 py-2 md:px-0 md:py-0 rounded-t-3xl flex flex-row md:flex-col items-center gap-2 md:gap-0">
      <img class="h-10 w-10 md:h-52 md:w-52 rounded-xl md:rounded-b-none md:rounded-t-3xl" :src="getProfilePicture" />
      <div class="flex flex-row justify-between md:pt-3 w-full items-start md:px-5 md:py-2">
        <div class="flex flex-col gap-1 items-start">
          <p class="text-sm capitalize">{{ this.firstName }} {{ this.lastName }}</p>
          <p class="text-xs font-bold">{{ numberOfSlydeposts }} Slydeposts</p>
        </div>
        <div class="flex md:flex-col flex-row gap-4 md:gap-0">
          <router-link :to="{ name: 'UserProfile', params: { userId: this.friendId } }">
            <div class="text-sm text-right text-moonstone font-bold">
              <p>View</p>
              <p>Profile</p>
            </div>
          </router-link>
          <div class="flex flex-row items-start py-1">
            <MoreOptionsButton
              class="text-left"
              v-click-outside="closeOptions"
              @click="this.showOptions = !this.showOptions"
              @reportUser="this.showReportUserModal = true"
              @unblockUser="this.showUnblockModal = true"
              :options="this.options"
              :showOptions="this.showOptions"
              :icon-color="'black'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';

export default {
  name: 'Blocked',
  components: {
    AreYouSureModal,
    MoreOptionsButton,
  },
  props: {
    firstName: {
      type: String,
      required: true,
      default: '',
    },
    lastName: {
      type: String,
      required: true,
      default: '',
    },
    numberOfSlydeposts: {
      type: Number,
      required: true,
      note: 'the number of slydeposts the user has created',
    },
    relationshipId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
    },
    friendId: {
      type: String,
    },
    profilePictureUrl: {
      type: String,
    },
  },
  data() {
    return {
      showOptions: false,
      options: [
        {
          text: 'Unblock',
          onClickEmit: 'unblockUser',
        },
        {
          text: 'Report',
          onClickEmit: 'reportUser',
        },
      ],
      showUnblockModal: false,
      showReportUserModal: false,
    };
  },
  methods: {
    closeOptions() {
      this.showOptions = false;
    },
    unblockUser() {
      this.$store.dispatch('relationshipStoreModule/unblockUser', this.relationshipId);
      this.showUnblockModal = false;
    },
    reportUser() {
      this.showReportUserModal = false;
    },
  },
  computed: {
    getProfilePicture() {
      return this.profilePictureUrl != null
        ? this.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
  },
};
</script>
