<template>
  <div
    :style="{ backgroundImage: `url(${coverPhoto})` }"
    :class="
      gridView === 'grid'
        ? 'w-full h-[177px] bg-no-repeat bg-cover bg-center rounded-2xl overflow-hidden'
        : 'w-full h-[177px] bg-no-repeat bg-cover bg-center rounded-2xl overflow-hidden'
    "
    @mouseenter="this.showEditIconOnhover = true"
    @mouseleave="this.showEditIconOnhover = false"
  >
    <div
      @click="$refs.addListModal.openModal()"
      v-if="addPost === true"
      class="w-full h-full bg-[#D9D9D9] flex flex-col justify-center cursor-pointer"
    >
      <div class="border-[1.6px] border-black w-[35px] h-[35px] rounded-full flex mx-auto mb-5">
        <img class="m-auto w-3 h-3" src="@/assets/plus.svg" />
      </div>
      <div class="uppercase font-[700] text-[14px] leading-[16px] text-center">create new list</div>
    </div>
    <div v-else :style="backgroundGradient()" class="w-full h-full px-5 flex flex-col items-center justify-end">
      <div
        :class="gridView === 'grid' ? 'text-white text-center' : 'text-white text-center flex items-center mb-5 px-3'"
      >
        <div :class="gridView === 'grid' ? '' : 'text-left w-[80%]'">
          <p class="uppercase text-[14px] font-[700] tracking-[2px] leading-[16px]">
            {{ title }}
          </p>
          <p class="uppercase text-[12px] font-[900] tracking-[2px]">{{ totalNumberOfPosts }} posts</p>
        </div>
        <div :class="gridView === 'grid' ? '' : 'text-right w-[20%]'">
          <div v-if="isFollowedByLoggedInUser">
            <div class="m-auto h-[38px] w-[38px] border-2 border-teal rounded-[14.5px] bg-black overflow-hidden">
              <img :src="profileUrl" alt="" />
            </div>
            <div class="text-xs leading-5 pb-5">{{ userName }}</div>
          </div>
          <div v-else>
            <div
              :class="
                gridView === 'grid'
                  ? 'py-3 flex flex-row justify-center items-center'
                  : 'py-3 flex flex-row justify-end items-center'
              "
            >
              <div class="mr-3">
                <img src="@/assets/love.png" alt="" />
              </div>
              <p class="text-[14px] font-[700]">{{ totalNumberOfFollows }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddListModal ref="addListModal" />
  </div>
</template>

<script>
import store from '@/store/store';
import { mapGetters } from 'vuex';
import AddListModal from '@/components/Modals/AddListModal.vue';
import slydepostLogo from '@/assets/no-photo-background-square.png';

export default {
  name: 'ListCard',
  props: {
    addPost: {
      type: Boolean,
      required: false,
      default: false,
      note: 'To add new post',
    },
    listBackgroundImage: {
      type: String,
      required: false,
    },
    title: String,
    totalPosts: {
      type: Number,
      default: 0,
    },
    isFollowedByLoggedInUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    profileUrl: {
      type: String,
      required: false,
    },
    userName: {
      type: String,
      required: false,
      default: '',
    },
    showEditIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    gridView: {
      type: String,
      required: '',
      default: 'grid',
    },
    totalFollows: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showEditIconOnhover: false,
    };
  },
  components: { AddListModal },
  computed: {
    ...mapGetters({
      firstName: 'getFirstName',
      lastName: 'getLastName',
      username: 'getUsername',
    }),
    totalNumberOfPosts() {
      return this.totalPosts == null ? 0 : this.totalPosts;
    },
    totalNumberOfFollows() {
      return this.totalFollows === null ? 0 : this.totalFollows;
    },
    coverPhoto() {
      return this.listBackgroundImage != null || this.listBackgroundImage === ''
        ? this.listBackgroundImage
        : slydepostLogo;
    },
  },

  methods: {
    backgroundGradient() {
      return 'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))';
    },
  },
  mounted() {},
  unmounted() {},
};
</script>
