<template>
  <div>
    Slydepost Terms of Service
    <br />
    Last updated 06/22/2023
    <br />
    <br />
    1. Your Relationship with US
    <br />
    <br />
    Welcome to Slydepost (the “Platform”), which is provided by Slydepost LLC, a Washington limited liability company in
    the United States (collectively such entities will be referred to as “Slydepost”, “we” or “us”).
    <br />
    <br />
    These terms of service (the “Terms”), which govern the relationship and serve as an agreement between you and us and
    sets forth the terms and conditions by which you may access and use the Platform and our related websites, services,
    applications, products and content (collectively, the “Services”). Access to certain Services or features of the
    Services (such as, by way of example and not limitation, the ability to submit or share User Content (defined
    below)) may be subject to age restrictions and not available to all users of the Services. Our Services are provided
    for private, non-commercial use. For purposes of these Terms, “you” and “your” means you as the user of the
    Services.
    <br />
    <br />
    Please take the time to read them carefully. The Terms form a legally binding agreement between you and us. By using
    the Services or by clicking to accept or agree to our Terms of Use when this option is made available to you, you
    accept and agree to be bound and abide by these Terms and to our Privacy Policy, found at [Privacy Policy Link],
    incorporated herein by reference If you do not agree to these Terms or to the Privacy Policy, you must not access or
    use the Services.
    <br />
    <br />
    The Services are offered and available to users who are 18 years of age or older. If you are under age 18, you may
    only use the Services with the consent of your parent or legal guardian. Please be sure your parent or legal
    guardian has reviewed and discussed these Terms and the Privacy Policy with you. By using the Services, you
    represent and warrant that you or your parent/legal guardian is competent to enter into and abide by these terms. If
    you or your parent/legal guardian do not meet these requirements, you must not access or use the Services.
    <br />
    <br />
    ARBITRATION NOTICE FOR USERS IN THE UNITED STATES: THESE TERMS CONTAIN AN ARBITRATION CLAUSE AND A WAIVER OF RIGHTS
    TO BRING A CLASS ACTION AGAINST US. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION CLAUSE, YOU
    AND SLYDEPOST AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND
    SLYDEPOST WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
    <br />
    <br />
    2. Accepting the Terms
    <br />
    <br />
    Slydepost is open to users over the age of 18 only.
    <br />
    <br />
    If you are under 18, you are not allowed to create a User Account and use the Application.
    <br />
    <br />
    By accessing or using our Services, you confirm that you can form a binding contract with Slydepost, that you accept
    these Terms and that you agree to comply with them. Your access to and use of our Services is also subject to our
    Privacy Policy and Community Guidelines, the terms of which can be found directly on the Platform. By using the
    Services, you consent to the terms of the Privacy policy. When creating your User Account, the information provided
    must correspond to your own identity and must not be false or erroneous.
    <br />
    <br />
    If you are accessing or using the Services on behalf of a business or entity, then (a) “you” and “your” includes you
    and that business or entity, (b) you represent and warrant that you are an authorized representative of the business
    or entity with the authority to bind the entity to these Terms, and that you agree to these Terms on the entity’s
    behalf, and (c) your business or entity is legally and financially responsible for your access or use of the
    Services as well as for the access or use of your account by others affiliated with your entity, including any
    employees, agents or contractors.
    <br />
    <br />
    You can accept the Terms by accessing or using our Services. You understand and agree that we will treat your access
    or use of the Services as acceptance of the Terms from that point onwards.
    <br />
    <br />
    3. Changes to the Terms
    <br />
    <br />
    We may revise, update, or amend these Terms from time to time in our sole discretion. For instance when we update
    the functionality of our Services, when we combine multiple apps or services operated by us or our affiliates into a
    single combined service or app, or when there are regulatory changes. We will use commercially reasonable efforts to
    generally notify all users of any material changes to these Terms, such as through a notice on our Platform,
    however, you should look at the Terms regularly to check for such changes. We will also update the “Last Updated”
    date at the top of these Terms, which reflect the effective date of such Terms. All changes are effective
    immediately when we post them and apply to all access to and use of the Services thereafter. Your continued access
    or use of the Services after the date of the new Terms constitutes your acceptance of the new Terms. If you do not
    agree to the new Terms, you must stop accessing or using the Services.
    <br />
    <br />
    4. Your Account with Slydepost
    <br />
    <br />
    To access or use some of our Services, you must create an account with us. When you create this account, you must
    provide accurate and up-to-date information. It is important that you maintain and promptly update your details and
    any other information you provide to us, to keep such information current and complete. You agree that all
    information you provide as part of the Services is governed by our Privacy Policy, and you consent to all actions we
    take with respect to your information consistent with our Privacy Policy.
    <br />
    <br />
    It is important that you keep your account password, and other log-in security information as confidential and you
    must not disclose it to any third party. You acknowledge that your account is personal to you and agree not to
    provide any other person with access to the Services or portions of it using your user name, password or other
    security information. If you know or suspect that any third party knows your password or has accessed your account,
    you must notify us immediately at: info@slydepost.com.You also agree to ensure that you exit from your account at
    the end of each session. You should use particular caution when accessing your account from a public or shared
    computer so that others are not able to view or record your password or other personal information.
    <br />
    <br />
    You agree that you are solely responsible (to us and to others) for (i) making all arrangements necessary for you to
    have access to the Services; (ii) ensuring that all persons who access or use the Services through your internet
    connection are aware of these Terms and comply with them; (iii) all activity that occurs under your account, whether
    or not they are taken by you, those under your care, or by third parties.
    <br />
    <br />
    We reserve the right to limit full access to the Services or to disable your user account at any time, including if
    you have failed to comply with any of the provisions of these Terms, or if activities occur on your account which,
    in our sole discretion, would or might cause damage to or impair the Services or infringe or violate any third party
    rights, or violate any applicable laws or regulations.
    <br />
    <br />
    If you no longer want to use our Services again, and would like your account deleted, contact us at:
    info@slydepost.com. We will provide you with further assistance and guide you through the process. Once you choose
    to delete your account, you will not be able to reactivate your account or retrieve any of the content or
    information you have added.
    <br />
    <br />
    5. Access and Use of Services
    <br />
    <br />
    Your access to and use of the Services is subject to these Terms and all applicable laws and regulations. You may
    not:
    <br />
    <ul class="list-disc list-outside p-4">
      <li>
        access or use the Services if you are not fully able and legally competent to agree to these Terms or are
        authorized to use the Services by your parent or legal guardian;
      </li>
      <li>
        In any way that violates any applicable federal, state, local, or international law or regulation (including,
        without limitation, any laws regarding the export of data or software to and from the US or other countries);
      </li>
      <li>
        To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as
        determined by us, may harm the Company or users of the Website, or expose them to liability; make unauthorized
        copies, modify, adapt, translate, reverse engineer, disassemble, decompile or create any derivative works of the
        Services or any content included therein, including any files, tables or documentation (or any portion thereof)
        or determine or attempt to determine any source code, algorithms, methods or techniques embodied by the Services
        or any derivative works thereof;
      </li>
      <li>
        distribute, license, transfer, or sell, in whole or in part, any of the Services or make, distribute, transfer
        or sell any derivative works thereof, market, rent or lease the Services for a fee or charge, or use the
        Services to advertise or perform any commercial solicitation;
      </li>
      <li>
        use the Services, without our express written consent, for any commercial or unauthorized purpose, including
        communicating or facilitating any commercial advertisement or solicitation or spamming;
      </li>
      <li>
        interfere with or attempt to interfere with the proper working of the Services, disrupt our website or any
        networks connected to the Services, or bypass any measures we may use to prevent or restrict access to the
        Services;
      </li>
      <li>
        incorporate the Services or any portion thereof into any other program or product. In such case, we reserve the
        right to refuse service, terminate accounts or limit access to the Services in our sole discretion;
      </li>
      <li>use automated scripts to collect information from or otherwise interact with the Services;</li>
      <li>
        impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with any
        person or entity, including giving the impression that any content you upload, post, transmit, distribute or
        otherwise make available emanates from the Services;
      </li>
      <li>
        intimidate or harass another, or promote sexually explicit material, violence or discrimination based on race,
        sex, religion, nationality, disability, sexual orientation or age;
      </li>
      <li>
        For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to
        inappropriate content, asking for personally identifiable information, or otherwise.
      </li>
      <li>
        use or attempt to use another’s account, service or system without authorization from Slydepost, or create a
        false identity on the Services;
      </li>
      <li>
        use the Services in a manner that may create a conflict of interest or undermine the purposes of the Services,
        such as trading reviews with other users or writing or soliciting fake reviews;
      </li>
      <li>
        use the Services to upload, transmit, distribute, store or otherwise make available in any way: files that
        contain viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful;
      </li>
      <li>
        send any unsolicited or unauthorized advertising, solicitations, promotional materials, “junk mail,” “spam,”
        “chain letters,” “pyramid schemes,” or any other prohibited form of solicitation;
      </li>
      <li>
        disclose any private information of any third party, including addresses, phone numbers, email addresses, number
        and feature in the personal identity document (e.g., National Insurance numbers, passport numbers) or credit
        card numbers;
      </li>
      <li>
        post or upload any material which does or may infringe any copyright, trademark or other intellectual property
        or privacy rights of any other person;
      </li>
      <li>
        post or upload any material which is defamatory of any person, obscene, offensive, pornographic, hateful or
        inflammatory;
      </li>
      <li>
        post or upload any material that would constitute, encourage or provide instructions for a criminal offense,
        dangerous activities or self-harm;
      </li>
      <li>
        post or upload any material that is deliberately designed to provoke or antagonize people, especially trolling
        and bullying, or is intended to harass, harm, hurt, scare, distress, embarrass or upset people;
      </li>
      <li>post or upload any material that contains a threat of any kind, including threats of physical violence;</li>
      <li>
        post or upload any material that is racist or discriminatory, including discrimination on the basis of someone’s
        race, religion, age, gender, disability or sexuality;
      </li>
      <li>
        post or upload any answers, responses, comments, opinions, analysis or recommendations that you are not properly
        licensed or otherwise qualified to provide; or
      </li>
      <li>
        post or upload material that, in the sole judgment of Slydepost, is objectionable or which restricts or inhibits
        any other person from using the Services, or which may expose Slydepost, the Services or its users to any harm
        or liability of any type.
      </li>
    </ul>
    <br />
    <br />
    In addition to the above, your access to and use of the Services must, at all times, be compliant with our Slydepost
    Community Guidelines.
    <br />
    <br />
    We reserve the right, at any time and without prior notice, to remove or disable access to content at our discretion
    for any reason or no reason. Some of the reasons we may remove or disable access to content may include finding the
    content objectionable, in violation of these Terms or our Community Policy, or otherwise harmful to the Services or
    our users.
    <br />
    <br />
    6. Copyright
    <br />
    <br />
    We respect intellectual property rights and ask you to do the same. As a condition of your access to and use of the
    Services, you agree to the terms of the Slydepost Copyright Guidelines.
    <br />
    <br />
    7. Services Description
    <br />
    <br />
    Slydepost Services allow you to create, publish, and share Slydepost Content with other users on the Platform.
    <br />
    <br />
    8. Trademarks
    <br />
    <br />
    The Company name, logo, and all related names, logos, product and service names, designs, and slogans are trademarks
    of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of
    the Company. All other names, logos, product and service names, designs, and slogans on this Website are the
    trademarks of their respective owners.
    <br />
    <br />
    9. Other Intellectual Property
    <br />
    <br />
    Slydepost Content
    <ul class="list-disc list-outside p-4">
      <li>
        As between you and Slydepost, all content, software, images, text, graphics, illustrations, logos, patents,
        trademarks, service marks, copyrights, photographs, audio, videos, music on and “look and feel” of the Services,
        and all intellectual property rights related thereto (the “Slydepost Content”), are either owned or licensed by
        Slydepost, it being understood that you or your licensors will own any User Content (as defined below) you
        upload or transmit through the Services. Use of the Slydepost Content or materials on the Services for any
        purpose not expressly permitted by these Terms is strictly prohibited. Such content may not be downloaded,
        copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed or otherwise exploited for
        any purpose whatsoever without our or, where applicable, our licensors’ prior written consent. We and our
        licensors reserve all rights not expressly granted in and to their content.
      </li>
      <li>
        You acknowledge and agree that we may generate revenues, increase goodwill or otherwise increase our value from
        your use of the Services, including, by way of example and not limitation, through the sale of advertising,
        sponsorships, promotions, usage data and Gifts (defined below), and except as specifically permitted by us in
        these Terms or in another agreement you enter into with us, you will have no right to share in any such revenue,
        goodwill or value whatsoever. You further acknowledge that, except as specifically permitted by us in these
        Terms or in another agreement you enter into with us, you (i) have no right to receive any income or other
        consideration from any User Content (defined below) or your use of any musical works, sound recordings or
        audiovisual clips made available to you on or through the Services, including in any User Content created by
        you, and (ii) are prohibited from exercising any rights to monetize or obtain consideration from any User
        Content within the Services or on any third party service ( e.g. , you cannot claim User Content that has been
        uploaded to a social media platform such as YouTube for monetization).
      </li>
      <li>
        Subject to the terms and conditions of the Terms, you are hereby granted a non-exclusive, limited,
        non-transferable, non-sublicensable, revocable, worldwide license to access and use the Services, and to access
        the Slydepost Content solely for your personal, non-commercial use through your use of the Services and solely
        in compliance with these Terms. Slydepost reserves all rights not expressly granted herein in the Services and
        the Slydepost Content. You acknowledge and agree that Slydepost may terminate this license at any time for any
        reason or no reason.
      </li>
      <li>
        You acknowledge and agree that when you view content provided by others on the Services, you are doing so at
        your own risk. The content on our Services is provided for general information only. It is not intended to
        amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or
        refraining from, any action on the basis of the content on our Services.
      </li>
      <li>
        We make no representations, warranties or guarantees, whether expressed or implied, that any Slydepost Content
        (including User Content) is accurate, complete or up to date. Where our Services contain links to other sites
        and resources provided by third parties, these links are provided for your information only. We have no control
        over the contents of those sites or resources. Such links should not be interpreted as approval by us of those
        linked websites or information you may obtain from them. You acknowledge that we have no obligation to
        pre-screen, monitor, review, or edit any content posted by you and other users on the Services (including User
        Content).
      </li>
    </ul>
    User-Generated Content
    <ul class="list-disc list-outside p-4">
      <li>
        Users of the Services may be permitted to upload, post or transmit (such as via a Slydepost) or otherwise make
        available content through the Services including, without limitation, any text, photographs, user videos, sound
        recordings and the musical works embodied therein, including videos that incorporate locally stored sound
        recordings from your personal music library and ambient noise (“User Content”). The information and materials in
        the User Content, including User Content that includes Slydepost Elements, have not been verified or approved by
        us. The views expressed by other users on the Services do not represent our views or values. Whenever you access
        or use a feature that allows you to upload or transmit User Content through the Services (including via certain
        third party social media platforms such as Instagram, Facebook, YouTube, Twitter), or to make contact with other
        users of the Services, you must comply with the standards set out at “Your Access to and Use of Our Services”
        above. You may also choose to upload or transmit your User Content, including User Content that includes
        Slydepost Elements, on sites or platforms hosted by third parties. If you decide to do this, you must comply
        with their content guidelines as well as with the standards set out at “Your Access to and Use of Our Services”
        above. As noted above, these features may not be available to all users of the Services, and we have no
        liability to you for limiting your right to certain features of the Services.
      </li>
      <li>
        You warrant that any such contribution does comply with those standards, and you will be liable to us and
        indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we
        suffer as a result of your breach of warranty.
      </li>
      <li>
        Any User Content will be considered non-confidential and non-proprietary. You must not post any User Content on
        or through the Services or transmit to us any User Content that you consider to be confidential or proprietary.
        When you submit User Content through the Services, you agree and represent that you own that User Content, or
        you have received all necessary permissions, clearances from, or are authorized by, the owner of any part of the
        content to submit it to the Services, to transmit it from the Services to other third party platforms, and/or
        adopt any third party content.
      </li>
      <li>
        If you only own the rights in and to a sound recording, but not to the underlying musical works embodied in such
        sound recordings, then you must not post such sound recordings to the Services unless you have all permissions,
        clearances from, or are authorized by, the owner of any part of the content to submit it to the Services
      </li>
      <li>
        You or the owner of your User Content still own the copyright in User Content sent to us, but by submitting User
        Content via the Services, you hereby grant us an unconditional, irrevocable, non-exclusive, royalty-free,
        sublicensable, fully transferable, perpetual worldwide license to use, modify, adapt, reproduce, make derivative
        works of, publish and/or transmit, and/or distribute and to authorize other users of the Services and other
        third-parties to view, access, use, download, modify, adapt, reproduce, perform, make available for public
        viewing, make derivative works of, publish and/or transmit your User Content in any format and on any platform,
        either now known or hereinafter invented.
      </li>
      <li>
        You further grant us a royalty-free license to use your user name, and User Content to identify you as the
        source of any of your User Content; provided, however, that your ability to provide an image, voice, and
        likeness may be subject to limitations due to age restrictions.
      </li>
      <li>
        Through-To-The-Audience Rights. All of the rights you grant in your User Content in these Terms are provided on
        a through-to-the-audience basis, meaning the owners or operators of third party services will not have any
        separate liability to you or any other third party for User Content posted or used on such third party service
        via the Services.
      </li>
      <li>
        Waiver of Rights to User Content. By posting User Content Publicly to or through the Slydepost Services, you
        waive any rights to prior inspection or approval of any marketing or promotional materials related to such User
        Content. You also waive any and all rights of privacy, publicity, or any other rights of a similar nature in
        connection with your User Content, or any portion thereof. To the extent any moral rights are not transferable
        or assignable, you hereby waive and agree never to assert any and all moral rights, or to support, maintain or
        permit any action based on any moral rights that you may have in or with respect to any User Content you Post to
        or through the Services.
      </li>
      <li>
        We, or authorized third parties, reserve the right to cut, crop, edit, republish or refuse to publish, display
        or make available for public viewing, your content at our or their sole discretion. We have the right to remove,
        disallow, block or delete any posting you make on our Services if, in our opinion, your post does not comply
        with the content standards set out at “Your Access to and Use of Our Services” above. In addition, we have the
        right – but not the obligation – in our sole discretion to remove, disallow, block or delete any User Content
        (i) that we consider to violate these Terms, or (ii) in response to complaints from other users or third
        parties, with or without notice and without any liability to you. As a result, we recommend that you save copies
        of any User Content that you post to the Services on your personal device(s) in the event that you want to
        ensure that you have permanent access to copies of such User Content. We do not guarantee the accuracy,
        integrity, appropriateness or quality of any User Content, and under no circumstances will we be liable in any
        way for any User Content.
      </li>
      <li>
        You control whether your User Content is made publicly available on the Services to all other users of the
        Services or only available to people you approve. To restrict access to your User Content, you should select the
        appropriate privacy setting available within the Platform.
      </li>
      <li>
        We accept no liability in respect of any content submitted by users and published by us or by authorized third
        parties.
      </li>
      <li>
        If you wish to file a complaint about information or materials uploaded by other users, contact us at:
        info@slydepost.com and the Subject line must contain the word: “REPORT”
      </li>
      <li>
        Slydepost takes reasonable measures to expeditiously remove from our Services any infringing material that we
        become aware of. It is Slydepost’s policy, in appropriate circumstances and at its discretion, to disable or
        terminate the accounts of users of the Services who repeatedly infringe copyrights or intellectual property
        rights of others.
      </li>
      <li>
        While our own staff is continually working to develop and evaluate our own product ideas and features, we pride
        ourselves on paying close attention to the interests, feedback, comments, and suggestions we receive from the
        user community. If you choose to contribute by sending us or our employees any ideas for products, services,
        features, modifications, enhancements, content, refinements, technologies, content offerings (such as audio,
        visual, games, or other types of content), promotions, strategies, or product/feature names, or any related
        documentation, artwork, computer code, diagrams, or other materials (collectively “Feedback”), then regardless
        of what your accompanying communication may say, the following terms will apply, so that future
        misunderstandings can be avoided. Accordingly, by sending Feedback to us, you agree that:
      </li>
      <li>
        Slydepost has no obligation to review, consider, or implement your Feedback, or to return to you all or part of
        any Feedback for any reason;
      </li>
      <li>
        Feedback is provided on a non-confidential basis, and we are not under any obligation to keep any Feedback you
        send confidential or to refrain from using or disclosing it in any way; and
      </li>
      <li>
        You irrevocably grant us perpetual and unlimited permission and an unrestricted, perpetual, royalty free,
        worldwide, transferable license to reproduce, distribute, create derivative works of, modify, publicly perform
        (including on a through-to-the-audience basis), communicate to the public, make available, publicly display, and
        otherwise use and exploit the Feedback and derivatives thereof for any purpose and without restriction, free of
        charge and without attribution of any kind, including by making, using, selling, offering for sale, importing,
        and promoting commercial products and services that incorporate or embody Feedback, whether in whole or in part,
        and whether as provided or as modified.
      </li>
    </ul>
    10. Indemnities
    <br />
    <br />
    You agree to defend, indemnify, and hold harmless Slydepost, its parents, subsidiaries, and affiliates, and each of
    their respective officers, directors, employees, agents and advisors from any and all claims, liabilities, costs,
    and expenses, including, but not limited to, attorneys’ fees and expenses, arising out of a breach by you or any
    user of your account of these Terms, any claims of infringement or breach of any intellectual property or
    intellectual property rights arising from your submissions or postings through the Services, or arising out of a
    breach of your obligations, representation and warranties under these Terms.
    <br />
    <br />
    11. Exclusion of Warranties
    <br />
    <br />
    NOTHING IN THESE TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU CANNOT CONTRACTUALLY AGREE TO ALTER OR WAIVE AND
    ARE LEGALLY ALWAYS ENTITLED TO AS A CONSUMER.
    <br />
    <br />
    THE SERVICES ARE PROVIDED “AS IS” AND WE MAKE NO WARRANTY OR REPRESENTATION TO YOU WITH RESPECT TO THEM. IN
    PARTICULAR WE DO NOT REPRESENT OR WARRANT TO YOU THAT:
    <ul class="list-disc list-outside p-4">
      <li>YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;</li>
      <li>YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR;</li>
      <li>ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; AND</li>
      <li>
        DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE
        CORRECTED.
      </li>
    </ul>
    NO CONDITIONS, WARRANTIES OR OTHER TERMS (INCLUDING ANY IMPLIED TERMS AS TO SATISFACTORY QUALITY, FITNESS FOR
    PURPOSE OR CONFORMANCE WITH DESCRIPTION) APPLY TO THE SERVICES EXCEPT TO THE EXTENT THAT THEY ARE EXPRESSLY SET OUT
    IN THE TERMS. WE MAY CHANGE, SUSPEND, WITHDRAW OR RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR PLATFORM FOR
    BUSINESS AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE
    <br />
    <br />
    12. Limitation of Liability.
    <br />
    <br />
    NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED
    BY APPLICABLE LAW. THIS INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY OUR NEGLIGENCE OR THE NEGLIGENCE
    OF OUR EMPLOYEES, AGENTS OR SUBCONTRACTORS AND FOR FRAUD OR FRAUDULENT MISREPRESENTATION.
    <br />
    <br />
    SUBJECT TO THE PARAGRAPH ABOVE, WE SHALL NOT BE LIABLE TO YOU FOR:
    <ul class="list-decimal list-outside p-4">
      <li>ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY);</li>
      <li>ANY LOSS OF GOODWILL;</li>
      <li>ANY LOSS OF OPPORTUNITY;</li>
      <li>ANY LOSS OF DATA SUFFERED BY YOU; OR</li>
      <li>
        ANY INDIRECT OR CONSEQUENTIAL LOSSES WHICH MAY BE INCURRED BY YOU. ANY OTHER LOSS WILL BE LIMITED TO THE AMOUNT
        PAID BY YOU TO SLYDEPOST WITHIN THE LAST 12 MONTHS.
      </li>
    </ul>
    <br />
    ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU AS A RESULT OF:
    <ul class="list-disc list-outside p-4">
      <li>
        ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY
        RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICE;
      </li>
      <li>
        ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF
        THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
      </li>
      <li>
        THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND OTHER COMMUNICATIONS DATA MAINTAINED OR
        TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES;
      </li>
      <li>YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT INFORMATION; OR</li>
      <li>YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL.</li>
    </ul>
    PLEASE NOTE THAT WE ONLY PROVIDE OUR PLATFORM FOR YOUR USE. WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS
    OF BUSINESS, LOSS OF GOODWILL OR BUSINESS REPUTATION, BUSINESS INTERRUPTION, OR LOSS OF BUSINESS OPPORTUNITY.
    <br />
    <br />
    THESE LIMITATIONS ON OUR LIABILITY TO YOU SHALL APPLY WHETHER OR NOT WE HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN
    AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.
    <br />
    <br />
    YOU ARE RESPONSIBLE FOR ANY MOBILE CHARGES THAT MAY APPLY TO YOUR USE OF OUR SERVICE, INCLUDING TEXT-MESSAGING AND
    DATA CHARGES. IF YOU’RE UNSURE WHAT THOSE CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE PROVIDER BEFORE USING THE
    SERVICE.
    <br />
    <br />
    TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE
    SERVICES, INCLUDING, BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER OR OTHER USER, IS DIRECTLY
    BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE US AND OUR AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS
    AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY
    CONNECTED WITH SUCH DISPUTES.
    <br />
    <br />
    13. Other Terms and Conditions
    <br />
    <br />
    Entire Agreement. These Terms constitute the whole legal agreement between you and Slydepost and govern your use of
    the Services and completely replace any prior agreements between you and Slydepost in relation to the Services.
    <br />
    <br />
    Links. You may link to our home page, provided you do so in a way that is fair and legal and does not damage our
    reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of
    association, approval or endorsement on our part where none exists. You must not establish a link to our Services in
    any website that is not owned by you. The website in which you are linking must comply in all respects with the
    content standards set out at above. We reserve the right to withdraw linking permission without notice.
    <br />
    <br />
    No Waiver. Our failure to insist upon or enforce any provision of these Terms shall not be construed as a waiver of
    any provision or right.
    <br />
    <br />
    Security. We do not guarantee that our Services will be secure or free from bugs or viruses. You are responsible for
    configuring your information technology, computer programs and platform to access our Services. You should use your
    own virus protection software.
    <br />
    <br />
    Severability. If any court of law, having jurisdiction to decide on this matter, rules that any provision of these
    Terms is invalid, then that provision will be removed from the Terms without affecting the rest of the Terms, and
    the remaining provisions of the Terms will continue to be valid and enforceable.
    <br />
    <br />
    ARBITRATION AND CLASS ACTION WAIVER. This Section includes an arbitration agreement and an agreement that all claims
    will be brought only in an individual capacity (and not as a class action or other representative proceeding).
    Please read it carefully.
    <br />
    <br />
    Informal Process First. You agree that in the event of any dispute between you and Slydepost, you will first contact
    Slydepost and make a good faith sustained effort to resolve the dispute before resorting to more formal means of
    resolution, including without limitation any court action.
    <br />
    <br />
    Arbitration. After the informal dispute resolution process any remaining dispute, controversy, or claim
    (collectively, “Claim”) relating in any way to your use of Slydepost’s services and/or products, including the
    Services, or relating in any way to the communications between you and Slydepost or any other user of the Services,
    will be finally resolved by binding arbitration.
    <br />
    <br />
    Arbitration shall be conducted in accordance with the JAMS office located in Seattle, Washington, or such other
    recognized arbitration association mutually selected by the parties to the dispute, and the case shall be
    administered by the same authority in accordance with its procedures, unless the parties mutually determine
    otherwise. The place of arbitration shall be Seattle, Washington. The number of arbitrators shall be one, who shall
    be mutually selected by the parties and who need not be associated with JAMS, if the parties so elect. If the
    parties cannot agree upon a single arbitrator, then each party shall each choose one arbitrator, and these two
    arbitrators shall choose a third arbitrator, and the third arbitrator shall preside over the proceedings. The awards
    rendered by the arbitrator(s) shall be final and binding upon both parties concerned, and judgment upon the award
    may be entered in any court having jurisdiction thereof. All aspects of the arbitration proceedings, including any
    settlement and awards, shall be strictly confidential.
    <br />
    <br />
    This mandatory arbitration agreement applies equally to you and Slydepost. However, this arbitration provision does
    not (a) govern any Claim by Slydepost for infringement of its intellectual property or access to the Services that
    is unauthorized or exceeds authorization granted in these Terms or (b) bar you from making use of applicable small
    claims court procedures in appropriate cases.
    <br />
    <br />
    You agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and
    that you and Slydepost are each waiving the right to a trial by jury or to participate in a class action. This
    arbitration provision will survive any termination of these Terms. If you wish to begin an arbitration proceeding,
    after following the informal dispute resolution procedure, you must send a letter requesting arbitration and
    describing your claim to:
    <br />
    <br />
    14. Contact Us
    <br />
    <br />
    You can reach us at: info@slydepost.com or write us at Slydepost LLC, P.O. Box 773, Bellevue, WA 98009-0773
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
};
</script>
