<template>
  <div class="w-full">
    <div class="float-right flex flex-col items-end">
      <ButtonWithIcon
        :text="addToListButtonText()"
        icon="fa-solid fa-bars"
        class="mb-2"
        width="w-36"
        @click="showAddToListModal"
      />
      <LikeButton
        v-show="isPreview === false"
        :isLiked="userInLikesList"
        :numberOfLikes="this.numberOfLikes"
        :likes="likesProp"
        @like="like"
      />
    </div>
    <div class="flex flex-col gap-1">
      <div class="text-left">
        <span class="text-moonstone uppercase text-xs md:text-sm font-bold border-solid border-b-2">
          {{ this.category }}
        </span>
        <span v-if="this.subcategory != null" class="text-moonstone text-xs md:text-sm font-bold border-none">
          &nbsp;&gt;&nbsp;
        </span>
        <span
          v-if="this.subcategory != null"
          class="text-moonstone uppercase text-xs md:text-sm font-bold border-solid border-b-2"
        >
          {{ this.subcategory }}
        </span>
      </div>
      <div class="text-blue text-start font-bold text-2xl md:text-3xl">
        {{ this.title }}
      </div>
      <div v-if="this.address != null && this.address !== ''" class="text-base text-start text-dark-gray">
        <font-awesome-icon class="text-base text-blue" icon="fa-solid fa-location-dot" />
        {{ this.address }}
      </div>
      <div v-if="isUrlValid" class="truncate text-start cursor-pointer max-w-xs">
        <a :href="this.link" class="text-gray text-sm md:text-base" rel="noopener noreferrer" target="_blank">
          {{ this.link }}
        </a>
      </div>
      <div v-else class="truncate text-start max-w-xs">
        <a class="text-gray text-sm md:text-base">{{ this.link }}</a>
      </div>
      <div class="text-left text-black text-sm md:text-base">
        {{ this.description }}
      </div>
    </div>
  </div>
</template>

<script>
import client from '@/axios/client';
import { uuid } from 'vue-uuid';
import LikeButton from '@/components/Utility/LikeButton.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { event } from 'vue-gtag';
import { mapGetters } from 'vuex';
import ButtonWithIcon from '@/components/Buttons/ButtonWithIcon.vue';

export default {
  name: 'SlydepostInfo',
  components: { ButtonWithIcon, FontAwesomeIcon, LikeButton },
  emits: ['likeSlydepost', 'deleteLikeSlydepost', 'showAddToListModal'],
  props: [
    'postId',
    'category',
    'title',
    'link',
    'address',
    'description',
    'isPreview',
    'likesProp',
    'subcategory',
    'postOwnerId',
    'isNewSlydePost',
  ],
  data() {
    return {
      isLikingSlydepost: false,
      // likes: []
    };
  },
  methods: {
    addToListButtonText() {
      if (this.getMyList.length) {
        for (const list of this.getMyList) {
          if (list.listPosts && list.listPosts.length) {
            for (const listPost of list.listPosts) {
              if (listPost.postId === this.postId) {
                return listPost.listTitle.toUpperCase();
              }
            }
          }
        }
      }
    },
    showAddToListModal() {
      if (this.isNewSlydePost) {
        this.$store.commit('listsStoreModule/setAddToListModalPayload', {
          ...this.getAddToListModalPayload,
          fromCreateSlydepost: true,
        });
      } else {
        this.$store.commit('listsStoreModule/setAddToListModalPayload', {
          ...this.getAddToListModalPayload,
          show: true,
          postId: this.postId,
        });
      }
      this.$emit('showAddToListModal');
    },
    like() {
      const userId = this.loggedInUserId;

      // if likes has current user in it, remove the like
      if (this.likes != null) {
        for (let i = 0; i < this.likes.length; i++) {
          if (this.likes[i].userId == userId) {
            const likeId = this.likes[i].id;
            this.deleteLikeSlydepost(likeId);
            return;
          }
        }
      }
      // user is not in likes list, add them
      this.likeSlydepost();
    },
    likeSlydepost() {
      if (this.isLikingSlydepost === true) {
        return;
      }
      if (this.postId == null || this.postId === '') {
        return;
      }
      this.isLikingSlydepost = true;
      const postId = this.postId;
      const friendId = this.loggedInUserId;
      const postTitle = this.title;
      const userId = this.postOwnerId;
      const profilePictureUrl = this.$store.getters['getS3Url'];
      const friendFirstName = this.$store.getters['getFirstName'];
      const friendLastName = this.$store.getters['getLastName'];
      const resized = this.$store.getters['getIsResized'];
      const jsonBody = JSON.stringify({
        postId,
        friendId,
        friendFirstName,
        friendLastName,
        postTitle,
        userId,
        profilePictureUrl,
        resized,
      });
      client
        .post(`/post/like`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': friendId,
          },
        })
        .then((response) => {
          response.data.firstName = friendFirstName;
          response.data.lastName = friendLastName;
          response.data.username = this.$store.getters['getUsername'];
          response.data.presignedUrl = this.$store.getters['getProfilePictureUrl'];
          this.$emit('likeSlydepost', response.data);
          this.isLikingSlydepost = false;
          event('like-slydepost', {
            date: new Date().toUTCString(),
          });
        })
        .catch((res) => {
          this.isLikingSlydepost = false;
          console.error(`Error like response: ${res.data}`);
        });
    },
    deleteLikeSlydepost(likeId) {
      if (likeId == null || likeId === '') {
        return;
      }
      const payload = {
        id: likeId,
        postId: this.postId,
      };
      this.$emit('deleteLikeSlydepost', payload);
      const userId = this.loggedInUserId;
      const removeLikeFromSlydepostResponse = client
        .delete(`/post/like/${likeId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {})
        .catch((response) => {
          console.error(`Unable to remove like. ${response}`);
        });
    },
  },
  computed: {
    ...mapGetters({
      slydepostLikes: 'viewSlydepostStoreModule/getLikes',
      loggedInSlydepostLikes: 'getLoggedInUserPostsLikes',
      loggedInLikedSlydepostLikes: 'getLoggedInUserLikedPostsLikes',
      getAddToListModalPayload: 'listsStoreModule/getAddToListModalPayload',
      getMyList: 'listsStoreModule/getMyList',
    }),
    likes() {
      if (this.likesProp == null) {
        return [];
      }
      return this.likesProp;
    },
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
    numberOfLikes() {
      return this.likes != null ? this.likes.length : 0;
    },
    userInLikesList() {
      const userId = this.loggedInUserId;
      return this.likes.filter((like) => like.userId === userId).length > 0;
    },
    isUrlValid() {
      try {
        return Boolean(new URL(this.link));
      } catch (e) {
        return false;
      }
    },
  },
};
</script>
