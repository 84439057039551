<template>
  <div class="flex flex-col">
    <p class="text-2xl text-dark-dark-gray font-bold">Frequently Asked Questions</p>
    <br />
    <p class="font-bold">What Is Slydepost and How Do I Create Slydeposts?</p>
    <br />

    What is Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>
        Slydepost is a platform to share and discuss reviews about anything important to you with your friends, family,
        and your community.
      </li>
    </ul>

    Do I need anything special to create a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>You only need a Title, Category, and one Aspect to create a Slydepost.</li>
      <li>We recommend adding photos and descriptions to give your community more information about your reviews.</li>
    </ul>

    How do I create a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Login to Slydepost.</li>
      <li>We recommend adding photos and descriptions to give your community more information about your reviews.</li>
      <li>Click New Slydepost in the top left corner of your feed.</li>
      <li>Complete Title of what you would like to review.</li>
      <li>
        Select Category of what you’re reviewing. If you do not see your category, email info@slydepost.com subject line
        CATEGORY and we will consider adding it.
      </li>
      <li>Complete Location (optional).</li>
      <li>Complete Link (optional).</li>
      <li>
        Complete Upload Media (optional). Note: videos are not available at this time as a media type, only photos.
      </li>
      <li>
        The > arrow in the bottom right-hand corner will notify you once you’ve completed what’s needed to create your
        Slydepost. Click &lt; if you need to return to the previous page to make edits.
      </li>
      <li>
        Select up to 5 Aspects that you would like to discuss in your review. Use our suggested Aspects, or simply
        create your own.
      </li>
      <li>
        Select Create your own and type in your Custom Aspect, then click on the + button, you can also hit return or
        enter on your keyboard after typing in your chosen aspect.
      </li>
      <li>Select > once you are finished to proceed.</li>
      <li>Add a description of your overall experience (optional).</li>
      <li>
        Use the Slyders to rate Aspects 0-10 (0 being the very worst, 10 the best) The number you score your Aspect will
        give your Slydepost its average score.
      </li>
      <li>Describe Aspect (optional).</li>
      <li>
        Select who you would like to share with, Public or Friends and then select Post to Slydepost. Note: Select &lt;
        in the bottom left of your screen to return to the previous page if you need to edit your Slydepost before
        posting.
      </li>
    </ul>

    How do I choose a rating for Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>
        Your rating for your Slydepost is created from your Aspects. We take the average of what you score each of your
        Aspects to create a score for your Slydepost.
      </li>
    </ul>

    What is an Aspect?
    <ul class="list-disc list-outside p-4">
      <li>
        Aspects are unique elements that users select when creating a Slydepost. Users can customize each Aspect to fit
        the Slydepost that you are creating. Each Aspect can be rated from 0 - 10 and users can select up to 5 Aspects
        per Slydepost.
      </li>
    </ul>

    How do I edit my rating for a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>You can edit your rating by changing what you rate your Aspects.</li>
    </ul>

    What Category should I choose for my Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>
        Choose a Category that best fits what you're reviewing. If you don't see your Category, choose the most similar
        Category, and if we don’t have anything remotely close, email info@slydepost.com subject line ‘Category’ and we
        will consider adding it.
      </li>
    </ul>

    <p class="font-bold">How do I view and interact with Slydeposts on the platform?</p>
    <br />

    How can I view Slydeposts that I have liked?
    <ul class="list-disc list-outside p-4">
      <li>Click on your profile photo in the top right-hand corner of the page.</li>
      <li>Select Likes and scroll down; this will show you each Slydepost you’ve liked.</li>
    </ul>

    Where do I find Slydeposts that I’ve created?
    <ul class="list-disc list-outside p-4">
      <li>Click on your profile photo in the top right-hand corner of the page.</li>
      <li>
        Select Slydeposts in the right tab; this will show the number of Slydeposts you have created. Keep scrolling
        down to see all of your Slydeposts.
      </li>
    </ul>

    How can I upload a photo to my Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Select Upload Media with the camera icon.</li>
      <li>Choose a photo from your photo library.</li>
      <li>
        Select photos chronologically that you want to show your community. The first photo you choose will be the first
        photo that shows on your Slydepost. After uploading your photos, you can rearrange the photo order by holding
        down and dragging images into your preferred order placement before posting your Slydepost.
      </li>
    </ul>

    How can I change or delete my photos in my Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>
        If you are in the process of making a Slydepost and you want to change out the photos, click on Upload Media
        (camera icon) and select different photos, this will replace the photos you originally selected.
      </li>
      <li>If you want to remove all of your photos, delete your Slydepost and start over.</li>
    </ul>

    How many photos can I put in a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>The maximum number of photos you can include in a Slydepost is 5 photos.</li>
    </ul>

    Do I need to have a photo to post a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>No, a photo is not required to create a Slydepost, but it is recommended.</li>
    </ul>

    How can I toggle my newsfeed of the Slydeposts I see?
    <ul class="list-disc list-outside p-4">
      <li>
        In your newsfeed, click on the blue arrow at the top of the Newsfeed and select the feed you want to see, either
        Public or Friends.
      </li>
    </ul>

    How can I write a comment on a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Select Join the discussion.</li>
      <li>Complete your comment.</li>
      <li>Select enter on your keyboard once the comment is complete.</li>
    </ul>

    How can I remove or edit my comment from a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Click on … to the right of your comment and click Edit or Delete.</li>
      <li>If editing, change text, and once finished, select Edit.</li>
      <li>
        If deleting, you will be prompted to delete your comment, and if you are sure you want to move forward, select
        Yes.
      </li>
    </ul>

    How do I view comments on my Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Comments on your Slydepost are listed below your aspects and what you posted about.</li>
    </ul>

    How do I respond to comments on Slydeposts?
    <ul class="list-disc list-outside p-4">
      <li>
        You can either join the discussion and comment below a Slydepost or respond to another person’s comment in the
        discussion by clicking on Reply and insert text, then click on return on your keyboard to post your comment.
      </li>
    </ul>

    How do I like a Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Select the heart icon on the Slydepost you like.</li>
    </ul>

    How do I find a Slydepost that I have already liked?
    <ul class="list-disc list-outside p-4">
      <li>Click on your profile photo.</li>
      <li>Click on the Likes tab below your Description. This will show you all Slydeposts you’ve like.</li>
    </ul>

    How do I delete a Slydepost that I have created?
    <ul class="list-disc list-outside p-4">
      <li>View your Slydepost in Newsfeed or through your Profile.</li>
      <li>Click Profile.</li>
      <li>Click Slydeposts.</li>
      <li>Click … in the top right-hand corner of your post.</li>
      <li>Click Delete.</li>
    </ul>

    Is there a way to recover a Slydepost once it’s deleted?
    <ul class="list-disc list-outside p-4">
      <li>Not at this time.</li>
    </ul>

    How do I edit my Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Select … on the top right-hand corner of your post.</li>
      <li>Select Edit. This will bring you back to the beginning step of your Slydepost.</li>
      <li>Go through each step and edit what you would like.</li>
      <li>To edit photos, click Upload Media and select new photos you would like to add.</li>
      <li>Once you have made the edits you would like, select > to go through each step, and then click on Post.</li>
    </ul>

    <p class="font-bold">How do I use the Slydepost My Community features?</p>
    <br />

    How do I follow my friends?
    <ul class="list-disc list-outside p-4">
      <li>In the navigation bar at the top of the user feed, enter your friend's username, or email address.</li>
      <li>Once you find them, select Follow.</li>
    </ul>

    How do I know who my Followers are on Slydepost?
    <ul class="list-disc list-outside p-4">
      <li>Select My Community and this is where you can see your Followers & Following.</li>
    </ul>

    How can I invite my friends to Slydepost if I am not seeing them as active members?
    <ul class="list-disc list-outside p-4">
      <li>Invite them with this link: https://www.slydepost.com</li>
    </ul>

    How can I block a user?
    <ul class="list-disc list-outside p-4">
      <li>
        Go to My Community, select Followers/Following and find the user you want to block. Select … and click Block.
      </li>
    </ul>

    How can I mute a user?
    <ul class="list-disc list-outside p-4">
      <li>
        Go to My Community, select Followers/Following and find the user you want to mute. Select … and click Mute.
      </li>
    </ul>

    How can I report a user?
    <ul class="list-disc list-outside p-4">
      <li>
        Go to My Community, select Followers/Following and find the user you want to report. Select … and click Report.
      </li>
    </ul>

    How do I make my account private?
    <ul class="list-disc list-outside p-4">
      <li>Go to Settings > Privacy & Security.</li>
      <li>Select Private under Profile Visibility.</li>
    </ul>

    How do I share my posts with just my friends even if I have a Public Account?
    <ul class="list-disc list-outside p-4">
      <li>
        The last step in creating a Slydepost you have the option to review and select posting to the Public or Friends
        newsfeed.
      </li>
    </ul>

    Why are accounts suspended?
    <ul class="list-disc list-outside p-4">
      <li>Please refer to https://www.slydepost.com/settings Legal</li>
    </ul>

    How do I report spam?
    <ul class="list-disc list-outside p-4">
      <li>Select … on the top right hand corner of the post you are reporting.</li>
      <li>Select Report.</li>
    </ul>

    <p class="font-bold">Slydepost Settings and Legal Policies</p>
    <br />

    How do I update my email address?
    <ul class="list-disc list-outside p-4">
      <li>Go to Settings > Account Management.</li>
      <li>Edit Email address.</li>
      <li>Click Update Email once finished.</li>
    </ul>

    How do I update my phone number?
    <ul class="list-disc list-outside p-4">
      <li>Go to Settings > Profile.</li>
      <li>Scroll down to bottom of page and edit phone number.</li>
      <li>Click Save once finished.</li>
    </ul>

    How do I change my profile photo?
    <ul class="list-disc list-outside p-4">
      <li>Go to Settings > Profile</li>
      <li>Select edit icon on photo and select a new image.</li>
      <li>Click Save once finished.</li>
    </ul>

    How do I delete my account?
    <ul class="list-disc list-outside p-4">
      <li>Go to Settings.</li>
      <li>Select Permanently Delete Account.</li>
      <li>Select Yes when it prompts you to Delete.</li>
    </ul>

    Where can I find out more about terms and services?
    <ul class="list-disc list-outside p-4">
      <li>Go to Settings > Legal > Terms of Service.</li>
    </ul>

    How do I submit a complaint about copyright, impersonation, trademark, or other Terms of Service issues?
    <ul class="list-disc list-outside p-4">
      <li>
        Email us at info@slydepost.com Subject Line: Copyright, Impersonation, trademark. Make sure to review Settings >
        Legal > Terms of Service to determine what constitutes a violation and how to fix a related problem.
      </li>
    </ul>

    Not seeing what you’re looking for here? Contact us at info@slydepost.com.
  </div>
</template>

<script>
export default {
  name: 'FAQ',
};
</script>
