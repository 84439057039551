<template>
  <div>
    <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
      <div class="flex flex-row gap-2 items-center">
        <img :src="url" class="w-10 h-10 bg-cover bg-no-repeat rounded-xl" />
        <div class="flex flex-row gap-2 text-sm">
          <p>{{ firstName }} {{ lastName }}</p>
          <p v-if="username != null">&nbsp;- @{{ username }}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ListFollower',
  props: {
    userId: String,
    firstName: String,
    lastName: String,
    username: String,
    profilePictureUrl: String,
  },
  computed: {
    url() {
      return this.profilePictureUrl != null
        ? this.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
  },
};
</script>
