<template>
  <div class="px-4 lg:px-0 lg:pt-0 flex flex-col gap-2">
    <div class="pt-4 md:hidden">
      <button @click.prevent="backToDashboard">
        <font-awesome-icon :icon="['fas', 'chevron-left']" color="white" />
        Dashboard
      </button>
    </div>
    <div>
      <p class="text-2xl pb-2 text-dark-gray">Customers</p>
    </div>
    <Customers/>
  </div>
</template>

<script>
import Customers from '@/components/Insider/InsiderDashboardCustomers/Customers.vue';

export default {
  name: 'InsiderCustomers',
  components: {
    Customers
  },
  methods: {
    backToDashboard(){
      this.$router.push({ name: 'Insider' });
    }
  },
}
</script>