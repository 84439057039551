<template>
  <div class="bg-white border border-[#E0E0E0]">
    <p class="text-blue text-md text-center py-3 border-b border-[#E0E0E0]">Trending Lists</p>
    <div class="flex flex-col gap-2 justify-center items-center py-4">
      <div v-for="list in lists.slice(0, 5)" :key="list.listId">
        <router-link :to="{ name: 'SingleList', params: { listId: list.listId } }">
          <div class="w-[312px] relative hover:shadow-xl hover:ring-2 hover:ring-blue-500 hover:rounded-xl">
            <div class="flex flex-row items-center gap-4">
              <img
                :src="list.presignedUrl || require(`@/assets/no-photo-background-square.png`)"
                class="w-[80px] h-[80px] flex-shrink-0 object-cover object-center rounded-xl"
              />
              <div>
                <p class="capitalize font-bold line-clamp-2">
                  {{ list.title }}
                </p>
                <div class="flex flex-row gap-1 items-center text-sm line-clamp-1">
                  <img
                    :src="list.userProfilePicturePresignedUrl || require(`@/assets/no-photo-background-square.png`)"
                    alt="Profile picture"
                    class="w-6 h-6 object-cover object-center rounded-full"
                  />
                  <p v-if="list.username != null">@{{ list.username }}</p>
                  <p v-else>{{ list.userFirstName }} {{ list.userLastName }}</p>
                </div>
                <div class="line-clamp-1 text-sm font-bold text-moonstone">
                  <span>{{ list.totalFollows }} Follower{{ list.totalFollows !== 1 ? 's' : '' }}&nbsp;</span>
                  <span>{{ list.totalPosts }} Post{{ list.totalPosts !== 1 ? 's' : '' }}</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <router-link
      @click="setTrendingView"
      :to="{ name: 'Trending' }"
      class="block text-md text-center text-[#012337] pb-4 flex flex-row gap-2 items-center justify-center"
    >
      <p>View Trending Lists</p>
      <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
    </router-link>
  </div>
</template>

<script>
import Loading from '@/components/Utility/Loading.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'TrendingLists',
  components: {
    // Loading
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    truncated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      lists: 'trendingListStoreModule/getViewableLists',
      isLoading: 'trendingPostStoreModule/getIsLoading',
    }),
  },
  methods: {
    setTrendingView() {
      this.$store.commit('trendingStoreModule/setCurrentView', 'lists');
    },
  },
  mounted() {
    this.$store.dispatch('trendingListStoreModule/loadLast7Days');
  },
  unmounted() {},
};
</script>
