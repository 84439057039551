<template>
  <!-- ...existing template... -->
  <AreYouSureDraftModal
    :message="'Do you want to save your progress and update this draft?'"
    :show-modal="showDraftNavigateAwayModal"
    @accept="updateDraftAndNavigateAway"
    @cancel="navigateAwayWithoutSaving"
    @close="closeModalWithoutAction"
  />
  <AreYouSureDraftModal
    :message="'Do you want to save your progress as a draft?'"
    :show-modal="showNavigateAwayModal"
    @accept="saveDraftAndNavigateAway"
    @cancel="navigateAwayWithoutSaving"
    @close="closeModalWithoutAction"
  />
  <CreateSlydepost :is-editing="false" @exit="this.exit()" @slydepostCreated="this.completedSlydepost = true" />
  <!-- ...rest of the template... -->
</template>

<script>
import CreateSlydepost from '@/components/CreateSlydepost/CreateSlydepost.vue';
import AreYouSureDraftModal from '@/components/Modals/AreYouSureDraftModal.vue';
import router from '@/router';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore.js'; // Import the draft store

export default {
  name: 'NewSlydepost',
  components: {
    CreateSlydepost,
    AreYouSureDraftModal,
  },
  data() {
    return {
      navigateToPath: '',
      isNavigateAway: false,
      showNavigateAwayModal: false,
      completedSlydepost: false,
      showDraftNavigateAwayModal: false,
    };
  },
  methods: {
    exit() {
      router.push('/');
    },
    async updateDraftAndNavigateAway() {
      const draftData = this.getCurrentSlydepostData();
      draftData.id = this.$store.getters['createPostStoreModule/getDraftId'];
      const draftStore = useSlydepostDraftStore();
      await draftStore.updateDraft(draftData);
      this.navigateAwayWithoutSaving();
    },
    navigateAwayWithoutSaving() {
      this.$router.replace(this.navigateToPath || '/');
    },
    closeModalWithoutAction() {
      this.showNavigateAwayModal = false;
      this.showDraftNavigateAwayModal = false;
    },
    async saveDraftAndNavigateAway() {
      const draftData = this.getCurrentSlydepostData();
      const draftStore = useSlydepostDraftStore();
      let newDraft = await draftStore.addDraft(draftData);
      if (newDraft !== undefined) {
        draftStore.slydepostDrafts.unshift(newDraft);
      }
      this.navigateAwayWithoutSaving();
    },
    getCurrentSlydepostData() {
      return {
        title: this.$store.getters['createPostStoreModule/getTitle'],
        address: this.$store.getters['createPostStoreModule/getAddress'],
        link: this.$store.getters['createPostStoreModule/getLink'],
        category: this.$store.getters['createPostStoreModule/getCategory'],
        subcategory: this.$store.getters['createPostStoreModule/getSubcategory'],
        files: this.$store.getters['createPostStoreModule/getFiles'],
        aspects: this.$store.getters['createPostStoreModule/getSelectedAspects'],
        description: this.$store.getters['createPostStoreModule/getDescription'],
        sharedWith: this.$store.getters['createPostStoreModule/getSharedWith'],
        metaRating: this.$store.getters['createPostStoreModule/getMetaRating'],
        userId: this.$store.getters['authStoreModule/getUserId'],
        userFirstName: this.$store.getters['authStoreModule/getFirstName'],
        userLastName: this.$store.getters['authStoreModule/getLastName'],
        suggestedAspects: this.$store.getters['createPostStoreModule/getSuggestedAspects'],
        categoryDropdownSelectedOptionIndex:
          this.$store.getters['createPostStoreModule/getCategoryDropdownSelectedOptionIndex'],
        username: this.$store.getters['authStoreModule/getUser'].username,
        userProfilePictureUrl: this.$store.getters['authStoreModule/getProfilePhoto'],
      };
    },
  },
  beforeRouteLeave(to, from) {
    if (this.completedSlydepost === true && this.showDraftNavigateAwayModal === false) {
      return true;
    } else if (this.showNavigateAwayModal === false && this.showDraftNavigateAwayModal === false) {
      this.navigateToPath = to.path;
      let title = this.$store.getters['createPostStoreModule/getTitle'];
      if (title === '' || title === null || title === undefined) {
        return true;
      }
      let draftId = this.$store.getters['createPostStoreModule/getDraftId'];
      if (draftId !== null && draftId !== '') {
        this.showDraftNavigateAwayModal = true;
        return false;
      }
      this.showNavigateAwayModal = true;
      return false;
    }
  },
};
</script>
