<template>
  <div class="w-full">
    <div class="flex flex-row gap-2 items-center w-full">
      <img :src="url" class="w-10 h-10 bg-cover bg-no-repeat rounded-xl" />
      <div class="flex flex-row items-center justify-between text-sm w-full">
        <p class="line-clamp-2">
          <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
            {{ this.name }}
          </router-link>
          wants to follow your list
          <router-link :to="{ name: 'SingleList', params: { listId: this.listId } }" class="w-full">
            {{ listTitle }}
          </router-link>
        </p>
        <div class="whitespace-nowrap flex flex-row gap-2">
          <button @click.prevent="acceptFollowRequest" class="h-6 w-18 p-0 text-sm">Accept</button>
          <button
            @click.prevent="ignoreFollowRequest"
            class="bg-transparent text-blue border-none hover:bg-transparent hover:text-blue text-sm p-0"
          >
            Ignore
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListFollowRequest',
  props: {
    id: String,
    userId: String,
    firstName: String,
    lastName: String,
    username: String,
    profilePictureUrl: String,
    listId: String,
    listTitle: String,
  },
  computed: {
    url() {
      return this.profilePictureUrl != null
        ? this.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
    name() {
      if (this.username != null) {
        return `@${this.username}`;
      } else {
        return `${this.firstName} ${this.lastName}`;
      }
    },
  },
  methods: {
    acceptFollowRequest() {
      this.$store.dispatch('listsStoreModule/acceptFollowRequest', this.id);
    },
    ignoreFollowRequest() {
      this.$store.dispatch('listsStoreModule/ignoreFollowRequest', this.id);
    },
  },
};
</script>
