<template>
  <div v-if="options">
    <!-- Dropdown Input -->
    <div class="relative">
      <input
        class="cursor-pointer border border-teal text-dark-dark-gray h-9 rounded-none text-sm"
        @focus="showCategories()"
        v-model="searchFilter"
        :placeholder="placeholder"
      />
      <font-awesome-icon class="p-3 text-center -right-1 absolute bg-transparent text-sm" icon="fas fa-search" />
    </div>
    <!-- Selected Categories -->
    <div v-show="showOptions">
      <div
        class="bg-white border-x border-b border-teal text-dark-dark-gray overflow-auto max-h-52 z-10"
        v-show="selectedCategories.length > 0"
      >
        <ul>
          <li
            v-for="(option, index) in selectedCategories"
            :key="index"
            class="flex flex-row py-1 px-3 text-blue no-underline cursor-pointer text-start text-xs items-center gap-2"
          >
            <input class="w-3 h-3" type="checkbox" :id="index" :value="option" v-model="selectedCategories" />
            <label :for="index">{{ option }}</label>
          </li>
        </ul>
      </div>

      <!-- Dropdown Menu -->
      <div class="bg-white border border-t-0 border-teal text-dark-dark-gray overflow-auto max-h-52 z-10">
        <RecycleScroller class="h-36" :items="options" :item-size="16" key-field="id" v-slot="{ item }">
          <div
            class="flex flex-row py-1 px-3 text-blue no-underline cursor-pointer text-start text-xs items-center gap-2"
          >
            <input
              class="w-3 h-3"
              type="checkbox"
              :id="item.id"
              :value="item.category"
              v-model="selectedCategories"
              :disabled="selectedCategories.length >= 5"
            />
            <label :for="item.id">{{ item.category }}</label>
          </div>
        </RecycleScroller>
      </div>
      <div class="bg-white border border-t-0 border-teal">
        <p @click="this.clearCategories" class="text-teal underline text-xs py-1 px-3 cursor-pointer">
          Clear All Selections
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import categoriesList from '@/constants/categories-slim.json';

export default {
  name: 'RefinedSearchCategoryDropdown',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
    list: Array,
  },
  data() {
    return {
      selected: {},
      showOptions: false,
      optionsIndex: -1,
      searchFilter: '',
      options: [],
      maxOptions: 0,
    };
  },
  computed: {
    selectedCategories: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getRefinedCategories'];
      },
      set(categories) {
        this.$store.commit('refinedSearchStoreModule/setRefinedCategories', categories);
      },
    },
    filteredOptions() {
      if (this.searchFilter == null || this.searchFilter === '') {
        return this.options;
      }
      let filteredCategories = [];
      let filter = this.searchFilter.toLowerCase();
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].category.toLowerCase().includes(filter)) {
          filteredCategories.push(this.options[i]);
        }
      }
      return filteredCategories;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.searchFilter = this.selected.name;
      // this.showOptions = false;
      this.optionsIndex = -1;
      this.$emit('selected', this.selected);
    },
    showCategories() {
      this.searchFilter = '';
      this.showOptions = true;
    },
    exit() {
      if (!this.selected) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.optionsIndex = -1;
      this.showOptions = false;
    },
    clearCategories() {
      this.selectedCategories = [];
    },
  },
  mounted() {
    this.options = categoriesList;
    this.maxOptions = this.options.length;
  },
};
</script>

<style>
.scroller {
  height: 48px;
}

.user {
  padding: 0 12px;
  display: flex;
  align-items: center;
}
</style>
