<template>
  <div class="bg-blue">
    <div class="flex justify-center pt-3">
      <p class="camelcase text-4xl text-white font-bold" style="line-height: 48px; letter-spacing: 1px">Lists</p>
    </div>

    <div class="flex flex-row pt-4 justify-center">
      <div
        @click="this.selectFollowRequests()"
        :style="this.followedRequestsSelectedStyle()"
        class="w-28 cursor-pointer flex flex-col justify-center items-center bg-white rounded-t-2xl gap-0 text-blue"
      >
        <div class="text-2xl pt-2 tracking-[2px]">{{ requestsCount }}</div>
        <div class="text-xs uppercase text-center tracking-[2px] font-black">Follow Requests</div>
        <div class="font-bold text-xl text-blue">|</div>
      </div>
      <div
        @click="this.selectMyList()"
        :style="this.myListSelectedStyle()"
        class="w-28 cursor-pointer flex flex-col justify-center items-center text-white rounded-t-2xl"
      >
        <p class="text-2xl pt-2 tracking-[2px]">{{ myListsCount }}</p>
        <p class="text-xs uppercase text-center tracking-[2px] font-black">Your Lists</p>
        <p class="font-bold text-xl text-blue">|</p>
      </div>
      <div
        @click="this.selectFollowList()"
        :style="this.followedListSelectedStyle()"
        class="w-28 cursor-pointer flex flex-col justify-center items-center bg-white rounded-t-2xl gap-0 text-blue"
      >
        <div class="text-2xl pt-2 tracking-[2px]">{{ followedListsCount }}</div>
        <div class="text-xs uppercase text-center tracking-[2px] font-black">Lists you Follow</div>
        <div class="font-bold text-xl text-blue">|</div>
      </div>
    </div>
    <div class="bg-ice">
      <div class="flex flex-row justify-between px-3 md:px-2 lg:px-1 pt-5 pb-4">
        <div v-if="this.view === 'myLists'" class="text-2xl leading-7">My lists</div>
        <div v-else-if="view === 'followedLists'" class="text-2xl leading-7">Lists You follow</div>
        <div v-else-if="view === 'followRequests'" class="text-2xl leading-7">Follow Requests</div>
        <div v-if="view === 'followedLists' || view === 'myLists'" class="flex">
          <div class="mr-2" @click="gridView = 'list'">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.9999 0H1.99984C0.8954 0 0 0.89496 0 1.99965V5.99993C0 7.10459 0.8954 7.99957 1.99984 7.99957H17.9999C19.1045 7.99957 19.9999 7.10459 19.9999 5.99993V1.99965C19.9999 0.89496 19.1045 0 17.9999 0ZM17.9999 11.9999H1.99984C0.8954 11.9999 0 12.8951 0 13.9998V17.9998C0 19.1044 0.8954 19.9998 1.99984 19.9998H17.9999C19.1045 19.9998 19.9999 19.1044 19.9999 17.9998V13.9998C19.9999 12.8951 19.1045 11.9999 17.9999 11.9999Z"
                :fill="gridView === 'list' ? '#222222' : '#BBBBBB'"
              />
            </svg>
          </div>
          <div @click="gridView = 'grid'">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.00003 0H2.00001C0.89539 0 0 0.89496 0 1.99965V5.99993C0 7.10459 0.89539 7.99957 2.00001 7.99957H6.00003C7.10449 7.99957 8.00002 7.10459 8.00002 5.99993V1.99965C8.00002 0.89496 7.10449 0 6.00003 0ZM6.00003 11.9999H2.00001C0.89539 11.9999 0 12.8951 0 13.9998V17.9998C0 19.1044 0.89539 19.9998 2.00001 19.9998H6.00003C7.10449 19.9998 8.00002 19.1044 8.00002 17.9998V13.9998C8.00002 12.8951 7.10449 11.9999 6.00003 11.9999ZM14.0001 11.9999H17.9999C19.1045 11.9999 19.9999 12.8951 19.9999 13.9998V17.9998C19.9999 19.1044 19.1045 19.9998 17.9999 19.9998H14.0001C12.8954 19.9998 12 19.1044 12 17.9998V13.9998C12 12.8951 12.8954 11.9999 14.0001 11.9999ZM17.9999 0H14.0001C12.8954 0 12 0.89496 12 1.99965V5.99993C12 7.10459 12.8954 7.99957 14.0001 7.99957H17.9999C19.1045 7.99957 19.9999 7.10459 19.9999 5.99993V1.99965C19.9999 0.89496 19.1045 0 17.9999 0Z"
                :fill="gridView === 'grid' ? '#222222' : '#BBBBBB'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.00003 0H2.00001C0.89539 0 0 0.89496 0 1.99965V5.99993C0 7.10459 0.89539 7.99957 2.00001 7.99957H6.00003C7.10449 7.99957 8.00002 7.10459 8.00002 5.99993V1.99965C8.00002 0.89496 7.10449 0 6.00003 0ZM6.00003 11.9999H2.00001C0.89539 11.9999 0 12.8951 0 13.9998V17.9998C0 19.1044 0.89539 19.9998 2.00001 19.9998H6.00003C7.10449 19.9998 8.00002 19.1044 8.00002 17.9998V13.9998C8.00002 12.8951 7.10449 11.9999 6.00003 11.9999ZM14.0001 11.9999H17.9999C19.1045 11.9999 19.9999 12.8951 19.9999 13.9998V17.9998C19.9999 19.1044 19.1045 19.9998 17.9999 19.9998H14.0001C12.8954 19.9998 12 19.1044 12 17.9998V13.9998C12 12.8951 12.8954 11.9999 14.0001 11.9999ZM17.9999 0H14.0001C12.8954 0 12 0.89496 12 1.99965V5.99993C12 7.10459 12.8954 7.99957 14.0001 7.99957H17.9999C19.1045 7.99957 19.9999 7.10459 19.9999 5.99993V1.99965C19.9999 0.89496 19.1045 0 17.9999 0Z"
                fill="url(#paint0_linear_1923_12725)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1923_12725"
                  x1="15.5396"
                  y1="24.46"
                  x2="24.4601"
                  y2="4.46024"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0.01" />
                  <stop offset="1" stop-color="white" stop-opacity="0.26" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <div v-if="this.view === 'myLists'" class="flex flex-wrap pb-4">
        <div
          v-for="list in myList"
          :key="list.id"
          :class="
            gridView === 'grid'
              ? 'flex justify-center items-center w-[50%] p-2 sm:w-[33%]'
              : 'flex justify-center items-center w-[100%] p-2 sm:pb-5'
          "
        >
          <router-link :to="{ name: 'SingleList', params: { listId: list.id } }" class="w-full">
            <ListCardV2
              :id="list.id"
              :cover="list.presignedUrl"
              :title="list.title"
              :post-count="list.totalPosts"
              :follow-count="list.totalFollows"
              :profile-picture-url="list.userProfilePicturePresignedUrl"
              :status='list.status'
            />
          </router-link>
        </div>
        <InfiniteLoading @infinite="loadMoreLists" />
        <div
          :class="
            gridView === 'grid'
              ? 'flex justify-center items-center w-[50%] sm:w-[33%] p-2'
              : 'flex justify-center items-center w-[100%] p-2'
          "
        >
          <ListCard :data="{}" :addPost="true" :gridView="gridView" />
        </div>
      </div>

      <div v-else-if="view === 'followedLists'" class="flex flex-wrap pb-4">
        <div
          v-for="list in followedLists"
          :key="list.id"
          :class="
            gridView === 'grid'
              ? 'flex justify-center items-center w-[50%] p-2 sm:w-[33%] sm:pb-5'
              : 'flex justify-center items-center w-[100%] p-2 sm:pb-5'
          "
        >
          <router-link :to="{ name: 'SingleList', params: { listId: list.id } }" class="w-full">
            <ListCardV2
              :id="list.id"
              :cover="list.presignedUrl"
              :title="list.title"
              :follow-count="list.totalFollows"
              :post-count="list.totalPosts"
              :profile-picture-url="list.userProfilePicturePresignedUrl"
              :status='list.status'
            />
          </router-link>
        </div>
        <InfiniteLoading @infinite="loadMoreFollowedLists" />
      </div>

      <div v-else-if="view === 'followRequests'" class="flex flex-wrap pb-4 w-full px-2 lg:px-0">
        <div v-for="list in followRequests" :key="list.id" class="w-full">
          <ListFollowRequest
            :id="list.id"
            :user-id="list.userId"
            :first-name="list.userFirstName"
            :last-name="list.userLastName"
            :username="list.username"
            :profile-picture-url="list.userProfilePicturePresignedUrl"
            :list-id="list.listId"
            :list-title="list.listTitle"
          />
        </div>
        <InfiniteLoading @infinite="loadMoreFollowRequests" />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store';
import { mapGetters } from 'vuex';
import ListCard from '@/components/ViewLists/ListCard.vue';
import ListCardV2 from '@/components/ViewLists/ListCardV2.vue';
import ListFollowRequest from '@/components/ViewLists/ListFollowRequest.vue';
import InfiniteLoading from 'v3-infinite-loading';

export default {
  name: 'Lists',
  data() {
    return {
      gridView: 'grid',
    };
  },
  components: {
    ListCard,
    ListCardV2,
    ListFollowRequest,
    InfiniteLoading,
  },
  computed: {
    ...mapGetters({
      myList: 'listsStoreModule/getMyList',
      followedLists: 'listsStoreModule/getFollowedLists',
      followRequests: 'listsStoreModule/getFollowRequests',
      myListsCount: 'listsStoreModule/getMyListsSize',
      followedListsCount: 'listsStoreModule/getFollowedListsSize',
      requestsCount: 'listsStoreModule/getFollowRequestsSize',
    }),
    view: {
      get() {
        return this.$store.getters['listsStoreModule/getView'];
      },
      set(view) {
        this.$store.commit('listsStoreModule/setView', view);
      },
    },
  },
  methods: {
    selectMyList() {
      this.view = 'myLists';
    },
    selectFollowList() {
      this.view = 'followedLists';
    },
    selectFollowRequests() {
      this.view = 'followRequests';
    },
    myListSelectedStyle() {
      if (this.view === 'myLists') {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    followedListSelectedStyle() {
      if (this.view === 'followedLists') {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    followedRequestsSelectedStyle() {
      if (this.view === 'followRequests') {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    loadMoreLists() {
      this.$store.dispatch('listsStoreModule/fetchMyList');
    },
    loadMoreFollowedLists() {
      this.$store.dispatch('listsStoreModule/fetchFollowedLists');
    },
    loadMoreFollowRequests() {
      this.$store.dispatch('listsStoreModule/getFollowRequests');
    },
  },
  mounted() {
    this.$store.commit('listsStoreModule/clear');
    this.$store.dispatch('listsStoreModule/fetchMyList');
    this.$store.dispatch('listsStoreModule/fetchFollowedLists');
    this.$store.dispatch('listsStoreModule/getFollowRequests');
  },
  unmounted() {},
};
</script>
