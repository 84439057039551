<template>
  <div class="relative inline-block m-1">
    <img class="object-contain max-h-28" :src="this.file" />
    <!-- <span id="close-image" @click="$emit('removeFile')">x</span> -->
  </div>
</template>

<script>
export default {
  name: 'S3ImagePreview',
  props: ['file'],
  emits: ['removeFile'],
  data() {
    return {
      src: '',
    };
  },
  methods: {
    loadPicture(file) {
      this.src = file;
    },
  },
  mounted() {
    this.loadPicture(this.file);
  },
};
</script>
