<template>
  <div class="hidden md:inline px-4 lg:px-0">
    <p class="text-2xl font-bold pb-5">Settings</p>
    <div class="flex flex-row gap-5">
      <div class="cursor-pointer" @click="profileSettings">
        <p :style="getProfileStyling">Profile</p>
      </div>
      <div class="cursor-pointer" @click="privacySettings">
        <p :style="getPrivacySecurityStyling">Privacy & Security</p>
      </div>
      <div class="cursor-pointer" @click="accountManagementSettings">
        <p :style="getAccountManagementStyling">Account Management</p>
      </div>
      <div class="cursor-pointer" @click="clickFAQ">
        <p :style="getFAQStyling">Help & FAQ</p>
      </div>
      <div class="cursor-pointer" @click="clickLegal">
        <p :style="getLegalStyling">Legal</p>
      </div>
    </div>
  </div>
  <div v-show="showMenu" class="text-dark-dark-gray p-5">
    <div class="md:hidden">
      <p class="text-2xl font-bold pb-5">Settings</p>
      <div @click="profileSettings" class="py-2 text-lg flex flex-row justify-between items-center">
        <p>Profile</p>
        <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
      </div>
      <hr />
      <div @click="privacySettings" class="py-2 text-lg flex flex-row justify-between items-center">
        <p>Privacy & Security</p>
        <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
      </div>
      <hr />
      <div @click="accountManagementSettings" class="py-2 text-lg flex flex-row justify-between items-center">
        <p>Account Management</p>
        <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
      </div>
      <hr />
      <div @click="clickFAQ" class="py-2 text-lg flex flex-row justify-between items-center">
        <p>Help & FAQ</p>
        <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
      </div>
      <hr />
      <div @click="clickLegal" class="py-2 text-lg flex flex-row justify-between items-center">
        <p>Legal</p>
        <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
      </div>
    </div>
  </div>
  <div v-show="showSettings">
    <div class="p-4 md:hidden">
      <button @click.prevent="settingsMenu">
        <font-awesome-icon :icon="['fas', 'chevron-left']" color="white" />
        Settings
      </button>
    </div>
    <div v-if="showProfileSettings">
      <ProfileSettings />
    </div>
    <div v-if="showPrivacySettings">
      <PrivacySettings />
    </div>
    <div v-if="showAccountManagementSettings">
      <AccountManagementSettings />
    </div>
    <div v-if="showLegal">
      <Legal />
    </div>
    <div v-if="showFAQ">
      <FAQ />
    </div>
  </div>
</template>

<script>
import ProfileSettings from '@/components/Settings/ProfileSettings.vue';
import PrivacySettings from '@/components/Settings/PrivacySettings.vue';
import AccountManagementSettings from '@/components/Settings/AccountManagementSettings.vue';
import FAQ from '@/components/Documentation/Help/FAQ.vue';
import Legal from '@/views/Legal.vue';
import { useDisplay } from 'vuetify';

export default {
  name: 'Settings',
  data() {
    return {};
  },
  components: {
    ProfileSettings,
    PrivacySettings,
    AccountManagementSettings,
    Legal,
    FAQ,
  },
  computed: {
    showMenu: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowMenu'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowMenu', show);
      },
    },
    showSettings: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowSettings'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowSettings', show);
      },
    },
    showProfileSettings: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowProfileSettings'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowProfileSettings', show);
      },
    },
    showPrivacySettings: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowPrivacySettings'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowPrivacySettings', show);
      },
    },
    showNotificationSettings: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowNotificationSettings'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowNotificationSettings', show);
      },
    },
    showAccountManagementSettings: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowAccountManagementSettings'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowAccountManagementSettings', show);
      },
    },
    showFAQ: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowFaq'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowFaq', show);
      },
    },
    showLegal: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowLegal'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowLegal', show);
      },
    },
    showCropper: {
      get() {
        return this.$store.getters['settingsStoreModule/getShowCropper'];
      },
      set(show) {
        this.$store.commit('settingsStoreModule/setShowCropper', show);
      },
    },
    getProfileStyling() {
      return this.showProfileSettings
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getNotificationStyling() {
      return this.showNotificationSettings
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getPrivacySecurityStyling() {
      return this.showPrivacySettings
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getAccountManagementStyling() {
      return this.showAccountManagementSettings
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getLegalStyling() {
      return this.showLegal
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getFAQStyling() {
      return this.showFAQ
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
  },
  methods: {
    settingsMenu() {
      this.showMenu = true;
      this.showProfileSettings = false;
      this.showPrivacySettings = false;
      this.showAccountManagementSettings = false;
      this.showLegal = false;
      this.showFAQ = false;
      this.showSettings = false;
    },
    profileSettings() {
      this.showMenu = false;
      this.showPrivacySettings = false;
      this.showProfileSettings = true;
      this.showAccountManagementSettings = false;
      this.showLegal = false;
      this.showFAQ = false;
      this.showSettings = true;
    },
    privacySettings() {
      this.showMenu = false;
      this.showProfileSettings = false;
      this.showPrivacySettings = true;
      this.showAccountManagementSettings = false;
      this.showLegal = false;
      this.showFAQ = false;
      this.showSettings = true;
    },
    accountManagementSettings() {
      this.showMenu = false;
      this.showProfileSettings = false;
      this.showPrivacySettings = false;
      this.showAccountManagementSettings = true;
      this.showLegal = false;
      this.showFAQ = false;
      this.showSettings = true;
    },
    clickLegal() {
      this.showMenu = false;
      this.showProfileSettings = false;
      this.showPrivacySettings = false;
      this.showAccountManagementSettings = false;
      this.showLegal = true;
      this.showFAQ = false;
      this.showSettings = true;
    },
    clickFAQ() {
      this.showMenu = false;
      this.showProfileSettings = false;
      this.showPrivacySettings = false;
      this.showAccountManagementSettings = false;
      this.showLegal = false;
      this.showFAQ = true;
      this.showSettings = true;
    },
  },
  mounted() {
    this.$store.dispatch('getLoggedInUserProfile');
    const page = this.$route.query.page;
    if (this.xs) {
      this.showMenu = true;
      this.showSettings = false;
    } else if (this.mdAndUp) {
      this.showMenu = false;
      this.showSettings = true;
      if (page === 'help') {
        this.clickFAQ();
      } else {
        this.profileSettings();
      }
    }
  },
  setup() {
    // Destructure only the keys we want to use
    const { xs, mdAndUp } = useDisplay();
    return { xs, mdAndUp };
  },
};
</script>
