<template>
  <div class="pb-1 bg-white">
    <p class="hidden md:block">Results</p>
    <div v-show="contentType === 'all' || contentType === 'slydeposts'" class="flex flex-col gap-2">
      <div class="flex flex-row items-center pt-4 pb-3 justify-between">
        <div class="flex flex-row gap-2 items-center">
          <p class="text-xs">Slydeposts ({{ this.totalSlydepostResults }})</p>
          <font-awesome-icon
            v-if="showSlydeposts"
            @click="showSlydeposts = !showSlydeposts"
            class="text-sm"
            :color="'#30C3E3'"
            icon="fa-solid fa-angle-down"
          />
          <font-awesome-icon
            v-else
            @click="showSlydeposts = !showSlydeposts"
            class="text-sm"
            :color="'#30C3E3'"
            icon="fa-solid fa-angle-up"
          />
        </div>
        <div class="md:hidden">
          <p @click="filterResultsSlydeposts" class="text-[13px] text-teal">(View All)</p>
        </div>
      </div>
      <div v-if="!isSlydepostsFound">
        <p class="py-1 h-12 flex items-center">No results found.</p>
      </div>
      <div v-else-if="showSlydeposts" class="flex flex-col gap-2">
        <PostSearchResult
          v-for="post in this.slydepostResults"
          :key="post.postId"
          :id="post.id"
          :image-url="post.presignedUrl"
          :title="post.title"
          :subcategory="post.subcategory"
          :category="post.category"
          :meta-rating="post.metaRating"
          :location="post.address"
          :date="this.epochToDate(post.createdAt)"
          :aspects="post.aspects"
          :username="post.username"
          :first-name="post.userFirstName"
          :last-name="post.userLastName"
        />
      </div>
    </div>
    <p
      v-show="
        (contentType === 'all' || contentType === 'slydeposts') && this.isSlydepostLimit === true && isSlydepostsFound
      "
      @click="filterResultsSlydeposts"
      class="hidden md:block text-xs font-bold text-teal underline py-2 cursor-pointer"
    >
      See all Slydeposts
    </p>
    <hr v-show="contentType === 'all' || contentType === 'people'" class="my-4" />
    <div v-show="contentType === 'all' || contentType === 'people'">
      <div class="flex flex-row gap-2 items-center pt-4 pb-3">
        <p class="text-xs">People ({{ totalPeopleResults }})</p>
        <font-awesome-icon
          v-if="showPeople"
          @click="showPeople = !showPeople"
          class="text-sm"
          :color="'#30C3E3'"
          icon="fa-solid fa-angle-down"
        />
        <font-awesome-icon
          v-else
          @click="showPeople = !showPeople"
          class="text-sm"
          :color="'#30C3E3'"
          icon="fa-solid fa-angle-up"
        />
      </div>
      <div v-if="!isPeopleFound">
        <p class="py-1 h-12 flex items-center">No results found.</p>
      </div>
      <div class="flex flex-col gap-2" v-else-if="showPeople">
        <UserSearchResult
          v-for="user in userResults"
          :key="user.userId"
          :user-id="user.userId"
          :first-name="user.firstName"
          :last-name="user.lastName"
          :profile-picture-url="user.presignedUrl"
        />
      </div>
      <p
        v-show="(contentType === 'all' || contentType === 'people') && this.isPeopleLimit === true && isPeopleFound"
        @click="filterResultsPeople"
        class="hidden md:block text-xs font-bold text-teal underline py-2 cursor-pointer"
      >
        See all People
      </p>
    </div>
    <hr v-show="contentType === 'all' || contentType === 'lists'" class="my-4" />
    <div v-show="contentType === 'all' || contentType === 'lists'">
      <div class="flex flex-row gap-2 items-center pt-4 pb-3">
        <p class="text-xs">Lists ({{ totalListResults }})</p>
        <font-awesome-icon
          v-if="showLists"
          @click="showLists = !showLists"
          class="text-sm"
          :color="'#30C3E3'"
          icon="fa-solid fa-angle-down"
        />
        <font-awesome-icon
          v-else
          @click="showLists = !showLists"
          class="text-sm"
          :color="'#30C3E3'"
          icon="fa-solid fa-angle-up"
        />
      </div>
      <div v-if="!isListsFound">
        <p class="py-1 h-12 flex items-center">No results found.</p>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-6" v-else-if="showLists">
        <div v-for="list in limitedListResults" :key="list.listId">
          <ListCard
            :id="list.listId"
            :title="list.title"
            :cover="list.presignedUrl"
            :profile-picture-url="list.userProfilePicturePresignedUrl"
            :post-count="list.totalPosts"
            :follow-count="list.totalFollows"
          />
        </div>
      </div>
      <p
        v-show="(contentType === 'all' || contentType === 'lists') && this.isListsLimit === true && isListsFound"
        @click="filterResultsList"
        class="hidden md:block text-xs font-bold text-teal underline py-2 cursor-pointer"
      >
        See all Lists
      </p>
    </div>
    <InfiniteLoading @infinite="findMoreResults" />
  </div>
</template>

<script>
import PostSearchResult from '@/components/Search/SearchResults/PostSearchResult.vue';
import { mapGetters } from 'vuex';
import InfiniteLoading from 'v3-infinite-loading';
import UserSearchResult from '@/components/Search/SearchResults/UserSearchResult.vue';
import ListCard from '@/components/ViewLists/ListCardV2.vue';
import listSearchResult from '@/components/Search/SearchResults/ListSearchResult.vue';

export default {
  name: 'SearchResults',
  components: {
    UserSearchResult,
    PostSearchResult,
    InfiniteLoading,
    ListCard,
  },
  data() {
    return {
      slydepostLimit: 5,
      peopleLimit: 5,
      listLimit: 6,
      showSlydeposts: true,
      showPeople: true,
      showLists: true,
    };
  },
  computed: {
    ...mapGetters({
      keywords: 'refinedSearchStoreModule/getRefinedKeywords',
      postResults: 'refinedSearchStoreModule/getSearchResults',
      userResults: 'searchStoreModule/getUserSearchResults',
      listResults: 'searchStoreModule/getListSearchResults',
      isPeopleFound: 'searchStoreModule/getIsPeopleResultsFound',
      isSlydepostsFound: 'refinedSearchStoreModule/getIsSlydepostResultsFound',
      isListsFound: 'searchStoreModule/getIsListResultsFound',
      totalSlydepostResults: 'refinedSearchStoreModule/getTotalSearchResults',
      totalPeopleResults: 'searchStoreModule/getTotalPeopleSearchResults',
      totalListResults: 'searchStoreModule/getTotalListSearchResults',
    }),
    isPeopleLimit: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getIsLimitPeopleShown'];
      },
      set(value) {
        this.$store.commit('refinedSearchStoreModule/setIsLimitPeopleShown', value);
      },
    },
    isSlydepostLimit: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getIsLimitSlydepostsShown'];
      },
      set(value) {
        this.$store.commit('refinedSearchStoreModule/setIsLimitSlydepostsShown', value);
      },
    },
    isListsLimit: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getIsLimitListsShown'];
      },
      set(limit) {
        this.$store.commit('refinedSearchStoreModule/setIsLimitListsShown', limit);
      },
    },
    slydepostResults: {
      get() {
        return this.isSlydepostLimit ? this.postResults.slice(0, this.slydepostLimit) : this.postResults;
      },
    },
    peopleResults: {
      get() {
        return this.isPeopleLimit ? this.userResults.slice(0, this.peopleLimit) : this.userResults;
      },
    },
    limitedListResults() {
      return this.isPeopleLimit ? this.listResults.slice(0, this.listLimit) : this.listResults;
    },
    contentType: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getFilterContentType'];
      },
      set(type) {
        this.$store.commit('refinedSearchStoreModule/setFilterContentType', type);
      },
    },
  },
  methods: {
    epochToDate(epoch) {
      let date = new Date(epoch);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return date.toLocaleDateString('en-us', options);
    },
    findMoreResults() {
      if (this.showPeople === true && this.showSlydeposts === false && this.showLists === false) {
        let payload = {
          text: this.keywords,
          index: 'user',
        };
        this.$store.dispatch('searchStoreModule/indexSearch', payload);
      } else if (this.showSlydeposts === true && this.showPeople === false && this.showLists === false) {
        this.$store.dispatch('refinedSearchStoreModule/findMoreResults');
      } else if (this.showLists === true && this.showPeople === false && this.showSlydeposts === false) {
        let payload = {
          text: this.keywords,
          index: 'list',
        };
        this.$store.dispatch('searchStoreModule/indexSearch', payload);
      }
    },
    filterResultsAll() {},
    filterResultsSlydeposts() {
      this.showPeople = false;
      this.showSlydeposts = true;
      this.showLists = false;
      this.isSlydepostLimit = false;
      this.contentType = 'slydeposts';
    },
    filterResultsPeople() {
      this.showPeople = true;
      this.showSlydeposts = false;
      this.showLists = false;
      this.isPeopleLimit = false;
      this.contentType = 'people';
    },
    filterResultsList() {
      this.showPeople = false;
      this.showSlydeposts = false;
      this.showLists = true;
      this.isPeopleLimit = false;
      this.contentType = 'lists';
    },
  },
};
</script>
