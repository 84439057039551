<template>
  <div>
    <div class="relative bg-gradient-to-r from-white to-chambray h-12 rounded-full flex justify-between z-10">
      <div
        class="bg-transparent max-w-[80px] flex items-center justify-start font-bold pl-4 text-blue cursor-pointer hover:underline truncate"
      >
        <p class="line-clamp-1 pr-1">
          {{ text }}
        </p>
      </div>
      <div
        class="rounded-full border-4 border-solid border-blue flex justify-center blue bg-transparent h-12 w-12 cursor-pointer"
        @click="$emit('click')"
      >
        <div class="bg-transparent flex items-center justify-center">
          <font-awesome-icon :icon="icon" class="bg-transparent text-xl text-blue" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonWithIcon',
  props: {
    text: {
      type: String,
      default: 'List',
    },
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: 'w-24',
    },
  },
};
</script>

<!--<template>-->
<!--  <div :class="[{'bg-gradient-to-l': buttonText}, {'w-12': !buttonText}, width]"-->
<!--       class="relative from-white to-chambray h-12 rounded-full flex z-10 justify-end">-->
<!--    <div v-if="buttonText" class="bg-transparent flex items-center justify-center font-bold text-blue mr-2">-->
<!--      {{ buttonText.length > 5 ? buttonText.slice(0, 5) + '...' : buttonText }}-->
<!--    </div>-->
<!--    <div-->
<!--        class="rounded-full border-4 border-solid border-blue flex justify-center blue bg-transparent cursor-pointer w-12"-->
<!--        @click="$emit('click')"-->
<!--    >-->
<!--      <div class="bg-transparent flex items-center justify-center">-->
<!--        <font-awesome-icon :icon="icon" class="bg-transparent text-xl text-blue"/>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->
