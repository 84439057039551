<template>
  <div v-if="this.active" class="text-end right-0 bg-transparent border border-dark-blue rounded-xl w-36">
    <div
      v-for="option in this.options"
      :key="option.itemName"
      class="bg-white hover:bg-chambray hover:text-dark-blue first:rounded-t-xl last:rounded-b-xl"
    >
      <AppDropdownItem
        :optionName="option.itemName"
        :optionLink="option.link"
        :pushLink="option.push"
        :isSignOut="option.isSignOut"
        :notification-getter="option.notificationGetter"
        class="pr-2"
      />
    </div>
  </div>
</template>

<script>
import AppDropdownItem from './DropdownItem.vue';
export default {
  name: 'AppDropdownContent',
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
      note: 'Whehter or not the content is being shown',
    },
    options: {
      type: Array,
      required: true,
      note: 'Options of the dropdown. optionName, optionLink, pushLink',
    },
  },
  components: {
    AppDropdownItem,
  },
  mounted() {},
};
</script>
