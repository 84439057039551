<template>
  <div class="flex items-center justify-center" v-if="this.text != null && this.text.length > 0">
    <div :style="thisStyle" class="text-center">
      <span v-if="this.showingFullText === false" :class="showLimitedLines ? 'line-clamp-3' : ''">
        {{ formattedBody }}
      </span>
      <span v-else>{{ formattedBody }}</span>
      <span
        v-show="this.text != null && this.text.length > this.readMoreCount"
        class="underline text-[#30C2E2]"
        @click="showingFullText = !showingFullText"
      >
        {{ showingFullText ? 'less' : 'more' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadMoreText',
  props: {
    text: {
      type: String,
    },
    thisStyle: {
      type: String,
      default: 'text-center',
    },
    readMoreCount: {
      type: Number,
      default: 200,
    },
    showLimitedLines: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showingFullText: false,
    };
  },
  computed: {
    formattedBody() {
      if (this.showingFullText) {
        return this.text;
      } else if (this.text.length < 150) {
        return this.text;
      }
      return `${this.text.slice(0, this.readMoreCount).trim()}... `;
    },
  },
};
</script>
