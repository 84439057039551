<template>
  <div>
    <VueDatePicker
      class="date-picker"
      v-model="dateRange"
      range
      placeholder="Select a range of dates"
      :enable-time-picker="false"
      :clearable="false"
    />
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'RefinedSearchDate',
  components: {
    VueDatePicker,
  },
  computed: {
    dateRange: {
      get() {
        return this.$store.getters['refinedSearchStoreModule/getRefinedDateRange'];
      },
      // format date ranges
      // start date needs to be YYYY-MM-DD 00:00:00
      // end date needs to be YYYY-MM-DD 23:59:59
      set(range) {
        let formattedRange = [];
        if (range[0] != null) {
          const startDate = range[0];
          const year = startDate.getFullYear();
          let month = startDate.getMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }
          let day = startDate.getDate();
          if (day < 10) {
            day = `0${day}`;
          }
          const startRange = `${year}-${month}-${day} 00:00:00`;
          formattedRange.push(startRange);
        }
        if (range[1] != null) {
          const endDate = range[1];
          const year = endDate.getFullYear();
          let month = endDate.getMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }
          let day = endDate.getDate();
          if (day < 10) {
            day = `0${day}`;
          }
          const endRange = `${year}-${month}-${day} 23:59:59`;
          formattedRange.push(endRange);
        }
        this.$store.commit('refinedSearchStoreModule/setRefinedDateRange', formattedRange);
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.dp__theme_light {
  --dp-primary-color: #015d79;
  --dp-hover-color: #c4e3ed;
  --dp-text-color: #333333;
}

button.dp__action_select {
  background-color: #015d79;
  /*background: #015D79;*/
}
</style>
