<template>
  <div class="flex flex-col gap-2 p-4">
    <div class="flex flex-row justify-between items-center">
      <p class="text-lg font-medium">Edit a Product</p>
      <font-awesome-icon
        @click="back"
        class="text-xl text-dark-blue"
        :icon="['fas', 'xmark']"
      />
    </div>
    <div v-if="productType === `LIST_PURCHASE`" class="line-clamp-2">
      <span>List:&nbsp;</span>
      <span class="font-medium">{{ contentName }}</span>
    </div>
    <div v-else-if='productType === `INSIDER_SUBSCRIPTION`' class='line-clamp-2'>
      <span>Subscription:&nbsp;</span>
      <span class='capitalize font-medium'>{{ formattedPriceType }}</span>
    </div>
    <div class="flex flex-col gap-1">
      <p>Price</p>
      <vue-number
        v-model="price"
        v-bind="priceFormatting"
        class="border border-light-light-gray"
      />
    </div>
    <div v-if='errorMessage !== ``'>
      <p class='text-red-500'>{{ errorMessage }}</p>
    </div>
    <LoaderButton
      :status="editProductStatus"
      :done-text="`Updated!`"
      :error-text="`Error, Try Again?`"
      :in-progress-text="`Updating...`"
      :waiting-text="`Update`"
      @click="edit"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Utility/Dropdown.vue';
import { component as VueNumber } from '@coders-tm/vue-number-format';
import { mapGetters } from 'vuex';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';

export default {
  name: 'EditInsiderProduct',
  emits: ['exit'],
  data() {
    return {
      checked: false,
      priceFormatting: {
        decimal: '.',
        separator: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  components: {
    Dropdown,
    VueNumber,
    LoaderButton,
  },
  computed: {
    ...mapGetters({
      productId: 'editProductStoreModule/getProductId',
      priceId: 'editProductStoreModule/getPriceId',
      contentId: 'editProductStoreModule/getContentId',
      contentName: 'editProductStoreModule/getContentName',
      productType: 'editProductStoreModule/getProductType',
      priceType: 'editProductStoreModule/getPriceType',
      editProductStatus: 'editProductStoreModule/getEditProductStatus',
      errorMessage: 'editProductStoreModule/getEditErrorMessage',
    }),
    price: {
      get() {
        return this.$store.getters['editProductStoreModule/getPrice'];
      },
      set(price) {
        this.$store.commit('editProductStoreModule/setPrice', price);
      },
    },
    formattedPriceType(){
      return this.priceType.toLowerCase();
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    edit() {
      this.$store.dispatch('editProductStoreModule/editProduct')
    },
  },
  unmounted() {
    this.$store.commit('editProductStoreModule/clear')
  },
};
</script>