<template>
  <div class="flex flex-col gap-4">
    <p class="text-2xl text-dark-dark-gray font-bold">Support</p>
    <p>Slydepost Contact Information</p>
    <p>Email: info@slydepost.com</p>
    <p>Phone: +1 425-610-9608</p>
    <p>Hours of operation 10am-3pm PT, Monday - Friday.</p>
    <p>Please be patient as we try to address your concerns as soon as possible.</p>
  </div>
</template>

<script>
export default {
  name: 'Support'
}
</script>