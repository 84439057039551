<template>
  <div v-show="showModal" class="fixed lg:top-0 bottom-0 left-0 right-0 flex justify-center z-50 lg:bg-modal">
    <div
      class="modal-animation lg:top-1/4 w-full lg:w-80 lg:h-96 bg-white p-0 rounded-[16px] flex flex-col gap-2 relative"
    >
      <div class="p-5 z-50">
        <div class="flex flex-row justify-between mr-2 pb-2">
          <div>
            <p class="text-xl">Likes</p>
          </div>
          <font-awesome-icon @click="closeModal" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
        </div>
        <div class="flex flex-col gap-4 pt-2 h-80 overflow-y-scroll">
          <div v-for="like in likes" :key="like.userId" class="flex flex-col">
            <div class="flex flex-row gap-4 items-center">
              <img :src="getProfilePicture(like.presignedUrl)" class="h-12 w-12 object-cover rounded-xl" />
              <div>
                <router-link :to="{ name: 'UserProfile', params: { userId: like.userId } }">
                  <div class="flex flex-row gap-2">
                    <p>{{ like.firstName }}</p>
                    <p>{{ like.lastName }}</p>
                  </div>
                  <div v-if="like.username != null">
                    <p class="text-sm">@{{ like.username }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewSlydepostLikesModal',
  components: {},
  props: {
    showModal: Boolean,
    likes: Array,
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    getProfilePicture(url) {
      return url != null ? url : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
  },
  computed: {},
};
</script>

<style scoped>
.modal-animation {
  animation: scaleUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes scaleUp {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
