<template>
  <div @click="showOptions()" class="w-full relative inline-block" v-if="options">
    <div>
      <!-- Dropdown Input -->
      <input
        class="cursor-pointer border border-light-light-gray z-0 capitalize"
        :style="`${bottomBorderRadius}`"
        :name="name"
        @focus="showOptions()"
        @blur="exit()"
        @keyup="keyMonitor"
        v-model="searchFilter"
        :disabled="disabled"
        :placeholder="placeholder"
      />
      <!--      <i class="fa-solid fa-angle-down absolute right-3 text-xl top-3 cursor-pointer"></i>-->
      <!-- TODO: Add a down arrow to indicate dropdown options -->
      <div class="absolute right-3 text-xl top-2 cursor-pointer" v-if="this.optionsShown === false">
        <font-awesome-icon class="text-xl" :color="'#30C3E3'" icon="fa-solid fa-angle-down" />
      </div>
      <div v-else class="absolute right-3 text-xl top-2 cursor-pointer">
        <font-awesome-icon class="text-xl" :color="'#30C3E3'" icon="fa-solid fa-angle-up" />
      </div>
    </div>

    <!-- Dropdown Menu -->
    <div
      class="absolute bg-white w-full border border-light-light-gray rounded-b-xl z-10 max-h-60 overflow-y-scroll"
      v-show="optionsShown"
    >
      <div
        class="p-2 no-underline cursor-pointer text-start font-sm z-10"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown',
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing',
    },
    overwriteSelectedOption: {
      type: Object,
      required: false,
      default() {
        return {};
      },
      note: 'If you need to have the option selected to a specific value',
    },
    allowCustomInput: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Whehter or not custom input can be entered',
    },
    initialOption: {
      type: Object,
      required: false,
      note: 'The initial option that is to be selected acting as a default value',
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
    };
  },
  // created() {
  //   this.$emit('selected', this.selected);
  // },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
    // overwrite the selected option if overwriteSelectedOption prop is used
    overwriteOption() {
      return this.overwriteSelectedOption;
    },
    bottomBorderRadius() {
      if (this.optionsShown === true) {
        return `border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;`;
      } else {
        return `border-bottom-right-radius: 10px; border-bottom-left-radius: 10px;`;
      }
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit('selected', this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      // User has selected entry and it is part of the list
      if (event.key === 'Enter' && this.filteredOptions[0]) {
        this.selectOption(this.filteredOptions[0]);
      }
      // user has selected entry but it is not part of the list
      // this is custom input, so add it to the list and make it selected
      else if (event.key === 'Enter' && this.allowCustomInput) {
        let option = {};
        // the id will be the end of the option list
        option.id = this.options.length;
        option.name = this.searchFilter;
        this.selectOption(option);
      }
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
    },
    // listens for any changes to overwriteSelectedOption prop and updates the selected value
    overwriteOption() {
      if (this.overwriteSelectedOption != null) {
        this.selected = this.overwriteSelectedOption;
        this.optionsShown = false;
        this.searchFilter = this.selected.name;
        this.$emit('overwritten', this.selected);
      }
    },
  },
  mounted() {
    if (this.initialOption != null) {
      this.selectOption(this.initialOption);
    }
  },
};
</script>
