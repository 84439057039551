<script>
import { mapGetters } from 'vuex';
import ButtonWithIcon from '@/components/Buttons/ButtonWithIcon.vue';
import slydepostLogo from '@/assets/no-photo-background-square.png';

export default {
  name: 'AddToListModel',
  components: {
    // ButtonWithIcon
  },
  emits: ['closeModal'],
  props: {
    isCreatePost: {
      type: Boolean,
      default: false,
    },
    postId: String,
    postOwnerId: String,
  },
  data() {
    return {
      showModal: false,
      searchText: '',
      createListInput: false,
      newListTitle: '',
    };
  },
  computed: {
    ...mapGetters({
      getAddToListModalPayload: 'listsStoreModule/getAddToListModalPayload',
      getMyList: 'listsStoreModule/getMyList',
    }),
    getListAddedInPost() {
      return this.getAddToListModalPayload.selectedListsForNewSlydepost;
    },
    filteredLists() {
      return this.getMyList.filter((list) => list.title.toLowerCase().includes(this.searchText.toLowerCase()));
    },
    postIds() {
      return this.getAddToListModalPayload.postId;
    },
  },
  methods: {
    listImage(imageUrl) {
      return imageUrl != null ? imageUrl : slydepostLogo;
    },
    closeModal() {
      this.$store.commit('listsStoreModule/setAddToListModalPayload', {
        ...this.getAddToListModalPayload,
        show: false,
        fromCreateSlydepost: false,
      });
      this.$emit('closeModal');
    },
    cancelCreateList() {
      this.createListInput = false;
      this.newListTitle = '';
    },
    async createList() {
      await this.$store.dispatch('listsStoreModule/createListByLoggedInUser', {
        title: this.newListTitle,
        status: 'PUBLIC',
      });
      this.createListInput = false;
      this.newListTitle = '';
    },
    isPostIdExists(list) {
      if (this.isCreatePost === true) {
        for (let i = 0; i < this.getListAddedInPost.length; i++) {
          if (this.getListAddedInPost[i] === list.id) {
            return true;
          }
        }
        return false;
      } else {
        const listPosts = list.listPosts ? list.listPosts : [];
        for (let i = 0; i < listPosts.length; i++) {
          if (listPosts[i].postId === this.postIds) {
            return true;
          }
        }
        return false;
      }
    },
    saveList(id, title) {
      if (this.isCreatePost === true) {
        this.$store.commit('listsStoreModule/setAddToListModalPayload', {
          ...this.getAddToListModalPayload,
          selectedListsForNewSlydepost: [...this.getListAddedInPost, id],
        });
      } else {
        // need listPostId, listId, listTitle, postOwnerId, loggedInUserId, loggedInUserFirstName, loggedInUserLastName, loggedInUserProfilePictureUrl -->
        const notification = {
          listId: id,
          listTitle: title,
          postOwnerId: this.postOwnerId,
          loggedInUserId: this.$store.getters['authStoreModule/getUserId'],
          friendProfilePictureUrl: this.$store.getters['getS3Url'],
          friendFirstName: this.$store.getters['getFirstName'],
          friendLastName: this.$store.getters['getLastName'],
          resized: this.$store.getters['getIsResized'],
        };
        const payload = {
          listId: id,
          postIds: [this.postIds],
          notification,
        };
        this.$store.dispatch('listsStoreModule/addPostIdsToList', payload);
      }
    },
    removeList(id, list) {
      if (this.isCreatePost === true) {
        this.$store.commit('listsStoreModule/setAddToListModalPayload', {
          ...this.getAddToListModalPayload,
          selectedListsForNewSlydepost: this.getListAddedInPost.filter((e) => e !== id),
        });
      } else {
        const listPosts = list.listPosts ? list.listPosts : [];
        const postSelected = listPosts.find((e) => e.postId === this.postIds);
        if (Object.entries(postSelected).length) {
          this.$store.dispatch('listsStoreModule/removePostsFromList', {
            listId: id,
            listPostIds: [postSelected.id],
          });
        }
      }
    },
  },
  async mounted() {
  },
  unmounted() {
    this.closeModal();
    this.$store.commit('listsStoreModule/clearListsFromCreateSlydepost');
  },
};
</script>

<template>
  <div
    v-show="getAddToListModalPayload.show || getAddToListModalPayload.fromCreateSlydepost"
    class="fixed top-0 bottom-0 left-0 right-0 flex justify-center z-50 bg-modal"
  >
    <div
      class="modal-animation bg-white box-border m-auto absolute bottom-0 w-full rounded-t-[16px] sm:static sm:bottom-unset sm:top-50 sm:w-[450px] sm:rounded-[16px]"
    >
      <div class="p-2 flex justify-center relative pt-6">
        <!--        <ButtonWithIcon icon="fa-solid fa-bars" text="Save" width="w-32" />-->
        <div class="absolute bottom-2 right-1">
          <div class="flex justify-end mr-2 cursor-pointer" @click="closeModal">
            <img alt="" class="w-[15px] h-[15px]" src="@/assets/close.svg" />
          </div>
        </div>
      </div>
      <div class="p-2 px-5">
        <input
          v-model="searchText"
          class="focus:outline outline-chambray border border-gray"
          placeholder="Search Your Lists"
          type="text"
        />
      </div>
      <div class="h-[300px] p-2 px-5 overflow-y-auto">
        <div v-for="(list, key) in filteredLists" :key="key" class="flex mb-2 items-center">
          <img
            alt="..."
            class="w-[70px] h-[70px] object-cover object-center rounded"
            v-lazy="listImage(list.presignedUrl)"
          />
          <div class="w-full p-4 flex items-center justify-between">
            <div class="text-blue font-bold uppercase pl-2">
              <router-link :to="{ name: 'SingleList', params: { listId: list.id } }" class="w-full line-clamp-2">
                {{ list.title }}
              </router-link>
            </div>
            <button
              v-if="isPostIdExists(list)"
              class="bg-red-600 hover:bg-red-400 uppercase border-0 w-24 flex justify-center"
              @click="removeList(list.id, list)"
            >
              Remove
            </button>
            <button v-else class="bg-blue uppercase w-24 flex justify-center" @click="saveList(list.id, list.title)">
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="border-t-2 border-[#347D94] py-5">
        <div v-show="createListInput" class="w-full flex pb-2 px-4">
          <input v-model="newListTitle" class="border-2 border-gray rounded-lg text-sm" type="text" />
          <div class="flex">
            <button class="mx-2 bg-red-600 border-0 hover:bg-red-400" @click="cancelCreateList">CANCEL</button>
            <button
              :disabled="newListTitle === ''"
              class="disabled:bg-gray disabled:cursor-not-allowed bg-blue border-0"
              @click="createList"
            >
              SAVE
            </button>
          </div>
        </div>
        <div
          v-show="!createListInput"
          class="flex justify-center items-center gap-3 cursor-pointer"
          @click="createListInput = true"
        >
          <div class="flex justify-center items-center">
            <svg fill="none" height="40" viewBox="0 0 35 35" width="40" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17.5" cy="17.5" fill="#347D94" r="17.5" />
              <path
                d="M16.6395 16.6303V12H19.0176V16.6303H23.6571V19.0036H19.0176V23.6571H16.6395V19.0036H12V16.6303L16.6395 16.6303Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="text-blue font-bold uppercase">new list</div>
        </div>
      </div>
    </div>
  </div>
</template>
