<template>
  <div v-show="showModal" class="fixed top-0 bottom-0 left-0 right-0 flex justify-center z-50 bg-modal">
    <div class="text-center h-fit mt-16">
      <div class="w-full bg-white p-4 rounded-lg flex flex-col gap-2">
        <div class="flex justify-end">
          <font-awesome-icon @click="close" class="cursor-pointer" icon="fa-solid fa-x" />
        </div>
        {{ this.message }}
        <div class="flex flex-row gap-2 justify-center">
          <button id="accept-button" @click="accept">Yes</button>
          <button id="cancel-button" @click="cancel">No</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AreYouSureDraftModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    message: {
      type: String,
      required: true,
      default: 'Are you sure?',
    },
  },
  methods: {
    accept() {
      this.$emit('accept');
    },
    cancel() {
      this.$emit('cancel');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
