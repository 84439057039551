import { defineStore } from 'pinia';
import { uuid } from 'vue-uuid';
import client from '@/axios/client';

export const useSlydepostDraftStore = defineStore('slydepostDrafts', {
  state: () => ({
    slydepostDrafts: [],
    listDrafts: [],
  }),
  actions: {
    async addDraft(payload) {
      const media = [];
      if (payload.files != null) {
        for (let i = 0; i < payload.files.length; i++) {
          const photoUrl = URL.createObjectURL(payload.files[i].file);
          const image = new Image();

          const imageDimensions = await new Promise((resolve) => {
            image.onload = () => {
              const dimensions = {
                height: image.height,
                width: image.width,
              };
              resolve(dimensions);
            };
            image.src = photoUrl;
          });
          const readyToUploadMedia = {
            name: payload.files[i].file.name,
            order: payload.files[i].order,
            width: imageDimensions.width,
          };
          media.push(readyToUploadMedia);
        }
      }

      const category = payload.category;
      const title = payload.title;
      const link = payload.link;
      const address = payload.address;
      const description = payload.description;
      const files = payload.files;
      const subcategory = payload.subcategory;
      const aspects = payload.aspects;
      const userId = payload.userId;
      const metaRating = isNaN(payload.metaRating) ? 0 : payload.metaRating;
      const sharedWith = payload.sharedWith === '' ? 'public' : payload.sharedWith;
      const userFirstName = payload.userFirstName;
      const userLastName = payload.userLastName;
      const lists = payload.lists;
      const username = payload.username;
      const userProfilePictureUrl = payload.userProfilePictureUrl;
      const jsonBody = JSON.stringify({
        media,
        category,
        subcategory,
        title,
        link,
        address,
        description,
        aspects,
        userId,
        metaRating,
        sharedWith,
        userFirstName,
        userLastName,
        lists,
        username,
        userProfilePictureUrl,
      });
      let fileUrls = [];
      if (files != null) {
        for (let i = 0; i < files.length; i++) {
          fileUrls.push({
            presignedUrl: URL.createObjectURL(files[i].file),
            order: i,
            type: this.getFileType(files[i].file),
          });
        }
        payload.media = fileUrls;
      }
      payload.tags = payload.aspects;
      const loggedInUserId = payload.userId;
      return await client
        .post(`/post/draft/post`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then(async (response) => {
          for (const file of payload.files) {
            for (const url of response.data.presignedUrlsForUpload) {
              if (url.includes(file.file.name)) {
                await client
                  .put(url, file.file, {
                    headers: {
                      'Content-Type': 'image/jpeg',
                    },
                  })
                  .then((response) => {})
                  .catch((response) => {
                    console.error(`could not upload`, response);
                  });
              }
            }
          }
          response.data.lists = payload.lists;
          return response.data;
        })
        .catch((res) => {
          console.error(`could not create draft`, res);
          return undefined;
        });
    },
    async deleteDraft(id, userId) {
      if (!userId || userId === '') {
        return;
      }
      await client
        .delete(`/post/draft/post/${id}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          const index = this.slydepostDrafts.findIndex((draft) => draft.id === id);
          this.slydepostDrafts.splice(index, 1);
        })
        .catch((response) => {
          console.error(`could not delete draft`, response);
        });
    },
    async deleteListDraft(id, userId) {
      if (!userId || userId === '') {
        return;
      }
      await client
        .delete(`/post/draft/list/${id}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          const index = this.listDrafts.findIndex((draft) => draft.id === id);
          this.listDrafts.splice(index, 1);
        })
        .catch((response) => {
          console.error(`could not delete list draft`, response);
        });
    },
    async updateDraft(payload) {
      const media = [];
      for (let i = 0; i < payload.files.length; i++) {
        const photoUrl = URL.createObjectURL(payload.files[i].file);
        const image = new Image();

        const imageDimensions = await new Promise((resolve) => {
          image.onload = () => {
            const dimensions = {
              height: image.height,
              width: image.width,
            };
            resolve(dimensions);
          };
          image.src = photoUrl;
        });
        const readyToUploadMedia = {
          name: payload.files[i].file.name,
          order: payload.files[i].order,
          width: imageDimensions.width,
        };
        media.push(readyToUploadMedia);
      }

      const category = payload.category;
      const title = payload.title;
      const link = payload.link;
      const address = payload.address;
      const description = payload.description;
      const files = payload.files;
      const subcategory = payload.subcategory;
      const aspects = payload.aspects;
      const userId = payload.userId;
      const metaRating = isNaN(payload.metaRating) ? 0 : payload.metaRating;
      const sharedWith = payload.sharedWith === '' ? 'public' : payload.sharedWith.toLowerCase();
      const userFirstName = payload.userFirstName;
      const userLastName = payload.userLastName;
      const lists = payload.lists;
      const username = payload.username;
      const userProfilePictureUrl = payload.userProfilePictureUrl;
      const images = payload.images;
      const oldDraft = this.slydepostDrafts.filter((d) => d.id === payload.id)[0];
      const oldDraftIndex = this.slydepostDrafts.indexOf(oldDraft);
      const jsonBody = JSON.stringify({
        media,
        category,
        subcategory,
        title,
        link,
        address,
        description,
        aspects,
        userId,
        metaRating,
        sharedWith,
        userFirstName,
        userLastName,
        lists,
        username,
        userProfilePictureUrl,
        images,
      });
      let fileUrls = [];
      for (let i = 0; i < files.length; i++) {
        fileUrls.push({
          presignedUrl: URL.createObjectURL(files[i].file),
          order: i,
          type: this.getFileType(files[i].file),
        });
      }
      payload.media = fileUrls;
      payload.tags = payload.aspects;
      const loggedInUserId = payload.userId;
      return await client
        .put(`/post/draft/post/${payload.id}`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then(async (response) => {
          for (const file of payload.files) {
            for (const url of response.data.presignedUrlsForUpload) {
              if (url.includes(file.file.name)) {
                await client
                  .put(url, file.file, {
                    headers: {
                      'Content-Type': 'image/jpeg',
                    },
                  })
                  .then((response) => {})
                  .catch((response) => {
                    console.error(`could not upload`, response);
                  });
              }
            }
          }
          this.slydepostDrafts[oldDraftIndex] = response.data;
        })
        .catch((res) => {
          console.error(`could not create draft`, res);
          return undefined;
        });
    },
    async updateListDraft(payload, userId) {
      if (userId === null) {
        return;
      }
      const listId = payload.id;
      const title = payload.title;
      const description = payload.description;
      const status = payload.status;
      let url = undefined;
      if (payload.file && payload.file) {
        url = payload.file.name;
      }
      const jsonBody = JSON.stringify({
        listId,
        url,
        title,
        description,
        userId,
        status,
      });
      const oldDraftIndex = this.listDrafts.indexOf(this.listDrafts.filter((d) => d.id === payload.id)[0]);
      if (oldDraftIndex === -1) {
        return;
      }
      await client
        .put(`/post/draft/list/${payload.id}`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then(async (response) => {
          const { data } = response;
          if (data.presignedUrlForUpload) {
            await client
              .put(data.presignedUrlForUpload, payload.file, {
                headers: {
                  'Content-Type': 'image/jpeg',
                },
              })
              .then((response) => {
                this.listDrafts[oldDraftIndex] = data;
              })
              .catch((response) => {
                console.error(`could not upload`, response);
              });
          } else {
            this.listDrafts[oldDraftIndex] = data;
          }
        })
        .catch((error) => {
          console.error('Unable to create a new list', error);
          return undefined;
        });
    },
    async fetchDrafts(userId) {
      client
        .get(`/post/drafts/${userId}?`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          if (response.data.postDrafts.length > 0) {
            response.data.postDrafts.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
            this.slydepostDrafts = response.data.postDrafts;
          }
          if (response.data.listDrafts.length > 0) {
            response.data.listDrafts.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
            this.listDrafts = response.data.listDrafts;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async createListDraft(payload, userId) {
      if (userId === null) {
        return;
      }
      const title = payload.title;
      const description = payload.description;
      const status = payload.status;
      let url = undefined;
      if (payload.file && payload.file) {
        url = payload.file.name;
      }
      const jsonBody = JSON.stringify({
        url,
        title,
        description,
        userId,
        status,
      });
      return await client
        .post(`/post/draft/list`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then(async (response) => {
          const { data } = response;
          if (data.presignedUrl) {
            await client
              .put(data.presignedUrl, payload.file, {
                headers: {
                  'Content-Type': 'image/jpeg',
                },
              })
              .then((response) => {})
              .catch((response) => {
                console.error(`could not upload`, response);
              });
          }
          // if (payload.file !== null && payload.file !== "") {
          //     response.data.presignedUrl = URL.createObjectURL(payload.file);
          // }
          this.listDrafts.unshift(response.data);
        })
        .catch((error) => {
          console.error('Unable to create a new list', error);
          return undefined;
        });
    },
    getFileType(file) {
      if (file.type.match('image.*')) return 'IMAGE';
      if (file.type.match('video.*')) return 'VIDEO';
    },
  },
  persist: true,
});
