<template>
  <div>
    <div v-show="status === 'waiting' && showWaitingIcon === true">
      <font-awesome-icon :icon="['fas', waitingIcon]" />
    </div>
    <div v-show="status === 'inProgress'">
      <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
    </div>
    <div v-show="status === 'done'">
      <font-awesome-icon :icon="['fas', 'check']" />
    </div>
    <div v-show="status === 'error'">
      <font-awesome-icon v-show="showErrorIcon" :color="'#F37653'" :icon="['fas', 'x']" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderIcon',
  props: {
    status: {
      type: String,
      note: 'the current status of the button',
    },
    waitingIcon: {
      type: String,
      note: 'the name of the icon to be displayed while in waiting state',
      default: 'floppy-disk',
    },
    showWaitingIcon: {
      type: Boolean,
      note: 'boolean whether or not to show the waiting icon',
      default: true,
    },
    showErrorIcon: {
      type: Boolean,
      note: 'boolean as to whether or not the error icon should show or not',
      default: true,
    },
  },
};
</script>
