import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const searchStoreModule = {
  namespaced: true,
  state: {
    keyword: '',

    userSearchResults: [],
    postSearchResults: [],
    listSearchResults: [],
    isSlydepostResultsFound: false,
    isPeopleResultsFound: false,
    isListResultsFound: false,
    isSearchInProgress: false,
    totalPeopleSearchResults: 0,
    totalListSearchResults: 0,
    listLimit: 6,
    isListLimited: true,
  },
  getters: {
    getListSearchResults(state) {
      return state.listSearchResults;
    },
    getLimitedListResults(state) {
      return state.isListLimited ? this.listSearchResults.splice(0, this.listLimit) : this.listSearchResults;
    },
    getIsListResultsFound(state) {
      return state.isListResultsFound;
    },
    getTotalListSearchResults(state) {
      return state.totalListSearchResults;
    },
    getTotalPeopleSearchResults(state) {
      return state.totalPeopleSearchResults;
    },
    getKeyword(state) {
      return state.keyword;
    },
    getPostSearchResults(state) {
      return state.postSearchResults;
    },
    getUserSearchResults(state) {
      return state.userSearchResults;
    },
    getIsPeopleResultsFound(state) {
      return state.isPeopleResultsFound;
    },
    getIsSlydepostResultsFound(state) {
      return state.isSlydepostResultsFound;
    },
    getIsSearchInProgress(state) {
      return state.isSearchInProgress;
    },
  },
  mutations: {
    setTotalListSearchResults(state, total) {
      state.totalListSearchResults = total;
    },
    setListSearchResults(state, results) {
      state.listSearchResults = results;
    },
    setIsListResultsFound(state, found) {
      state.isListResultsFound = found;
    },
    setTotalPeopleSearchResults(state, total) {
      state.totalPeopleSearchResults = total;
    },
    setKeyword(state, keyword) {
      state.keyword = keyword;
    },
    setIsPeopleResultsFound(state, isFound) {
      state.isPeopleResultsFound = isFound;
    },
    setIsSlydepostResultsFound(state, isFound) {
      state.isSlydepostResultsFound = isFound;
    },
    addToUserSearchResults(state, payload) {
      state.userSearchResults.push(payload);
    },
    addToPostSearchResults(state, payload) {
      state.postSearchResults.push(payload);
    },
    addToListSearchResults(state, payload) {
      state.listSearchResults.push(payload);
    },
    setUserSearchResults(state, payload) {
      state.userSearchResults = payload;
    },
    setPostSearchResults(state, payload) {
      state.postSearchResults = payload;
    },
    setIsSearchInProgress(state, value) {
      state.isSearchInProgress = value;
    },
  },
  actions: {
    omnisearch({ commit, getters, rootGetters }, text) {
      commit('setUserSearchResults', []);
      commit('setPostSearchResults', []);
      commit('setListSearchResults', []);
      commit('setIsSearchInProgress', true);
      if (text == null || text === '') {
        return;
      }
      commit('setIsSlydepostResultsFound', true);
      commit('setIsPeopleResultsFound', true);
      commit('setIsListResultsFound', true);
      const jsonBody = JSON.stringify({
        text,
      });
      const userId = rootGetters['authStoreModule/getUserId'];
      const searchResponse = client
        .post(`/search/omni/`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('setIsSearchInProgress', false);
          const userResults = response.data.userResults.hits.hits;
          commit('setTotalPeopleSearchResults', response.data.userResults.hits.total.value);
          const postResults = response.data.postResults.hits.hits;
          commit('refinedSearchStoreModule/setTotalSearchResults', response.data.postResults.hits.total.value, {
            root: true,
          });
          const listResults = response.data.listResults.hits.hits;
          commit('setTotalListSearchResults', response.data.listResults.hits.total.value);
          if (userResults.length === 0) {
            commit('setIsPeopleResultsFound', false);
          }
          if (postResults.length === 0) {
            commit('setIsSlydepostResultsFound', false);
          }
          if (listResults.length === 0) {
            commit('setIsListResultsFound', false);
          }
          for (let i = 0; i < userResults.length; i++) {
            commit('addToUserSearchResults', userResults[i]._source);
          }
          for (let i = 0; i < postResults.length; i++) {
            commit('addToPostSearchResults', postResults[i]._source);
          }
          for (let i = 0; i < listResults.length; i++) {
            commit('addToListSearchResults', listResults[i]._source);
          }
        })
        .catch((res) => {
          console.error(res);
        });
    },
    indexSearch({ commit, getters, rootGetters }, payload) {
      const index = payload.index;
      const text = payload.text;
      commit('setUserSearchResults', []);
      commit('setPostSearchResults', []);
      commit('setListSearchResults', []);
      commit('setIsSearchInProgress', true);
      if (text == null || text === '') {
        return;
      }
      commit('setIsSlydepostResultsFound', true);
      commit('setIsPeopleResultsFound', true);
      commit('setIsListResultsFound', true);
      const jsonBody = JSON.stringify({
        text,
      });
      const userId = rootGetters['authStoreModule/getUserId'];
      const searchResponse = client
        .post(`/search/${index}/`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          let userResultsFound = false;
          let postResultsFound = false;
          let listResultsFound = false;
          commit('setIsSearchInProgress', false);
          if (response.data.userResults != null) {
            const userResults = response.data.userResults.hits.hits;
            commit('setTotalPeopleSearchResults', response.data.userResults.hits.total.value);
            if (userResults.length !== 0) {
              commit('setIsPeopleResultsFound', true);
            }
            for (let i = 0; i < userResults.length; i++) {
              commit('addToUserSearchResults', userResults[i]._source);
            }
          }
          if (response.data.postResults != null) {
            const postResults = response.data.postResults.hits.hits;
            this.$store.commit(
              'refinedSearchStoreModule/setTotalSearchResults',
              response.data.postResults.hits.total.value
            );
            if (postResults.length !== 0) {
              commit('setIsSlydepostResultsFound', true);
            }
            for (let i = 0; i < postResults.length; i++) {
              commit('addToPostSearchResults', postResults[i]._source);
            }
          }
          if (response.data.listResults != null) {
            const listResults = response.data.listResults.hits.hits;
            commit('setTotalListSearchResults', response.data.listResults.hits.total.value);
            if (listResults.length !== 0) {
              commit('setIsListResultsFound', true);
            }
            for (let i = 0; i < listResults.length; i++) {
              commit('addToListSearchResults', listResults[i]._source);
            }
          }
        })
        .catch((res) => {
          console.error(res);
        });
    },
  },
};

// export the entire module
export { searchStoreModule };
