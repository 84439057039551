<template>
  <div class="flex justify-center md:max-w-2xl w-full bg-white rounded-lg">
    <CreateSlydepostInfo
      v-if="this.showInfo === true"
      @exit="this.exit()"
      @next="(this.showInfo = false), (this.showAspects = true)"
      @draft="draftCreated"
    />
    <CreateSlydepostAspects
      v-if="this.showAspects === true"
      @back="(this.showAspects = false), (this.showInfo = true)"
      @exit="this.exit()"
      @next="(this.showAspects = false), (this.showRatings = true)"
      @draft="draftCreated"
    />
    <CreateSlydepostRatings
      v-if="this.showRatings === true"
      @back="(this.showRatings = false), (this.showAspects = true)"
      @exit="this.exit()"
      @next="(this.showRatings = false), (this.showPreview = true)"
      @draft="draftCreated"
    />
    <CreateSlydepostPreview
      v-if="this.showPreview === true"
      :is-editing="this.isEditing"
      :isNewSlydePost="true"
      @back="(this.showPreview = false), (this.showRatings = true)"
      @exit="this.exit()"
      @next="closeModal"
      @draft="draftCreated"
    />
  </div>
</template>

<script>
import CreateSlydepostInfo from './CreateSlydepostInfo';
import CreateSlydepostAspects from './CreateSlydepostAspects.vue';
import CreateSlydepostRatings from './CreateSlydepostRatings.vue';
import CreateSlydepostPreview from './CreateSlydepostPreview.vue';
import store from '@/store/store';
import router from '@/router/index';

export default {
  name: 'CreateSlydepost',
  data() {
    return {
      showInfo: true,
      showAspects: false,
      showRatings: false,
      showPreview: false,
    };
  },
  props: ['isEditing', 'postId', 'createdAt'],
  // props can be passed in if the slydepost is being edited
  components: {
    CreateSlydepostInfo,
    CreateSlydepostAspects,
    CreateSlydepostRatings,
    CreateSlydepostPreview,
  },
  methods: {
    // reset modal so it starts at first step next time it is opened
    closeModal() {
      this.$emit('slydepostCreated');
      this.showInfo = false;
      this.showAspects = false;
      this.showRatings = false;
      this.showPreview = false;
      store.state.newSlydepost = {};
      router.push('/');
    },
    exit() {
      router.push('/');
    },
    draftCreated() {
      this.$emit('slydepostCreated');
      this.showInfo = false;
      this.showAspects = false;
      this.showRatings = false;
      this.showPreview = false;
      store.state.newSlydepost = {};
      router.push({ name: 'Drafts' });
    },
  },
  mounted() {
    if (this.isEditing == null || this.isEditing === false) {
      store.state.newSlydepost = {};
      store.state.editedSlydepost = {};
    }
    this.showInfo = true;
    this.showAspects = false;
    this.showRatings = false;
    this.showPreview = false;
  },
  unmounted() {
    this.$store.commit('createPostStoreModule/clear');
  },
};
</script>
