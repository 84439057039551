import { uuid } from 'vue-uuid';
import { event } from 'vue-gtag';
import client from '@/axios/client';

const editPostStoreModule = {
  namespaced: true,
  state: {
    id: '',
    title: '',
    address: '',
    link: '',
    category: '',
    subcategory: '',
    categoryDropdownSearchFilter: '',
    categoryDropdownSelectedOptionIndex: -1,
    files: [],
    aspects: [],
    suggestedAspects: [],
    existingS3Images: [],
    description: '',
    sharedWith: '',
    editSlydepostStatus: 'waiting', // waiting, inProgress, done, error
  },
  getters: {
    getId(state) {
      return state.id;
    },
    getTitle(state) {
      return state.title;
    },
    getAddress(state) {
      return state.address;
    },
    getLink(state) {
      return state.link;
    },
    getCategory(state) {
      return state.category;
    },
    getSubcategory(state) {
      return state.subcategory;
    },
    getCategoryDropdownSearchFilter(state) {
      return state.categoryDropdownSearchFilter;
    },
    getCategoryDropdownSelectedOptionIndex(state) {
      return state.categoryDropdownSelectedOptionIndex;
    },
    getFiles(state) {
      return state.files;
    },
    getExistingS3Images(state) {
      return state.existingS3Images;
    },
    getAspects(state) {
      return state.aspects;
    },
    getSuggestedAspects(state) {
      return state.suggestedAspects;
    },
    getTotalAspectsSelected(state) {
      let selectedAspectLength = 0;
      for (let i = 0; i < state.aspects.length; i++) {
        if (state.aspects[i].selected === true) {
          selectedAspectLength += 1;
        }
      }
      return selectedAspectLength;
    },
    getSelectedAspects(state) {
      let selectedAspects = [];
      for (let i = 0; i < state.aspects.length; i++) {
        if (state.aspects[i].selected === true) {
          selectedAspects.push(state.aspects[i]);
        }
      }
      return selectedAspects;
    },
    getDescription(state) {
      return state.description;
    },
    getMetaRating(state) {
      let totalAspectRating = 0;
      let numberOfAspects = 0;
      state.aspects.forEach((aspect) => {
        if (aspect.selected === true) {
          totalAspectRating += parseInt(aspect.rating);
          numberOfAspects += 1;
        }
      });
      const finalRating = Math.round((totalAspectRating / numberOfAspects) * 10) / 10;
      return finalRating;
    },
    getImages(state) {
      let images = [];
      if (state.files.length > 0) {
        for (let i = 0; i < state.files.length; i++) {
          images.push({
            file: URL.createObjectURL(state.files[i].file),
            order: i,
            type: state.files[i].type,
          });
        }
      } else if (state.existingS3Images.length > 0) {
        images = state.existingS3Images;
      }
      return images;
    },
    getEditSlydepostStatus(state) {
      return state.editSlydepostStatus;
    },
    getSharedWith(state) {
      return state.sharedWith;
    },
  },
  mutations: {
    setId(state, id) {
      state.id = id;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setLink(state, link) {
      state.link = link;
    },
    setCategory(state, category) {
      state.category = category;
    },
    setSubcategory(state, subcategory) {
      state.subcategory = subcategory;
    },
    setCategoryDropdownSearchFilter(state, filter) {
      state.categoryDropdownSearchFilter = filter;
    },
    setCategoryDropdownSelectedOptionIndex(state, index) {
      state.categoryDropdownSelectedOptionIndex = index;
    },
    setFiles(state, files) {
      state.files = files;
    },
    setExistingS3Images(state, images) {
      state.existingS3Images = images;
    },
    setAspects(state, aspects) {
      state.aspects = aspects;
    },
    setSuggestedAspects(state, aspects) {
      state.suggestedAspects = aspects;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setEditSlydepostStatus(state, status) {
      state.editSlydepostStatus = status;
    },
    setSharedWith(state, shared) {
      state.sharedWith = shared;
    },
    clear(state) {
      state.title = '';
      state.address = '';
      state.link = '';
      state.category = '';
      state.subcategory = '';
      state.categoryDropdownSearchFilter = '';
      state.categoryDropdownSelectedOptionIndex = -1;
      state.files = [];
      state.aspects = [];
      state.existingS3Images = [];
      state.suggestedAspects = [];
      state.description = '';
      state.sharedWith = '';
      state.editSlydepostStatus = 'waiting';
    },
  },
  actions: {
    async editSlydepost({ dispatch, commit, getters, rootGetters }, payload) {
      if (getters.getEditSlydepostStatus === 'inProgress') {
        return;
      }
      commit('setEditSlydepostStatus', 'inProgress');
      const media = [];
      for (let i = 0; i < payload.files.length; i++) {
        const photoUrl = URL.createObjectURL(payload.files[i].file);
        const image = new Image();

        const imageDimensions = await new Promise((resolve) => {
          image.onload = () => {
            const dimensions = {
              height: image.height,
              width: image.width,
            };
            resolve(dimensions);
          };
          image.src = photoUrl;
        });
        const readyToUploadMedia = {
          name: payload.files[i].file.name,
          order: payload.files[i].order,
          width: imageDimensions.width,
        };
        media.push(readyToUploadMedia);
      }
      const id = payload.id;
      const category = payload.category;
      const title = payload.title;
      const link = payload.link;
      const address = payload.address;
      const description = payload.description;
      const files = payload.files;
      const images = payload.images;
      const subcategory = payload.subcategory;
      const aspects = payload.aspects;
      const userId = payload.userId;
      const metaRating = payload.metaRating;
      const sharedWith = payload.sharedWith;
      const userFirstName = payload.userFirstName;
      const userLastName = payload.userLastName;
      const existingS3Images = payload.existingS3Images;
      const jsonBody = JSON.stringify({
        id,
        media,
        category,
        subcategory,
        title,
        link,
        address,
        description,
        aspects,
        images,
        userId,
        metaRating,
        sharedWith,
        userFirstName,
        userLastName,
        existingS3Images,
      });
      if (files != null && files.length > 0) {
        let fileUrls = [];
        for (let i = 0; i < files.length; i++) {
          fileUrls.push({
            presignedUrl: URL.createObjectURL(files[i].file),
            order: i,
            type: getFileType(files[i].file),
          });
        }
        payload.media = fileUrls;
      } else {
        // no new files, use the presignedUrls
        // EXISTING IMAGES NOT CURRENTLY SHOWING UP
        payload.media = images;
      }

      payload.tags = payload.aspects;

      // commit("replaceSlydepost", payload, {root: true})
      const loggedInUserId = payload.userId;
      await client
        .put(`/post`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then(async (response) => {
          // upload the files to the presigned URLs returned from backend
          for (const file of payload.files) {
            for (const url of response.data.presignedUrlsForUpload) {
              if (url.includes(file.file.name)) {
                await client
                  .put(url, file.file, {
                    headers: {
                      'Content-Type': 'image/jpeg',
                    },
                  })
                  .then((response) => {})
                  .catch((response) => {
                    console.error(`bad upload response`, response);
                  });
              }
            }
          }
          commit('replaceSlydepost', payload, { root: true });
          commit('exploreStoreModule/replaceSlydepost', payload, {
            root: true,
          });
          commit('setEditSlydepostStatus', 'done');
          event('slydepost-edited', {
            date: new Date().toUTCString(),
          });
        })
        .catch((res) => {
          commit('setEditSlydepostStatus', 'error');
          console.error(res);
        });
    },
  },
};

function getFileType(file) {
  if (file.type.match('image.*')) return 'IMAGE';
  if (file.type.match('video.*')) return 'VIDEO';
}

// export the entire module
export { editPostStoreModule };
