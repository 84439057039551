<template>
  <div>
    <p>Verifying...</p>
  </div>
</template>

<script>
export default {
  name: 'VerifyCheckout',
  mounted(){
    const sessionId = this.$route.query.session_id;
    const insiderId = this.$route.query.insider_id;
    const customerId = this.$route.query.customer_id;
    const contentId = this.$route.query.content_id;
    const contentType = this.$route.query.content_type;
    const payload = {
      sessionId,
      insiderId,
      customerId,
      contentId,
      contentType
    }
    this.$store.dispatch('insiderStoreModule/verifyCheckoutSession', payload);
  }
}
</script>