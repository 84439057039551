<template>
  <div>
    <div
      @click="$refs.file.click()"
      class="h-12 w-48 pb-2 bg-gradient-to-r from-ice to-chambray rounded-xl flex cursor-pointer border border-blue"
    >
      <div class="w-12 h-full rounded-xl border border-blue bg-transparent flex items-center justify-center">
        <img class="h-7 bg-transparent" src="@/assets/media-icon.png" alt="" />
      </div>
      <input type="file" ref="file" class="hidden" @change="selectedFile" accept="image/*" />
      <div class="h-full flex bg-transparent items-center justify-center flex-2">
        <p class="bg-transparent">Upload Media</p>
      </div>
    </div>
    <div v-if="existingImageUrl != null" class="relative w-1/6">
      <S3ImagePreview class="list-group-item" :file="existingImageUrl" />
    </div>
    <div v-else-if="file != null" class="relative w-1/6">
      <ImagePreview class="list-group-item" :file="this.getLocalUrlFromFile(file)" draggable="true" />
      <div class="absolute top-0 -right-1 h-5 w-5 rounded-full border border-dark-blue cursor-pointer bg-chambray z-20">
        <div class="h-full flex justify-center items-center">
          <font-awesome-icon @click="file = ''" class="text-xs text-dark-blue" :icon="['fas', 'xmark']" />
        </div>
      </div>
    </div>
    <div v-if="errorMessage">
      <p class="text-red-500">{{ errorMessage }}</p>
    </div>
    <canvas id="canvas-add-list-media" style="display: none"></canvas>
  </div>
</template>

<script>
import ImagePreview from '@/components/MediaUpload/ImagePreview.vue';
import S3ImagePreview from '@/components/MediaUpload/S3ImagePreview.vue';
import { uuidv4 } from '@/helpers/uuid';

export default {
  name: 'UploadListCoverPhoto',
  components: {
    ImagePreview,
    S3ImagePreview,
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  computed: {
    file: {
      get() {
        return this.$store.getters['editListStoreModule/getFile'];
      },
      set(file) {
        this.$store.commit('editListStoreModule/setFile', file);
      },
    },
    existingImageUrl: {
      get() {
        return this.$store.getters['editListStoreModule/getExistingImageUrl'];
      },
      set() {
        this.$store.commit('editListStoreModule/setExistingImageUrl');
      },
    },
  },
  methods: {
    async selectedFile(event) {
      this.errorMessage = '';
      this.existingImageUrl = null;
      const tempFiles = event.target.files;
      if (tempFiles && tempFiles.length) {
        const file = tempFiles[0];
        if (file.type.match('image.*')) {
          this.file = file;
          try {
            const resizedBlob = await this.resizeImage(file, 1080, 1080);
            let uuid = await uuidv4();
            const uuidFile = new File([resizedBlob], uuid + file.type.replaceAll('image/', '.'), {
              type: resizedBlob.type,
            });
            this.$emit('imageSelected', uuidFile);
          } catch (e) {
            console.error('Error resizing image');
          }
        } else {
          this.errorMessage = 'Select only images';
        }
      }
    },
    getLocalUrlFromFile(file) {
      return URL.createObjectURL(file);
    },
    async resizeImage(file, maxWidth, maxHeight) {
      return new Promise((resolve, reject) => {
        const canvas = document.getElementById('canvas-add-list-media');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          let newWidth, newHeight;
          // if image size is smaller than max, don't change anything
          if (image.width < maxWidth && image.height < maxHeight) {
            newWidth = image.width;
            newHeight = image.height;
          }
          // Calculate new dimensions while maintaining the aspect ratio
          else if (image.width > image.height) {
            newWidth = maxWidth;
            newHeight = (image.height * maxWidth) / image.width;
          } else {
            newHeight = maxHeight;
            newWidth = (image.width * maxHeight) / image.height;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          // Draw the image on the canvas with the new dimensions
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
          // Convert the canvas content to a Blob
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            file.type,
            1.0
          );
        };

        image.src = URL.createObjectURL(file);
      });
    },
  },
};
</script>
