const legalStoreModule = {
  namespaced: true,
  state: {
    page: 'terms', // terms, privacy, community
  },
  getters: {
    getPage(state) {
      return state.page;
    },
  },
  mutations: {
    setPage(state, page) {
      state.page = page;
    },
    clear(state) {
      state.page = 'privacy';
    },
  },
  actions: {},
};

// export the entire module
export { legalStoreModule };
