<template>
  <div>
    <button
      :disabled="disabledButton"
      class="w-full bg-blue rounded-lg h-12 px-4 disabled:cursor-not-allowed disabled:bg-neutral-400 disabled:border-0"
    >
      <div v-show="status === 'waiting'">
        <div v-if="iconLeftSide">
          <font-awesome-icon v-if="waitingIcon" :icon="['fas', waitingIcon]" />
          {{ waitingText }}
        </div>
        <div v-else>
          {{ waitingText }}
          <font-awesome-icon v-if="waitingIcon" :icon="['fas', waitingIcon]" />
        </div>
      </div>
      <div v-show="status === 'inProgress'">
        <div v-if="iconLeftSide">
          <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
          {{ inProgressText }}
        </div>
        <div v-else>
          {{ inProgressText }}
          <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
        </div>
      </div>
      <div v-show="status === 'done'">
        <div v-if="iconLeftSide">
          <font-awesome-icon :icon="['fas', 'check']" />
          {{ doneText }}
        </div>
        <div v-else>
          {{ doneText }}
          <font-awesome-icon :icon="['fas', 'check']" />
        </div>
      </div>
      <div v-show="status === 'error'">
        <div v-if="iconLeftSide">
          <font-awesome-icon v-show="showErrorIcon" :color="'#F37653'" :icon="['fas', 'x']" />
          {{ errorText }}
        </div>
        <div v-else>
          {{ errorText }}
          <font-awesome-icon v-show="showErrorIcon" :color="'#F37653'" :icon="['fas', 'x']" />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'LoaderButton',
  props: {
    status: {
      type: String,
      note: 'the current status of the button',
    },
    waitingText: {
      type: String,
      note: 'text displayed while waiting for action',
    },
    inProgressText: {
      type: String,
      note: 'text displayed while action is in progress',
    },
    doneText: {
      type: String,
      note: 'text displayed while action was completed',
    },
    errorText: {
      type: String,
      note: 'text displayed while action had an error',
    },
    waitingIcon: {
      type: String,
      note: 'the name of the icon to be displayed while in waiting state',
      default: 'floppy-disk',
    },
    iconLeftSide: {
      type: Boolean,
      note: 'boolean as to what side the icon should be on',
      default: true,
    },
    showErrorIcon: {
      type: Boolean,
      note: 'boolean as to whether or not the error icon should show or not',
      default: true,
    },
    disabledButton: {
      type: Boolean,
      note: 'boolean as to whether or not the button should be disabled',
      default: false,
    },
  },
};
</script>
