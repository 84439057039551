<template>
  <div>
    <div class="uppercase text-dark-gray tracking-widest text-lg font-bold">comments</div>
    <div class="flex flex-col gap-2">
      <div v-for="comment in this.limitedComments" :key="comment.id">
        <SlydepostComment
          :id="comment.id"
          :userId="comment.userId"
          :postId="this.postId"
          :userFirstName="comment.userFirstName"
          :userLastName="comment.userLastName"
          :profile-photo-url="comment.presignedUrl"
          :createdAt="comment.createdAt"
          :comment="comment.comment"
          :likes="comment.likes"
          :likesProp="comment.likes"
          :childComments="comment.childComments"
          :post-owner-id="this.postOwnerId"
          @deleteComment="deleteComment"
          @likeComment="likeComment"
          @deleteCommentLike="deleteCommentLike"
          @replyToComment="replyToComment"
          @deleteReply="deleteReply"
          @replyToReply="replyToReply"
          @likeReply="likeReply"
          @deleteLikeReply="deleteLikeReply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MAX_NUM_COMMENTS } from '@/components/ViewSlydepost/constants';
import SlydepostComment from '@/components/ViewSlydepost/SlydepostComment.vue';

export default {
  name: 'SlydepostCommentsDisplay',
  emits: [
    'deleteComment',
    'likeComment',
    'deleteCommentLike',
    'replyToComment',
    'deleteReply',
    'replyToReply',
    'deleteLikeReply',
  ],
  props: {
    postId: String,
    postOwnerId: String,
    displayMoreComments: Boolean,
    commentsList: Array,
  },
  components: {
    SlydepostComment,
  },
  methods: {
    deleteLikeReply(payload) {
      this.$emit('deleteLikeReply', payload);
    },
    likeReply(payload) {
      this.$emit('likeReply', payload);
    },
    replyToReply(payload) {
      this.$emit('replyToReply', payload);
    },
    deleteReply(payload) {
      this.$emit('deleteReply', payload);
    },
    replyToComment(payload) {
      this.$emit('replyToComment', payload);
    },
    deleteCommentLike(payload) {
      this.$emit('deleteCommentLike', payload);
    },
    deleteComment(payload) {
      this.$emit('deleteComment', payload);
    },
    likeComment(payload) {
      this.$emit('likeComment', payload);
    },
  },
  computed: {
    limitedComments() {
      if (this.displayMoreComments === true) {
        return this.commentsList;
      } else {
        return this.commentsList.slice(0, MAX_NUM_COMMENTS);
      }
    },
  },
};
</script>
