import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const editProductStoreModule = {
  namespaced: true,
  state: {
    productId: '',
    priceId: '',
    productType: '',
    priceType: '',
    price: 0,
    contentId: '',
    contentName: '',
    editProductStatus: 'waiting', // waiting, inProgress, done, error
    editErrorMessage: ''
  },
  getters: {
    getProductId(state){
      return state.productId
    },
    getPriceId(state){
      return state.priceId;
    },
    getContentId(state){
      return state.contentId;
    },
    getContentName(state, name){
      return state.contentName
    },
    getProductType(state){
      return state.productType
    },
    getPriceType(state){
      return state.priceType
    },
    getPrice(state){
      return state.price
    },
    getEditProductStatus(state){
      return state.editProductStatus
    },
    getEditErrorMessage(state){
      return state.editErrorMessage;
    }
  },
  mutations: {
    setProductId(state, id){
      state.productId = id
    },
    setPriceId(state, id){
      state.priceId = id
    },
    setContentId(state, id){
      state.contentId = id
    },
    setContentName(state, name){
      state.contentName = name;
    },
    setProductType(state, type){
      state.productType = type
    },
    setPriceType(state, type){
      state.priceType = type;
    },
    setPrice(state, price){
      state.price = price;
    },
    setEditProductStatus(state, status){
      state.editProductStatus = status
    },
    setEditErrorMessage(state, message){
      state.editErrorMessage = message
    },
    clear(state){
      state.productId = '';
      state.priceId = '';
      state.contentId = '';
      state.contentName = '';
      state.productType = '';
      state.priceType = '';
      state.price = 0;
      state.editProductStatus = 'waiting';
      state.editErrorMessage = '';
    }
  },
  actions: {
    editProduct({ commit, getters, rootGetters }){
      if(getters.getEditProductStatus === 'inProgress'){
        return;
      }
      commit('setEditErrorMessage', '')
      commit('setEditProductStatus', 'inProgress')

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const price = parseFloat(getters.getPrice);
      const productType = getters.getProductType;
      const priceType = getters.getPriceType;
      const listId = getters.getListId;
      const priceId = getters.getPriceId;
      const jsonBody = JSON.stringify({
        userId: loggedInUserId,
        price,
        productType,
        priceType,
        listId,
        priceId
      });

      client
        .put(`/user/insider/product`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setEditProductStatus', 'done')
        })
        .catch((error) => {
          console.error('error', error.response);
          if (error.response.status === 400 && error.response.data === 'Price did not change'){
            commit('setEditProductStatus', 'error')
            commit('setEditErrorMessage', 'New price is the same as the current price')
          } else {
            commit('setEditProductStatus', 'error')
          }
        });
    }
  },
};

// export the entire module
export { editProductStoreModule };
