<template>
  <div>
    Slydepost Privacy Policy
    <br />
    <br />
    Last updated 06/22/2023
    <br />
    <br />
    Welcome to Slydepost (the “Platform”). The Platform is provided and controlled by Slydepost LLC. (“Slydepost”, “we”
    or “us”). We are committed to protecting and respecting your privacy.
    <br />
    <br />
    This privacy policy (“Privacy Policy” or “Policy”) covers the experience we provide for users age 18 and over on our
    Platform, and in email, text, and other electronic messages between you and this Platform. Please see below for our
    policy regarding children under 18.
    <br />
    <br />
    Please read this Policy carefully to understand our policies and practices regarding your information and how we
    will treat it. If you do not agree with our policies, your choice is not to use our Platform. By accessing or using
    this website, you agree to this privacy policy. Capitalized terms that are not defined in this policy have the
    meaning given to them in the Terms of Service.
    <br />
    <br />
    What information do we collect?
    <br />
    <br />
    We collect information when you create an account or use the Platform. We may also collect information you share
    with us from third-party social network providers, and technical and behavioral information about your use of the
    Platform. We also collect information contained in the messages you send through our Platform. More information
    about the categories and sources of information is provided below.
    <br />
    <br />
    Information you choose to provide
    <br />
    <br />
    For certain activities, such as when you register, upload content to the Platform, or contact us directly, you may
    provide some or all of the following information:
    <ul class="list-disc list-outside p-4">
      <li>Registration information, such as age, username and password, language, and email or phone number</li>
      <li>Profile information, such as name, social media account information, and profile image</li>
      <li>
        User-generated content, including comments, photographs, audio recordings, and videos, that you choose to create
        with or upload to the Platform (“User Content”). We collect User Content through pre-loading at the time of
        creation, import, or upload, regardless of whether you choose to save or upload that User Content.
      </li>
      <li>
        Content, including text, images, and video, found in your device’s clipboard, with your permission. For example,
        if you choose to initiate content sharing with a third-party platform, or choose to paste content from the
        clipboard Slydepost, we access this information stored in your clipboard in order to fulfill your request.
      </li>
      <li>
        Payment information, including payment card numbers or other third-party payment information (such as Stripe)
        where required for the purpose of payment
      </li>
      <li>
        In the future Slydepost may access your phone and social network contacts, with your permission. If you choose
        to find other users through your phone contacts, we will access and collect the names and phone numbers and
        match that information against existing users of the Platform. If you choose to find other users through your
        social network contacts, we will collect your public profile information as well as names and profiles of your
        social network contacts
      </li>
      <li>Your opt-in choices and communication preferences</li>
      <li>Information to verify an account such as proof of identity or age</li>
      <li>Information in correspondence you send to us</li>
      <li>
        Information you share through surveys or your participation in challenges, sweepstakes, or contests such as your
        gender, age, likeness, and preferences.
      </li>
    </ul>
    Information we obtain from other sources
    <br />
    <br />
    We may receive the information described in this Privacy Policy from other sources, such as:
    <ul class="list-disc list-outside p-4">
      <li>
        Social Media and Login Services. If you choose to link or sign up using a third-party social network or login
        service (such as Facebook, Twitter, Instagram, or Google), we may collect information from these services,
        including your contact lists for these services and information relating to your use of the Platform in relation
        to these services. If you link your Slydepost account to another service, we may receive information about your
        use of that service.
      </li>
      <li>
        Third-Party Services. We may collect information about you from third-party services, such as advertising
        partners, data providers, and analytics providers.
      </li>
      <li>
        Other Users of the Platform. Sometimes other users of the Platform may provide us information about you,
        including through customer service inquiries.
      </li>
      <li>Other Sources. We may collect information about you from other publicly available sources.</li>
    </ul>
    Information we collect automatically
    <br />
    <br />
    We automatically collect certain information from you when you use the Platform, including internet or other network
    activity information such as your IP address, geolocation-related data (as described below), unique device
    identifiers, browsing and search history (including content you have viewed in the Platform), and Cookies (as
    defined below).
    <br />
    <br />
    Usage Information
    <br />
    <br />
    We collect information regarding your use of the Platform and any other User Content that you generate through or
    upload to our Platform.
    <br />
    <br />
    Device Information
    <br />
    <br />
    We collect certain information about the device you use to access the Platform, such as your IP address, user agent,
    mobile carrier, time zone settings, identifiers for advertising purposes, model of your device, the device system,
    network type, device IDs, your screen resolution and operating system, app and file names and types, keystroke
    patterns or rhythms, battery state, audio settings and connected audio devices. Where you log-in from multiple
    devices, we will be able to use your profile information to identify your activity across devices. We may also
    associate you with information collected from devices other than those you use to log-in to the Platform.
    <br />
    <br />
    Location data
    <br />
    <br />
    We collect information about your approximate location, including location information based on your IP address.
    With your permission, we may also collect precise location data.
    <br />
    <br />
    Image and Audio Information
    <br />
    <br />
    We may collect information about the images and audio that are a part of your User Content, such as identifying the
    objects and scenery that appear, the existence and location within an image of face and body features and
    attributes, the nature of the audio, and the text of the words spoken in your User Content. We may collect this
    information to enable special video effects, for content moderation, for demographic classification, for content and
    ad recommendations, and for other non-personally-identifying operations. We may collect biometric identifiers and
    biometric information as defined under US laws, such as faceprints and voiceprints, from your User Content. Where
    required by law, we will seek any required permissions from you prior to any such collection.
    <br />
    <br />
    Messages
    <br />
    <br />
    We collect and process, which includes scanning and analyzing, information you provide when you compose, send, or
    receive messages through the Platform’s messaging functionality. That information includes the content of the
    message and information about when the message has been sent, received and/or read, as well as the participants of
    the communication. Please be aware that messages sent to other users of the Platform will be accessible by those
    users and that we are not responsible for the manner in which those users use or disclose messages.
    <br />
    <br />
    Metadata
    <br />
    <br />
    When you upload or create User Content, you automatically upload certain metadata that is connected to the User
    Content. Metadata describes other data and provides information about your User Content that will not always be
    evident to the viewer. In connection with your User Content the metadata can describe how, when, where, and by whom
    the piece of User Content was created, collected, or modified and how that content is formatted. It also includes
    information, such as your account name, that enables other users to trace back the User Content to your user
    account. Additionally, metadata includes data that you choose to provide with your User Content, e.g. any attributes
    used to mark keywords to the video and captions.
    <br />
    <br />
    Cookies
    <br />
    <br />
    We and our service providers and business partners use cookies and other similar technologies (e.g. web beacons,
    flash cookies, etc.) (“Cookies”) to automatically collect information, measure and analyze which web pages and
    advertisements you click on and how you use the Platform, enhance your experience using the Platform, improve the
    Platform, provide you with advertising on the Platform and elsewhere across your devices, and measure the
    effectiveness of advertisements. Cookies enable the Platform to provide certain features and functionality. Web
    beacons are very small images or small pieces of data embedded in images, also known as “pixel tags” or “clear
    GIFs,” that can recognize Cookies, the time and date a page is viewed, a description of the page where the pixel tag
    is placed, and similar information from your computer or device. To learn how to disable Cookies, see the “Your
    choices” section below.
    <br />
    <br />
    We and our service providers and business partners may link your contact or account information with your activity
    on and off our Platform across all your devices, using your email or other log-in or device information. Our service
    providers and business partners may use this information to display advertisements on our Platform and elsewhere
    online and across your devices tailored to your interests, preferences, and characteristics. We are not responsible
    for the privacy practices of these service providers and business partners, and the information practices of these
    service providers and business partners are not covered by this Privacy Policy.
    <br />
    <br />
    We may aggregate or de-identify the information described above. See below for our policy regarding our use of
    aggregated information.
    <br />
    <br />
    How we use your information
    <br />
    <br />
    As explained below, we use your information to improve, support and administer the Platform, to allow you to use its
    functionalities, and to fulfill and enforce our Terms of Service. We may also use your information to, among other
    things, show you suggestions, promote the Platform, and customize your ad experience.
    <br />
    <br />
    We generally use the information we collect:
    <ul class="list-disc list-outside p-4">
      <li>
        To fulfill requests for products, services, Platform functionality, support and information for internal
        operations, including troubleshooting, data analysis, testing, research, statistical, and survey purposes and to
        solicit your feedback
      </li>
      <li>
        To customize the content you see when you use the Platform. For example, we may provide you with services based
        on the settings you have chosen or show you content that is similar to content that you like or interacted with
      </li>
      <li>To send promotional materials from us or on behalf of our affiliates and trusted third parties</li>
      <li>To improve and develop our Platform and conduct product development</li>
      <li>
        To measure and understand the effectiveness of the advertising we serve to you and others and to deliver
        advertising
      </li>
      <li>To make suggestions and provide a customized ad experience</li>
      <li>
        To support the social functions of the Platform, including to permit you and other users to connect with each
        other through the Platform and for you and other users to share, download, and otherwise interact with User
        Content posted through the Platform
      </li>
      <li>To use User Content as part of our advertising and marketing campaigns to promote the Platform</li>
      <li>To understand how you use the Platform, including across your devices</li>
      <li>To infer additional information about you, such as your age, gender, and interests</li>
      <li>To help us detect abuse, fraud, and illegal activity on the Platform</li>
      <li>
        To prove your identity in order to use certain features, such as verified accounts, or when you apply for a Pro
        Account, ensure that you are old enough to use the Platform (as required by law), or in other instances where
        verification may be required
      </li>
      <li>To communicate with you, including to notify you about changes in our services</li>
      <li>
        To announce you as a winner of our contest, sweepstakes, or promotions if permitted by the promotion rule, and
        to send you any applicable prizes
      </li>
      <li>To enforce our terms, conditions, and policies</li>
      <li>
        Consistent with your permissions, to provide you with location-based services, such as advertising and other
        personalized content
      </li>
      <li>To inform our algorithms</li>
      <li>To combine all the information we collect or receive about you for any of the foregoing purposes</li>
      <li>
        For any other purposes disclosed to you at the time we collect your information or pursuant to your consent.
      </li>
      <li>To facilitate sales, promotion, and purchases of goods and services and to provide user support</li>
    </ul>
    How we share your information
    <br />
    <br />
    We are committed to maintaining your trust and Slydepost intends to support its services based on monthly
    subscriptions from our paying users. If you are a paying Slydepost user, depending on your subscription details, you
    will not see advertisements or only see advertisements in very specific situations. Although, Slydepost does not
    sell personal information to third parties, we want you to understand when and with whom we may share the
    information we collect for business purposes.
    <br />
    <br />
    Aggregated Information
    <br />
    <br />
    We may disclose aggregated information about our users, and information that does not identify any individual
    without restriction.
    <br />
    <br />
    Service Providers and Business Partners
    <br />
    <br />
    We may share the categories of personal information listed above with service providers and business partners to
    help us perform business operations and for business purposes, including research, payment processing and
    transaction fulfillment, database maintenance, administering contests and special offers, technology services,
    deliveries, sending communications, advertising, analytics, measurement, data storage and hosting, disaster
    recovery, search engine optimization, marketing, and data processing. These service providers and business partners
    may include:
    <ul class="list-disc list-outside p-4">
      <li>
        Payment processors and transaction fulfillment providers, who may receive the information you choose to provide,
        the information we obtain from other sources, and the information we collect automatically but who do not
        receive your message data.
      </li>
      <li>
        Customer and technical support providers, who may receive the information you choose to provide, the information
        we obtain from other sources, and the information we collect automatically.
      </li>
      <li>
        Research providers, who may receive the information you choose to provide, the information we obtain from other
        sources, and the information we collect automatically but would not receive your payment information or message
        data.
      </li>
      <li>
        Cloud providers, who may receive the information you choose to provide, the information we obtain from other
        sources, and the information we collect automatically.
      </li>
      <li>
        Advertising, marketing, and analytics vendors, who may receive the information you choose to provide, the
        information we obtain from other sources, and the information we collect automatically but would not receive
        your payment information or message data.
      </li>
    </ul>
    Within Our Corporate Group
    <br />
    <br />
    We may share all of the information we collect with a parent, subsidiary, or other affiliate of our corporate group.
    <br />
    <br />
    In Connection with a Sale, Merger, or Other Business Transfer
    <br />
    <br />
    We may share all of the information we collect to a buyer or other successor in connection with a substantial
    corporate transaction, such as the sale of a website, a merger, consolidation, asset sales, or in the event of
    bankruptcy.
    <br />
    <br />
    For Legal Reasons
    <br />
    <br />
    We may disclose any of the information we collect to respond to subpoenas, court orders, legal process, law
    enforcement requests, legal claims, or government inquiries, and to protect and defend the rights, interests,
    safety, and security of Slydepost LLC, the Platform, our affiliates, users, or the public. We may also share any of
    the information we collect to enforce any terms applicable to the Platform, to exercise or defend any legal claims,
    and comply with any applicable law.
    <br />
    <br />
    With Your Consent
    <br />
    <br />
    We may share your information for other purposes pursuant to your consent or at your direction.
    <br />
    <br />
    If you access third-party services, such as Facebook, Google, or Twitter, to login to the Platform or to share
    information about your usage on the Platform with others, these third-party services may be able to collect
    information about you, including information about your activity on the Platform, and they may notify your
    connections on the third-party services about your use of the Platform, in accordance with their privacy policies.
    If you choose to allow a third-party service to access your account, we will share certain information about you
    with the third party. Depending on the permissions you grant, the third party may be able to obtain your account
    information and other information you choose to provide.
    <br />
    <br />
    If you choose to engage in public activities on the Platform, you should be aware that any information you share may
    be read, collected, or used by other users. You should use caution in disclosing personal information while using
    the Platform. We are not responsible for the information you choose to submit.
    <br />
    <br />
    Your Rights
    <br />
    <br />
    You may submit a request to access or delete the information we have collected about you by sending your request to
    us at the email or physical address provided in the Contact section at the bottom of this policy. You may be
    entitled, in accordance with applicable law, to submit a request through an authorized agent. To designate an
    authorized agent to exercise choices on your behalf, please provide evidence that you have given such agent power of
    attorney or that the agent otherwise has valid written authority to submit requests to exercise rights on your
    behalf. We will respond to your request consistent with applicable law and subject to proper verification. We will
    verify your request by asking you to send it from the email address associated with your account or to provide
    information necessary to verify your account. We do not discriminate based on the exercise of any privacy rights
    that you might have.
    <br />
    <br />
    Rights Specific to States
    <br />
    <br />
    Sharing for Direct Marketing Purposes (Shine the Light)
    <br />
    <br />
    If you are a California resident, once a calendar year, you may be entitled to obtain information about personal
    information that we shared, if any, with other businesses for their own direct marketing uses. If applicable, this
    information would include the categories of customer information, as well as the names and addresses of those
    businesses with which we shared customer information for the immediately prior calendar year. To submit a request,
    contact us at: info@slydepost.com
    <br />
    <br />
    Content Removal for Users Under 18
    <br />
    <br />
    Users of the Platform who are California residents and are under 18 years of age may request and obtain removal of
    User Content they posted by contacting us at: info@slydepost.com. All requests must be labeled “CALIFORNIA REMOVAL
    REQUEST” on the email subject line. All requests must provide a description of the user Content you want removed and
    information reasonably sufficient to permit us to locate that User Content. We do not accept California Removal
    Requests via postal mail, telephone, or facsimile. We are not responsible for notices that are not labeled or sent
    properly, and we may not be able to respond if you do not provide adequate information. Please note that your
    request does not ensure complete or comprehensive removal of the material. For example, materials that you have
    posted may be republished or reposted by another user or third party.
    <br />
    <br />
    Colorado, Connecticut, Virginia, and Utah each provide their state residents with rights to:
    <ul class="list-disc list-outside p-4">
      <li>Confirm whether we process their personal information.</li>
      <li>Access and delete certain personal information.</li>
      <li>Data portability.</li>
      <li>Opt-out of personal data processing for targeted advertising and sales.</li>
    </ul>
    Colorado, Connecticut, and Virginia also provide their state residents with rights to:
    <ul class="list-disc list-outside p-4">
      <li>
        Correct inaccuracies in their personal information, taking into account the information's nature processing
        purpose.
      </li>
      <li>Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.</li>
    </ul>
    To exercise any of these rights, please contact us at: info@slydepost.com.
    <br />
    <br />
    The metrics for requests to access and requests to delete received by Slydepost during the previous calendar year
    can be found here.
    <br />
    <br />
    Your Choices
    <br />
    <br />
    You may be able to control some of the information we collect by adjusting your browser settings to refuse or
    disable Cookies. Because each browser is different, please consult the instructions provided by your browser. Please
    note that you may need to take additional steps to refuse or disable certain types of Cookies. In addition, your
    choice to disable cookies is specific to the particular browser or device that you are using when you disable
    cookies, so you may need to separately disable cookies for each type of browser or device. If you choose to refuse,
    disable, or delete Cookies, some of the functionality of the Platform may no longer be available to you. Without
    this information, we are not able to provide you with all the requested services.
    <br />
    <br />
    Depending on the version of the Platform you are using, you may be able to decide whether you see certain types of
    interest-based advertising on the Platform by visiting your in-app settings.
    <br />
    <br />
    Your device may have controls that determine what information we collect or how we can use that information. For
    example, you can control whether we can use your mobile advertising identifier for advertising through settings on
    your Apple and Android devices.
    <br />
    <br />
    You can opt out of marketing or advertising emails by using the “unsubscribe” link or mechanism noted in marketing
    or advertising emails.
    <br />
    <br />
    If you previously chose to share precise location information, you can prevent your device from sharing precise
    location information (e.g. GPS location information) with the Platform at any time through your device’s operating
    system settings.
    <br />
    <br />
    If you have registered for an account, you may access, review, and update certain personal information that you have
    provided to us by logging into your account and using available features and functionalities.
    <br />
    <br />
    Some browsers transmit “do-not-track” signals to websites. Because of differences in how browsers incorporate and
    activate this feature, we currently do not take action in response to these signals.
    <br />
    <br />
    Security and Storage
    <br />
    <br />
    We use reasonable measures to help protect information from loss, theft, misuse and unauthorized access, disclosure,
    alteration, and destruction. You should understand that no data storage system or transmission of data over the
    Internet or any other public network can be guaranteed to be 100 percent secure. Please note that information
    collected by third parties may not have the same security protections as information you submit to us, and we are
    not responsible for protecting the security of such information.
    <br />
    <br />
    Slydepost may transmit your data to its servers or data centers in the United States for storage and/or processing.
    Third parties with whom Slydepost may share your data as described herein may be located outside of the United
    States.
    <br />
    <br />
    Children
    <br />
    <br />
    The privacy of users under the age of 18 (“Younger Users”) is important to us.
    <br />
    <br />
    The Platform otherwise is not directed at children under the age of 18. No one under 18 may provide any personal
    information to or on the Platform. We do not knowingly collect any personal information from children under 18. If
    you are under 18, do not use or provide any information on this Platform or through any of its features. If we
    become aware that personal information has been collected on the Platform from a person under the age of 18 we will
    delete this information and terminate the person’s account. If you believe that we have collected information from a
    child under the age of 18 on the Platform, contact us at: info@slydepost.com
    <br />
    <br />
    Changes
    <br />
    <br />
    We may update this Privacy Policy from time to time. When we update the Privacy Policy, we will notify you by
    updating the “Last Updated” date at the top of this policy and posting the new Privacy Policy and providing any
    other notice required by applicable law. We recommend that you review the Privacy Policy each time you visit the
    Platform to stay informed of our privacy practices.
    <br />
    <br />
    Contact
    <br />
    <br />
    Questions, comments and requests regarding this policy should be addressed to:
    <br />
    <br />
    Mailing Address:
    <br />
    <br />
    Slydepost LLC, Attn: Slydepost Legal Department
    <br />
    <br />
    P.O. Box 773, Bellevue, WA 98009-0773
    <br />
    <br />
    Contact us: info@slydepost.com
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>
