import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const refinedSearchStoreModule = {
  namespaced: true,
  state: {
    refinedKeywords: '',
    refinedScoreRange: [0, 10],
    refinedCategories: [],
    // date range should be an array with 1-2 elements, starting and end date
    // end date may be null if they only pick a single date in which case the range would just be a single day
    refinedDateRange: [],
    refinedAspects: [],
    refinedLocation: '',
    searchResults: [],
    totalSearchResults: 0,
    isSearchInProgress: false,
    filterContentType: 'all',
    isLimitSlydepostsShown: true,
    isLimitPeopleShown: true,
    isLimitListsShown: true,
    isSlydepostResultsFound: true,
  },
  getters: {
    getIsSlydepostResultsFound(state) {
      return state.isSlydepostResultsFound;
    },
    getIsLimitSlydepostsShown(state) {
      return state.isLimitSlydepostsShown;
    },
    getIsLimitPeopleShown(state) {
      return state.isLimitPeopleShown;
    },
    getIsLimitListsShown(state) {
      return state.isLimitListsShown;
    },
    getFilterContentType(state) {
      return state.filterContentType;
    },
    getIsSearchInProgress(state) {
      return state.isSearchInProgress;
    },
    getTotalSearchResults(state) {
      return state.totalSearchResults;
    },
    getSearchResults(state) {
      return state.searchResults;
    },
    getRefinedLocation(state) {
      return state.refinedLocation;
    },
    getRefinedAspects(state) {
      return state.refinedAspects;
    },
    getRefinedDateRange(state) {
      return state.refinedDateRange;
    },
    getRefinedKeywords(state) {
      return state.refinedKeywords;
    },
    getRefinedScoreRange(state) {
      return state.refinedScoreRange;
    },
    getRefinedCategories(state) {
      return state.refinedCategories;
    },
  },
  mutations: {
    setIsSlydepostResultsFound(state, isFound) {
      state.isSlydepostResultsFound = isFound;
    },
    setIsLimitSlydepostsShown(state, value) {
      state.isLimitSlydepostsShown = value;
    },
    setIsLimitPeopleShown(state, value) {
      state.isLimitPeopleShown = value;
    },
    setIsLimitListsShown(state, value) {
      state.isLimitListsShown = value;
    },
    setFilterContentType(state, value) {
      state.filterContentType = value;
    },
    setIsSearchInProgress(state, value) {
      state.isSearchInProgress = value;
    },
    setTotalSearchResults(state, total) {
      state.totalSearchResults = total;
    },
    setSearchResults(state, results) {
      if (results.length === 0) {
        state.searchResults = [];
      }
      for (let i = 0; i < results.length; i++) {
        state.searchResults.push(results[i]._source);
      }
    },
    setRefinedLocation(state, location) {
      state.refinedLocation = location;
    },
    setRefinedAspects(state, aspects) {
      state.refinedAspects = aspects;
    },
    setRefinedDateRange(state, range) {
      state.refinedDateRange = range;
    },
    setRefinedKeywords(state, keywords) {
      state.refinedKeywords = keywords;
    },
    setRefinedScoreRange(state, range) {
      state.refinedScoreRange = range;
    },
    setRefinedCategories(state, categories) {
      state.refinedCategories = categories;
    },
    resetValues(state) {
      state.refinedLocation = '';
      state.refinedAspects = [];
      state.refinedDateRange = [];
      state.refinedKeywords = '';
      state.refinedScoreRange = [0, 10];
      state.refinedCategories = [];
    },
    addToSearchResults(state, payload) {
      state.searchResults.push(payload);
    },
  },
  actions: {
    findRefinedResults({ dispatch, commit, getters, rootGetters }) {
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      commit('setIsSearchInProgress', true);
      commit('setSearchResults', []);
      let payload = {};
      payload['startingFrom'] = 0;
      payload['index'] = 'post';
      payload['refinements'] = {};
      if (getters.getRefinedKeywords != null && getters.getRefinedKeywords !== '') {
        payload['text'] = getters.getRefinedKeywords;
      }
      if (getters.getRefinedCategories.length > 0) {
        let categories = [];
        let subcategories = [];
        for (let i = 0; i < getters.getRefinedCategories.length; i++) {
          if (getters.getRefinedCategories[i].includes(' > ')) {
            const category = getters.getRefinedCategories[i].split(' > ')[0];
            const subcategory = getters.getRefinedCategories[i].split(' > ')[1];
            categories.push(category);
            subcategories.push(subcategory);
          } else {
            categories.push(getters.getRefinedCategories[i]);
          }
        }
        payload['refinements']['categories'] = categories;
        payload['refinements']['subcategories'] = subcategories;
      }
      if (getters.getRefinedAspects.length > 0) {
        payload['refinements']['aspects'] = getters.getRefinedAspects;
      }
      if (getters.getRefinedDateRange.length === 1) {
        payload['refinements']['dateStart'] = getters.getRefinedDateRange[0];
      } else if (getters.getRefinedDateRange.length === 2) {
        payload['refinements']['dateStart'] = getters.getRefinedDateRange[0];
        payload['refinements']['dateEnd'] = getters.getRefinedDateRange[1];
      }
      if (getters.getRefinedLocation != null && getters.getRefinedLocation !== '') {
        payload['refinements']['address'] = getters.getRefinedLocation;
      }

      payload['refinements']['scoreStart'] = getters.getRefinedScoreRange[0];
      payload['refinements']['scoreEnd'] = getters.getRefinedScoreRange[1];

      const jsonBody = JSON.stringify(payload);
      client
        .post(`/search/refined/`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          // commit('refinedSearchStoreModule/setSearchResults', response.data.hits.hits)
          commit('setSearchResults', response.data.hits.hits);
          commit('setTotalSearchResults', response.data.hits.total.value);
          commit('setIsSearchInProgress', false);
        })
        .catch((res) => {
          console.error(res);
          commit('setIsSearchInProgress', false);
        });
    },
    findMoreResults({ dispatch, commit, getters, rootGetters }) {
      if (getters.getIsSearchInProgress === true) {
        return;
      }
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      commit('setIsSearchInProgress', true);
      let payload = {};
      payload['startingFrom'] = getters.getSearchResults.length;
      payload['index'] = 'post';
      payload['refinements'] = {};
      if (getters.getRefinedKeywords != null && getters.getRefinedKeywords !== '') {
        payload['text'] = getters.getRefinedKeywords;
      }
      if (getters.getRefinedCategories.length > 0) {
        let categories = [];
        let subcategories = [];
        for (let i = 0; i < getters.getRefinedCategories.length; i++) {
          if (getters.getRefinedCategories[i].includes(' > ')) {
            const category = getters.getRefinedCategories[i].split(' > ')[0];
            const subcategory = getters.getRefinedCategories[i].split(' > ')[1];
            categories.push(category);
            subcategories.push(subcategory);
          } else {
            categories.push(getters.getRefinedCategories[i]);
          }
        }
        payload['refinements']['categories'] = categories;
        payload['refinements']['subcategories'] = subcategories;
      }
      if (getters.getRefinedAspects.length > 0) {
        payload['refinements']['aspects'] = getters.getRefinedAspects;
      }
      if (getters.getRefinedDateRange.length === 1) {
        payload['refinements']['dateStart'] = getters.getRefinedDateRange[0];
      } else if (getters.getRefinedDateRange.length === 2) {
        payload['refinements']['dateStart'] = getters.getRefinedDateRange[0];
        payload['refinements']['dateEnd'] = getters.getRefinedDateRange[1];
      }
      if (getters.getRefinedLocation != null && getters.getRefinedLocation !== '') {
        payload['refinements']['address'] = getters.getRefinedLocation;
      }

      payload['refinements']['scoreStart'] = getters.getRefinedScoreRange[0];
      payload['refinements']['scoreEnd'] = getters.getRefinedScoreRange[1];

      const jsonBody = JSON.stringify(payload);
      client
        .post(`/search/refined/`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.hits.hits.length; i++) {
            commit('addToSearchResults', response.data.hits.hits[i]._source);
          }
          commit('setIsSearchInProgress', false);
        })
        .catch((res) => {
          console.error(res);
          commit('setIsSearchInProgress', false);
        });
    },
  },
};

// export the entire module
export { refinedSearchStoreModule };
